import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const deleteTimeType = (
    token: string,
    backend: string,
    id: string
) => {
    return fetch(
        `${getApiUrl(backend)}timeTypes/${id}`,
        {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default deleteTimeType;