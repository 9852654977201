import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const deleteOfferLibraryTextBlock = (
    token: string,
    backend: string,
    id
) => {
    return fetch(
        `${getApiUrl(backend)}offer_library_text_blocks/${id}`,
        {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        }
    );
};

export default deleteOfferLibraryTextBlock;
