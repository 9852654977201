import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";


const postUnit = (
    token: string,
    backend: string,
    name: string,
    description: string,
    created_by_id: string,
    changed_by_id: string,  
) => {

    return fetch(
        `${getApiUrl(backend)}units`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name,
                description,
                created_by_id,
                changed_by_id,


            })
        }
    );
};

export default postUnit;