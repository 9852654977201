import getApiUrl from "../utils/apiUrl"
import fetch from "../utils/mgFetch";

const postJsonFileSave = (
    token: string,
    backend: string,
    data: any,
) => {

    return fetch(`${getApiUrl(backend)}saveJsonFile`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
};

export default postJsonFileSave;
