import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getEmployeeCount = (token: string, backend: string, search: string) => {
    return fetch(`${getApiUrl(backend)}employees_count?query=${search}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getEmployeeCount