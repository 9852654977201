import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";
import moment from "moment"

const postDocument = (
    backend: string,
    token: string,
    Title: string,
    Text: string,
    MIMEtype: string,
    ReadAccessLevel: string,
    EditAccessLevel: string,
    document: File,
    CreatedByID: string,
    key: string,
    id: string,
    CategoryID?: string,
) => {
    const requestData = new FormData();
    requestData.append('document', document);
    requestData.append('MIMEtype', MIMEtype);
    if (CategoryID) {
        requestData.append('CategoryID', CategoryID.toString());
    }
    requestData.append(key, id.toString());
    requestData.append('CreatedByID', CreatedByID);
    requestData.append('ChangedByID', CreatedByID);
    requestData.append('ReadAccessLevel', ReadAccessLevel);
    requestData.append('EditAccessLevel', EditAccessLevel);
    requestData.append('Title', Title);
    requestData.append('Text', Text);
    requestData.append('Date', moment().format('YYYY-MM-DD'));

    return fetch(
        `${getApiUrl(backend)}create_document`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: requestData
        }
    );
};

export default postDocument;
