//Serviceworker is only used so chrome browser will accept the app as a progressive web app,
//This is needded for the banner on the phone where you get the option to add the web app to the homescreen with an icon shortcut

//Not supported in firefox, firefox cant register/update serviceworker if firefox settings for delete cookies and site data is closed
//https://bugzilla.mozilla.org/show_bug.cgi?id=1413615

export function register() {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', function() {
        navigator.serviceWorker.register('/custom-service-worker.js');
    });
  } else {
      console.log("Browser does not support service workers")
  }
}