import React, { useState } from "react"

import styled from "styled-components"
import { useSelector } from "react-redux"
import useDispatch from "../utils/useDispatch"
import { Button, ControlLabel, FormControl, Modal } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { removeDocument, updateDocument, loadDocumentCategories } from "../store/actions/app"

const AccessLevelPickerContainer = styled.div`
    display: flex;
`

const AccessLevelPickerColumn = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const EditDocumentButton = ({showAccessLevels = true, disabled = false, document, onEditDocument}) => {
    const [title, setTitle] = useState(document.Title)
    const [text, setText] = useState(document.Text)
    const [readAccessLevel, setReadAccessLevel] = useState(document.ReadAccessLevel)
    const [editAccessLevel, setEditAccessLevel] = useState(document.EditAccessLevel)
    const [modalOpen, setModalOpen] = useState(false)
    const [categoryId, setCategoryId] = useState(document.CategoryID ? document.CategoryID : undefined)

    const documentCategories = useSelector(state => state.app.documentCategories)

    const [t, i18e] = useTranslation()

    const dispatch = useDispatch()
    const handleSave = () => {
        dispatch(updateDocument(document.id, title, text, readAccessLevel, editAccessLevel, categoryId))
        .then(() => {
            setModalOpen(false)
            onEditDocument()
        });
    }

    const handleDelete = () => {
        if(window.confirm(t("AreYouSureDeleteMessage"))) {
            dispatch(removeDocument(document.id))
            .then(() => {
                setModalOpen(false)
                onEditDocument()
            });
        }
    }

    return <>
        <Button disabled={disabled} bsSize="xs" onClick={()=>{
            setModalOpen(true)
            dispatch(loadDocumentCategories())}}><i className="fa fa-edit"/></Button>
        <Modal show={modalOpen} onHide={()=>{setModalOpen(false)}}>
            <Modal.Header>
                <h4>{t("EditDocument")}</h4>
            </Modal.Header>
            <Modal.Body>
                <ControlLabel>{t("Title")}</ControlLabel>
                <FormControl placeholder={t("Title")} value={title} onChange={(e:any)=>{setTitle(e.target.value)}}/>
                <br></br>
                <ControlLabel>{t("Text")}</ControlLabel>
                <FormControl style={{resize: "vertical"}} componentClass="textarea" placeholder={t("Text")} value={text} onChange={(e:any)=>{setText(e.target.value)}}/>
                <br></br>
                <ControlLabel>{t("Category")}</ControlLabel>
                <FormControl componentClass="select" value={categoryId} onChange={(e: any) => { setCategoryId(+e.target.value) }}>
                    <option value={undefined}>-- {t("PickHere")} --</option>
                    {documentCategories && documentCategories.map(documentCategory => <option key={documentCategory.id} value={documentCategory.id}>
                        {documentCategory.Name}
                    </option>)}
                </FormControl>
                <br></br>
                {showAccessLevels && <AccessLevelPickerContainer>
                    <AccessLevelPickerColumn style={{alignItems: "flex-start"}}>
                        <div>
                            <b>{t("Access")}</b>
                        </div>
                        <div>
                            {t("CanRead")}
                        </div>
                        <div>
                            {t("CanEdit")}
                        </div>
                    </AccessLevelPickerColumn>
                    <AccessLevelPickerColumn>
                        <div>
                            <b>{t("All")}</b>
                        </div>
                        <div>
                            <input type="radio" checked={readAccessLevel === 0} onChange={(e: any) => {setReadAccessLevel(+e.target.value)}} name="readAccessLevel" value={0}/>
                        </div>
                        <div>
                            <input type="radio" checked={editAccessLevel === 0} onChange={(e: any) => {setEditAccessLevel(+e.target.value)}}  name="writeAccessLevel" value={0}/>
                        </div>
                    </AccessLevelPickerColumn>
                    <AccessLevelPickerColumn>
                        <div>
                            <b>{t("Admin")}</b>
                        </div>
                        <div>
                            <input type="radio" checked={readAccessLevel === 1} onChange={(e: any) => {setReadAccessLevel(+e.target.value)}}  name="readAccessLevel" value={1}/>
                        </div>
                        <div>
                            <input type="radio" checked={editAccessLevel === 1} onChange={(e: any) => {setEditAccessLevel(+e.target.value)}} name="writeAccessLevel" value={1}/>
                        </div>
                    </AccessLevelPickerColumn>
                </AccessLevelPickerContainer>}
            </Modal.Body>
            <Modal.Footer>
                <Button bsStyle="danger" onClick={handleDelete}>{t("Delete")}</Button>
                <Button onClick={()=>{ setModalOpen(false) }}>{t("Cancel")}</Button>
                <Button bsStyle="primary" onClick={handleSave}>{t("Save")}</Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default EditDocumentButton