export default `<ul class="nav navbar-top-links navbar-right">
    <!-- /.dropdown -->

    <li class="dropdown user-menu" style="margin-right: 20px;">
        <a class="dropdown-toggle" data-toggle="dropdown">
            <i class="fa fa-user fa-2x"></i> <i class="fa fa-caret-down"></i>
            <i
                id="user-green-lock"
                style="color:limegreen"
                class="fa fa-lock fa-fw"
            ></i>
        </a>
        <ul class="dropdown-menu dropdown-user">
            <li>
                <a id="dropdown-menu-color" ng-click="navigateTo('/user')"
                    ><i class="fa fa-user fa-fw dropdown-menu-color"></i>
                    Användarprofil</a
                >
            </li>
            <li class="divider"></li>
            <li ng-if="useAttests">
                <a id="dropdown-menu-color" ng-click="navigateTo('/attest')"
                    ><i class="fa fa-clock-o fa-fw"></i> Attestering</a
                >
            </li>
            <li>
                <a id="dropdown-menu-color" ng-click="navigateTo('/forms')"
                    ><i
                    class="fa fa-file-o fa-fw"
                ></i> Formulär</a
                >
            </li>
            <li>
                <a id="dropdown-menu-color" ng-click="navigateTo('/projects')"
                    ><i
                    class="fa fa-briefcase fa-fw"
                ></i> Aktiva projekt</a
                >
            </li>
            <li class="divider" ng-if="useAttests"></li>
            <li>
                <a href="#" ng-click="toggle('add', 0)"
                    ><i
                        class="fa fa-plus-circle fa-fw dropdown-menu-color-add"
                    ></i>
                    Skapa kund</a
                >
            </li>
            <li>
                <a ng-click="addCustomerProject('add')"
                    ><i
                        class="fa fa-plus-circle fa-fw dropdown-menu-color-add"
                    ></i>
                    Skapa projekt</a
                >
            </li>
            <!--dashboard.timereports-->
            <li class="divider" ng-if="userRole !== 'user'"></li>
            <li ng-if="userRole !== 'user'">
                <a id="dropdown-menu-color" ng-click="goToUrl()"
                    ><i class="fa fa-desktop fa-fw"></i> Adminwebben</a
                >
            </li>
            <li>
                <a
                    id="dropdown-menu-color"
                    style="cursor: pointer;"
                    ng-click="showAlertLatestRelease()"
                    target="_self"
                    ><i class="fa fa-refresh fa-fw"></i> Release notes</a
                >
            </li>
            <li class="divider"></li>
            <li ui-sref-active="active" class="alpha-test">
                <a
                    href="mailto:info@mygizmo.se?&subject=MyGizmo%20Buggreport%20&body=Företag:%20%0D%0AAnställd:%20%0D%0A%0D%0ABeskriv%20buggen%20du%20upplever:%20%0D%0A%0D%0A%0D%0A"
                    target="_blank"
                    ><i class="fa fa-comments  fa-fw"></i> Support</a
                >
            </li>
            <li class="divider"></li>
            <li>
                <a href="" ng-click="signOut()"
                    ><i class="fa fa-sign-out fa-fw dropdown-menu-color"></i>
                    Logga ut</a
                >
            </li>
        </ul>
        <!-- /.dropdown-user -->
    </li>

    <!-- /.dropdown -->
</ul>`
