import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putProject = (
    token: string,
    backend: string,
    id: any,
    name: string,
    description: string,
    customer_id: number,
    start_date: string,
    end_date: string,
    internal_information: string,
    project_type: string,
    estimated_hours: number,
    fixed_price: number,
    project_leader: number,
    need_customer_signature: boolean,
    customer_object_id: number,
    has_external_project_leader: boolean,
    external_project_leader_name: string,
    property_code: string,
    created_by_id: number,
    erp_customer_id?: any,
    erp_project_id?: any,
    erp_order_number?: any,
    fortnox_document_number?: any,
    ShowProjectInMap?: string,
    PaymentPlanMaxTotalSafetyPercentage?: string,
    PaymentPlanSafetyPercentage?: string,
    UsePaymentPlan?: boolean,
    ShowPaymentPlanDueDate?: boolean,
    ShowPaymentPlanInvoiceDate?: boolean,
    sendConfirmationEmailTo?: string,
    onlyShowProjectForSelectedEmployees?: boolean,
) => {

    return fetch(
        `${getApiUrl(backend)}projects/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                project_name: name,
                description,
                customer_id,
                start_date,
                end_date,
                internal_information,
                project_type,
                estimated_hours,
                fixed_price,
                project_leader,
                need_customer_signature: need_customer_signature ? "true": "false",
                customer_object_id,
                has_external_project_leader : has_external_project_leader ? "true" : "false",
                external_project_leader_name,
                property_code,
                created_by_id,
                changed_by_id: created_by_id,
                erp_customer_id: erp_customer_id ? erp_customer_id : "",
                erp_project_id: erp_project_id ? erp_project_id : "",
                erp_order_number: erp_order_number ? erp_order_number : "",
                fortnox_document_number: fortnox_document_number,
                ShowProjectInMap,
                PaymentPlanMaxTotalSafetyPercentage,
                PaymentPlanSafetyPercentage,
                UsePaymentPlan,
                ShowPaymentPlanDueDate,
                ShowPaymentPlanInvoiceDate,
                sendConfirmationEmailTo,
                onlyShowProjectForSelectedEmployees,
            })
        }
    );
};

export default putProject;
