import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getDocuments = (token: string, backend: string, KeyID, id) => {
    return fetch(`${getApiUrl(backend)}get_documents/${KeyID}/${id}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export default getDocuments