import { type } from 'jquery';
import { convertTypeAcquisitionFromJson, isDoStatement } from 'typescript';
import PaginatedResponse from '../../types/PaginatedResponse';
import PaymentPlanElement from '../../types/PaymentPlanElement';
import ProjectVariation from '../../types/ProjectVariation';
import ProjectVariationCounts from '../../types/ProjectVariationCounts';
import ProjectVariationLinkResponse from '../../types/ProjectVariationLinkResponse';
import { types } from '../actions/app';

export type AppState = {
    token?: string,
    backend?: string,
    user: any,
    activeRequests: number,
    showInactiveAccountModal: boolean,
    accountActivationStatus: 'INITIAL' | 'SUCCESS' | 'FAILED',
    employee: any,
    projects: any,
    products: any,
    customers: any,
    latestProjectSearch: any,
    latestCustomerSearch: any,

    // Actually typed
    companyLogo?: string,

    // REPORTED TIMES
    creatingReportedTime?: boolean,
    updatingReportedTime?: boolean,
    deletingReportedTime?: boolean,

    // REPORTED PRODUCTS
    creatingReportedProduct?: boolean,
    updatingReportedProduct?: boolean,
    deletingReportedProduct?: boolean,

    // PROJECT VARIATIONS
    loadingProjectVariationCounts?: boolean,
    projectVariationCounts?: ProjectVariationCounts,
    projectVariation?: ProjectVariation,
    projectVariations?: Array<ProjectVariation>,
    loadingProjectVariations?: boolean,
    loadingProjectVariation?: boolean,
    creatingProjectVariation?: boolean,
    updatingProjectVariation?: boolean,
    deletingProjectVariation?: boolean,

    // PROJECT VARIATION LINKS
    creatingProjectVariationLink?: boolean,
    deletingProjectVariationLink?: boolean,
    sendingProjectVariationLinkAnswer?: boolean,
    loadingProjectVariationLinkResponse?: boolean,
    projectVariationLinkResponse?: ProjectVariationLinkResponse

    // PAYMENT PLAN ELEMENTS
    paymentPlanElements?: Array<PaymentPlanElement>
    loadingPaymentPlanElements?: boolean,
    creatingPaymentPlanElements?: boolean,
    updatingPaymentPlanElements?: boolean,
    removingPaymentPlanElements?: boolean,
}

const initialState: AppState = {
    token: undefined,
    backend: undefined,
    user: null,
    activeRequests: 0,
    showInactiveAccountModal: false,
    accountActivationStatus: 'INITIAL',
    employee: null,
    projects: {},
    products: {},
    customers: {},
    latestProjectSearch: "",
    latestCustomerSearch: ""
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.REQUEST_STARTED:
            return {
                ...state,
                activeRequests: state.activeRequests + 1,
            };
        case types.REQUEST_COMPLETED:
            return {
                ...state,
                activeRequests: state.activeRequests - 1,
            };
        case types.SIGN_IN_USER_SUCCESS_BUT_NEED_TO_PICK_BACKEND:
            return {
                ...state,
                ...action.payload,
            };
        case types.PICK_USER_CONNECTED_BACKEND: 
            return {
                ...state,
                backend: action.payload
            }
        case types.SIGN_IN_USER_SUCCESS:
            return {
                ...state,
                ...action.payload,
            };
        case types.LOAD_USER_DATA_SUCCESS:
            return {
                ...state,
                ...action.payload
            }
        case types.SIGN_OUT:
            return initialState;
        case types.LOAD_EMPLOYEE_BOOKINGS_COMPLETE:
            return {
                ...state,
                bookings: action.bookings,
                loadingBookings: false,
            };
        case types.LOAD_EMPLOYEE_BOOKINGS_BEGIN:
            return {
                ...state,
                loadingBookings: true,
            };
        case types.LOAD_REPORTS_BEGIN:
            return {
                ...state,
                [action.namespace + "loadingReports"]: true,
            };
        case types.LOAD_REPORTS_COMPLETE:
            return {
                ...state,
                [action.namespace + "reports"]: action.reports,
                [action.namespace + "reportsFilter"]: action.filter,
                [action.namespace + "loadingReports"]: false,
            };
        case types.LOAD_PROJECTS_BEGIN: 
            return {
                ...state,
                [action.namespace + "loadingProjects"]: true,
                latestProjectSearch: action.payload
            };
        case types.LOAD_PROJECTS_COMPLETE: 
            if(state.latestProjectSearch === action.search) {
                return {
                    ...state,
                    [action.namespace + "projects"]: action.projects,
                    [action.namespace + "loadingProjects"]: false
                };
            }
        case types.CREATE_INVOICE_IN_FORTNOX_BEGIN: {
            return {
                ...state,
                creatingInvoiceInFortnox: true
            }
        }
        case types.CREATE_INVOICE_IN_FORTNOX_SUCCESS: {
            return {
                ...state,
                creatingInvoiceInFortnox: false
            }
        }
        case types.CREATE_INVOICE_IN_EACCOUNTING_BEGIN: {
            return {
                ...state,
                creatingInvoiceInEaccounting: true
            }
        }
        case types.CREATE_INVOICE_IN_EACCOUNTING_SUCCESS: {
            return {
                ...state,
                creatingInvoiceInEaccounting: false
            }
        }
        case types.LOAD_EACCOUNTING_ORDERS_BEGIN: {
            return {
                ...state,
                loadingEaccountingOrders: true
            }
        }
        case types.LOAD_EACCOUNTING_ORDERS_SUCCESS: {
            return {
                ...state,
                loadingEaccountingOrders: false,
                eaccountingOrders: action.payload
            }
        }
        case types.LOAD_EACCOUNTING_CUSTOMERS_BEGIN: {
            return {
                ...state,
                loadingEaccountingCustomers: true
            }
        }
        case types.LOAD_EACCOUNTING_CUSTOMERS_SUCCESS: {
            return {
                ...state,
                loadingEaccountingCustomers: false,
                eaccountingCustomers: action.payload
            }
        }
        case types.LOAD_FORTNOX_ORDERS_BEGIN: {
            return {
                ...state,
                loadingFortnoxOrders: true
            }
        }
        case types.LOAD_FORTNOX_ORDERS_SUCCESS: {
            return {
                ...state,
                loadingFortnoxOrders: false,
                fortnoxOrders: action.payload
            }
        }
        case types.LOAD_FORTNOX_CUSTOMERS_BEGIN: {
            return {
                ...state,
                loadingFortnoxCustomers: true
            }
        }
        case types.LOAD_FORTNOX_CUSTOMERS_SUCCESS: {
            return {
                ...state,
                loadingFortnoxCustomers: false,
                fortnoxCustomers: action.payload
            }
        }
        case types.LOAD_REPORTED_ABSENCE_BEGIN: {
            return {
                ...state,
                loadingReportedAbsence: true
            }
        }
        case types.LOAD_REPORTED_ABSENCE_SUCCESS: {
            return {
                ...state,
                loadingReportedAbsence: false,
                reportedAbsence: action.payload
            }
        }
        case types.LOAD_PROJECT_BEGIN: 
            return {
                ...state,
                [action.namespace + "loadingProject"]: true,
            };
        case types.LOAD_PROJECT_COMPLETE: 
            return {
                ...state,
                [action.namespace + "project"]: action.project,
                [action.namespace + "loadingProject"]: false,
            };
        case types.CLEAR_DOCUMENTS:
            return {
                ...state,
                documents: undefined
            }
        case types.COPY_OFFER_TO_NEW_VERSION_BEGIN:
            return {
                ...state,
                copyingOfferToNewVersion: true
            }
        case types.COPY_OFFER_TO_NEW_VERSION_SUCCESS:
            return {
                ...state,
                copyingOfferToNewVersion: false
            }
        case types.LOAD_ACTIVE_TIME_TYPES_BEGIN:
            return {
                ...state,
                loadingActiveTimeTypes: true
            }
        case types.LOAD_ACTIVE_TIME_TYPES_COMPLETE:
            return {
                ...state,
                activeTimeTypes: action.timeTypes,
                loadingActiveTimeTypes: true
            }
        case types.LOAD_MORE_PROJECTS_BEGIN: 
            return {
                ...state,
                loadingMoreProjects: true
            };
        case types.LOAD_MORE_PROJECTS_COMPLETE: 
            return {
                ...state,
                [action.namespace + "projects"]: {
                    ...action.projects,
                    data: [...state[action.namespace + "projects"].data, ...action.projects.data]
                },
                loadingMoreProjects: false
            };
        case types.LOAD_MORE_CUSTOMERS_BEGIN: 
            return {
                ...state,
                loadingMoreCustomers: true
            };
        case types.LOAD_MORE_CUSTOMERS_COMPLETE: 
            return {
                ...state,
                customers: {
                    ...action.customers,
                    data: [...state.customers.data, ...action.customers.data]
                },
                loadingMoreCustomers: false
            };
        case types.LOAD_MY_RECENT_PROJECTS_BEGIN: 
            return {
                ...state,
                loadingMyRecentProjects: true
            };
        case types.LOAD_MY_RECENT_PROJECTS_COMPLETE: 
            return {
                ...state,
                myRecentProjects: action.myRecentProjects,
                loadingMyRecentProjects: false
            };
        case types.SHOW_INACTIVE_ACCOUNT_MODAL:
            return {
                ...state,
                showInactiveAccountModal: true,
            };
        case types.HIDE_INACTIVE_ACCOUNT_MODAL:
            return {
                ...state,
                showInactiveAccountModal: false,
            };
        case types.ACTIVATE_ACCOUNT_COMPLETED:
            return {
                ...state,
                accountActivationStatus: action.payload
            }
        case types.SEND_ACCESS_TOKEN_BEGIN: 
            return {
                ...state,
                sendingAccessToken: true
            }
        case types.SEND_ACCESS_TOKEN_SUCCESS: 
            return {
                ...state,
                sendingAccessToken: false,
                accessTokenSent: true
            }
        case types.CHECK_LOGIN_DETAILS_BEGIN:
                return {
                    ...state,
                    checkingLoginDetails: true
                }
        case types.CHECK_LOGIN_DETAILS_SUCCESS:
            return {
                ...state,
                checkingLoginDetails: false,
                ...action.payload
            }
        case types.CHECK_IF_EMAIL_EXISTS_BEGIN:
            return {
                ...state,
                checkingIfEmailExists: true,
                doesEmailExist: "",
                checkedEmail: ""
            }
        case types.CHECK_IF_EMAIL_EXISTS_SUCCESS:
            return {
                ...state,
                checkingIfEmailExists: false,
                ...action.payload
            }
        case types.CHECK_ACCESS_TOKEN_BEGIN:
            return {
                ...state,
                checkingAccessToken: true
            }
        case types.CHECK_ACCESS_TOKEN_SUCCESS:
            return {
                ...state,
                checkingAccessToken: false,
                ...action.payload
            }
        case types.CHECK_PASSWORD_RESET_BEGIN:
            return {
                ...state,
                checkingResetPasswordToken: true
            }
        case types.CHECK_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                checkingResetPasswordToken: false,
                ...action.payload
            }
        case types.SUBMIT_REGISTER_USER_DETAILS: 
            return {
                ...state,
                ...action.payload
            }
        case types.CREATE_USER_WITH_TOKEN_BEGIN:
                return {
                    ...state,
                    creatingUserWithToken: true
                }
        case types.CREATE_USER_WITH_TOKEN_SUCCESS:
            return {
                ...state,
                creatingUserWithToken: false
            }
        case types.CREATE_TENANT_PROSPECT_BEGIN:
            return {
                ...state,
                creatingTenantProspect: true
            }
        case types.CREATE_TENANT_PROSPECT_SUCCESS:
            return {
                ...state,
                creatingTenantProspect: false
            }
        case types.CREATE_REPORTED_TIME_BEGIN: 
            return {
                ...state,
                creatingReportedTime: true
            }
        case types.CREATE_REPORTED_TIME_COMPLETE: 
            return {
                ...state,
                creatingReportedTime: false
            }
        case types.UPDATE_REPORTED_TIME_BEGIN: 
            return {
                ...state,
                updatingReportedTime: true
            }
        case types.UPDATE_REPORTED_TIME_COMPLETE: 
            return {
                ...state,
                updatingReportedTime: false
            }
        case types.DELETE_REPORTED_TIME_BEGIN: 
            return {
                ...state,
                deletingReportedTime: true
            }
        case types.DELETE_REPORTED_TIME_COMPLETE: 
            return {
                ...state,
                deletingReportedTime: false
            }
        case types.DELETE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_BEGIN: 
            return {
                ...state,
                deletingProjectAccessEmployeeRelations: true
            }
        case types.DELETE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_SUCCESS: 
            return {
                ...state,
                deletingProjectAccessEmployeeRelations: false
            }
        case types.CREATE_REPORTED_PRODUCT_BEGIN: 
            return {
                ...state,
                creatingReportedProduct: true
            }
        case types.CREATE_REPORTED_PRODUCT_COMPLETE: 
            return {
                ...state,
                creatingReportedProduct: false
            }
        case types.UPDATE_REPORTED_PRODUCT_BEGIN: 
            return {
                ...state,
                updatingReportedProduct: true
            }
        case types.UPDATE_REPORTED_PRODUCT_COMPLETE: 
            return {
                ...state,
                updatingReportedProduct: false
            }
        case types.DELETE_REPORTED_PRODUCT_BEGIN: 
            return {
                ...state,
                deletingReportedProduct: true
            }
        case types.DELETE_REPORTED_PRODUCT_COMPLETE: 
            return {
                ...state,
                deletingReportedProduct: false
            }
        case types.LOAD_TASKS_BEGIN:
            return {
                ...state,
                loadingTasks: true
            }
        case types.LOAD_TASKS_COMPLETE:
            return {
                ...state,
                loadingTasks: false,
                tasks: action.payload
            }
        case types.CREATE_PROJECT_ELEMENT_BEGIN:
            return {
                ...state,
                creatingProjectElement: true
            }
        case types.CREATE_PROJECT_ELEMENT_SUCCESS:
            return {
                ...state,
                creatingProjectElement: false
            }
        case types.UPDATE_PROJECT_ELEMENT_BEGIN:
            return {
                ...state,
                updatingProjectElement: true
            }
        case types.UPDATE_PROJECT_ELEMENT_SUCCESS:
            return {
                ...state,
                updatingProjectElement: false
            }
        case types.REMOVE_PROJECT_ELEMENT_BEGIN:
            return {
                ...state,
                removingProjectElement: true
            }
        case types.REMOVE_PROJECT_ELEMENT_SUCCESS:
            return {
                ...state,
                removingProjectElement: false
            }
        case types.LOAD_BOOKABLE_RESOURCES_BEGIN:
            return {
                ...state,
                loadingBookableResources: true
            }
        case types.LOAD_BOOKABLE_RESOURCES_SUCCESS:
            return {
                ...state,
                loadingBookableResources: false,
                bookableResources: action.payload
            }
        case types.LOAD_BOOKABLE_VEHICLES_BEGIN:
            return {
                ...state,
                loadingBookableVehicles: true
            }
        case types.LOAD_BOOKABLE_VEHICLES_SUCCESS:
            return {
                ...state,
                loadingBookableVehicles: false,
                bookableVehicles: action.payload
            }
        case types.LOAD_BOOKINGS_BEGIN:
            return {
                ...state,
                loadingBookings: true
            }
        case types.LOAD_BOOKINGS_SUCCESS:
            return {
                ...state,
                loadingBookings: false,
                bookings: action.payload
            }
        case types.CREATE_BOOKING_BEGIN: 
            return {
                ...state,
                creatingBooking: true
            }
        case types.CREATE_BOOKING_SUCCESS: 
            return {
                ...state,
                creatingBooking: false
            }
        case types.CLEAR_BOOKING_PROJECT:
            return {
                ...state,
                bookingProject: undefined
            }
        case types.LOAD_BOOKING_PROJECT_BEGIN:
            return {
                ...state,
                loadingBookingProject: true
            }
        case types.LOAD_BOOKING_PROJECT_COMPLETE:
            return {
                ...state,
                loadingBookingProject: false,
                bookingProject: action.payload
            }
        case types.UPDATE_BOOKING_BEGIN:
            return {
                ...state,
                updatingBooking: true
            }
        case types.UPDATE_BOOKING_SUCCESS:
            return {
                ...state,
                updatingBooking: false
            }
        case types.DELETE_BOOKING_BEGIN:
            return {
                ...state,
                deletingBooking: true
            }
        case types.DELETE_BOOKING_SUCCESS:
            return {
                ...state,
                deletingBooking: false
            }
        case types.CREATE_BOOKING_RESOURCE_BEGIN:
            return {
                ...state,
                creatingBookingResource: true
            }
        case types.CREATE_BOOKING_RESOURCE_SUCCESS:
            return {
                ...state,
                creatingBookingResource: false
            }
        case types.UPDATE_BOOKING_RESOURCE_BEGIN:
            return {
                ...state,
                updatingBookingResource: true
            }
        case types.UPDATE_BOOKING_RESOURCE_SUCCESS:
            return {
                ...state,
                updatingBookingResource: false
            }
        case types.DELETE_BOOKING_RESOURCE_BEGIN: 
            return {
                ...state,
                deletingBookingResource: true
            }
        case types.DELETE_BOOKING_RESOURCE_SUCCESS: 
            return {
                ...state,
                deletingBookingResource: false
            }
        case types.LOAD_PRODUCTS_BEGIN:
            return {
                ...state,
                loadingProducts: true
            }
        case types.LOAD_PRODUCTS_COMPLETE:
            return {
                ...state,
                loadingProducts: false,
                products: action.payload
            }
        case types.LOAD_MORE_PRODUCTS_BEGIN:
            return {
                ...state,
                loadingMoreProducts: true
            }
        case types.LOAD_MORE_PRODUCTS_COMPLETE:
            return {
                ...state,
                loadingMoreProducts: false,
                products: {
                    ...action.payload,
                    data: [...state.products.data, ...action.payload.data]
                },
            }
        case types.CREATE_PROJECT_ASSIGNMENT_BEGIN:
            return {
                ...state,
                creatingProjectAssignment: true
            }
        case types.CREATE_PROJECT_ASSIGNMENT_COMPLETE:
            return {
                ...state,
                creatingProjectAssignment: false
            }
        case types.CREATE_PROJECT_PRODUCT_ASSIGNMENT_BEGIN:
            return {
                ...state,
                creatingProjectProductAssignment: true
            }
        case types.CREATE_PROJECT_PRODUCT_ASSIGNMENT_COMPLETE:
            return {
                ...state,
                creatingProjectProductAssignment: false
            }
        case types.LOAD_PRODUCT_ASSIGNMENT_STATS_BEGIN: 
            return {
                ...state,
                loadingProductAssignmentStats: true
            }
        case types.LOAD_PRODUCT_ASSIGNMENT_STATS_COMPLETE: 
            return {
                ...state,
                loadingProductAssignmentStats: false,
                productAssignmentStats: action.payload
            }
        case types.CREATE_DOCUMENT_BEGIN:
            return {
                ...state,
                creatingDocument: true
            }
        case types.CREATE_DOCUMENT_COMPLETE:
            return {
                ...state,
                creatingDocument: false
            }
        case types.UPDATE_DOCUMENT_BEGIN:
            return {
                ...state,
                updatingDocument: true
            }
        case types.UPDATE_DOCUMENT_COMPLETE:
            return {
                ...state,
                updatingDocument: false
            }
        case types.DELETE_DOCUMENT_BEGIN:
            return {
                ...state,
                deletingDocument: true
            }
        case types.DELETE_DOCUMENT_COMPLETE:
            return {
                ...state,
                deletingDocument: false
            }
        case types.CREATE_NOTE_BEGIN:
            return {
                ...state,
                creatingNote: true
            }
        case types.CREATE_NOTE_COMPLETE:
            return {
                ...state,
                creatingNote: false
            }
        case types.UPDATE_NOTE_COMPLETE:
            return {
                ...state,
                updatingNote: true
            }
        case types.UPDATE_NOTE_COMPLETE:
            return {
                ...state,
                updatingNote: false
            }
        case types.DELETE_NOTE_BEGIN:
            return {
                ...state,
                deletingNote: true
            }
        case types.DELETE_NOTE_COMPLETE:
            return {
                ...state,
                deletingNote: false
            }
        case types.LOAD_DOCUMENT_CATEGORIES_BEGIN:
            return {
                ...state,
                loadingDocumentCategories: true
            }
        case types.LOAD_DOCUMENT_CATEGORIES_COMPLETE:
            return {
                ...state,
                loadingDocumentCategories: false,
                documentCategories: action.payload
            }
        case types.MARK_PROJECT_AS_DONE_BEGIN: 
            return {
                ...state,
                markingProjectAsDone: true
            }
        case types.MARK_PROJECT_AS_DONE_COMPLETE: 
            return {
                ...state,
                markingProjectAsDone: false
            }
        case types.MARK_PROJECT_AS_UNDONE_BEGIN: 
            return {
                ...state,
                markingProjectAsUndone: true
            }
        case types.MARK_PROJECT_AS_UNDONE_COMPLETE: 
            return {
                ...state,
                markingProjectAsUndone: false
            }
        case types.CREATE_REPORTED_ABSENCE_BEGIN: 
            return {
                ...state,
                creatingReportedAbsence: true
            }
        case types.CREATE_REPORTED_ABSENCE_COMPLETE: 
            return {
                ...state,
                creatingReportedAbsence: false
            }
        case types.UPDATE_REPORTED_ABSENCE_BEGIN: 
            return {
                ...state,
                updatingReportedAbsence: true
            }
        case types.UPDATE_REPORTED_ABSENCE_COMPLETE: 
            return {
                ...state,
                updatingReportedAbsence: false
            }
        case types.DELETE_REPORTED_ABSENCE_BEGIN: 
            return {
                ...state,
                deletingReportedAbsence: true
            }
        case types.DELETE_REPORTED_ABSENCE_COMPLETE: 
            return {
                ...state,
                deletingReportedAbsence: false
            }
        case types.LOAD_ACTIVE_ABSENCE_TYPES_BEGIN:
            return {
                ...state,
                loadingActiveAbsenceTypes: true
            }
        case types.LOAD_ACTIVE_ABSENCE_TYPES_COMPLETE:
            return {
                ...state,
                loadingActiveAbsenceTypes: false,
                activeAbsenceTypes: action.payload
            }
        case types.LOAD_SETTINGS_BEGIN: 
            return {
                ...state,
                loadingSettings: true
            }
        case types.LOAD_SETTINGS_COMPLETE:
            return {
                ...state,
                loadingSettings: false,
                settings: action.payload
            }
        case types.LOAD_PROJECT_FORMS_BEGIN:
            return {
                ...state,
                loadingProjectForms: true
            }
        case types.LOAD_PROJECT_FORMS_COMPLETE:
            return {
                ...state,
                loadingProjectForms: false,
                projectForms: action.payload
            }
        case types.LOAD_CUSTOMER_OBJECTS_BEGIN:
            return {
                ...state,
                loadingCustomerObjects: true
            }
        case types.LOAD_CUSTOMER_OBJECTS_COMPLETE:
            return {
                ...state,
                loadingCustomerObjects: false,
                customerObjects: action.payload
            }
        case types.CLEAR_PRICE_IN_PRICE_LIST:
            return {
                ...state,
                priceInPriceList: undefined
            }
        case types.LOOKUP_PRICE_IN_PRICELIST_BEGIN:
            return {
                ...state,
                loadingPriceInPriceList: true
            }
        case types.LOOKUP_PRICE_IN_PRICELIST_SUCCESS:
            return {
                ...state,
                loadingPriceInPriceList: false,
                priceInPriceList: action.payload
            }
        case types.LOAD_CUSTOMERS_BEGIN:
            return {
                ...state,
                loadingCustomers: true,
                latestCustomerSearch: action.payload
            }
        case types.LOAD_CUSTOMERS_COMPLETE:
            if(state.latestCustomerSearch === action.search) {
                return {
                    ...state,
                    loadingCustomers: false,
                    customers: action.payload,
                }
            } else {
                return {
                    ...state
                }
            }
        case types.CREATE_CUSTOMER_BEGIN:
            return {
                ...state,
                creatingCustomer: true
            }
        case types.CREATE_CUSTOMER_COMPLETE:
            return {
                ...state,
                creatingCustomer: false
            }
        case types.CREATE_CUSTOMER_OBJECT_BEGIN:
            return {
                ...state,
                creatingCustomerObject: true
            }
        case types.CREATE_CUSTOMER_OBJECT_COMPLETE:
            return {
                ...state,
                creatingCustomerObject: false
            }
        case types.CREATE_PROJECT_BEGIN:
            return {
                ...state,
                creatingProject: true
            }
        case types.CREATE_PROJECT_COMPLETE:
            return {
                ...state,
                creatingProject: false
            }
        case types.LOAD_EMPLOYEES_BEGIN: 
            return {
                ...state,
                loadingEmployees: true
            }
        case types.LOAD_EMPLOYEES_COMPLETE:
            return {
                ...state,
                loadingEmployees: false,
                employees: action.payload
            }
        case types.LOAD_OFFER_LIBRARY_TEXT_BLOCKS_BEGIN: {
            return {
                ...state,
                loadingOfferLibraryTextBlocks: true
            }
        }
        case types.LOAD_OFFER_LIBRARY_TEXT_BLOCKS_SUCCESS: {
            return {
                ...state,
                loadingOfferLibraryTextBlocks: false,
                offerLibraryTextBlocks: action.payload
            }
        }
        case types.LOAD_HOUSE_WORK_DEDUCTION_TYPES_BEGIN: {
            return {
                ...state,
                loadingHouseWorkDeductionTypes: true
            }
        }
        case types.LOAD_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS: {
            return {
                ...state,
                loadingHouseWorkDeductionTypes: false,
                houseWorkDeductionTypes: action.payload
            }
        }
        case types.CREATE_HOUSE_WORK_DEDUCTION_TYPES_BEGIN: {
            return {
                ...state,
                creatingHouseWorkDeductionType: true
            }
        }
        case types.CREATE_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS: {
            return {
                ...state,
                creatingHouseWorkDeductionType: false
            }
        }
        case types.UPDATE_HOUSE_WORK_DEDUCTION_TYPES_BEGIN: {
            return {
                ...state,
                updatingHouseWorkDeductionType: true
            }
        }
        case types.UPDATE_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS: {
            return {
                ...state,
                updatingHouseWorkDeductionType: false
            }
        }
        case types.DELETE_HOUSE_WORK_DEDUCTION_TYPES_BEGIN: {
            return {
                ...state,
                deletingHouseWorkDeductionType: true
            }
        }
        case types.DELETE_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS: {
            return {
                ...state,
                deletingHouseWorkDeductionType: false
            }
        }
        case types.CREATE_OFFER_LIBRARY_TEXT_BLOCK_BEGIN: {
            return {
                ...state,
                creatingOfferLibraryTextBlock: true
            }
        }
        case types.CREATE_OFFER_LIBRARY_TEXT_BLOCK_SUCCESS: {
            return {
                ...state,
                creatingOfferLibraryTextBlock: false
            }
        }
        case types.UPDATE_OFFER_LIBRARY_TEXT_BLOCK_BEGIN: {
            return {
                ...state,
                updatingOfferLibraryTextBlock: true
            }
        }
        case types.UPDATE_OFFER_LIBRARY_TEXT_BLOCK_SUCCESS: {
            return {
                ...state,
                updatingOfferLibraryTextBlock: false
            }
        }
        case types.DELETE_OFFER_LIBRARY_TEXT_BLOCK_BEGIN: {
            return {
                ...state,
                deletingOfferLibraryTextBlock: true
            }
        }
        case types.DELETE_OFFER_LIBRARY_TEXT_BLOCK_SUCCESS: {
            return {
                ...state,
                deletingOfferLibraryTextBlock: false
            }
        }
        case types.ACTIVATE_CUSTOMER_BEGIN: 
            return {
                ...state,
                activatingCustomer: true
            }
        case types.ACTIVATE_CUSTOMER_COMPLETE: 
            return {
                ...state,
                activatingCustomer: false
            }
        case types.HIDE_FOOTER:
            return {
                ...state,
                hideFooter: true
            }
        case types.SHOW_FOOTER:
            return {
                ...state,
                hideFooter: false
            }
        case types.LOAD_TODOS_BEGIN: 
            return {
                ...state,
                loadingTodos: true
            }
        case types.LOAD_TODOS_COMPLETE:
            return {
                ...state,
                loadingTodos: false,
                todos: action.payload
            }
        case types.LOAD_UNITS_BEGIN:
            return {
                ...state,
                loadingUnits: true,
            }
        case types.LOAD_UNITS_SUCCESS:
            return {
                ...state,
                loadingUnits: false,
                units: action.payload
            }
        case types.LOAD_FORM_TEMPLATES_BEGIN: 
            return {
                ...state,
                loadingFormTemplates: true
            }
        case types.LOAD_FORM_TEMPLATES_SUCCESS: 
            return {
                ...state,
                loadingFormTemplates: false,
                formTemplates: action.payload
            }
        case types.CREATE_PROJECT_FORM_FROM_TEMPLATE_BEGIN:
            return {
                ...state,
                creatingProjectFormFromTemplate: true
            }
        case types.CREATE_PROJECT_FORM_FROM_TEMPLATE_SUCCESS:
            return {
                ...state,
                creatingProjectFormFromTemplate: false
            }
        case types.LOAD_SIGNATURE_BEGIN:
            return {
                ...state,
                loadingSignature: true
            }
        case types.LOAD_SIGNATURE_SUCCESS:
            return {
                ...state,
                loadingSignature: false,
                signature: action.payload
            }
        case types.SEND_SIGNATURE_CONFIRMATION_BEGIN:
            return {
                ...state,
                sendingSignatureConfirmation: true
            }
        case types.SEND_SIGNATURE_CONFIRMATION_SUCCESS:
            return {
                ...state,
                sendingSignatureConfirmation: false
            }
        case types.LOAD_DAILY_REPORTS_BEGIN:
            return {
                ...state,
                loadingDailyReports: true
            }
        case types.LOAD_DAILY_REPORTS_SUCCESS:
            return {
                ...state,
                loadingDailyReports: false,
                dailyReports: action.payload
            }
        case types.CLEAR_REPORTS:
            return {
                ...state,
                reports: null
            }
        case types.CLEAR_DAILY_REPORTS:
            return {
                ...state,
                dailyReports: null
            }
        case types.LOAD_CURRENT_TENANT_FEATURE_FLAGS_BEGIN:
            return {
                ...state,
                loadingCurrentTenantFeatureFlags: true
            }
        case types.LOAD_CURRENT_TENANT_FEATURE_FLAGS_SUCCESS:
            return {
                ...state,
                currentTenantFeatureFlags: action.payload
            }
        case types.LOAD_REPORTS_TO_ATTEST_BEGIN: 
            return {
                ...state,
                loadingReportsToAttest: true
            }
        case types.LOAD_REPORTS_TO_ATTEST_SUCCESS: 
            return {
                ...state,
                loadingReportsToAttest: false,
                reportsToAttest: action.payload
            }
        case types.CLEAR_REPORTS_TO_ATTEST: 
            return {
                ...state,
                reportsToAttest: undefined
            }
        case types.LOAD_REPORTS_FOR_ME_TO_ATTEST_BEGIN: 
            return {
                ...state,
                loadingReportsForMeToAttest: true
            }
        case types.LOAD_REPORTS_FOR_ME_TO_ATTEST_SUCCESS: 
            return {
                ...state,
                loadingReportsForMeToAttest: false,
                reportsForMeToAttest: action.payload
            }
        case types.LOAD_PROJECT_COUNTS_BEGIN:
            return {
                ...state,
                loadingProjectCounts: true
            }
        case types.LOAD_PROJECT_COUNTS_SUCCESS:
            return {
                ...state,
                loadingProjectCounts: false,
                projectCounts: action.payload
            }
        case types.EXPORT_PROJECTS_TO_FORTNOX_BEGIN:
            return {
                ...state,
                exportingProjectsToFortnox: true
            }
        case types.EXPORT_PROJECTS_TO_FORTNOX_SUCCESS:
            return {
                ...state,
                exportingProjectsToFortnox: false
            }
        case types.UPDATE_PROJECT_BEGIN:
            return {
                ...state,
                updatingProject: true
            }
        case types.UPDATE_PROJECT_SUCCESS:
            return {
                ...state,
                updatingProject: false
            }
        case types.LOAD_CUSTOMER_BEGIN:
            return {
                ...state,
                loadingCustomer: true
            }
        case types.LOAD_CUSTOMER_SUCCESS:
            return {
                ...state,
                loadingCustomer: false,
                customer: action.payload
            }
        case types.CREATE_ATTEST_BEGIN:
            return {
                ...state,
                creatingAttest: true
            }
        case types.CREATE_ATTEST_SUCCESS:
            return {
                ...state,
                creatingAttest: false
            }
        case types.CREATE_ATTESTS_SUCCESS:
            return {
                ...state,
                creatingAttests: false
            }
        case types.CREATE_ATTESTS_SUCCESS:
            return {
                ...state,
                creatingAttests: false
            }
        case types.CLEAR_BOOKINGS:
            return {
                ...state,
                bookings: undefined
            }
        case types.LOAD_ATTESTS_BEGIN: 
            return {
                ...state,
                loadingAttests: true
            }
        case types.LOAD_ATTESTS_SUCCESS: 
            return {
                ...state,
                loadingAttests: false,
                attests: action.payload
            }
        case types.DELETE_ATTEST_BEGIN:
            return {
                ...state,
                deletingAttest: true
            }
        case types.DELETE_ATTEST_SUCCESS:
            return {
                ...state,
                deletingAttest: false
            }
        case types.LOAD_OFFERS_BEGIN: {
            return {
                ...state,
                loadingOffers: true
            }
        }
        case types.LOAD_OFFERS_SUCCESS: {
            return {
                ...state,
                loadingOffers: false,
                offers: action.payload
            }
        }
        case types.CREATE_PROJECT_FROM_OFFER_BEGIN: {
            return {
                ...state,
                creatingProjectFromOffer: true
            }
        }
        case types.CREATE_PROJECT_FROM_OFFER_SUCCESS: {
            return {
                ...state,
                creatingProjectFromOffer: false
            }
        }
        case types.LOAD_USE_OFFER_LINK_BEGIN: {
            return {
                ...state,
                loadingOfferLinkResponse: true
            }
        }
        case types.LOAD_USE_OFFER_LINK_SUCCESS: {
            return {
                ...state,
                loadingOfferLinkResponse: false,
                offerLinkResponse: action.payload
            }
        }
        case types.COPY_OFFER_TO_TEMPLATE_BEGIN: {
            return {
                ...state,
                copyingOfferToTemplate: true
            }
        }
        case types.COPY_OFFER_TO_TEMPLATE_SUCCESS: {
            return {
                ...state,
                copyingOfferToTemplate: false
            }
        }
        case types.SEND_OFFER_LINK_ANSWER_BEGIN: {
            return {
                ...state,
                sendingOfferLinkAnswer: true
            }
        }
        case types.SEND_OFFER_LINK_ANSWER_SUCCESS: {
            return {
                ...state,
                sendingOfferLinkAnswer: false
            }
        }
        case types.DELETE_OFFER_LINK_BEGIN: {
            return {
                ...state,
                deletingOfferLink: true
            }
        }
        case types.DELETE_OFFER_LINK_SUCCESS: {
            return {
                ...state,
                deletingOfferLink: false
            }
        }
        case types.CREATE_OFFER_LINK_BEGIN: {
            return {
                ...state,
                creatingOfferLink: true
            }
        }
        case types.CREATE_OFFER_LINK_SUCCESS: {
            return {
                ...state,
                creatingOfferLink: false
            }
        }
        case types.CLEAR_OFFER_COUNTS: {
            return {
                ...state,
                offerCounts: null
            }
        }
        case types.LOAD_OFFER_COUNTS_BEGIN: {
            return {
                ...state,
                loadingOfferCounts: true
            }
        }
        case types.LOAD_OFFER_COUNTS_SUCCESS: {
            return {
                ...state,
                loadingOfferCounts: false,
                offerCounts: action.payload
            }
        }
        case types.LOAD_ALL_OFFER_COUNTS_BEGIN: {
            return {
                ...state,
                loadingAllOfferCounts: true
            }
        }
        case types.LOAD_ALL_OFFER_COUNTS_SUCCESS: {
            return {
                ...state,
                loadingAllOfferCounts: false,
                allOfferCounts: action.payload
            }
        }
        case types.LOAD_OFFER_BEGIN: {
            return {
                ...state,
                loadingOffer: true
            }
        }
        case types.LOAD_OFFER_SUCCESS: {
            return {
                ...state,
                loadingOffer: false,
                offer: action.payload
            }
        }
        case types.CLEAR_OFFERS: {
            return {
                ...state,
                offers: undefined
            }
        }
        case types.CLEAR_OFFER: {
            return {
                ...state,
                offer: undefined
            }
        }
        case types.CREATE_OFFER_BEGIN: {
            return {
                ...state,
                creatingOffer: true
            }
        }
        case types.CREATE_OFFER_SUCCESS: {
            return {
                ...state,
                creatingOffer: false
            }
        }
        case types.UPDATE_OFFER_BEGIN: {
            return {
                ...state,
                updatingOffer: true
            }
        }
        case types.UPDATE_OFFER_SUCCESS: {
            return {
                ...state,
                updatingOffer: false
            }
        }
        case types.DELETE_OFFER_BEGIN: {
            return {
                ...state,
                deletingOffer: true
            }
        }
        case types.DELETE_OFFER_SUCCESS: {
            return {
                ...state,
                deletingOffer: false
            }
        }
        case types.MARK_PROJECT_AS_INACTIVE_BEGIN:
            return {
                ...state,
                markingProjectAsInactive: true
            }
        case types.MARK_PROJECT_AS_INACTIVE_SUCCESS:
            return {
                ...state,
                markingProjectAsInactive: false
            }
        case types.MARK_PROJECT_AS_UNINACTIVE_BEGIN:
            return {
                ...state,
                markingProjectAsUninactive: true
            }
        case types.MARK_PROJECT_AS_UNINACTIVE_SUCCESS:
            return {
                ...state,
                markingProjectAsUninactive: false
            }
        case types.DELETE_PROJECT_BEGIN:
            return {
                ...state,
                deletingProject: true
            }
        case types.DELETE_PROJECT_SUCCESS:
            return {
                ...state,
                deletingProject: false
            }
        case types.CLEAR_PROJECT:
            return {
                ...state,
                project: undefined
            }
        case types.UPDATE_PROJECT_FORM_BEGIN:
            return {
                ...state,
                updatingProjectForm: true
            }
        case types.UPDATE_PROJECT_FORM_SUCCESS:
            return {
                ...state,
                updatingProjectForm: false
            }
        case types.DELETE_PROJECT_ASSIGNMENT_BEGIN:
            return {
                ...state,
                deletingProjectAssignment: true
            }
        case types.DELETE_PROJECT_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                deletingProjectAssignment: false
            }
        case types.DELETE_PROJECT_PRODUCT_ASSIGNMENT_BEGIN:
            return {
                ...state,
                deletingProjectProductAssignment: true
            }
        case types.DELETE_PROJECT_PRODUCT_ASSIGNMENT_SUCCESS:
            return {
                ...state,
                deletingProjectProductAssignment: false
            }
        case types.CLEAR_PROJECT_FORMS:
            return {
                ...state,
                projectForms: null
            }
        case types.DELETE_PROJECT_FORM_BEGIN:
            return {
                ...state,
                deletingProjectForm: true
            }
        case types.DELETE_PROJECT_FORM_SUCCESS:
            return {
                ...state,
                deletingProjectForm: false
            }
        case types.CREATE_PROJECT_FORM_BEGIN:
            return {
                ...state,
                creatingProjectForm: true
            }
        case types.CREATE_PROJECT_FORM_SUCCESS:
            return {
                ...state,
                creatingProjectForm: false
            }
        case types.CREATE_FORM_TEMPLATE_BEGIN:
            return {
                ...state,
                creatingFormTemplate: true
            }
        case types.CREATE_FORM_TEMPLATE_SUCCESS:
            return {
                ...state,
                creatingFormTemplate: false
            }
        case types.UPDATE_FORM_TEMPLATE_BEGIN:
            return {
                ...state,
                updatingFormTemplate: true
            }
        case types.UPDATE_FORM_TEMPLATE_SUCCESS:
            return {
                ...state,
                updatingFormTemplate: false
            }
        case types.DELETE_FORM_TEMPLATE_BEGIN:
            return {
                ...state,
                deletingFormTemplate: true
            }
        case types.DELETE_FORM_TEMPLATE_SUCCESS:
            return {
                ...state,
                deletingFormTemplate: false
            }
        case types.CREATE_INVOICE_BEGIN:
            return {
                ...state,
                creatingInvoice: true
            }
        case types.CREATE_INVOICE_SUCCESS:
            return {
                ...state,
                creatingInvoice: false
            }
        case types.LOAD_INVOICES_BEGIN:
            return {
                ...state,
                loadingInvoices: true
            }
        case types.LOAD_INVOICES_SUCCESS:
            return {
                ...state,
                loadingInvoices: false,
                invoices: action.payload
            }
        case types.CLEAR_INVOICES:
            return {
                ...state,
                invoices: null
            }
        case types.CLEAR_DOCUMENTS:
            return {
                ...state,
                documents: undefined
            }
        case types.CLEAR_INVOICE:
            return {
                ...state,
                invoice: null
            }
        case types.LOAD_INVOICE_BEGIN:
            return {
                ...state,
                loadingInvoice: true
            }
        case types.LOAD_INVOICE_SUCCESS:
            return {
                ...state,
                loadingInvoice: false,
                invoice: action.payload
            }
        case types.DELETE_INVOICE_BEGIN:
            return {
                ...state,
                deletingInvoice: true
            }
        case types.DELETE_INVOICE_SUCCESS:
            return {
                ...state,
                deletingInvoice: false
            }
        case types.LOAD_COMPANY_LOGO_BEGIN:
            return {
                ...state,
                loadingCompanyLogo: true
            }
        case types.LOAD_COMPANY_LOGO_SUCCESS:
            return {
                ...state,
                loadingCompanyLogo: false,
                companyLogo: action.payload
            }
        case types.LOAD_REPORTED_TIME_BEGIN:
            return {
                ...state,
                loadingReportedTime: true
            }
        case types.LOAD_REPORTED_TIME_SUCCESS:
            return {
                ...state,
                loadingReportedTime: false,
                reportedTime: action.payload
            }
        case types.LOAD_REPORTED_PRODUCT_BEGIN:
            return {
                ...state,
                loadingReportedProduct: true
            }
        case types.LOAD_REPORTED_PRODUCT_SUCCESS:
            return {
                ...state,
                loadingReportedProduct: false,
                reportedProduct: action.payload
            }
        case types.CLEAR_REPORTED_TIME:
            return {
                ...state,
                reportedTime: null
            }
        case types.CLEAR_REPORTED_PRODUCT:
            return {
                ...state,
                reportedProduct: null
            }
        case types.LOAD_REPORTED_NOTE_BEGIN: {
            return {
                ...state,
                loadingReportedNote: true
            }
        }
        case types.LOAD_REPORTED_NOTE_SUCCESS: {
            return {
                ...state,
                loadingReportedNote: false,
                reportedNote: action.payload
            }
        }
        case types.CLEAR_SUPPLIER_INVOICE: {
            return {
                ...state,
                supplierInvoice: null
            }
        }
        case types.CLEAR_SUPPLIER_INVOICES: {
            return {
                ...state,
                supplierInvoices: null
            }
        }
        case types.LOAD_SUPPLIER_INVOICE_BEGIN: {
            return {
                ...state,
                loadingSupplierInvoice: true
            }
        }
        case types.LOAD_SUPPLIER_INVOICE_SUCCESS: {
            return {
                ...state,
                loadingSupplierInvoice: false,
                supplierInvoice: action.payload
            }
        }
        case types.LOAD_SUPPLIER_INVOICES_BEGIN: {
            return {
                ...state,
                loadingSupplierInvoices: true
            }
        }
        case types.LOAD_SUPPLIER_INVOICES_SUCCESS: {
            return {
                ...state,
                loadingSupplierInvoices: false,
                supplierInvoices: action.payload
            }
        }
        case types.CREATE_SUPPLIER_INVOICE_BEGIN: {
            return {
                ...state,
                creatingSupplierInvoice: true
            }
        }
        case types.CREATE_SUPPLIER_INVOICE_SUCCESS: {
            return {
                ...state,
                creatingSupplierInvoice: false
            }
        }
        case types.UPDATE_SUPPLIER_INVOICE_BEGIN: {
            return {
                ...state,
                updatingSupplierInvoice: true
            }
        }
        case types.UPDATE_SUPPLIER_INVOICE_SUCCESS: {
            return {
                ...state,
                updatingSupplierInvoice: false
            }
        }
        case types.DELETE_SUPPLIER_INVOICE_BEGIN: {
            return {
                ...state,
                deletingSupplierInvoice: true
            }
        }
        case types.DELETE_SUPPLIER_INVOICE_SUCCESS: {
            return {
                ...state,
                deletingSupplierInvoice: false
            }
        }
        case types.LOAD_SUPPLIER_INVOICE_PROJECT_RELATIONS_BEGIN: {
            return {
                ...state,
                loadingSupplierInvoiceProjectRelation: true
            }
        }
        case types.LOAD_SUPPLIER_INVOICE_PROJECT_RELATIONS_SUCCESS: {
            return {
                ...state,
                loadingSupplierInvoiceProjectRelation: false,
                supplierInvoiceProjectRelation: action.payload
            }
        }
        case types.CREATE_SUPPLIER_INVOICE_PROJECT_RELATION_BEGIN: {
            return {
                ...state,
                creatingSupplierInvoiceProjectRelation: true
            }
        }
        case types.CREATE_SUPPLIER_INVOICE_PROJECT_RELATION_SUCCESS: {
            return {
                ...state,
                creatingSupplierInvoiceProjectRelation: false
            }
        }
        case types.UPDATE_SUPPLIER_INVOICE_PROJECT_RELATION_BEGIN: {
            return {
                ...state,
                updatingSupplierInvoiceProjectRelation: true
            }
        }
        case types.UPDATE_SUPPLIER_INVOICE_PROJECT_RELATION_SUCCESS: {
            return {
                ...state,
                updatingSupplierInvoiceProjectRelation: false
            }
        }
        case types.DELETE_SUPPLIER_INVOICE_PROJECT_RELATION_BEGIN: {
            return {
                ...state,
                deletingSupplierInvoiceProjectRelation: true
            }
        }
        case types.DELETE_SUPPLIER_INVOICE_PROJECT_RELATION_SUCCESS: {
            return {
                ...state,
                deletingSupplierInvoiceProjectRelation: false
            }
        }
        case types.CLEAR_REPORTED_NOTE: {
            return {
                ...state,
                reportedNote: null
            }
        }
        case types.CLEAR_SUPPLIER_INVOICE_PROJECT_RELATION: {
            return {
                ...state,
                supplierInvoiceProjectRelation: null
            }
        }
        case types.LOAD_CUSTOMER_OBJECT_BEGIN: {
            return {
                ...state,
                loadingCustomerObject: true
            }
        }
        case types.LOAD_CUSTOMER_OBJECT_SUCCESS: {
            return {
                ...state,
                loadingCustomerObject: false,
                customerObject: action.payload
            }
        }
        case types.CLEAR_CUSTOMER_OBJECT: {
            return {
                ...state,
                customerObject: null
            }
        }
        case types.UPDATE_CUSTOMER_OBJECT_BEGIN: {
            return {
                ...state,
                updateCustomerObject: true
            }
        }
        case types.UPDATE_CUSTOMER_OBJECT_SUCCESS: {
            return {
                ...state,
                updateCustomerObject: false
            }
        }
        case types.LOAD_ABSENCE_TYPES_BEGIN: {
            return {
                ...state,
                loadingAbsenceTypes: true,
            }
        }
        case types.LOAD_ABSENCE_TYPES_SUCCESS: {
            return {
                ...state,
                loadingAbsenceTypes: false,
                absenceTypes: action.payload
            }
        }
        case types.LOAD_FORTNOX_SUPPLIER_INVOCIES_BEGIN: {
            return {
                ...state,
                loadingFortnoxSupplierInvoices: true
            }
        }
        case types.LOAD_FORTNOX_SUPPLIER_INVOCIES_SUCCESS: {
            return {
                ...state,
                loadingFortnoxSupplierInvoices: false,
                fortnoxSupplierInvoices: action.payload
            }
        }
        case types.LOAD_FORTNOX_FINANCIAL_YEARS_BEGIN: {
            return {
                ...state,
                loadingFortnoxFinancialYears: true
            }
        }
        case types.LOAD_FORTNOX_FINANCIAL_YEARS_SUCCESS: {
            return {
                ...state,
                loadingFortnoxFinancialYears: false,
                fortnoxFinancialYears: action.payload
            }
        }
        case types.IMPORT_FORTNOX_SUPPLIER_INVOICES_BEGIN: {
            return {
                ...state,
                importingFortnoxSupplierInvoices: true
            }
        } 
        case types.IMPORT_FORTNOX_SUPPLIER_INVOICES_SUCCESS: {
            return {
                ...state,
                importingFortnoxSupplierInvoices: false
            }
        } 
        case types.LOAD_FORTNOX_VOUCHER_SERIES_BEGIN: {
            return {
                ...state,
                loadingFortnoxVoucherSeries: true
            }
        }
        case types.LOAD_FORTNOX_VOUCHER_SERIES_SUCCESS: {
            return {
                ...state,
                loadingFortnoxVoucherSeries: false,
                fortnoxVoucherSeries: action.payload
            }
        }
        case types.LOAD_FORTNOX_ARTICLES_BEGIN: {
            return {
                ...state,
                loadingFortnoxArticles: true
            }
        }
        case types.LOAD_FORTNOX_ARTICLES_SUCCESS: {
            return {
                ...state,
                loadingFortnoxArticles: false,
                fortnoxArticles: action.payload
            }
        }
        case types.IMPORT_FORTNOX_DATA_TO_MYGIZMO_BEGIN: {
            return {
                ...state,
                importingFortnoxData: true
            }
        }
        case types.IMPORT_FORTNOX_DATA_TO_MYGIZMO_SUCCESS: {
            return {
                ...state,
                importingFortnoxData: false
            }
        }
        case types.IMPORT_SINGLE_FORTNOX_DATA_TO_MYGIZMO_BEGIN: {
            return {
                ...state,
                importingSingleFortnoxData: true
            }
        }
        case types.IMPORT_SINGLE_FORTNOX_DATA_TO_MYGIZMO_SUCCESS: {
            return {
                ...state,
                importingSingleFortnoxData: false
            }
        }
        case types.LOAD_FORTNOX_ARTICLE_UNITS_BEGIN: {
            return {
                ...state,
                loadingFortnoxArticleUnits: true
            }
        }
        case types.LOAD_FORTNOX_ARTICLE_UNITS_SUCCESS: {
            return {
                ...state,
                loadingFortnoxArticleUnits: false,
                fortnoxArticleUnits: action.payload
            }
        }
        case types.LOAD_FORTNOX_ARTICLE_UNIT_GROUPS_BEGIN: {
            return {
                ...state,
                loadingFortnoxArticleUnitGroups: true
            }
        }
        case types.LOAD_FORTNOX_ARTICLE_UNIT_GROUPS_SUCCESS: {
            return {
                ...state,
                loadingFortnoxArticleUnitGroups: false,
                fortnoxArticleUnitGroups: action.payload
            }
        }
        case types.UPDATE_FORTNOX_ARTICLE_UNIT_GROUPS_BEGIN: {
            return {
                ...state,
                updatingFortnoxArticleUnitGroups: true
            }
        }
        case types.UPDATE_FORTNOX_ARTICLE_UNIT_GROUPS_SUCCESS: {
            return {
                ...state,
                updatingFortnoxArticleUnitGroups: false
            }
        }
        case types.LOAD_FORTNOX_STATUS_BEGIN: {
            return {
                ...state,
                loadingFortnoxStatus: true
            }
        }
        case types.LOAD_FORTNOX_STATUS_SUCCESS: {
            return {
                ...state,
                loadingFortnoxStatus: false,
                fortnoxStatus: action.payload
            }
        }
        case types.LOAD_EACCOUNTING_STATUS_BEGIN: {
            return {
                ...state,
                loadingEaccountingStatus: true
            }
        }
        case types.LOAD_EACCOUNTING_STATUS_SUCCESS: {
            return {
                ...state,
                loadingEaccountingStatus: false,
                eaccountingStatus: action.payload
            }
        }
        case types.LOAD_EACCOUNTING_ARTICLES_BEGIN: {
            return {
                ...state,
                loadingEaccountingArticles: true
            }
        }
        case types.LOAD_EACCOUNTING_ARTICLES_SUCCESS: {
            return {
                ...state,
                loadingEaccountingArticles: false,
                eaccountingArticles: action.payload
            }
        }
        case types.IMPORT_EACCOUNTING_DATA_TO_MYGIZMO_BEGIN: {
            return {
                ...state,
                importingEaccountingData: true
            }
        }
        case types.IMPORT_EACCOUNTING_DATA_TO_MYGIZMO_SUCCESS: {
            return {
                ...state,
                importingEaccountingData: false
            }
        }
        case types.IMPORT_SINGLE_EACCOUNTING_DATA_TO_MYGIZMO_BEGIN: {
            return {
                ...state,
                importingSingleEaccountingData: true
            }
        }
        case types.IMPORT_SINGLE_EACCOUNTING_DATA_TO_MYGIZMO_SUCCESS: {
            return {
                ...state,
                importingSingleEaccountingData: false
            }
        }
        case types.LOAD_EACCOUNTING_ARTICLE_UNITS_BEGIN: {
            return {
                ...state,
                loadingEaccountingArticleUnits: true
            }
        }
        case types.LOAD_PROJECT_VARIATION_COUNTS_BEGIN: {
            return {
                ...state,
                loadingProjectVariationCounts: true
            }
        }
        case types.LOAD_PROJECT_VARIATION_COUNTS_SUCCESS: {
            return {
                ...state,
                loadingProjectVariationCounts: false,
                projectVariationCounts: action.payload
            }
        }
        case types.LOAD_EACCOUNTING_ARTICLE_UNITS_SUCCESS: {
            return {
                ...state,
                loadingEaccountingArticleUnits: false,
                eaccountingArticleUnits: action.payload
            }
        }
        case types.LOAD_EACCOUNTING_ARTICLE_UNIT_GROUPS_BEGIN: {
            return {
                ...state,
                loadingEaccountingArticleUnitGroups: true
            }
        }
        case types.LOAD_EACCOUNTING_ARTICLE_UNIT_GROUPS_SUCCESS: {
            return {
                ...state,
                loadingEaccountingArticleUnitGroups: false,
                eaccountingArticleUnitGroups: action.payload
            }
        }
        case types.UPDATE_EACCOUNTING_ARTICLE_UNIT_GROUPS_BEGIN: {
            return {
                ...state,
                updatingEaccountingArticleUnitGroups: true
            }
        }
        case types.UPDATE_EACCOUNTING_ARTICLE_UNIT_GROUPS_SUCCESS: {
            return {
                ...state,
                updatingEaccountingArticleUnitGroups: false
            }
        }
        case types.CREATE_ABSENCE_TYPE_BEGIN: {
            return {
                ...state,
                creatingAbsenceType: true
            }
        }
        case types.CREATE_ABSENCE_TYPE_SUCCESS: {
            return {
                ...state,
                creatingAbsenceType: false
            }
        }
        case types.UPDATE_ABSENCE_TYPE_BEGIN: {
            return {
                ...state,
                updatingAbsenceType: true
            }
        }
        case types.UPDATE_ABSENCE_TYPE_SUCCESS: {
            return {
                ...state,
                updatingAbsenceType: false
            }
        }
        case types.CLEAR_CUSTOMERS: {
            return {
                ...state,
                customers: undefined
            }
        }
        case types.CLEAR_CUSTOMER: {
            return {
                ...state,
                customer: undefined
            }
        }
        case types.LOAD_CUSTOMER_COUNTS_BEGIN: {
            return {
                ...state,
                loadingCustomerCounts: true
            }
        }
        case types.LOAD_CUSTOMER_COUNTS_SUCCESS: {
            return {
                ...state,
                loadingCustomerCounts: false,
                customerCounts: action.payload
            }
        }
        case types.UPDATE_CUSTOMER_BEGIN: {
            return {
                ...state,
                updatingCustomer: true
            }
        }
        case types.UPDATE_CUSTOMER_SUCCESS: {
            return {
                ...state,
                updatingCustomer: false
            }
        }
        case types.DELETE_CUSTOMER_OBJECT_BEGIN: {
            return {
                ...state,
                deletingCustomerObject: true
            }
        }
        case types.DELETE_CUSTOMER_OBJECT_SUCCESS: {
            return {
                ...state,
                deletingCustomerObject: false
            }
        }
        case types.SEND_PROJECT_VARIATION_LINK_ANSWER_BEGIN: {
            return {
                ...state,
                sendingProjectVariationLinkAnswer: true
            }
        }
        case types.SEND_PROJECT_VARIATION_LINK_ANSWER_SUCCESS: {
            return {
                ...state,
                sendingProjectVariationLinkAnswer: false
            }
        }
        case types.LOAD_USE_PROJECT_VARIATION_LINK_BEGIN: {
            return {
                ...state,
                loadingProjectVariationLinkResponse: true
            }
        }
        case types.LOAD_USE_PROJECT_VARIATION_LINK_SUCCESS: {
            return {
                ...state,
                loadingProjectVariationLinkResponse: false,
                projectVariationLinkResponse: action.payload
            }
        }
        case types.LOAD_PROJECT_VARIATION_BEGIN: {
            return {
                ...state,
                loadingProjectVariation: true
            }
        }
        case types.LOAD_PROJECT_VARIATION_SUCCESS: {
            return {
                ...state,
                loadingProjectVariation: false,
                projectVariation: action.payload
            }
        }
        case types.LOAD_PROJECT_VARIATIONS_BEGIN: {
            return {
                ...state,
                loadingProjectVariations: true
            }
        }
        case types.LOAD_PROJECT_VARIATIONS_SUCCESS: {
            return {
                ...state,
                loadingProjectVariations: false,
                projectVariations: action.payload
            }
        }
        case types.CLEAR_PROJECT_VARIATION: {
            return {
                ...state,
                projectVariation: undefined
            }
        }
        case types.CLEAR_PROJECT_VARIATIONS: {
            return {
                ...state,
                projectVariations: undefined
            }
        }
        case types.LOAD_PAYMENT_PLAN_ELEMENTS_BEGIN: {
            return {
                ...state,
                loadingPaymentPlanElements: true
            }
        }
        case types.LOAD_PAYMENT_PLAN_ELEMENTS_SUCCESS: {
            return {
                ...state,
                loadingPaymentPlanElements: false,
                paymentPlanElements: action.payload
            }
        }
        case types.CLEAR_PAYMENT_PLAN_ELEMENTS: {
            return {
                ...state,
                paymentPlanElements: []
            }
        }
        case types.CREATE_PAYMENT_PLAN_ELEMENT_BEGIN: {
            return {
                ...state,
                creatingPaymentPlanElement: true
            }
        }
        case types.CREATE_PAYMENT_PLAN_ELEMENT_SUCCESS: {
            return {
                ...state,
                creatingPaymentPlanElement: false
            }
        }
        case types.UPDATE_PAYMENT_PLAN_ELEMENT_BEGIN: {
            return {
                ...state,
                updatingPaymentPlanElement: true
            }
        }
        case types.UPDATE_PAYMENT_PLAN_ELEMENT_SUCCESS: {
            return {
                ...state,
                updatingPaymentPlanElement: false
            }
        }
        case types.DELETE_PAYMENT_PLAN_ELEMENT_BEGIN: {
            return {
                ...state,
                deletingPaymentPlanElement: true
            }
        }
        case types.DELETE_PAYMENT_PLAN_ELEMENT_SUCCESS: {
            return {
                ...state,
                deletingPaymentPlanElement: false
            }
        }
        case types.CREATE_PROJECT_VARIATION_BEGIN: {
            return {
                ...state,
                creatingProjectVariation: true
            }
        }
        case types.CREATE_PROJECT_VARIATION_SUCCESS: {
            return {
                ...state,
                creatingProjectVariation: false
            }
        }
        case types.UPDATE_PROJECT_VARIATION_BEGIN: {
            return {
                ...state,
                updatingProjectVariation: true
            }
        }
        case types.UPDATE_PROJECT_VARIATION_SUCCESS: {
            return {
                ...state,
                updatingProjectVariation: false
            }
        }
        case types.DELETE_PROJECT_VARIATION_BEGIN: {
            return {
                ...state,
                deletingProjectVariation: true
            }
        }
        case types.DELETE_PROJECT_VARIATION_SUCCESS: {
            return {
                ...state,
                deletingProjectVariation: false
            }
        }
        case types.CREATE_PROJECT_VARIATION_LINK_BEGIN: {
            return {
                ...state,
                creatingProjectVariationLink: true
            }
        }
        case types.CREATE_PROJECT_VARIATION_LINK_SUCCESS: {
            return {
                ...state,
                creatingProjectVariationLink: false
            }
        }
        case types.DELETE_PROJECT_VARIATION_LINK_BEGIN: {
            return {
                ...state,
                deletingProjectVariationLink: true
            }
        }
        case types.DELETE_PROJECT_VARIATION_LINK_SUCCESS: {
            return {
                ...state,
                deletingProjectVariationLink: false
            }
        }
        case types.CLEAR_CUSTOMER_OBJECTS: {
            return {
                ...state,
                customerObjects: []
            }
        }
        case types.CLEAR_CUSTOMER_COUNTS: {
            return {
                ...state,
                customerCounts: null
            }
        }
        case types.CLEAR_PROJECTS: {
            return {
                ...state,
                [action.namespace + "projects"]: null
            }
        }
        case types.CLEAR_PROJECT_COUNTS: {
            return {
                ...state,
                projectCounts: null
            }
        }
        case types.LOAD_DOCUMENTS_BEGIN: {
            return {
                ...state,
                loadingDocuments: true
            }
        }
        case types.LOAD_DOCUMENTS_SUCCESS: {
            return {
                ...state,
                loadingDocuments: false,
                documents: action.payload
            }
        }
        case types.LOAD_ARTICLES_REGISTRY_BEGIN: {
            return {
                ...state,
                loadingArticles: true
            }
        }
        case types.LOAD_ARTICLES_REGISTRY_SUCCESS: {
            return {
                ...state,
                loadingArticles: false,
                articleRegistry: action.payload

            }
        }
        case types.CREATE_ARTICLE_BEGIN: {
            return {
                ...state,
                creatingArticle: true
            }
        }
        case types.CREATE_ARTICLE_SUCCESS: {
            return {
                ...state,
                creatingArticle: false
            }
        }
        case types.DELETE_ARTICLE_BEGIN: {
            return {
                ...state,
                deletingArticle: true
            }
        }
        case types.DELETE_ARTICLE_SUCCESS: {
            return {
                ...state,
                deletingArticle: false
            }
        }
        case types.UPDATE_ARTICLE_BEGIN: {
            return {
                ...state,
                updatingArticle: true

            }
        }
        case types.UPDATE_ARTICLE_SUCCESS: {
            return {
                ...state,
                updatingArticle: false
            }
        }
        case types.LOAD_PRODUCT_COUNTS_BEGIN:
            return {
                ...state,
                loadingProductCounts: true
            }
        case types.LOAD_PRODUCT_COUNTS_SUCCESS:
            return {
                ...state,
                loadingProductCounts: false,
                productCounts: action.payload
            }
            case types.LOAD_PRODUCT_BEGIN: 
            return {
                ...state,
                loadingProject: true
            };
        case types.LOAD_PRODUCT_SUCCESS: 
            return {
                ...state,
                product: action.payload,
                loadingProject: false
            };
        case types.LOAD_UNITS_BEGIN:
            return {
                ...state,
                loadingUnits: true
            }
        case types.LOAD_UNITS_SUCCESS:
            return {
                ...state,
                units: action.payload,
                loadingUnits: false
            }
        case types.LOAD_PRODUCT_TYPES_BEGIN:
            return {
                ...state,
                loadingProductTypes: true
            }
        case types.LOAD_PRODUCT_TYPES_SUCCESS:
            return {
                ...state,
                productTypes: action.payload,
                loadingProductTypes: false
            }
        case types.LOAD_ALL_TASKS_BEGIN:
            return {
                ...state,
                loadingAllTasks: true
            }
        case types.LOAD_ALL_TASKS_SUCCESS:
            return {
                ...state,
                allTasks: action.payload,
                loadingAllTasks: false
            }
        case types.CLEAR_TASKS:
            return {
                ...state,
                allTasks: null,
            }
        case types.LOAD_PRODUCT_STATS_BEGIN:
            return {
                ...state,
                loadingProductStats: true
            }
        case types.LOAD_PRODUCT_STATS_SUCCESS:
            return {
                ...state,
                productStats: action.payload,
                loadingProductStats: false
            }
        case types.CLEAR_PRODUCT_STATS:
            return {
                ...state,
                productStats: null
            }
        case types.LOAD_FILTERED_ATTESTABLE_REPORTS_BEGIN:
            return {
                ...state,
                loadingAttestableReports: true
            }
        case types.LOAD_FILTERED_ATTESTABLE_REPORTS_SUCCESS:
            return {
                ...state,
                filteredAttestableReports: action.payload,
                loadingAttestableReports: false
            }
        case types.CREATE_UNIT_BEGIN:
            return {
                ...state,
                creatingUnit: true
            }
        case types.CREATE_UNIT_SUCCESS:
            return {
                ...state,
                creatingUnit: false
            }
        case types.UPDATE_UNIT_BEGIN:
            return {
                ...state,
                updatingUnit: true
            }
        case types.UPDATE_UNIT_SUCCESS:
            return {
                ...state,
                updatingUnit: false
            }
        case types.CREATE_PRODUCT_TYPE_BEGIN:
            return {
                ...state,
                creatingProductType: true
            }
        case types.CREATE_PRODUCT_TYPE_SUCCESS:
            return {
                ...state,
                creatingProductType: false
            }
        case types.UPDATE_PRODUCT_TYPE_SUCCESS:
            return {
                ...state,
                updatingProductType: true
            }
        case types.UPDATE_PRODUCT_TYPE_BEGIN:
            return {
                ...state,
                updatingProductType: false
            }
        case types.LOAD_TASK_WITH_TASK_TYPE_BEGIN:
            return {
                ...state,
                loadingTaskWithTaskType: true
            }
        case types.LOAD_TASK_WITH_TASK_TYPE_SUCCESS:
            return {
                ...state,
                loadingTaskWithTaskType: false,
                task: action.payload
            }
        case types.LOAD_TASK_TYPES_BEGIN:
            return {
                ...state,
                loadingTaskTypes: true
            }
        case types.LOAD_TASK_TYPES_SUCCESS:
            return {
                ...state,
                taskTypes: action.payload,
                loadingTaskTypes: false
            }
        case types.DELETE_TASK_BEGIN:
            return {
                ...state,
                deletingTask: true
            }
        case types.DELETE_TASK_SUCCESS:
            return {
                ...state,
                deletingTask: false
            }
        case types.CLEAR_TASK:
            return {
                ...state,
                task: null
            }
        case types.UPDATE_TASK_TYPE_BEGIN:
            return {
                ...state,
                updatingTaskType: true,
            }
        case types.UPDATE_TASK_TYPE_SUCCESS:
            return {
                ...state,
                updatingTaskType: false,
            }
        case types.CREATE_TASK_TYPE_BEGIN:
            return {
                ...state,
                creatingTaskType: true,
            }
        case types.CREATE_TASK_TYPE_SUCCESS:
            return {
                ...state,
                creatingTaskType: false,
            }
        case types.CREATE_TASK_BEGIN:
            return {
                ...state,
                creatingTask: true,
            }
        case types.CREATE_TASK_SUCCESS:
            return {
                ...state,
                creatingTask: false,
            }
        case types.DELETE_TASK_TYPE_BEGIN:
            return {
                ...state,
                deletingTaskType: true,
            }
        case types.DELETE_TASK_TYPE_SUCCESS:
            return {
                ...state,
                deletingTaskType: false,
            }
        case types.DELETE_PRODUCT_TYPE_BEGIN:
            return {
                ...state,
                deletingProductType: true,
            }
        case types.DELETE_PRODUCT_TYPE_SUCCESS:
            return {
                ...state,
                deletingProductType: false,
            }
        case types.DELETE_UNIT_BEGIN:
            return {
                ...state,
                deletingUnit: true,
            }
        case types.DELETE_UNIT_SUCCESS:
            return {
                ...state,
                deletingUnit: false,
            }
        case types.CREATE_SALARY_BEGIN: {
            return {
                ...state,
                creatingSalary: true
            }
        }
        case types.CREATE_SALARY_SUCCESS: {
            return {
                ...state,
                creatingSalary: false
            }
        }
        case types.LOAD_SALARIES_BEGIN: {
            return {
                ...state,
                loadingSalaries: true
            }
        }
        case types.LOAD_SALARIES_SUCCESS: {
            return {
                ...state,
                loadingSalaries: false,
                salaries: action.payload
            }
        }
        case types.CLEAR_SALARIES: {
            return {
                ...state,
                salaries: undefined
            }
        }
        case types.DELETE_SALARY_BEGIN: {
            return {
                ...state,
                deletingSalary: true
            }
        }
        case types.DELETE_SALARY_SUCCESS: {
            return {
                ...state,
                deletingSalary: false
            }
        }
        case types.LOAD_TASK_STATS_BEGIN:
            return {
                ...state,
                loadingTaskStats: true,
            }
        case types.LOAD_TASK_STATS_SUCCESS:
            return {
                ...state,
                taskStats: action.payload,
                loadingTaskStats: false,
            }
        case types.CLEAR_TASK_STATS:
            return {
                ...state,
                taskStats: null
            }
        case types.UPDATE_EMPLOYEE_BEGIN:
            return {
                ...state,
                updatingEmployee: true,
            }
        case types.UPDATE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                updatingEmployee: false,
            }
        case types.UPDATE_PASSWORD_BEGIN:
            return {
                ...state,
                updatingPassword: true,
            }
        case types.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                updatingPassword: false,
            }
        case types.UPDATE_PROFILE_PICTURE_BEGIN:
            return {
                ...state,
                updatingProfilePicture: true,
            }
        case types.UPDATE_PROFILE_PICTURE_SUCCESS:
            return {
                ...state,
                updatingProfilePicture: false,
            }
        case types.LOAD_EMPLOYEE_REPORTED_HOURS_BEGIN:
            return {
                ...state,
                loadingEmployeeReportedTimeSum: true,
            }
        case types.LOAD_EMPLOYEE_REPORTED_HOURS_SUCCESS:
            return {
                ...state,
                employeeReportedTimeSum: action.payload,
                loadingEmployeeReportedTimeSum: false,
            }
        case types.CLEAR_PRODUCT:
            return {
                ...state,
                product: null
            }
        case types.CLEAR_PRODUCTS:
            return {
                ...state,
                products: null
            }
        case types.CLEAR_PRODUCT_COUNTS:
            return {
                ...state,
                productCounts: null
            }
        case types.UPDATE_REVOKE_SOFT_DELETED_TASK_BEGIN:
            return {
                ...state,
                updatingSoftDeleteOnTask: true,
            }
        case types.UPDATE_REVOKE_SOFT_DELETED_TASK_SUCCESS:
            return {
                ...state,
                updatingSoftDeleteOnTask: false,
            }
        case types.LOAD_REVOKE_SOFT_DELETED_PRODUCT_BEGIN:
            return {
                ...state,
                updatingSoftDeleteOnProduct: true,
            }
        case types.LOAD_REVOKE_SOFT_DELETED_PRODUCT_SUCCESS:
            return {
                ...state,
                updatingSoftDeleteOnProduct: false,
            }
        case types.LOAD_EMPLOYEE_BEGIN:
            return {
                ...state,
                loadingRegisterEmployee: true,
            }
        case types.LOAD_EMPLOYEE_SUCCESS:
            return {
                ...state,
                registerEmployee: action.payload,
                loadingRegisterEmployee: false,
            }
        case types.CLEAR_EMPLOYEE_DATA:
            return {
                ...state,
                registerEmployee: null
            }
        case types.DELETE_EMPLOYEE_SOFT_DELETE_BEGIN:
            return {
                ...state,
                softDeletingEmployee: true,
            }
        case types.DELETE_EMPLOYEE_SOFT_DELETE_SUCCESS:
            return {
                ...state,
                softDeletingEmployee: false,
            }
        case types.LOAD_REGISTER_EMPLOYEES_BEGIN:
            return {
                ...state,
                loadingRegisterEmployees: true,
            }
        case types.LOAD_REGISTER_EMPLOYEES_SUCCESS:
            return {
                ...state,
                registerEmployees: action.payload,
                loadingRegisterEmployees: false,
            }
        case types.LOAD_EMPLOYEE_COUNT_BEGIN:
            return {
                ...state,
                loadingEmployeeCount: true,
            }
        case types.LOAD_EMPLOYEE_COUNT_SUCCESS:
            return {
                ...state,
                employeeCount: action.payload,
                loadingEmployeeCount: false,
            }
        case types.LOAD_REVOKE_SOFT_DELETED_EMPLOYEE_BEGIN:
            return {
                ...state,
                loadingRevokeSoftDeletedEmployee: true,
            }
        case types.LOAD_REVOKE_SOFT_DELETED_EMPLOYEE_SUCCESS:
            return {
                ...state,
                loadingRevokeSoftDeletedEmployee: false,
            }
        case types.CLEAR_EMPLOYEE_REPORTED_TIME_SUM:
            return {
                ...state,
                employeeReportedTimeSum: null
            }
        case types.CRAETE_EMPLOYEE_BEGIN:
            return {
                ...state,
                creatingEmployee: true,
            }
        case types.CRAETE_EMPLOYEE_SUCCESS:
            return {
                ...state,
                crateingEmployee: false,
            }
        case types.LOAD_DOES_EMPLOYEES_EMAIL_EXIST_BEGIN:
            return {
                ...state,
                loadingDoesEmployeesEmailExist: true,
            }
        case types.LOAD_DOES_EMPLOYEES_EMAIL_EXIST_SUCCESS:
            return {
                ...state,
                loadingDoesEmployeesEmailExist: false,
            }
        case types.LOAD_DOES_EMPLOYEE_NUMBER_EXIST_BEGIN:
            return {
                ...state,
                loadingDoesEmployeeNumberExist: true
            }
        case types.LOAD_DOES_EMPLOYEE_NUMBER_EXIST_SUCCESS:
            return {
                ...state,
                loadingDoesEmployeeNumberExist: false
            }
        case types.SEND_USER_INVITE_TO_CURRENT_TENNANT_BEGIN:
            return {
                ...state,
                sendinUserInviteToCurrentTennant: true,
            }
        case types.SEND_USER_INVITE_TO_CURRENT_TENNANT_SUCCESS:
            return {
                ...state,
                sendinUserInviteToCurrentTennant: false,
            }
        case types.LOAD_ALL_TIME_TYPES_BEGIN:
            return {
                ...state,
                loadingAllTimeTypes: true,
            }
        case types.LOAD_ALL_TIME_TYPES_SUCCESS:
            return {
                ...state,
                allTimeTypes: action.payload,
                loadingAllTimeTypes: false,
            }
        case types.UPDATE_USERS_ALLOWED_TO_SEE_PRICE_BEGIN:
            return {
                ...state,
                updatingUserPriceSettings: true,
            }
        case types.UPDATE_USERS_ALLOWED_TO_SEE_PRICE_SUCCESS:
            return {
                ...state,
                updatingUserPriceSettings: false,
            }
        case types.LOAD_PRICE_LISTS_BEGIN:
            return {
                ...state,
                loadingPriceLists: true,
            }
        case types.LOAD_PRICE_LISTS_SUCCESS:
            return {
                ...state,
                priceLists: action.payload,
                loadingPriceLists: false,
            }
        case types.UPDATE_SETTINGS_BEGIN:
            return {
                ...state,
                updatingSettings: true,
            }
        case types.UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                updatingSettings: false
            }
        case types.UPDATE_COMPANY_LOGO_BEGIN:
            return {
                ...state,
                uploadingCompanyLogo: true,
            }
        case types.UPDATE_COMPANY_LOGO_SUCCESS:
            return {
                ...state,
                uploadingCompanyLogo: false,
            }
        case types.UPDATE_CUSTOM_PROJECT_SEQUENCE_BEGIN:
            return {
                ...state,
                updatingCustomProjectSequence: true,
            }
        case types.UPDATE_CUSTOM_PROJECT_SEQUENCE_SUCCESS:
            return {
                ...state,
                updatingCustomProjectSequence: false,
            }
        case types.CREATE_TIME_TYPE_BEGIN:
            return {
                ...state,
                creatingTimeType: true
            }
        case types.CREATE_TIME_TYPE_SUCCESS:
            return {
                ...state,
                creatingTimeType: false
            }
        case types.LOAD_ARE_TIME_TYPE_HANDLED_BEGIN:
            return {
                ...state,
                loadingIsTimeTypeHandled: true,
            }
        case types.LOAD_ARE_TIME_TYPE_HANDLED_SUCCESS:
            return {
                ...state,
                loadingIsTimeTypeHandled: false,
            }
        case types.LOAD_TIME_TYPE_BEGIN:
            return {
                ...state,
                loadingTimeType: true,
            }
        case types.LOAD_TIME_TYPE_SUCCESS:
            return {
                ...state,
                timeType: action.payload,
                loadingTimeType: false,
            }
        case types.CLEAR_TIME_TYPE:
            return {
                ...state,
                timeType: null
            }
        case types.DELETE_TIME_TYPE_BEGIN:
            return {
                ...state,
                deletingTimeType: true,
            }
        case types.DELETE_TIME_TYPE_SUCCESS:
            return {
                ...state,
                deletingTimeType: false,
            }
        case types.CREATE_DOCUMENT_CATEGORY_BEGIN:
            return {
                ...state,
                creatingDocumentCategory: true,
            }
        case types.CREATE_DOCUMENT_CATEGORY_SUCCESS:
            return {
                ...state,
                creatingDocumentCategory: false,
            }
        case types.UPDATE_TIME_TYPE_BEGIN:
            return {
                ...state,
                updatingTimeType: true
            }
        case types.UPDATE_TIME_TYPE_SUCCESS:
            return {
                ...state,
                updatingTimeType: false
            }
        case types.UPDATE_DOCUMENT_CATEGORY_BEGIN:
            return {
                ...state,
                updatingDocumentCategory: true,
            }
        case types.UPDATE_DOCUMENT_CATEGORY_SUCCESS:
            return {
                ...state,
                updatingDocumentCategory: false,
            }
        case types.DELETE_DOCUMENT_CATEGORY_BEGIN:
            return {
                ...state,
                deletingDocumentCategory: true,
            }
        case types.DELETE_DOCUMENT_CATEGORY_SUCCESS:
            return {
                ...state,
                deletingDocumentCategory: false,
            }
        case types.UPDATE_USERS_ALLOWED_TO_EDIT_REPORTS_BEGIN:
            return {
                ...state,
                updatingUsersAllowedToEditReports: true
            }
        case types.UPDATE_USERS_ALLOWED_TO_EDIT_REPORTS_SUCCESS:
            return {
                ...state,
                updatingUsersAllowedToEditReports: false
            }
        case types.UPDATE_SHOW_TASK_FIRST_BEGIN:
            return {
                ...state,
                updatingShowTaskFirst: true,
            }
        case types.UPDATE_SHOW_TASK_FIRST_SUCCESS:
            return {
                ...state,
                updatingShowTaskFirst: false,
            }
        case types.CREATE_PRICE_ADJUSTMENT_BEGIN:
            return {
                ...state,
                creatingPriceAdjustment: true,
            }
        case types.CREATE_PRICE_ADJUSTMENT_SUCCESS:
            return {
                ...state,
                creatingPriceAdjustment: false,
            }
        case types.DELETE_PRICE_ADJUSTMENT_BEGIN:
            return {
                ...state,
                deletingPriceAdjustment: true,
            }
        case types.DELETE_PRICE_ADJUSTMENT_SUCCESS:
            return {
                ...state,
                deletingPriceAdjustment: false,
            }
        case types.LOAD_PRICE_LISTS_ITEM_DISCOUNTS_BEGIN:
            return {
                ...state,
                loadingPriceListItemDiscounts: true,
            }
        case types.LOAD_PRICE_LISTS_ITEM_DISCOUNTS_SUCCESS:
            return {
                ...state,
                priceListsItemDiscounts: action.payload,
                loadingPriceListItemDiscounts: false,
            }
        case types.CREATE_PRICE_LIST_DISCOUNTS_BEGIN:
            return {
                ...state,
                creatingPriceListDiscount: true,
            }
        case types.CREATE_PRICE_LIST_DISCOUNTS_SUCCESS:
            return {
                ...state,
                creatingPriceListDiscount: false,
            }
        case types.DELETE_PRICE_LIST_DISCOUNTS_BEGIN:
            return {
                ...state,
                deletingPriceListDiscount: true,
            }
        case types.DELETE_PRICE_LIST_DISCOUNTS_SUCCESS:
            return {
                ...state,
                deletingPriceListDiscount: false,
            }
        case types.UPDATE_PRICE_ADJUSTMENT_BEGIN:
            return {
                ...state,
                updatingPriceAdjustment: true,
            }
        case types.UPDATE_PRICE_ADJUSTMENT_SUCCESS:
            return {
                ...state,
                updatingPriceAdjustment: false,
            }
        case types.CLEAR_PRICE_LISTS_ITEM_DISCOUNTS:
            return {
                ...state,
                priceListsItemDiscounts: null,
            }
        case types.LOAD_ONGOING_PROJECTS_BEGIN:
            return {
                ...state,
                loadingOngoingProjects: true,
            }
        case types.LOAD_ONGOING_PROJECTS_SUCCESS:
            return {
                ...state,
                ongoingProjects: action.payload,
                loadingOngoingProjects: false,
            }
        case types.LOAD_OPON_PROJECTS_WITH_CUSTOMER_NAME_BEGIN:
            return {
                ...state,
                loadingOpenProjectsWithCustomerName: true,
            }
        case types.LOAD_OPON_PROJECTS_WITH_CUSTOMER_NAME_SUCCESS:
            return {
                ...state,
                openProjectsWithCustomerName: action.payload,
                loadingOpenProjectsWithCustomerName: false,
            }
        case types.LOAD_PROJECT_STATS_BEGIN:
            return {
                ...state,
                loadingProjectStats: true,
            }
        case types.LOAD_PROJECT_STATS_SUCCESS:
            return {
                ...state,
                projectStats: action.payload,
                loadingProjectStats: false,
            }
        case types.LOAD_UNHANDLED_REPORTS_BEGIN:
            return {
                ...state,
                loadingUnhandledReports: true,
            }
        case types.LOAD_UNHANDLED_REPORTS_SUCCESS:
            return {
                ...state,
                unhandledReports: action.payload,
                loadingUnhandledReports: false,
            }
        case types.LOAD_UNHANDLED_REPORTED_TIME_BETWEEN_DATES_BEGIN:
            return {
                ...state,
                loadingUnhandledReportedTimeBetweenDates: true,
            }
        case types.LOAD_UNHANDLED_REPORTED_TIME_BETWEEN_DATES_SUCCESS:
            return {
                ...state,
                unhandledReportedTimeBetweenDates: action.payload,
                loadingUnhandledReportedTimeBetweenDates: false,
            }
        case types.LOAD_UNHANDLED_INVOICE_AMOUNT_SUM_BEGIN:
            return {
                ...state,
                loadingUnhandledInvoiceAmountSum: true
            }
        case types.LOAD_UNHANDLED_INVOICE_AMOUNT_SUM_SUCCESS:
            return {
                ...state,
                unhandledInvoiceAmountSum: action.payload,
                loadingUnhandledInvoiceAmountSum: false
            }
        case types.UPDATE_PRICE_LIST_DISCOUNTS_BEGIN:
            return {
                ...state,
                updatePriceListsItemDiscount: true,
            }
        case types.UPDATE_PRICE_LIST_DISCOUNTS_SUCCESS:
            return {
                ...state,
                updatePriceListsItemDiscount: false,
            }
        case types.SEND_USER_INVITE_TO_CURRENT_TENNANT_AGAIN_BEGIN:
            return {
                ...state,
                sendingUserInviteToCurrentTennantAgain: true,
            }
        case types.SEND_USER_INVITE_TO_CURRENT_TENNANT_AGAIN_SUCCESS:
            return {
                ...state,
                sendingUserInviteToCurrentTennantAgain: false,
            }
        case types.LOAD_CUSTOMERS_WITH_DATES_BEGIN:
            return {
                ...state,
                loadingCustomersWithDates: true,
            }
        case types.LOAD_CUSTOMERS_WITH_DATES_SUCCESS:
            return {
                ...state,
                customersWithDates: action.payload,
                loadingCustomersWithDates: false,
            }
        case types.LOAD_PRODUCTS_WITH_DATES_BEGIN:
            return {
                ...state,
                loadingProductsWithDates: true,
            }
        case types.LOAD_PRODUCTS_WITH_DATES_SUCCESS:
            return {
                ...state,
                productsWithDates: action.payload,
                loadingProductsWithDates: false,
            }
        case types.LOAD_INVOICES_WITH_DATES_BEGIN:
            return {
                ...state,
                loadingInvoicesWithDates: true,
            }
        case types.LOAD_INVOICES_WITH_DATES_SUCCESS:
            return {
                ...state,
                invoicesWithDates: action.payload,
                loadingInvoicesWithDates: false,
            }
        case types.LOAD_ALL_INTEGRATION_ITEM_GROUPS_BEGIN:
            return {
                ...state,
                loadingAllIntegrationItemGroups: true,
            }
        case types.LOAD_ALL_INTEGRATION_ITEM_GROUPS_SUCCESS:
            return {
                ...state,
                allIntegrationItemGroups: action.payload,
                loadingAllIntegrationItemGroups: false,
            }
        case types.LOAD_PROJECTS_BY_ERP_PROJECT_ID_BEGIN:
            return {
                ...state,
                loadingProjectsByErpProjectId: true,
            }
        case types.LOAD_PROJECTS_BY_ERP_PROJECT_ID_SUCCESS:
            return {
                ...state,
                projectsByErpProjectId: action.payload,
                loadingProjectsByErpProjectId: false,
            }
        case types.LOAD_CUSTOMERS_BY_ERP_CUSTOMER_ID_BEGIN:
            return {
                ...state,
                loadingCustomersByErpCustomerId: true,
            }
        case types.LOAD_CUSTOMERS_BY_ERP_CUSTOMER_ID_SUCCESS:
            return {
                ...state,
                customersByErpCustomerId: action.payload,
                loadingCustomersByErpCustomerId: false,
            }
        case types.DELETE_INTEGRATION_ITEM_GROUP_BEGIN:
            return {
                ...state,
                deletingIntegrationItemGroup: true,
            }
        case types.DELETE_INTEGRATION_ITEM_GROUP_SUCCESS:
            return {
                ...state,
                deletingIntegrationItemGroup: false,
            }
        case types.LOAD_INTEGRATION_ITEM_GROUP_BEGIN:
            return {
                ...state,
                loadingIntegrationItemGroup: true,
            }
        case types.LOAD_INTEGRATION_ITEM_GROUP_SUCCESS:
            return {
                ...state,
                integrationItemGroup: action.payload,
                loadingIntegrationItemGroup: false,
            }
        case types.UPDATE_INTEGRATION_ITEM_GROUP_BEGIN:
            return  {
                ...state,
                updatingIntegrationItemGroup: true,
            }
        case types.UPDATE_INTEGRATION_ITEM_GROUP_SUCCESS:
            return  {
                ...state,
                updatingIntegrationItemGroup: false,
            }
        case types.LOAD_REPORTED_HOURS_BETWEEN_DATES_BEGIN:
            return {
                ...state,
                loadingReportedHoursBetweenDates: true
            }
        case types.LOAD_REPORTED_HOURS_BETWEEN_DATES_SUCCESS:
            return {
                ...state,
                reportedHoursBetweenDates: action.payload,
                loadingReportedHoursBetweenDates: false
            }
        case types.LOAD_UNHANDLED_SALARY_DETALIS_COUNT_BEGIN:
            return {
                ...state,
                loadingUnhandledSalaryDetailsCount: true
            }
        case types.LOAD_UNHANDLED_SALARY_DETALIS_COUNT_SUCCESS:
            return {
                ...state,
                unhandledSalaryDetailsCount: action.payload,
                loadingUnhandledSalaryDetailsCount: false
            }
        case types.LOAD_ALL_PROJECT_COUNTS_BEGIN:
            return {
                ...state,
                loadingAllProjectCounts: true
            }
        case types.LOAD_ALL_PROJECT_COUNTS_SUCCESS:
            return {
                ...state,
                loadingAllProjectCounts: false,
                allProjectCounts: action.payload
            }
        case types.LOAD_PROJECTS_ADRESS_INFORMATION_BEGIN:
            return {
                ...state,
                loadingProjectsAdressInformation: true,
            }
        case types.LOAD_PROJECTS_ADRESS_INFORMATION_SUCCESS:
            return {
                ...state,
                projectsAdressInformation: action.payload,
                loadingProjectsAdressInformation: false,
            }
        case types.POST_JSON_FILE_READ_BEGIN:
            return {
                ...state,
                loadingExportFromVisma: true
            }
        case types.POST_JSON_FILE_READ_SUCCESS:
            return {
                ...state,
                exportFromVisma: action.payload,
                loadingExportFromVisma: false
            }
        case types.LOAD_PRODUCT_TYPE_BY_NAME_BEGIN:
            return {
                ...state,
                loadingProductTypeByName: true
            }
        case types.LOAD_PRODUCT_TYPE_BY_NAME_SUCCESS:
            return {
                ...state,
                productTypeByName: action.payload,
                loadingProductTypeByName: false
            }
        case types.LOAD_TOTAL_AMOUNT_TO_INVOICE_BEGIN:
            return {
                ...state,
                loadingTotalAmountToInvoice: true,   
            }
        case types.LOAD_TOTAL_AMOUNT_TO_INVOICE_SUCCESS:
            return {
                ...state,
                loadingTotalAmountToInvoice: false,
                totalAmountToInvoice: action.payload
            }
        case types.LOAD_UNIT_BY_NAME_BEGIN:
            return {
                ...state,
                loadingUnitByName: true
            }
        case types.LOAD_UNIT_BY_NAME_SUCCESS:
            return {
                ...state,
                unitByName: action.payload,
                loadingUnitByName: false
            }
        case types.UPDATE_REVOKE_SOFT_DELETED_TIMETYPE_BEGIN:
            return {
                ...state,
                updatingSoftDeleteOnTimeType: true,
            }
        case types.UPDATE_REVOKE_SOFT_DELETED_TIMETYPE_SUCCESS:
            return {
                ...state,
                updatingSoftDeleteOnTimeType: false,
            }
        case types.POST_JSON_FILE_SAVE_BEGIN:
            return {
                ...state,
                updatingJsonFile: true,
            }
        case types.POST_JSON_FILE_SAVE_SUCCESS:
            return {
                ...state,
                updatingJsonFile: false,
            }
        case types.LOAD_ALL_ACTIVE_TASKS_WITH_DATES_BEGIN:
            return {
                ...state,
                loadingAllActiveTasksWithDates: true,
            }
        case types.LOAD_ALL_ACTIVE_TASKS_WITH_DATES_SUCCESS:
            return {
                ...state,
                loadingAllActiveTasksWithDates: false,
            }
        case types.UPDATE_USER_DETAILS_BEGIN:
            return {
                ...state,
                updatingUserDetails: true,
            }
        case types.UPDATE_USER_DETAILS_SUCCESS:
            return {
                ...state,
                updatingUserDetails: false,
            }
        case types.POST_IMPORT_SINGLE_FORTNOX_SUPPLIER_INVOICE_BEGIN:
            return {
                ...state,
                importingSupplierInvoice: true,
            }
        case types.POST_IMPORT_SINGLE_FORTNOX_SUPPLIER_INVOICE_SUCCESS:
            return {
                ...state,
                importingSupplierInvoice: false,
            }
        case types.LOAD_INVOICE_LINES_BEGIN:
            return {
                ...state,
                loadingInvoiceLines: true,
            }
        case types.LOAD_INVOICE_LINES_SUCCESS:
            return {
                ...state,
                invoiceLines: action.payload,
                loadingInvoiceLines: false,
            }
        case types.LOAD_PROJECT_ASSIGNMENTS_BEGIN:
            return {
                ...state,
                loadingProjectAssignments: true,
            }
        case types.LOAD_PROJECT_ASSIGNMENTS_SUCCESS:
            return {
                ...state,
                projectAssignments: action.payload,
                loadingProjectAssignments: false,
            }
        case types.LOAD_PROJECT_ACCESS_EMPLOYEE_RELATIONS_BEGIN:
            return {
                ...state,
                loadingProjectAccessEmployeeRelations: true,
            }
        case types.LOAD_PROJECT_ACCESS_EMPLOYEE_RELATIONS_SUCCESS:
            return {
                ...state,
                projectAccessEmployeeRelations: action.payload,
                loadingProjectAccessEmployeeRelations: false,
            }
        case types.CREATE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_BEGIN:
            return {
                ...state,
                creatingProjectAccessEmployeeRelations: true,
            }
        case types.CREATE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_SUCCESS:
            return {
                ...state,
                creatingProjectAccessEmployeeRelations: false,
            }
        case types.LOAD_TENANT_MIGRATION_STATUS_BEGIN:
            return {
                ...state,
                loadingTenantMigrationStatus: true,
            }
        case types.LOAD_TENANT_MIGRATION_STATUS_SUCCESS:
            return {
                ...state,
                tenantMigrationStatus: action.payload,
                loadingTenantMigrationStatus: false,
            }
        case types.LOAD_TENANT_MIGRATION_REDIRECT_URL_BEGIN:
            return {
                ...state,
                loadingTenantMigrationRedirectUrl: true,
            }
        case types.LOAD_TENANT_MIGRATION_REDIRECT_URL_SUCCESS:
            return {
                ...state,
                tenantMigrationRedirectUrl: action.payload,
                loadingTenantMigrationRedirectUrl: false,
            }
        case types.LOAD_GADGET_QUOTATION_SENDING_URL_BEGIN:
            return {
                ...state,
                loadingGadgetQuotationSendingUrl: true,
            }
        case types.LOAD_GADGET_QUOTATION_SENDING_URL_SUCCESS:
            return {
                ...state,
                gadgetQuotationSendingUrl: action.payload,
                loadingGadgetQuotationSendingUrl: false,
            }
        case types.LOAD_GADGET_VARIATION_SENDING_URL_BEGIN:
            return {
                ...state,
                loadingGadgetVariationSendingUrl: true,
            }
        case types.LOAD_GADGET_VARIATION_SENDING_URL_SUCCESS:
            return {
                ...state,
                gadgetVariationSendingUrl: action.payload,
                loadingGadgetVariationSendingUrl: false,
            }
        case types.CREATE_PIPEDRIVE_LEAD_BEGIN:
            return {
                ...state,
                creatingPipedriveLead: true,
            }
        case types.CREATE_PIPEDRIVE_LEAD_SUCCESS:
            return {
                ...state,
                creatingPipedriveLead: false,
            }
        case types.UPDATE_PIPEDRIVE_LEAD_BEGIN:
            return {
                ...state,
                updatingPipedriveLead: true,
            }
        case types.UPDATE_PIPEDRIVE_LEAD_SUCCESS:
            return {
                ...state,
                updatingPipedriveLead: false,
            }
        default:
            return {
                ...state,
            };
    }
};

export default appReducer;
