import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getCustomersByName = (
    token: string, 
    backend: string, 
    customerName: string,
    ) => {
    return fetch(`${getApiUrl(backend)}customers/by_name/${customerName}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getCustomersByName