import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";
 
const postSendUserInviteToCurrentTennant = (
    token: string,
    backend: string,
    Email: string,
    TenantRole: string,
    TenantEmployeeID: string,
    ) => {
    return fetch(
        `${getApiUrl(backend)}send_user_invite_to_current_tenant`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Email,
                TenantRole,
                TenantEmployeeID,
            })
        }
    );
};

export default postSendUserInviteToCurrentTennant;
