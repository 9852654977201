import getApiUrl from "../utils/apiUrl"
import fetch from "../utils/mgFetch";

const getActivateAccount = (activateToken: string, backend: string) => {
    return fetch(
        `${getApiUrl(backend)}authenticate/activation/${activateToken}`,
        {
            method: 'GET',
        }
    )
};  

export default getActivateAccount;
