
import getApiUrl from "../utils/apiUrl"
import fetch from "../utils/mgFetch";

const postBooking = (
    token: string, 
    backend: string, 
    StartTime, 
    EndTime, 
    EmployeeCount,
    ProjectID, 
    ProjectElementID, 
    OnWeekends,
    ChangedByID
    ) => {
    return fetch(`${getApiUrl(backend)}create_booking`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            StartTime, 
            EndTime, 
            EmployeeCount,
            ProjectID, 
            ProjectElementID, 
            OnWeekends,
            ChangedByID,
            CreatedByID: ChangedByID
        })
})};

export default postBooking;