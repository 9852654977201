import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getDoesEmployeeNumberExist = (token: string, backend: string, id) => {
    return fetch(`${getApiUrl(backend)}employees/does_employeenumber_exist/${id}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export default getDoesEmployeeNumberExist