import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postNote = (
    backend: string,
    token: string,
    Title: string,
    Text: string,
    Date: string,
    ProjectID: number,
    Public: boolean,
    EmployeeID: number,
    CreatedByID: number
) => {
    return fetch(
        `${getApiUrl(backend)}notes`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Title,
                Text,
                Date,
                Public: Public ? "true" : "false",
                ProjectID,
                AssignmentID: null,
                EmployeeID,
                CreatedByID,
                ChangedByID: CreatedByID
            })
        }
    );
};

export default postNote;
