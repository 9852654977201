import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putPriceListDiscount = (
    token: string,
    backend: string,
    ChangedByID: string,
    CreatedByID: string,
    PriceListID: string,
    Discount: string,
    ArticleID: string,
    Type: string,
    id: string,
    FixedPrice: string,
) => {

    const formData = new FormData();

    formData.append('ChangedByID', ChangedByID)
    formData.append('CreatedByID', CreatedByID)
    formData.append('PriceListID', PriceListID)
    formData.append('Discount', Discount)
    formData.append('FixedPrice', FixedPrice)

    //Article, Veichle, Compensation
    if(Type === "article"){
        formData.append('ProductID', ArticleID)
    }
    //Task
    else if(Type === "task"){
        formData.append('TaskID', ArticleID)
    }
    //Article category
    else if(Type === "articleCategory"){
        formData.append('ProductTypeID', ArticleID)
    }
    //Task category
    else if(Type === "taskCategory"){
        formData.append('TaskTypeID', ArticleID)
    }
        
    return fetch(
        `${getApiUrl(backend)}price_list_discounts/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData
        }
    );
};

export default putPriceListDiscount;