import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getAttests = (
    backend: string,
    token: string,
    ReportedTimeID,
    ReportedProductID,
    ReportedAbsenceID,
) => {
    return fetch(
        `${getApiUrl(backend)}attests?${ReportedTimeID ? "&ReportedTimeID=" + ReportedTimeID : ""}${ReportedProductID ? "&ReportedProductID=" + ReportedProductID : ""}${ReportedAbsenceID ? "&ReportedAbsenceID=" + ReportedAbsenceID : ""}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getAttests;
