import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getInvoicesWithDates = (
    token: string, 
    backend: string, 
    startDate?: string, 
    endDate?: string,
    ) => {
    return fetch(`${getApiUrl(backend)}get_invoices_with_dates/${startDate}/${endDate}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getInvoicesWithDates