import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import appReducer, { AppState } from './store/reducers/appReducer';
import modalReducer from './components/GenericModal/ModalSlice';
import loginReducer from './login/LoginSlice';

export type RootState = {
    app: AppState
}

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        app: appReducer,
        modal: modalReducer,
        login: loginReducer,
    });

export default createRootReducer;
