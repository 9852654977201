import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getProjectForms = (
    backend: string,
    token: string,
    employee: number,
    onlyNotSigned: boolean,
    project?
) => {
    return fetch(
        `${getApiUrl(backend)}project_forms?${employee ? '&employee=' + employee : ''}${project ? '&project=' + project : ''}${onlyNotSigned ? '&signed=false' : ''}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getProjectForms;
