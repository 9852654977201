import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch"

const deleteSupplierInvoice = (
    token: string,
    backend: string,
    id: number
) => {
    return fetch(
        `${getApiUrl(backend)}supplier_invoices/${id}`,
        {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
        }
    );
};

export default deleteSupplierInvoice;
