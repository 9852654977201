import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const deleteHouseWorkDeductionType = (
    token: string,
    backend: string,
    id
) => {
    return fetch(
        `${getApiUrl(backend)}house_work_deduction_types/${id}`,
        {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            }
        }
    );
};

export default deleteHouseWorkDeductionType;
