import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postMarkProjectAsUndone = (
    backend: string,
    token: string,
    id: number
) => {
    return fetch(
        `${getApiUrl(backend)}unMarkProjectEmployeeDoneMarker/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
};

export default postMarkProjectAsUndone;
