import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getTenantMigrationStatus = (token, backend) => {
    return fetch(`${getApiUrl(backend)}tenant-migration-status`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export default getTenantMigrationStatus