
import getApiUrl from "../utils/apiUrl"
import fetch from "../utils/mgFetch";

const postBookingResource = (
    token: string, 
    backend: string, 
    BookingID, 
    ProductID, 
    EmployeeID,
    CreatedByID
    ) => {
    return fetch(`${getApiUrl(backend)}create_booking_resource`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            BookingID, 
            ProductID,
            EmployeeID,
            CreatedByID,
            ChangedByID: CreatedByID
        })
})};

export default postBookingResource;