import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postCustomerObject = (
    backend: string,
    token: string,
    customer_id,
    name,
    description, 
    adress_line1,
    adress_line2,
    adress_line3,
    adress_line4,
    postal_code,
    city,
    country,
    contact_person,
    phone_number,
    lat,
    long,
    created_by_id,
) => {
    return fetch(
        `${getApiUrl(backend)}customerobjects`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                customer_id,
                name,
                description, 
                adress_line1,
                adress_line2,
                adress_line3,
                adress_line4,
                postal_code,
                city,
                country,
                contact_person: contact_person ? contact_person : "",
                phone_number,
                lat,
                long,
                created_by_id,
                changed_by_id: created_by_id
            })
        }
    );
};

export default postCustomerObject;
