import angular from 'angular';
import { reactAngularModule } from 'react-angular';
import filterPanel from './filterPanel/filterPanel';
import reportCard from './reportCard/reportCard';
import signaturePad from './signaturePad/signaturePad';
import MyBookingsController from './home/MyBookingsController';
import CalendarController from './home/CalendarController';

import HeaderNotification from './directives/header-notification/HeaderNotification';

import 'angular-filter';
import 'angular-moment';
import 'ui-select';
import 'angular-sanitize';
import 'angular-ui-tree';
import './toggleSwitch/toggleSwitch';
import './toggleSwitch/toggleSwitch.css';
import './resourcePlanner/ui-select.css';

import './home/calendar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/overrides.css';
import './css/sb-admin-2.css';

import 'jsplumb'
import 'font-awesome/css/font-awesome.min.css';

import $ from 'jquery';
import projectCard from './projectCard/projectCard';
import datepicker from './datepicker/datepicker';
import documentButton from './documentButton/documentButton';

import "css-element-queries/src/ResizeSensor.js"
import "css-element-queries/src/ElementQueries.js"

import "angular-gantt/assets/angular-gantt.css"
import "angular-gantt/assets/angular-gantt-tree-plugin.css"
import "angular-gantt/assets/angular-gantt-movable-plugin.css"
import "angular-gantt/assets/angular-gantt-table-plugin.css"
import "angular-gantt/assets/angular-gantt-tooltips-plugin.css"
import "angular-gantt/assets/angular-gantt-progress-plugin.css"
import "angular-gantt/assets/angular-gantt-groups-plugin.css"
import "angular-gantt/assets/angular-gantt-dependencies-plugin.css"

import "angular-gantt/assets/angular-gantt"
import "angular-gantt/assets/angular-gantt-tree-plugin"
import "angular-gantt/assets/angular-gantt-table-plugin"
import "angular-gantt/assets/angular-gantt-progress-plugin"
import "angular-gantt/assets/angular-gantt-drawtask-plugin"
import "angular-gantt/assets/angular-gantt-movable-plugin"
import "angular-gantt/assets/angular-gantt-groups-plugin"
import "angular-gantt/assets/angular-gantt-resizeSensor-plugin"
import "angular-gantt/assets/angular-gantt-tooltips-plugin"
import "angular-gantt/assets/angular-gantt-dependencies-plugin"

window.$ = window.jQuery = $;


require('jquery-ui/ui/widgets/datepicker');
require('bootstrap');
require('angular-bootstrap/ui-bootstrap-tpls');
require('angular-sanitize')

/**
 * @ngdoc overview
 * @name sbAdminApp
 * @description
 * # sbAdminApp
 *
 * Main module of the application.
 */

class angularApp {
    static init(requestStarted, requestCompleted) {
        const app = angular.module('sbAdminApp', [
            // reactAngularModule(false).name,
            'angular.filter',
            'ui.bootstrap',
            'ngSanitize',
            'ui.select',
            'toggle-switch',
            'gantt',
            'gantt.tree',
            'gantt.table',
            'gantt.movable',
            'gantt.drawtask',
            'gantt.resizeSensor',
            'gantt.tooltips',
            'gantt.progress',
            'gantt.groups',
            'gantt.dependencies',
        ]);

        // app.run((reactAngularProductionReady) => reactAngularProductionReady());

        // https://stackoverflow.com/questions/18611214/turn-off-url-manipulation-in-angularjs
        // Disable AngularJS location service so that i does not interfear with React Router
        app.config([
            '$provide',
            function ($provide) {
                $provide.decorator('$browser', [
                    '$delegate',
                    function ($delegate) {
                        $delegate.onUrlChange = function () {};
                        $delegate.url = function () {
                            return '';
                        };
                        return $delegate;
                    },
                ]);
            },
        ]);

        let requests = 0;
        let responses = 0;
        let errors = 0;
        app.config([
            '$httpProvider',
            function ($httpProvider) {
                $httpProvider.interceptors.push(function () {
                    return {
                        request: function (config) {
                            if (config.url && config.url.indexOf('api') > 0) {
                                requestStarted();
                            }

                            return config;
                        },
                        response: function (response) {
                            if (response.config.url && response.config.url.indexOf('api') > 0) {
                                requestCompleted();
                            }

                            return response;
                        },
                        responseError: (error) => {
                            requestCompleted()
                            return error;
                        },
                        requestError: (error) => {
                            requestCompleted()
                            return error;
                        },
                    };
                });
            },
        ]);

        /**
         *  Thanks to: https://codepen.io/WinterJoey/pen/sfFaK
         */
        
        app.filter('capitalize', function () {
            return function (input, all) {
                var reg = all ? /([^\W_]+[^\s-]*) */g : /([^\W_]+[^\s-]*)/;

                return !!input
                    ? input.replace(reg, function (txt) {
                          return (
                              txt.charAt(0).toUpperCase() +
                              txt.substr(1).toLowerCase()
                          );
                      })
                    : '';
            };
        });

        app.filter('passwordCount', [
            function () {
                return function (value, peak) {
                    var value = angular.isString(value) ? value : '',
                        peak = isFinite(peak) ? peak : 7;

                    return (
                        value &&
                        (value.length > peak ? peak + '+' : value.length)
                    );
                };
            },
        ]);

        app.directive('customOnDrop', function () {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    var onDropHandler = scope.$eval(attrs.customOnDrop);

                    var isAdvancedUpload = (function () {
                        var div = document.createElement('div');
                        return (
                            ('draggable' in div ||
                                ('ondragstart' in div && 'ondrop' in div)) &&
                            'FormData' in window &&
                            'FileReader' in window
                        );
                    })();
                    if (isAdvancedUpload) {
                        element.addClass('dropArea');
                        element.on(
                            'drag dragstart dragend dragover dragenter dragleave drop',
                            function (e) {
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        );
                        element.bind('dragover dragenter', function (e) {
                            element.addClass('is-dragover');
                        });
                        element.bind('dragleave dragend drop', function (e) {
                            element.removeClass('is-dragover');
                        });
                        element.bind('drop', function (e) {
                            onDropHandler(e);
                        });
                    }
                },
            };
        });

        app.directive('customOnChange', function () {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    var onChangeHandler = scope.$eval(attrs.customOnChange);
                    element.on('change', onChangeHandler);
                    element.on('$destroy', function () {
                        element.off();
                    });
                },
            };
        });

        /**
         * @ngdoc directive
         * @name izzyposWebApp.directive:oneClickOnly
         * @description
         * This is a directive that replaces ng-click, and makes sure that it is only possible to click once on form submit
         * buttons.
         * Credit for this directive goes to: Daniel van Heerden. url: https://stackoverflow.com/a/36860120. Altough I've made
         * some changes. This code is under the MIT-license according to this statement:
         * https://meta.stackexchange.com/questions/271080/the-mit-license-clarity-on-using-code-on-stack-overflow-and-stack-exchange
         * As the post was from after the 1st of february 2016 this is in fact under the MIT-license.
         */
        app.directive('oneClickOnly', [
            '$parse',
            '$compile',
            function ($parse, $compile) {
                return {
                    restrict: 'A',
                    compile: function (tElement, tAttrs) {
                        if (tAttrs.ngClick)
                            throw 'Cannot have both ng-click and one-click-only on an element';

                        tElement.attr('ng-click', 'oneClick($event)');
                        tElement.attr('ng-dblclick', 'dblClickStopper($event)');

                        tElement.removeAttr('one-click-only');
                        var fn = $parse(tAttrs['oneClickOnly']);

                        return {
                            pre: function (
                                scope,
                                iElement,
                                iAttrs,
                                controller
                            ) {
                                //console.log(scope, controller);
                                var run = false;
                                var timeOfClick = null;
                                scope.oneClick = function (event) {
                                    if (run) {
                                        if (timeOfClick !== null) {
                                            if (
                                                new Date().getTime() <
                                                timeOfClick + 1000
                                            ) {
                                                throw 'Already clicked';
                                            }
                                        }
                                    }
                                    run = true;
                                    timeOfClick = new Date().getTime();

                                    //console.log('time of click: ' + timeOfClick);

                                    //$(event.toElement).attr('disabled', 'disabled');

                                    fn(scope, { $event: event });

                                    return true;
                                };
                                scope.dblClickStopper = function (event) {
                                    event.preventDefault();
                                    throw 'Double click not allowed!';
                                    return false;
                                };

                                $compile(iElement)(scope);
                            },
                        };
                    },
                    scope: true,
                };
            },
        ]);

        app.controller('MyBookingsController', MyBookingsController);
        app.controller('CalendarController', CalendarController);
        
        app.directive('headerNotification', HeaderNotification);

        filterPanel();
        reportCard();
        signaturePad();
        projectCard();
        datepicker();
        documentButton();
    }
}

export default angularApp;
