import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putEmployee = (
    token: string,
    backend: string,
    id: string,
    FirstName?: string,
    LastName?: string,
    PhoneNumber?: string,
    employee_nr?: string,
    adress_line1?: string,
    socialSecurityNumber?: string,
    e_mail?: string,
    Role?: string,
    EmployeeNumber?: string, 
) => {
    return fetch(
        `${getApiUrl(backend)}employees/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                employee_nr,
                FirstName,
                LastName,
                PhoneNumber,
                EmployeeNumber,
                adress_line1,
                socialSecurityNumber,
                e_mail,
                Role,
            })
        }
    );
};

export default putEmployee