import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postReportedTime = (
    backend: string,
    token: string,
    employee_id: number,
    time_in_hours: number,
    date: string,
    created_by_id: number,
    time_type_id: number,
    comment: string,
    assignment_id: number,
    task_id: number,
    project_id: number,
    customer_id: number,
    sales_price,
    cost_price,
    salary_time_in_hours?, 
    invoice_time_in_hours?,
    project_variation_id?
) => {
    return fetch(
        `${getApiUrl(backend)}reportedtimes`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                employee_id,
                time_in_hours,
                date,
                created_by_id,
                changed_by_id: created_by_id,
                time_type_id,
                comment,
                assignment_id,
                task_id,
                project_id,
                customer_id,
                sales_price,
                cost_price,
                salary_time_in_hours,
                invoice_time_in_hours,
                project_variation_id
            })
        }
    );
};

export default postReportedTime;
