import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postAttests = (
    backend: string,
    token: string,
    attests: any[]
) => {
    return fetch(
        `${getApiUrl(backend)}attests`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({attests})
        }
    );
};

export default postAttests;
