import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getInvoiceLines = (token: string, backend: string, filter, projectId) => {
    return fetch(`${getApiUrl(backend)}invoice_lines/${filter}/${projectId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
    }) 
}

export default getInvoiceLines