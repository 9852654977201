import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postImportSingleFortnoxDataToMyGizmo = (
    token: string,
    backend: string,
    key,
    id,
    created_by_id
) => {
    return fetch(
        `${getApiUrl(backend)}import_single_fortnox_data_to_mygizmo`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                [key]: id,
                created_by_id
            })
        }
    );
};

export default postImportSingleFortnoxDataToMyGizmo;
