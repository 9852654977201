import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getProjectCounts = (
    token: string,
    backend: string,
    search: string,
    customerId?: number,
    projectLeader?: number
) => {
    return fetch(
        `${getApiUrl(backend)}project_counts/?search=${search}${customerId ? '&customer=' + customerId : ''}${projectLeader ? '&projectLeader=' + projectLeader : ''}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getProjectCounts;
