import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putTask = (
    token: string,
    backend: string,
    created_by_id: string,
    changed_by_id: string,
    title: string,
    description: string,
    task_type_id: string,
    active: number,
    sales_price: string,
    cost_price: string,
    on_salary: string,
    id: string,
    erpProductId?: any,
    fortnox_article_number?: any,
) => {
 
    return fetch(    
        `${getApiUrl(backend)}tasks/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                created_by_id,
                changed_by_id,
                title,
                description,
                task_type_id,
                active,
                sales_price: sales_price == "0" ? "0.00" : sales_price,
                cost_price: cost_price == "0" ? "0.00" : cost_price,
                on_salary,
                erp_product_id: erpProductId ? erpProductId : null,
                fortnox_article_number,
            })
        }
    );
};

export default putTask