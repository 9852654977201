export default `<div>
    <button style="position: relative; margin: 9px 0;" type="button" class="btn btn-default" ng-class="(documents.length > 0 || (lazy && documentCount && documentCount > 0)) ? 'btn-success' : ''" ng-click="toggleDocumentModal()" style="margin: 4px;">
        <i class="fa fa-file-o fa-fw"></i>
        <div ng-if="!fetching" style="display: flex; justify-content: center; align-items: center; position: absolute; font-size: 0.8em; height: 22px; width: 22px; border-radius: 50%; top: -10px; right: -10px; background: darkorange; color: white;">
            {{documents.length}}
        </div>
        <div ng-if="fetching && lazy && documentCount" style="display: flex; justify-content: center; align-items: center; position: absolute; font-size: 0.8em; height: 22px; width: 22px; border-radius: 50%; top: -10px; right: -10px; background: darkorange; color: white;">
            {{documentCount}}
        </div>
    </button>
    <div class="modal fade" id="{{documentsModalId}}">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="border: 0; display: flex; justify-content: space-between; align-items: flex-start;">
                    <div style="display: flex; flex-wrap: wrap; flex: 1; margin-right: 24px;">
                        <h4 class="modal-title" style="flex: 1; white-space:normal; margin-bottom: 16px;">{{headerText ? headerText : "Dokument"}}</h4>
                        <select ng-change="handleFilterChange()" style="width: 200px;" class="form-control" ng-model="documentCategoryFilter">
                            <option value="" selected>Visa alla</option>
                            <option value="null">Endast okatigoriserade</option>
                            <option disabled ng-if="fetchingCategories">
                                Hämtar kategorier..    
                            </option>
                            <option ng-repeat="category in documentCategories" value="{{category.id}}">
                                {{category.Name}}
                            </option>
                        </select>
                    </div>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                </div>    
                <div class="modal-body" style="padding: 0;">
                    <div style="display: flex; flex-direction: column;">
                        <div ng-if="fetching" style="display: flex; margin: 15px;">
                            <div class="loaderIcon" style="margin-right: 8px;"></div>
                            <p>Hämtar dokument..</p>
                        </div>
                        <p style="margin: 15px;" ng-if="getDocumentsError">{{getDocumentsError}}</p>
                        <p style="margin: 15px;" ng-if="documents.length==0 && !fetching && !getDocumentsError && !updating">Inga uppladdade dokument.</p>
                        <div ng-if="updating" style="display: flex; justify-content: center; align-items: center; position: absolute; top:0; left: 0; height: 100%; width: 100%; z-index: 10; background: #ffffff99;"></div>
                        <div ng-if="documents.length > 0"  style="overflow:auto; max-height: calc(100vh - 200px); padding: 15px;">
                            <div ng-repeat="document in documents | filter: { CategoryID: documentCategoryFilterValue}">
                                <p style="margin: 24px 0 2px 0;" ng-if="document.ProjectName">Tillhör kundprojekt {{document.ProjectName}}</p>
                                <p style="margin: 24px 0 2px 0;" ng-if="document.CustomerName">Tillhör projektkund {{document.CustomerName}} {{document.CustomerMiddleName}} {{document.CustomerLastName}}</p>
                                <div 
                                    style="box-shadow: 0 0 15px lightgrey; background:white; display: flex; flex-direction: column; justify-content: space-between; padding: 12px; margin-bottom: 16px;" 
                                    >
                                    <div style="display: flex;">
                                        <div style="position: relative; display: flex; justify-content: center; align-items: flex-start; width: 50px; margin-right: 12px;">
                                            <img ng-if="document.MIMEtype.indexOf('image') != -1" ng-src="{{document.url}}" style="width: 50px; height: 50px; object-fit: cover; border-radius: 50%;"/>
                                            <video ng-if="document.MIMEtype.indexOf('video') != -1" ng-src="{{trustSrc(document.url)}}" style="width: 50px; height: 50px; object-fit: cover; border-radius: 50%;"></video>
                                            <div ng-if="document.MIMEtype == 'application/pdf'" style="font-weight: bold; height: 50px; width: 50px; display: flex; justify-content: center; align-items: center;">PDF</div>
                                            <div ng-if="document.MIMEtype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" style="font-weight: bold; height: 50px; width: 50px; display: flex; justify-content: center; align-items: center; text-align: center">MS Word</div>
                                        </div>
                                        <div style="flex: 1; display: flex; justify-content: space-between;">
                                            <div>
                                                <p style="font-weight: bold;">{{document.Title}}</p>
                                                <p>{{document.Text}}</p>
                                                <p>{{formatCreatedDate(document.created_at)}}</p>
                                            </div>
                                            <div>
                                                <p style="font-style: italic;">{{document.CategoryName}}</p> 
                                            </div>
                                        </div>
                                    </div>
                                    <div style="display: flex; justify-content: space-between; align-items: center;">
                                        <div>
                                            <button 
                                                class="btn btn-default" 
                                                style="margin-right: 8px;"
                                                ng-disabled="!document.url || document.url === ''"
                                                ng-click="viewDocument(document)">
                                                Öppna
                                            </button>
                                            <button 
                                                ng-disabled="!document.UserAllowedToEdit"
                                                class="btn btn-default" 
                                                ng-click="openEditDocumentModal(document)">
                                                Redigera
                                            </button>
                                        </div>
                                        <div>
                                            <a style="color: #5d5d5d;" target="_blank" download="true" href="{{document.url}}">
                                                <button 
                                                    class="btn btn-default">
                                                    <span class="glyphicon glyphicon-download"></span>
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer" style="border: 0;">
                    <button type="submit" class="btn btn-primary" style=float:right ng-click="togglePickDocumentModal()">
                        Ladda upp dokument
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="{{viewerModalId}}">
        <!-- <div ng-if="selectedDocument.url && selectedDocument.url != ''" class="modal-dialog"> -->
        <div class="modal-dialog">
            <div ng-if="selectedDocument.MIMEtype == 'application/pdf'" class="modal-content" style="display: flex; flex-direction: column; height: calc(100vh - 100px); background: #e6e6e6; width:650px; max-width: calc(100vw - 20px);">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close""><span aria-hidden="true">×</span></button>
                </div> 
                <pdfjs-viewer style="height: 100%;" src="{{ trustSrc(selectedDocument.url) }}"></pdfjs-viewer>
            </div>
            <div ng-if="selectedDocument.MIMEtype.indexOf('image') != -1" class="modal-content" style="display: flex; flex-direction: column; max-height: calc(100vh - 100px); background: #e6e6e6; width:650px; max-width: calc(100vw - 20px);">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close""><span aria-hidden="true">×</span></button>
                </div> 
                <div class="modal-body" style="position: relative; display: flex; justify-content: center; align-items: center;">
                    <img style="max-width: 100%; max-height: 100%;" ng-src="{{selectedDocument.url}}">
                </div>
            </div>
            <div ng-if="selectedDocument.MIMEtype.indexOf('video') != -1" class="modal-content" style="display: flex; flex-direction: column; max-height: calc(100vh - 100px); background: #e6e6e6; width:650px; max-width: calc(100vw - 20px);">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close""><span aria-hidden="true">×</span></button>
                </div> 
                <div class="modal-body" style="position: relative; display: flex; justify-content: center; align-items: center;">
                    <video controls="true" style="max-width: 100%; max-height: 100%;" ng-src="{{trustSrc(selectedDocument.url)}}">
                </div>
            </div>
            <div 
                ng-if="selectedDocument.MIMEtype == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" 
                class="modal-content" 
                style="display: flex; flex-direction: column; max-height: calc(100vh - 100px); background: #e6e6e6; width:650px; max-width: calc(100vw - 20px);">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close""><span aria-hidden="true">×</span></button>
                </div> 
                <div class="modal-body" style="position: relative; overflow: auto; padding: 5px;">
                    <div style="overflow: auto;" id="{{docxViewerId}}"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="{{uploadDocumentModalId}}" tabindex="-1" role="dialog" aria-labelledby="myModalImageLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close""><span aria-hidden="true">×</span></button>
                    <h4 class="modal-title">Ladda upp ett dokument</h4>
                    <p ng-if="pickedDocumentToBig" style="margin: 0;" class="text-warning">Filen får vara max 20MB stor</p>
                </div>
                <div  class="modal-body" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <form style="width: 240px;">
                        <div class="form-group">
                            <label>Titel</label>
                            <input ng-model="TitleInput" class="form-control" />
                        </div>
                        <div class="form-group">
                            <label>Text</label>
                            <input ng-model="TextInput" class="form-control" />
                        </div>
                        <p ng-if="pickedDocumentToBig" style="margin: 0;" class="text-warning">Filen får vara max 20MB stor</p>
                        <div class="form-group">
                            <label>Kategori</label>
                            <select class="form-control" ng-model="selectedCategoryID">
                                <option value="" selected>-- Välj här --</option>
                                <option disabled ng-if="fetchingCategories">
                                    Hämtar kategorier..    
                                </option>
                                <option ng-repeat="category in documentCategories" value="{{category.id}}">
                                    {{category.Name}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group" style="margin-top: 20px;">
                            <table style="width: 100%;">
                                <thead>
                                    <th>Behörighet</th>
                                    <th style="text-align: center;">Alla</th>
                                    <th style="text-align: center;">Admin</th>
                                </thead>
                                <tr>
                                    <td>
                                        Får se
                                    </td>
                                    <td style="text-align: center;">
                                        <input 
                                            style="cursor: pointer;"
                                            ng-click="setReadAccessLevel(0)" 
                                            class="form-check-input" 
                                            type="radio"
                                            value="option1" 
                                            ng-checked="readAccessLevelInput === 0">
                                    </td>
                                    <td style="text-align: center;">
                                        <input 
                                            style="cursor: pointer;"
                                            ng-click="setReadAccessLevel(1)" 
                                            class="form-check-input" 
                                            type="radio"
                                            value="option1" 
                                            ng-checked="readAccessLevelInput === 1">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Får redigera
                                    </td>
                                    <td style="text-align: center;">
                                        <input 
                                            style="cursor: pointer;"
                                            ng-click="setEditAccessLevel(0)" 
                                            class="form-check-input" 
                                            type="radio"
                                            value="option1" 
                                            ng-checked="editAccessLevelInput === 0">
                                    </td>
                                    <td style="text-align: center;">
                                        <input 
                                            style="cursor: pointer;"
                                            ng-click="setEditAccessLevel(1)" 
                                            class="form-check-input" 
                                            type="radio"
                                            value="option1" 
                                            ng-checked="editAccessLevelInput === 1">
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <div class="form-group" ng-switch="!!pickedDocumentFile">
                            <label>Dokument</label>
                            <p>Video, bild, .pdf eller .docx  (Max 20MB)</p>
                            <div ng-switch-when="false" style="min-height: 84px; ">
                                <div custom-on-drop="handleDrop">
                                    <div>
                                        <label style="font-weight: normal;">Släpp fil eller</label>
                                        <label for="{{uploadFileInputId}}" class="btn btn-default">
                                            välj en fil
                                        </label>
                                    </div>
                                </div>
                                <p ng-if="pickedDocumentToBig" style="margin: 0;" class="text-warning">Filen får vara max 20MB stor</p>
                                <p ng-if="wrongFileType" style="margin: 0;" class="text-warning">Endast bilder och pdf:er stöds</p>
                                <div style="height: 0; width: 0; overflow: hidden;">
                                    <input 
                                        id="{{uploadFileInputId}}" 
                                        ng-value="pickedDocumentFile" 
                                        custom-on-change="handleDocumentPicked" 
                                        accept="application/pdf,image/*,video/mp4,application/vnd.openxmlformats-officedocument.wordprocessingml.document" 
                                        type="file">
                                </div>
                            </div>
                            <div ng-switch-when="true">
                                <p style="margin: 0;">{{ pickedDocumentFile ? pickedDocumentFile.name : ""}}</p>
                                <p>{{ pickedDocumentFile ? pickedDocumentFile.type : ""}}</p>
                                <p>{{ pickedDocumentFile ? (pickedDocumentFile.size/1000000).toFixed(2) + "MB" : ""}}</p>
                                <button ng-click="removePickedDocument()" class="btn btn-warning">Ta bort</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button ng-disabled="!pickedDocumentFile || uploading" type="submit" ng-click="upload()" class="btn btn-primary" style=float:right>
                        <div ng-if="uploading" style="display: flex;"><div class="loaderIcon" style="margin-right: 8px;"></div><div>Laddar upp...</div></div>
                        <div ng-if="!uploading">Ladda upp</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="{{editDocumentModalId}}" tabindex="-1" role="dialog" aria-labelledby="myModalImageLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close""><span aria-hidden="true">×</span></button>
                    <h4 class="modal-title" style="color:darkorange" id="myModalImageLabel"><i
                            class="fa fa-edit fa-fw" style="color: lightgray;"></i>Redigera dokument</h4>
                </div>
                <div class="modal-body" style="display: flex; flex-direction: column; justify-content: center; align-items: center;">
                    <form>
                        <div class="form-group">
                            <label>Titel</label>
                            <input ng-model="TitleInput" class="form-control" />
                        </div>
                        <div class="form-group">
                            <label>Text</label>
                            <input ng-model="TextInput" class="form-control" />
                        </div>
                        <div class="form-group">
                            <label>Kategori</label>
                            <select class="form-control" ng-model="selectedCategoryID">
                                <option value="">-- Välj här --</option>
                                <option disabled ng-if="fetchingCategories">
                                    Hämtar kategorier..    
                                </option>
                                <option ng-repeat="category in documentCategories" value="{{category.id}}">
                                    {{category.Name}}
                                </option>
                            </select>
                        </div>
                        <div class="form-group" style="margin-top: 20px;">
                            <table style="width: 240px;">
                                <thead>
                                    <th>Behörighet</th>
                                    <th style="text-align: center;">Alla</th>
                                    <th style="text-align: center;">Admin</th>
                                </thead>
                                <tr>
                                    <td>
                                        Får se
                                    </td>
                                    <td style="text-align: center;">
                                        <input 
                                            style="cursor: pointer;"
                                            ng-click="setReadAccessLevel(0)" 
                                            class="form-check-input" 
                                            type="radio"
                                            value="option1" 
                                            ng-checked="readAccessLevelInput === 0">
                                    </td>
                                    <td style="text-align: center;">
                                        <input 
                                            style="cursor: pointer;"
                                            ng-click="setReadAccessLevel(1)" 
                                            class="form-check-input" 
                                            type="radio"
                                            value="option1" 
                                            ng-checked="readAccessLevelInput === 1">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        Får redigera
                                    </td>
                                    <td style="text-align: center;">
                                        <input 
                                            style="cursor: pointer;"
                                            ng-click="setEditAccessLevel(0)" 
                                            class="form-check-input" 
                                            type="radio"
                                            value="option1" 
                                            ng-checked="editAccessLevelInput === 0">
                                    </td>
                                    <td style="text-align: center;">
                                        <input 
                                            style="cursor: pointer;"
                                            ng-click="setEditAccessLevel(1)" 
                                            class="form-check-input" 
                                            type="radio"
                                            value="option1" 
                                            ng-checked="editAccessLevelInput === 1">
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button ng-disabled="uploading || deleting" type="submit" ng-click="deleteDocument()" class="btn btn-danger" style="margin-right: 8px;">
                        <div ng-if="deleting" style="display: flex;"><div class="loaderIcon" style="margin-right: 8px;"></div><div>Raderar dokument...</div></div>
                        <div ng-if="!deleting">Radera dokument</div>
                    </button>
                    <button ng-disabled="uploading || deleting" type="submit" ng-click="saveDocumentEdit()" class="btn btn-primary">
                        <div ng-if="uploading" style="display: flex;"><div class="loaderIcon" style="margin-right: 8px;"></div><div>Sparar...</div></div>
                        <div ng-if="!uploading">Spara</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>`