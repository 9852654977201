import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postProjectProductAssignment = (
    backend: string,
    token: string,
    project_id: number,
    product_id: number,
    created_by_id: number,
    description?: string,
    sales_price?: any,
    cost_price?: any,
    estimated_quantity?: any,
    quantity?: any,
    unhandledQuantity?: any,
    toInvoiceQuantity?: any,
    discount?: any,
    active?: any,
    id?: any,
) => {

    return fetch(
        
        `${getApiUrl(backend)}product_assignments${id ? '/' + id : ''}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                active: active === 0 ? 0 : 1,
                project_id,
                product_id,
                created_by_id,
                changed_by_id: created_by_id,
                description,
                sales_price,
                cost_price,
                estimated_quantity,
                quantity,
                unhandledQuantity,
                toInvoiceQuantity,
                discount,
            })
        }
    );
};

export default postProjectProductAssignment;
