import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch"

const deleteArticle = (
    backend: string,
    token: string,
    id: number
) => {
 
    return fetch(
        
        `${getApiUrl(backend)}products/${id}`,
        {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
        }
    );
};

export default deleteArticle