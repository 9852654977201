import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postReportedAbsence = (
    backend: string,
    token: string,
    employeeID: number,
    timeinhours: number,
    date: string,
    created_by_id: number,
    comment: string,
    absenceID: number
) => {
    return fetch(
        `${getApiUrl(backend)}employee_absence`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                employeeID,
                timeinhours,
                date,
                created_by_id,
                changed_by_id: created_by_id,
                comment,
                absenceID,
                deleted_at: null
            })
        }
    );
};

export default postReportedAbsence;
