import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postProduct = (
    backend: string,
    token: string,
    name: string,
    description: string,
    unit_id: string,
    product_kind: string,
    created_by_id: number
) => {
    return fetch(
        `${getApiUrl(backend)}products`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name,
                description,
                unit_id,
                active: 1,
                product_kind,
                created_by_id,
                changed_by_id: created_by_id
            })
        }
    );
};

export default postProduct;
