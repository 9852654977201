import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getTimeTypesByErpProductId = (
    token: string,
    backend: string,
    erpproductid: number,
) => {
 
    return fetch(
        `${getApiUrl(backend)}timeTypes/by_erpproductid/${erpproductid}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getTimeTypesByErpProductId;