import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const isValid = (email, password) => password.length > 0 && email.indexOf("@") > -1 && email.split('@')[1].indexOf(".") > -1;

const loginSlice = createSlice({
    name: 'login',
    initialState: { email: '', password: '', isValid: false },
    reducers: {
        setEmail(state, action: PayloadAction<string>) {
            state.email = action.payload;
            state.isValid = isValid(state.email, state.password);
        },
        setPassword(state, action: PayloadAction<string>) {
            state.password = action.payload;
            state.isValid = isValid(state.email, state.password);
        },
        clearForm(state) {
            state.email = '';
            state.password = '';
            state.isValid = false;
        },
        clearPassword(state) {
            state.password = '';
            state.isValid = false;
        }
    },
});

export const { setEmail, setPassword, clearForm, clearPassword } = loginSlice.actions;
export default loginSlice.reducer;
