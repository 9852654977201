import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putProjectElement = (
    backend: string,
    token: string,
    id,
    {
        Description,
        ColorCode,
        EstimatedTimeInHours,
        FixedPrice,
        ProjectID,
        TaskID,
        ParentProjectElementID
    },
    ChangedByID
) => {
    return fetch(
        `${getApiUrl(backend)}project_elements/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Description,
                ColorCode,
                EstimatedTimeInHours,
                FixedPrice,
                ProjectID,
                TaskID,
                ParentProjectElementID,
                ChangedByID 
            })
        }
    );
};

export default putProjectElement;
