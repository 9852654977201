import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postSupplierInvoiceProjectRelation = (
    backend: string,
    token: string,
    ProjectID,
    SupplierInvoiceID,
    Amount,
    Charge,
    AttachToInvoice,
    ProjectVariationID,
    CreatedByID
) => {
    return fetch(
        `${getApiUrl(backend)}supplier_invoice_project_relations`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ProjectID,
                SupplierInvoiceID,
                Amount,
                Charge,
                AttachToInvoice,
                ProjectVariationID: ProjectVariationID ? ProjectVariationID : null,
                CreatedByID,
                ChangedByID: CreatedByID
            })
        }
    );
};

export default postSupplierInvoiceProjectRelation;
