import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postSalary = (
    backend: string,
    token: string,
    EmployeeID,
    SalaryStartDate,
    SalaryEndDate,
    ReportedAbsences,
    ReportedTimes,
    ReportedProducts,
    Notes,
    CreatedByID: number
) => {
    return fetch(
        `${getApiUrl(backend)}salaries`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                EmployeeID,
                SalaryStartDate,
                SalaryEndDate,
                ReportedAbsences,
                ReportedTimes,
                ReportedProducts,
                Notes,
                CreatedByID,
                ChangedByID: CreatedByID
            })
        }
    );
};

export default postSalary;
