import angular from 'angular';
import filterPanelTemplate from './filterPanelTemplate';

function FilterPanel() {
    // Life-cycle
    this.$onInit = () => {
        this.filter = {
            customer: null,
            project: null,
            employee: null,
            from: null,
            to: null,
        };
        this.onChange(this.filter);
        this.setUpPickersOnNextReportsChange = true;
    };

    this.$onChanges = (changes) => {
        const reports = changes.reports && changes.reports.currentValue;
        const type = changes.type;
        const from = changes.from;
        const to = changes.to;

        if (type || from || to) {
            this.filter = {
                customer: null,
                project: null,
                employee: null,
                from: null,
                to: null,
            };
            this.onChange(this.filter);
            this.setUpPickersOnNextReportsChange = true;
        }

        if (reports) {
            this.reports = reports;

            if (this.setUpPickersOnNextReportsChange) {
                this.setUpPickersOnNextReportsChange = false;
                this.setUpPickers();
            }
        }
    };

    // Methods
    this.setUpPickers = () => {
        this.employees = this.reports.reduce((acc, curr) => {
            if (
                curr.EmployeeID &&
                !acc.find((employee) => employee.value === curr.EmployeeID)
            ) {
                acc.push({
                    value: curr.EmployeeID,
                    text: curr.FirstName + ' ' + curr.LastName,
                });
                return acc;
            }
            return acc;
        }, []);

        this.customers = this.reports.reduce((acc, curr) => {
            if (
                curr.CustomerID &&
                !acc.find((customer) => customer.value === curr.CustomerID)
            ) {
                acc.push({
                    value: curr.CustomerID,
                    text: curr.CustomerName,
                });
                return acc;
            }
            return acc;
        }, []);

        this.projects = this.reports.reduce((acc, curr) => {
            if (!acc.find((project) => project.value === curr.ProjectID)) {
                acc.push({
                    value: curr.ProjectID,
                    text: curr.ProjectName,
                    CustomerID: curr.CustomerID,
                });
                return acc;
            }
            return acc;
        }, []);
    };

    this.onCustomerFilterChange = () => {
        this.filter.project = null;
    };

    this.onFilterChange = () => {
        this.onChange(this.filter);
    };

    this.onDateRangeChange = (start, end) => {
        this.filter.from = start ? start.format('YYYY-MM-DD') : null;
        this.filter.to = end ? end.format('YYYY-MM-DD') : null;

        this.onChange(this.filter);
    };

    this.resetFilter = () => {
        this.filter = {
            customer: null,
            employee: null,
            project: null,
            from: this.filter.from,
            to: this.filter.to,
        };
        this.onChange(this.filter);
    };
}

export default () => {
    angular.module('sbAdminApp').component('filterPanel', {
        controller: FilterPanel,
        template: filterPanelTemplate,
        bindings: {
            reports: '<',
            hideCustomerFilter: '=',
            hideProjectFilter: '=',
            hideEmployeeFilter: '=',
            hideIntervalFilter: '=',
            onChange: '<',
            type: '<',
        },
    });
};
