import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getCustomerObject = (token: string, backend: string, id: number) => {
    return fetch(`${getApiUrl(backend)}customerobjects/${id}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getCustomerObject