import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getOfferCounts = (token: string, backend: string, type?, search? ) => {
    return fetch(`${getApiUrl(backend)}offer_counts?${type ? '&type=' + type : ''}${search ? '&search=' + search : ''}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getOfferCounts