const translateResetPasswordStatus = (error: any) => {
    if (error.mail_inputted === false && error.email_exists === false) {
        return 'Du måste skicka med en giltig emailadress.';
    } else if (error.mail_inputted === true && error.email_exists === false) {
        return 'Det finns ingen användare med den emailadressen.';
    } else if (error === 'user_account_is_deleted') {
        return 'Kontot har arkiverats, kontakta systemadministratör.';
    } else {
        return 'Ett fel har uppstått.';
    }
};

export default translateResetPasswordStatus;
