import {toast} from "react-toastify"

export default (message) => {
    toast.error(message, {
        position: window.innerWidth > 900 ? "bottom-center" : "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}