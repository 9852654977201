import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const deleteReportedAbsence = (
    backend: string,
    token: string,
    id: number,
) => {
    return fetch(
        `${getApiUrl(backend)}employee_absence/${id}`,
        {
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default deleteReportedAbsence;
