import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putUnit = (
    token: string,
    backend: string,
    changed_by_id: string,
    name: string,
    description: string,
    id: string,
) => {
    return fetch(
        `${getApiUrl(backend)}units/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                changed_by_id,
                name,
                description,
                id,
            })
        }
    );
};

export default putUnit