import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putSupplierInvoiceProjectRelation = (
    backend: string,
    token: string,
    id,
    ProjectID,
    SupplierInvoiceID,
    Amount,
    Charge,
    AttachToInvoice,
    ProjectVariationID,
    ChangedByID
) => {
    return fetch(
        `${getApiUrl(backend)}supplier_invoice_project_relations/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ProjectID,
                SupplierInvoiceID,
                Amount,
                Charge,
                AttachToInvoice,
                ProjectVariationID: ProjectVariationID ? ProjectVariationID : null,
                ChangedByID
            })
        }
    );
};

export default putSupplierInvoiceProjectRelation;
