import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postProjectFormFromTemplate = (
    backend: string,
    token: string,
    ProjectID: number,
    FormTemplateID: number,
    CreatedByID: number,
    Responsee?: any,
) => {
    return fetch(
        `${getApiUrl(backend)}project_form_from_template`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ProjectID,
                FormTemplateID,
                CreatedByID,
                ChangedByID: CreatedByID,
                Responsee,
            })
        }
    );
};

export default postProjectFormFromTemplate;
