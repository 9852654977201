import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import angularApp from './angularjs/app';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, Middleware, AnyAction } from 'redux';
import { routerMiddleware } from 'connected-react-router'
import localStorage from 'localStorage';
import { requestStarted, requestCompleted, loadTenantMigrationStatus } from './store/actions/app';
import { createBrowserHistory } from 'history';
import { ConnectedRouter } from 'connected-react-router';
import { Switch, Route } from "react-router-dom"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './i18n';
import './types.d.ts'
import { composeWithDevTools } from 'redux-devtools-extension';
import createRootReducer from './root';
import thunk from 'redux-thunk';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import OfferLinkView from './offer/OfferLinkView';
import ProjectVariationLinkView from './project-variations/ProjectVariationLinkView';
import getApiUrl from './utils/apiUrl';
import postLogout from './api/postLogout';

const isLocalHost = window.location.href.includes("localhost")
const isDevelopment = window.location.href.includes("app.dev.mygizmo")

Bugsnag.start({
    apiKey: 'a614e25f6423d52a42877ad26b1d959c',
    plugins: [new BugsnagPluginReact()],
    releaseStage: isLocalHost ? "localhost" : isDevelopment ? "development" : "production",
    enabledReleaseStages: ["development", "production"],
    metadata: {
        tenant: {
        db_name: localStorage.getItem('backend')
        }
    }
})

const BugsnagReactPlugin = Bugsnag.getPlugin('react')

const ErrorBoundary = BugsnagReactPlugin ? BugsnagReactPlugin.createErrorBoundary(React) : ({children}) => <div>{children}</div>

const App = React.lazy(() => import('./App'));
const RegisterIndex = React.lazy(() => import('./register/RegisterIndex'));
const InviteIndex = React.lazy(() => import('./invite/InviteIndex'));
const ResetPasswordForm = React.lazy(() => import('./reset-password/ResetPasswordForm'));

angularApp.init(() => store.dispatch(requestStarted()), () => store.dispatch(requestCompleted()));

export const history = createBrowserHistory();

const migrationMiddleware: Middleware<{}, any, any> = (store) => {
    let lastInvocationTime = 0;
    let isFetching = false;

    return (next) => async (action: AnyAction) => {

        const actionsWithoutFurtherProcessing = [
            '@@router/LOCATION_CHANGE',
            'CHECK_IF_EMAIL_EXISTS_BEGIN',
            'CHECK_IF_EMAIL_EXISTS_SUCCESS',
            'CHECK_ACCESS_TOKEN_BEGIN',
            'CHECK_ACCESS_TOKEN_SUCCESS',
            'SEND_ACCESS_TOKEN_BEGIN',
            'SEND_ACCESS_TOKEN_SUCCESS',
            'SUBMIT_REGISTER_USER_DETAILS',
            'CREATE_USER_WITH_TOKEN_BEGIN',
            'CREATE_USER_WITH_TOKEN_SUCCESS',
            'SIGN_OUT',
            'CREATE_TENANT_PROSPECT_BEGIN',
            'CREATE_TENANT_PROSPECT_SUCCESS',
            'REQUEST_STARTED',
            'REQUEST_COMPLETED',
            'login/setEmail',
            'login/setPassword',
            'login/clearForm',
            'modal/showModal',
            'modal/hideModal',
            'RESET_USER_PASSWORD_FAILED',
            'RESET_USER_PASSWORD_SUCCESS',
            'CHECK_PASSWORD_RESET_BEGIN',
            'CHECK_PASSWORD_RESET_SUCCESS',
            'RESET_PASSWORD_BEGIN',
            'RESET_PASSWORD_SUCCESS',
            'CREATE_PIPEDRIVE_PROSPECT_BEGIN',
            'CREATE_PIPEDRIVE_PROSPECT_SUCCESS',
            'CREATE_PIPEDRIVE_ORGANIZATION_BEGIN',
            'CREATE_PIPEDRIVE_ORGANIZATION_SUCCESS',
        ];

        //Pass theese actions to the middleware imedietly without further processing
        if(actionsWithoutFurtherProcessing.includes(action.type)) {
            return next(action)
        }

        //Prevents multiple actions to check migration status
        if(['LOAD_TENANT_MIGRATION_STATUS_BEGIN', 'LOAD_TENANT_MIGRATION_STATUS_SUCCESS'].includes(action.type)) {
            return next(action);
        }

        console.log('action type', action.type)

        const currentTime = Date.now();

        // Check if 5 seconds have passed since the last invocation
        if (currentTime - lastInvocationTime >= 5000 && !isFetching) {

            console.log('checking migration status...')

            const { token, backend } = store.getState().app;

            if (token && backend) {
                
                lastInvocationTime = currentTime;

                isFetching = true;

                const fetchPromise = new Promise<void>((resolve, reject) => {
                    store.dispatch(loadTenantMigrationStatus())
                    .then(res => {

                        isFetching = false; 
                        resolve(); // Resolve the promise when the action is completed
                    })
                    .catch(error => {
                        console.error('Error loading migration status:', error);

                        isFetching = false;
                        reject(error);
                    });
                });

                await fetchPromise
            }
        }

        // Pass the action to the next middleware or the reducer
        return next(action);
    };
};

export const store = createStore(
    createRootReducer(history),
    {
        app: {
            token: localStorage.getItem('token'),
            backend: localStorage.getItem('backend'),
            accountActivationStatus: 'INITIAL',
            activeRequests: 0,
        },
    },
    composeWithDevTools(applyMiddleware(thunk, routerMiddleware(history)))
);

store.subscribe(() => {
    if (store.getState().app.token) {
        localStorage.setItem('token', store.getState().app.token);
    } else {
        localStorage.removeItem('token');
    }

    if (store.getState().app.backend) {
        Bugsnag.addMetadata('tenant', {
            db_name: store.getState().app.backend
        })
        localStorage.setItem('backend', store.getState().app.backend);
    } else {
        Bugsnag.clearMetadata('tenant');
        localStorage.removeItem('backend');
    }
});

ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <Switch>
                    <Route path={"/external/offer/:tenant/:email/:linkToken"}>
                        <Suspense fallback={<div>Laddar länk...</div>}>
                            <OfferLinkView />
                        </Suspense>
                    </Route>
                    <Route path={"/external/projectvariations/:tenant/:email/:linkToken"}>
                        <Suspense fallback={<div>Laddar länk...</div>}>
                            <ProjectVariationLinkView />
                        </Suspense>
                    </Route>
                    <Route path={"/invite"}>
                        <Suspense fallback={<div>Laddar länk...</div>}>
                            <InviteIndex />
                        </Suspense>
                    </Route>
                    <Route path={"/register"}>
                        <Suspense fallback={<div>Laddar registreringsformulär...</div>}>
                            <RegisterIndex />
                        </Suspense>
                    </Route>
                    <Route path={"/reset-password/:email/:token"}>
                        <Suspense fallback={<div>Laddar återställ lösenord...</div>}>
                            <ResetPasswordForm />
                        </Suspense>
                    </Route>
                    <Route>
                        <Suspense fallback={<div>Laddar applikation...</div>}>
                            <App />
                        </Suspense>
                    </Route>
                </Switch>
                <ToastContainer />
            </ConnectedRouter>
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
