import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getReportedTime = (
    token: string,
    backend: string,
    id: number
) => {
    return fetch(
        `${getApiUrl(backend)}reportedtimes/${id}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*'
            },
        }
    );
};

export default getReportedTime;
