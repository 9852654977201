import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getSalaries = (token: string, backend: string, employeeId?) => {
    return fetch(`${getApiUrl(backend)}get_all_salaries?${employeeId ? '&employee=' + employeeId : ''}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getSalaries