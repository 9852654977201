import ProjectVariation from "../types/ProjectVariation";
import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getProjectVariations = (
    token: string,
    backend: string,
    projectId: number,
    status?: string,
    search?: string
) => {
    return fetch(
        `${getApiUrl(backend)}project_variations?${projectId ? 'projectId='+projectId : ''}${status ? '&status='+status : ''}${search ? '&search='+search : ''}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*'
            }
        }
    );
};

export default getProjectVariations;
