import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postInvoiceInFortnox = (
    token: string,
    backend: string,
    invoiceId: any,
    invoiceRows: any,
    addProjectName: any,
    blob?: any,
) => {

    var requestData = new FormData();
    requestData.append('invoiceId', invoiceId);
    requestData.append('invoiceRows', JSON.stringify(invoiceRows));
    requestData.append('addProjectName', addProjectName);

    if(blob) {
        requestData.append('attachment', blob);
    }
    
    return fetch(
        `${getApiUrl(backend)}create_invoice_in_fortnox`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: requestData
        }
    );
};

export default postInvoiceInFortnox;
