import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getInvoice = (token: string, backend: string, id) => {
    return fetch(`${getApiUrl(backend)}invoices/${id}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
        }
    }) 
}

export default getInvoice