
import getApiUrl from "../utils/apiUrl"
import fetch from "../utils/mgFetch";

const deleteBooking = (
    token: string, 
    backend: string, 
    id
    ) => {
    return fetch(`${getApiUrl(backend)}remove_booking/${id}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
})};

export default deleteBooking;