import getApiUrl from "../utils/apiUrl"
import fetch from "../utils/mgFetch";

const postCheckPasswordResetToken  = (token:string, email: string) => {
    return fetch(`${getApiUrl()}check_reset_password_token/${token}/${email}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

export default postCheckPasswordResetToken;
