import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putPriceAdjustment = (
    token: string,
    backend: string,
    ChangedByID: string,
    id,
    Name: string,
    Discount: string,
) => {
    return fetch(
        `${getApiUrl(backend)}price_lists/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ChangedByID,
                Name,
                Discount,
            })
        }
    );
};

export default putPriceAdjustment;
