import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getProducts = (
    backend: string,
    token: string,
    query?: string,
    page?: number,
    productKind?: string,
    orderBy?: string,
    
) => {
    return fetch(
        `${getApiUrl(backend)}get_all_products?${page !== undefined ? '&page=' + page : ''}${query ? '&query=' + query : ''}${productKind ? '&productKind=' + productKind : ''}&orderBy=${orderBy || ""}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getProducts;
