import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getActivateCustomer = (token: string, backend: string, customerId: number, revokeSoftDelete = false) => {
    let url = "customers/activate/"

    if(revokeSoftDelete) {
        url = "customers/revoke_soft_delete/"
    }

    return fetch(`${getApiUrl(backend)}${url}${customerId}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getActivateCustomer