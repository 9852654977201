import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putTaskType = (
    token: string,
    backend: string,
    changed_by_id: string,
    type_title: string,
    description: string,
    id: string,
) => {
    return fetch(
        `${getApiUrl(backend)}tasktypes/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                changed_by_id,
                type_title,
                description,
                id,
            })
        }
    );
};

export default putTaskType