import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postTenantProspect = (token, Name, FirstName = "", LastName = "", SendWelcomeEmail = false) => {
    return fetch(`${getApiUrl()}tenant_prospects/`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            Name,
            FirstName,
            LastName,
            SendWelcomeEmail
        })
    })
}

export default postTenantProspect