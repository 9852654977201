import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getUnhandledReportedTimeBetweenDates = (
    token: string,
    backend: string,
    fromDate: string,
    toDate: string,
) => {
    return fetch(
        `${getApiUrl(backend)}unhandled_reported_time_between_dates/${fromDate}/${toDate}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getUnhandledReportedTimeBetweenDates;
