import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putReportedTime = (
    backend: string,
    token: string,
    id: number,
    employee_id: number,
    time_in_hours: number,
    date: string,
    changed_by_id: number,
    time_type_id: number,
    comment: string,
    assignment_id: number,
    task_id: number,
    sales_price, 
    cost_price, 
    invoice_time_in_hours, 
    salary_time_in_hours,
    project_id,
    project_variation_id
) => {
    return fetch(
        `${getApiUrl(backend)}reportedtimes/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                employee_id,
                time_in_hours,
                date,
                changed_by_id,
                time_type_id,
                comment,
                assignment_id,
                task_id,
                sales_price, 
                cost_price, 
                invoice_time_in_hours, 
                salary_time_in_hours,
                project_id,
                project_variation_id
            })
        }
    );
};

export default putReportedTime;
