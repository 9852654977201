import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postImportSelectedEaccountingDataToMyGizmo = (
    token: string,
    backend: string,
    type,
    ids,
    created_by_id
) => {
    return fetch(
        `${getApiUrl(backend)}import_selected_eaccounting_data_to_mygizmo`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type,
                ids,
                created_by_id
            })
        }
    );
};

export default postImportSelectedEaccountingDataToMyGizmo;
