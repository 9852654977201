import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getFortnoxSupplierInvoices = (token: string, backend: string, page, financialYearDate, sortBy, sortOrder, search) => {
    return fetch(`${getApiUrl(backend)}get_fortnox_supplier_invoices/${page}/${financialYearDate}?sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getFortnoxSupplierInvoices