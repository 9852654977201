import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postSendSignatureConfirmation = (
    backend: string,
    token: string,
    signatureId: string,
    email: string
) => {
    return fetch(
        `${getApiUrl(backend)}send_signature_confirmation/${signatureId}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email
            })
        }
    );
};

export default postSendSignatureConfirmation;