import getApiUrl from "../utils/apiUrl"
import fetch from "../utils/mgFetch";

const postResetPassword = (token: string, email: string, newPassword: string) => {
    return fetch(`${getApiUrl()}reset_password/${token}/${email}/${newPassword}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

export default postResetPassword;
