import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postImportEaccountingDataToMyGizmo = (
    token: string,
    backend: string,
    key,
    created_by_id
) => {
    return fetch(
        `${getApiUrl(backend)}import_eaccounting_data_to_mygizmo`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                [key]: "true",
                created_by_id
            })
        }
    );
};

export default postImportEaccountingDataToMyGizmo;
