export default `<div style="margin-bottom: 1em; box-shadow: 1px 0 4px lightgrey; border-radius: 3px; background: white;">
    <div ng-if="$ctrl.project.employee_done_marker" style="background-color: #278e27; color: white; padding: 0.5em; border-radius: 3px 3px 0 0;">
        <i class="fa fa-check"></i> Klarmarkerat: {{$ctrl.project.employee_done_marker}}
    </div>
    <div style="padding: 1em;">
        <div style="display: flex; justify-content: space-between; margin-bottom: 0.5em;">
            <div>
                <div style="opacity: 0.7;">
                    {{$ctrl.project.customer.Name}}
                </div>
                <div>
                    <b>P{{$ctrl.useCustomProjectSequence ? $ctrl.project.CustomSequenceID : $ctrl.project.id}} - {{$ctrl.project.ProjectName}}</b>
                </div>
            </div>
            <div style="font-style: italic;">
                <span ng-if="$ctrl.project.ProjectType === 'fixed'">Fastpris</span>
                <span ng-if="$ctrl.project.ProjectType === 'open'">Löpande</span>
            </div>
        </div>
        <div style="margin-bottom: 1em;">
            {{$ctrl.project.Description}}
        </div>
        <div style="display: flex; justify-content: space-between;">
            <div style="display: flex;">
                <div style="margin-right: 0.5em;">
                    <div style="opacity: 0.7;">
                        Startdatum
                    </div>    
                    <div>
                        {{$ctrl.formatDate($ctrl.project.StartDate)}}
                    </div>
                </div>
                <div>
                    <div style="opacity: 0.7;">
                        Slutdatum
                    </div>
                    <div>
                        {{$ctrl.formatDate($ctrl.project.EndDate)}}
                    </div>
                </div>
            </div>
            <div style="text-align: end;">
                <div style="opacity: 0.7;">
                    Projektledare
                </div>
                <div>
                    <div>
                        <span ng-if="$ctrl.iamProjectLeader" style="color: #286090;">
                            {{$ctrl.displayProjectLeader()}}
                        </span>
                        <span ng-if="!$ctrl.iamProjectLeader">
                            {{$ctrl.displayProjectLeader()}}
                        </span>
                    </div>
                    <div>
                        <button ng-if="$ctrl.iamProjectLeader" class="btn btn-primary" ng-click="$ctrl.openEditProject()">
                            <i class="fa fa-cogs"></i> Projektledarvyn
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>`