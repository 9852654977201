import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putReportedProduct = (
    backend: string,
    token: string,
    id: number,
    employee_id: number,
    quantity: number,
    date: string,
    changed_by_id: number,
    comment: string,
    product_assignment_id: number,
    product_id: number,
    assignment_id: number, 
    sales_price, 
    cost_price, 
    invoice_quantity, 
    salary_quantity,
    name,
    unit_id,
    project_id,
    project_variation_id
) => {
    return fetch(
        `${getApiUrl(backend)}reported_products/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                employee_id,
                quantity,
                date,
                changed_by_id,
                comment,
                product_assignment_id,
                product_id,
                assignment_id,
                sales_price, 
                cost_price, 
                invoice_quantity, 
                salary_quantity,
                name,
                unit_id,
                project_id,
                project_variation_id
            })
        }
    );
};

export default putReportedProduct;
