import ProjectVariation from "../types/ProjectVariation";
import ProjectVariationLink from "../types/ProjectVariationLink";
import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putProjectVariation = (
    token: string,
    backend: string,
    id: number,
    body: ProjectVariation
) => {
    return fetch(
        `${getApiUrl(backend)}project_variations/${id}`,
        {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
    );
};

export default putProjectVariation;
