import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postProjectAccessEmployeeRelation = (
    backend: string,
    token: string,
    ProjectID: number,
    EmployeeID: number,
    CreatedByID: number,
) => {
    return fetch(
        
        `${getApiUrl(backend)}project_access_employee_relations`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ProjectID,
                EmployeeID,
                CreatedByID,
                ChangedByID: CreatedByID,
            })
        }
    );
};

export default postProjectAccessEmployeeRelation;
