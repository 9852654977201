import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";
 
const postSendUserInviteToCurrentTennantAgain = (
    token: string,
    backend: string,
    UserActivationID: string,
    ToEmail: string,
    ToPhoneNumber: string,
    ) => {
    return fetch(
        `${getApiUrl(backend)}send_user_invite_to_current_tenant_again`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                UserActivationID,
                ToEmail,
                ToPhoneNumber,
            })
        }
    );
};

export default postSendUserInviteToCurrentTennantAgain;
