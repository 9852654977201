import { store } from "../index"
import { requestCompleted, signOutAction } from "../store/actions/app";
import errorToast from "./errorToast";

interface FetchOptions {
    method: string,
    headers?: any,
    body?: any,
}

export default (url : string, options : FetchOptions = { method: "GET", headers: {}}) => {
    return fetch(url, 
        {
            ...options, 
            headers: {
                ...options.headers,
                Accept: "application/json, text/plain, */*"
            }
        }
    ).catch(()=>{

        const backend = localStorage.getItem('backend')

        if(backend && backend.indexOf(".mygizmo.") > -1) {
            store.dispatch(signOutAction() as any)
            store.dispatch(requestCompleted())
        } else {
            errorToast("Server error");
        }

        return Promise.reject()
        
    }).then((res) => {
        if(res.status >= 400 && res.status < 500) {

            return new Promise( (resolve, reject) => {
                res.json().then(body => {
                    if(body.autherror === true || (body.message === "Unauthenticated.")) {
                        store.dispatch(signOutAction() as any)
                        reject()
                        store.dispatch(requestCompleted())
                    }

                    if(body.message) {
                        if(body.message !== "Unauthenticated.") {
                            errorToast(body.message)
                        }
                        reject()
                        store.dispatch(requestCompleted())
                    }
                    
                    if(body.toastMessage) {
                        errorToast(body.toastMessage)
                        reject()
                        store.dispatch(requestCompleted())
                    }

                    resolve(body)
                })
            })
        }

        return res.json()
    })
}