import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postSettings = (
    token: string,
    backend: string,
    ChangedByID: string,
    UseAttest?: string,
    ShowTaskDescriptionOnInvoiceDetails?: string,
    UseCustomProjectSequence?: string,
    ImportFortnoxSupplierInvoicesFromDate?: string,
    FortnoxSupplierInvoiceVoucherSeries?: string,
    FortnoxExportProjectInfoToInvoiceAs?: string,
    VismaImportCustomers?: string,
    VismaImportProjects?: string,
    VismaImportOrders?: string,
    VismaImportProducts?: string,
    VismaExportCustomers?: string,
    VismaExportProducts?: string,
    VismaExportInvoiceProposal?: string,
    VismaExportBreakOnDate?: string,
    VismaExportBreakOnAll?: string,
    VismaExportDisplayComment?: string,
    DefaultOfferHeadline?,
    DefaultOfferFooter?,
    StartOfferNumberSequenceAt?,
    VismaExportStartDate?,
    VismaExportEndDate?,
    VismaImportStartDate?,
    VismaImportEndDate?,
    VismaImportCreateReportProject?,
    StartCustomProjectSequenceAt?: string,
    UsersAllowedToSeePrice?: string,
    UseProjectVariations?,
    UsersCanCreateProjectVariations?,
    DefaultPaymentPlanMaxTotalSafetyPercentage?,
    DefaultPaymentPlanSafetyPercentage?,
    DefaultPaymentPlanDaysBeforeDueDate?,
    DaysBeforePaymentPlanDueDateWarning?,
    UsePaymentPlans?,
    UseProjectAccesses?,
) => {
 
    return fetch(
        `${getApiUrl(backend)}settings`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ChangedByID,
                UseAttest,
                ShowTaskDescriptionOnInvoiceDetails,
                UseCustomProjectSequence,
                ImportFortnoxSupplierInvoicesFromDate,
                FortnoxSupplierInvoiceVoucherSeries,
                FortnoxExportProjectInfoToInvoiceAs,
                VismaImportCustomers,
                VismaImportProjects,
                VismaImportOrders,
                VismaImportProducts,
                VismaExportCustomers,
                VismaExportProducts,
                VismaExportInvoiceProposal,
                VismaExportBreakOnDate,
                VismaExportBreakOnAll,
                VismaExportDisplayComment,
                DefaultOfferHeadline,
                DefaultOfferFooter,
                StartOfferNumberSequenceAt,
                VismaExportStartDate,
                VismaExportEndDate,
                VismaImportStartDate,
                VismaImportEndDate,
                VismaImportCreateReportProject,
                StartCustomProjectSequenceAt,
                UsersAllowedToSeePrice,
                UseProjectVariations,
                UsersCanCreateProjectVariations,
                DefaultPaymentPlanMaxTotalSafetyPercentage,
                DefaultPaymentPlanSafetyPercentage,
                DefaultPaymentPlanDaysBeforeDueDate,
                DaysBeforePaymentPlanDueDateWarning,
                UsePaymentPlans,
                UseProjectAccesses,
            })
        }
    );
};

export default postSettings;
