
import getApiUrl from "../utils/apiUrl"
import fetch from "../utils/mgFetch";

const postUpdateUserDetails = (
    token: string, 
    backend: string, 
    user_id: string,
    FirstName: string,
    LastName: string,
    Email: string,
    MobileNumber: string,
    ) => {
    return fetch(`${getApiUrl(backend)}user/changeuserdetails`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user_id,
            FirstName,
            LastName,
            Email,
            MobileNumber,
        })
})};

export default postUpdateUserDetails;