import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putSupplierInvoice = (
    token: string,
    backend: string,
    id,
    Description,
    InvoiceDate,
    DueDate,
    TotalAmount,
    ChangedByID,
    DeleteDocument,
    document,
    FortnoxGivenNumber,
    FortnoxVoucherNumber,
    FortnoxVoucherSeries,
) => {

    const requestData = new FormData();
    requestData.append('document', document);
    requestData.append('Description', Description);
    requestData.append('InvoiceDate', InvoiceDate);
    requestData.append('DueDate', DueDate);
    requestData.append('TotalAmount', TotalAmount);
    requestData.append('TotalAmount', TotalAmount);
    requestData.append('ChangedByID', ChangedByID);
    requestData.append('FortnoxGivenNumber', FortnoxGivenNumber);
    requestData.append('FortnoxVoucherNumber', FortnoxVoucherNumber);
    requestData.append('FortnoxVoucherSeries', FortnoxVoucherSeries);
    if(DeleteDocument) {
        requestData.append('DeleteDocument', DeleteDocument);
    }
    
    return fetch(
        `${getApiUrl(backend)}supplier_invoices/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: requestData
        }
    );
};

export default putSupplierInvoice;
