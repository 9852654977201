import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getProject = (
    backend: string,
    token: string,
    id: number,
    byCustomSequenceId?: boolean
) => {
    return fetch(
        `${getApiUrl(backend)}projects/${id}${byCustomSequenceId ? '?byCustomSequenceId=true' : ''}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getProject;
