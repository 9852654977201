import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getEaccountingCustomers = (token: string, backend: string, page, sortBy: string, search: string) => {
    return fetch(`${getApiUrl(backend)}read_visma_eaccounting_customers/?page=${page}&sortBy=${sortBy}&search=${search}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getEaccountingCustomers