import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postExportProjectsToFortnox = (
    token: string,
    backend: string
) => {
    return fetch(
        `${getApiUrl(backend)}export_projects_to_fortnox`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }
    );
};

export default postExportProjectsToFortnox;
