import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getEaccountingArticles = (token: string, backend: string, page, sortBy, search) => {
    return fetch(`${getApiUrl(backend)}read_visma_eaccounting_articles/?page=${page}&orderBy=${sortBy}&search=${search}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getEaccountingArticles