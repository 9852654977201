import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postRevokeSoftDeletedTask = (
    token: string,
    backend: string,
    id: string,
) => {
    return fetch(
        `${getApiUrl(backend)}revoke_task_soft_delete/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
        }
    );
};

export default postRevokeSoftDeletedTask;
