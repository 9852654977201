import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postOfferLink = (
    token: string,
    backend: string,
    OfferID,
    SendToEmail,
    CreatedByID: string,
) => {
    return fetch(
        `${getApiUrl(backend)}offer_links`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                OfferID,
                SendToEmail,
                CreatedByID
            })
        }
    );
};

export default postOfferLink;
