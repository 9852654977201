import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getCustomers = (token: string, backend: string, search: string, page, filter = "active", sortField?, sortOrder?) => {
    return fetch(`${getApiUrl(backend)}customers?filter=${filter}&search=${search}&page=${page}&sortby=${sortField || ""}&sortdirection=${sortOrder || ""}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getCustomers