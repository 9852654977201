import getApiUrl from "../utils/apiUrl"
import fetch from "../utils/mgFetch";

const postAuthenticateUser = (email: string, password: string, tenant_employee_id: string = "", backend: string = "", tenant_role: string = "") => {
    return fetch(`${getApiUrl(backend)}auth/login`, {
        method: "POST",
        body: JSON.stringify({
            email,
            password,
            tenant_employee_id,
            tenant_role
        }),
        headers: {
            "Content-Type": "application/json"
        }
    }) 
}

export default postAuthenticateUser