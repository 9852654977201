import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getHouseWorkDeductionTypes = (token: string, backend: string, id?) => {
    return fetch(`${getApiUrl(backend)}house_work_deduction_types/${id || ""}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getHouseWorkDeductionTypes