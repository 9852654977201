export default `<div
    style="box-shadow: 1px 0 3px lightgrey; margin-bottom: 1.0em; border-radius: 3px;"
    ng-style="$ctrl.style()"
    ng-click="$ctrl.handleClick()"
>
    <div
        style="padding: 0.5em; background: pink; border-radius: 3px 3px 0 0;"
        ng-if="$ctrl.isRejected()"
    >
        Har felmarkerats av {{$ctrl.rejectedBy}}
    </div>
    <div
        ng-if="!$ctrl.isEditable()"
        style="padding: 0.5em; background: lightgreen; border-radius: 3px 3px 0 0;"
    >
        Godkänd av admin (ej längre redigerbar)
    </div>
    <div style="display: flex; align-items: center; padding: 1em;">
        <div ng-if="$ctrl.checkable" style="margin-right: 1em;">
            <input
                ng-if="$ctrl.isEditable() && $ctrl.checkable"
                type="checkbox"
                ng-checked="$ctrl.report._checked"
            />
        </div>
        <div style="flex: 1">
            <div style="display: flex; justify-content: space-between;">
                <div style="flex: 1;">
                    <a ng-click="$ctrl.viewProjectDetails(); $event.stopPropagation();">
                        P{{$ctrl.useCustomSequenceId ? $ctrl.report.CustomProjectSequenceID : $ctrl.report.ProjectID}} - {{$ctrl.report.CustomerName}} - {{$ctrl.report.ProjectName}}
                    </a>
                </div>
                <div style="text-align: end;">
                    <a ng-if="$ctrl.SignatureID" ng-click="$ctrl.viewSignature(); $event.stopPropagation();"><i class="fa fa-pencil"></i> #{{$ctrl.SignatureID}}</a>
                </div>
            </div>
            <div style="display: flex; align-items: center; justify-content: space-between;">
                <div> 
                    <div>
                        <i
                            tooltip="Kräver kundsignatur"
                            class="fa fa-exclamation-triangle"
                            ng-if="$ctrl.report.NeedCustomerSignature === 'true' && $ctrl.report.HasExternalProjectLeader === 'false'"
                        ></i>
                        <i
                            tooltip="Kräver signatur av extern projektledare"
                            class="fa fa-exclamation-triangle"
                            ng-if="$ctrl.report.HasExternalProjectLeader === 'true'"
                        ></i>
                        <b ng-if="$ctrl.report._type === 'reported_time'"
                            >{{$ctrl.report.Title}}: {{$ctrl.report.TimeTypeName}}</b
                        >
                        <b ng-if="$ctrl.report._type === 'reported_product'"
                            >{{$ctrl.report.Name}}</b
                        >
                    </div>
                    <div ng-if="$ctrl.report.Comment">
                        <i>{{$ctrl.report.Comment}}</i>
                    </div>
                    <div style="opacity: 0.6; margin-top: 0.5em;">
                        Rapporterat av {{$ctrl.report.FirstName}}
                        {{$ctrl.report.LastName}}
                    </div>
                </div>
                <div>
                    <div ng-if="$ctrl.report._type === 'reported_time'">
                        {{$ctrl.report.TimeInHours}} h
                    </div>
                    <div ng-if="$ctrl.report._type === 'reported_product'">
                        {{$ctrl.report.Quantity}} {{$ctrl.report.UnitName}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>`;
