import PaymentPlanElement from "../types/PaymentPlanElement";
import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putPaymentPlanElement = (
    token: string,
    backend: string,
    id: number,
    body: PaymentPlanElement
) => {
    return fetch(
        `${getApiUrl(backend)}payment_plan_elements/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
    );
};

export default putPaymentPlanElement;
