import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getUseOfferLink = (
    backend: string,
    email: string,
    linkToken: string
) => {
    return fetch(
        `${getApiUrl()}offer_links/${backend}/${email}/${linkToken}`,
        {
            method: 'GET',
            headers: {}
        }
    );
};

export default getUseOfferLink;
