import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postSendProjectVariationLinkAnswer = (
    backend: string,
    email: string,
    linkToken: string,
    answer: string,
    comment: string,
    signature?: Blob,
    signatureName = ""
) => {

    const requestData = new FormData();
    
    if (signature !== undefined) {
        requestData.append('Signature', signature);
        requestData.append('SignatureName', signatureName);
    }
    
    requestData.append('Answer', answer);
    requestData.append('Comment', comment);
    
    return fetch(
        `${getApiUrl()}project_variation_link_answers/${backend}/${email}/${linkToken}`,
        {
            method: 'POST',
            headers: {},
            body: requestData
        }
    );
};

export default postSendProjectVariationLinkAnswer;