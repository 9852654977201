import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postTimeType = (
    token: string,
    backend: string,
    name: string,
    description: string,
    short_name: string,
    salary_code: string,
    tab_sequence: string,
    sales_price: string,
    cost_price: string,
    active: string,
    erp_product_id: string,
    created_by_id: string,
    changed_by_id: string,
) => {

    return fetch(
        `${getApiUrl(backend)}timeTypes`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name,
                description,
                short_name,
                salary_code,
                tab_sequence,
                sales_price,
                cost_price,
                active,
                erp_product_id,
                created_by_id,
                changed_by_id,
            })
        }
    );
};

export default postTimeType;