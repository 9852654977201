import React from "react"

import styled from "styled-components"

const DocumentTypeIcon = styled.div`
    width: 30px;
    height: 30px;
    font-size: 0.6em;
    border-radius: 50%;
    background: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    cursor: pointer;
`

const VideoPreview = styled.video`
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
`

const ImagePreview = styled.img`
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
`

const DocumentPreview = ({document, onClick, style}) => {
    
    
    if (document.MIMEtype.indexOf("video") != -1) {
        return <VideoPreview onClick={onClick} style={style} src={document.url} />
    }


    if (document.MIMEtype.indexOf("image") != -1) {
        return <ImagePreview crossOrigin="anonymous" onClick={onClick} style={style} src={document.url} />
    }



    return <DocumentTypeIcon onClick={onClick} style={style}>
        {document.MIMEtype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'&& "Word"}
        {document.MIMEtype === 'application/pdf'&& "PDF"}
    </DocumentTypeIcon>
}

export default DocumentPreview