
import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postCopyOfferToNewVersion = (backend: string, token: string, id, CreatedByID) => {
    return fetch(`${getApiUrl(backend)}copy_offer_to_new_version/${id}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            CreatedByID
        })
    })
}

export default postCopyOfferToNewVersion