import getApiUrl from "../utils/apiUrl"
import fetch from "../utils/mgFetch";

const postJsonFileRead = (
    token,
    backend,
    loadFile
) => {

    //Available files to load
    // exportFromMG
    // exportFromVisma

    const formData = new FormData();

    formData.append('folder_name', "VismaIntegration")
    formData.append('file_name', loadFile)

    return fetch(`${getApiUrl(backend)}readJsonFile`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
        },
        body: formData
    })
};

export default postJsonFileRead;
