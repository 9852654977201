import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const deleteEmployeeSoftDelete = (
    token: string, 
    backend: string, 
    id: string
    ) => {
    return fetch(`${getApiUrl(backend)}employees/soft_delete/${id}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default deleteEmployeeSoftDelete