export default `<div style="height: 2em;" ></div>
<div style="margin-bottom: 0.5em;" ng-if="!$ctrl.hideIntervalFilter">
    <input
        placeholder="Välj start/slut-datum"
        jqdaterangepicker
        from="$ctrl.filter.from"
        to="$ctrl.filter.to"
        on-range-change="$ctrl.onDateRangeChange"
    />
</div>
<div style="display: flex; margin-bottom: 1em;">
    <select
        style="flex: 1; margin-right: 0.5em;"
        class="form-control"
        ng-model="$ctrl.filter.employee"
        ng-change="$ctrl.onFilterChange()"
        ng-if="!$ctrl.hideEmployeeFilter"
    >
        <option value="">Alla anställda</option>
        <option
            ng-repeat="employee in $ctrl.employees"
            value="{{employee.value}}"
        >
            {{employee.text}}
        </option>
    </select>
    <select
        style="flex: 1; margin-right: 0.5em;"
        class="form-control"
        ng-model="$ctrl.filter.customer"
        ng-change="$ctrl.onCustomerFilterChange(); $ctrl.onFilterChange();"
        ng-if="!$ctrl.hideCustomerFilter"
    >
        <option value="">Alla kunder</option>
        <option
            ng-repeat="customer in $ctrl.customers"
            value="{{customer.value}}"
        >
            {{customer.text}}
        </option>
    </select>
    <select
        style="flex: 1;"
        class="form-control"
        ng-model="$ctrl.filter.project"
        ng-disabled="!$ctrl.filter.customer"
        ng-change="$ctrl.onFilterChange();"
    >
        <option value="">Alla projekt</option>
        <option
            ng-repeat="project in $ctrl.projects | filter: { CustomerID: $ctrl.filter.customer}"
            value="{{project.value}}"
        >
            {{project.text}}
        </option>
    </select>
</div>`;
