import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postSupplierInvoice = (
    token: string,
    backend: string,
    Description,
    InvoiceDate,
    DueDate,
    TotalAmount,
    CreatedByID,
    file
) => {

    const requestData = new FormData();
    requestData.append('document', file);
    requestData.append('Description', Description);
    requestData.append('InvoiceDate', InvoiceDate);
    requestData.append('DueDate', DueDate);
    requestData.append('TotalAmount', TotalAmount);
    requestData.append('CreatedByID', CreatedByID);
    requestData.append('ChangedByID', CreatedByID);
    
    return fetch(
        `${getApiUrl(backend)}supplier_invoices`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: requestData
        }
    );
};

export default postSupplierInvoice;
