import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getFortnoxOrders = (token: string, backend: string, page, sortBy, sortOrder = "descending", search) => {
    return fetch(`${getApiUrl(backend)}get_fortnox_orders/${page}?sortBy=${sortBy}&sortOrder=${sortOrder}&search=${search}`, { method: "GET",    
    headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getFortnoxOrders