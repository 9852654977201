import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putArticle = (
    token: string,
    backend: string,
    created_by_id: string,
    changed_by_id: string,
    name: string,
    description: string,
    productCategory: string,
    unit_id: string,
    price: string,
    cost_price: string,
    on_salary: boolean,
    salary_code: string,
    on_salary_amount: string,
    product_type_id: string,
    id: number,
    erpProductId?: any,
    fortnox_article_number?: any,
    check_box?: string,
) => {
    return fetch(
        `${getApiUrl(backend)}products/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                created_by_id,
                changed_by_id,
                name,
                description,
                productCategory,
                unit_id,
                price,
                cost_price,
                on_salary,
                salary_code,
                product_type_id,
                on_salary_amount,
                check_box,
                erp_product_id: erpProductId ? erpProductId : null,
                fortnox_article_number,
            })
        }
    );
};

export default putArticle