import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postSendOfferLinkAnswer = (
    backend: string,
    email: string,
    linkToken: string,
    answer: string,
    comment: string,
    signature?: Blob,
    signatureName = "",
    pdf?: Blob
) => {

    const requestData = new FormData();
    
    if (signature !== undefined) {
        requestData.append('Signature', signature);
        requestData.append('SignatureName', signatureName);
    }

    if (pdf !== undefined) {
        requestData.append('pdf', pdf);
    }
    
    requestData.append('Answer', answer);
    requestData.append('Comment', comment);
    
    return fetch(
        `${getApiUrl()}offer_link_answers/${backend}/${email}/${linkToken}`,
        {
            method: 'POST',
            headers: {},
            body: requestData
        }
    );
};

export default postSendOfferLinkAnswer;