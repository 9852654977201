import React, {Suspense, useEffect, useRef, useState} from "react"
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Document, Page, pdfjs } from 'react-pdf';

const FileViewer = React.lazy(() =>  import('react-file-viewer'));

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const DocumentViewer = (({inline = false, document = null} : any) => {
    const [viewDocument, setViewDocument] : [any, any] = useState(document);
    const [pdfWrapperWidth, setPdfWrapperWidth] : [any, any] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const pdfWrapper: any = useRef(null);
    const [t] = useTranslation()


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    function handleViewDocumentEvent(e) {
        setViewDocument(e.detail)
    }

    useEffect(() => {
        if(document && !document.MIMEtype) {
            const xhttp = new XMLHttpRequest();
            xhttp.open('GET', document.url);
            xhttp.onreadystatechange = (e: any) => {
                const MIMEtype = e.target.getResponseHeader('Content-Type');
                
                setViewDocument({
                    ...document,
                    MIMEtype
                })
            };
            xhttp.send();
        }

    }, [document])
    
    useEffect(() => {
        if(pdfWrapper) {
            if(pdfWrapper.current !== null) {
                setPdfWrapperWidth(pdfWrapper.current.offsetWidth)
            }
        }

        window.document.addEventListener('customViewDocumentEvent', handleViewDocumentEvent)
        return () => {
            window.document.removeEventListener('customViewDocumentEvent', handleViewDocumentEvent)
        }
    });

    let fileViewer;

    if(viewDocument !== null) {
        if(!viewDocument.MIMEtype) {
            fileViewer = <div className="loader-icon" />
        }else if(viewDocument.MIMEtype === "application/pdf") {
            const pages : any = []

            for(let pageIdx = 0; pageIdx < numPages!; pageIdx++) {
                pages.push(<Page width={pdfWrapperWidth - 40} pageIndex={pageIdx}></Page>)
            }

            fileViewer = <div ref={pdfWrapper}>
                <Document 
                    file={viewDocument.url}
                    onLoadSuccess={onDocumentLoadSuccess}
                >   
                    <div style={{padding: 20, background: "lightgrey"}}>
                        {pages.map((page, idx) => <div key={idx} style={{marginBottom: 20}}>
                            <div>Sida {idx + 1} av {numPages}</div>
                            {page}
                        </div>)}
                    </div>
                </Document>
            </div>
        } else if(viewDocument.MIMEtype === "application/vnd.openxmlformats-officedocument.wordprocessingml.document"){
            fileViewer = <Suspense fallback={<div>Loading..</div>}>
                <FileViewer
                    fileType={"docx"}
                    filePath={viewDocument.url}
                />
            </Suspense>
        } else if(viewDocument.MIMEtype.indexOf("video") !== -1) {
            fileViewer = <div style={{position: "relative", display: "flex", justifyContent: "center", alignItems: "center", padding: 20}}>
                <video controls style={{maxWidth: "100%", maxHeight: "100%"}} src={viewDocument.url}></video>
            </div>
        } else if(viewDocument.MIMEtype.indexOf("image") !== -1) {
            fileViewer = <div style={{position: "relative", display: "flex", justifyContent: "center", alignItems: "center", padding: 20}}>
                <img style={{maxWidth: "100%", maxHeight: "100%"}} src={viewDocument.url}></img>
            </div>
        }
    }

    if(inline) {
        return fileViewer
    }
    
    return <Modal show={viewDocument !== null} onHide={()=>{setViewDocument(null)}}>
        <Modal.Header closeButton>
            <i className="fa fa-search"/> {t("Document")}
        </Modal.Header>
        <Modal.Body style={{padding: 0}}>
            {fileViewer}
        </Modal.Body>
    </Modal>
})

export default DocumentViewer