import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postFormTemplate = (
    backend: string,
    token: string,
    ProjectID: number,
    Active: number,
    Name: string,
    Description: string,
    ChangedByID: number,
    components?
) => {
    return fetch(
        `${getApiUrl(backend)}form_templates`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                ProjectID,
                Active,
                Name,
                Description,
                ChangedByID,
                CreatedByID: ChangedByID,
                components
            })
        }
    );
};

export default postFormTemplate;
