import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getFortnoxArticleUnits = (token: string, backend: string) => {
    return fetch(`${getApiUrl(backend)}fortnox_article_units`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getFortnoxArticleUnits