import React, { useEffect, useRef, useState } from "react"
import Card from "../components/Card"
import { useTranslation } from "react-i18next";
import useDispatch from "../utils/useDispatch";
import displayNumber from "../utils/displayNumber";
import styled from "styled-components"
import roundNumber from "../utils/roundNumber";
import generateOfferPdf from "../pdf/generateOfferPdf"
import { Button } from "react-bootstrap";
import MgPaginate from "../components/MgPaginate";
import useForceUpdate from "../utils/useForceUpdate";
import DocumentCard from "../documents/DocumentCard";

const ArticleTable = styled.table`
    width: 100%;

    td, th {
        padding: 0px 8px;
    }
`

const Block = styled.div`
    padding-bottom: 1.5em;
`

const TotalsBlock = styled.div`
    padding: 1em;
    background: #f8f8f8;
    padding-top: 1.5em;
`

const RowsBlock = styled.div`
    padding: 1em;
    background: #f8f8f8;
`

const BlockTitle = styled.div`
    padding-bottom: 4px;
`

const DisplayOffer = ({companyLogo, superCompact = false, compact = false, offer, rows, textBlocks, documents, Number = null, Version = null, paymentPlanElements, signature = null, onPagesRendered = (pages) => {}}) => {
    const [t] = useTranslation()
    const dispatch = useDispatch()
    const [showPage, setShowPage] = useState(1)
    const [savingAsPdf, setShowingAsPdf] = useState(false)
    const [isValidLogo, setIsValidLogo] = useState(false);
    const DEBUG = false
    const priceIncVAT = offer.CustomerType !== "company"
    useEffect(()=>{
        onPagesRendered(pageIds)
    })

    const refs: any = []
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())
    refs.push(useRef())

    const footerRef: any = useRef()

    const forceUpdate = useForceUpdate();

    useEffect(() => {
        forceUpdate()
    }, [offer, rows, textBlocks])

    useEffect(() => {
        validateLogo();
    }, [])

    const validateLogo = async () => {
        try {
            const response = await fetch(companyLogo);
            if (response.ok) {
                setIsValidLogo(true);
            }
        } catch (error) {
            console.error('Error loading image:', error);
            setIsValidLogo(false);
        }
    }

    const [roundedTotal, roundedDecimals] = priceIncVAT ? roundNumber(offer.Total) : roundNumber(offer.NetTotal)
    
    const TotalDeductionAmount = offer.MaxDeductionAmount && (offer.MaxDeductionAmount < offer.TotalDeductionAmount) ? offer.MaxDeductionAmount : offer.TotalDeductionAmount
    const TotalAfterDeduction = roundedTotal - TotalDeductionAmount

    const pageHeight = 740;

    const footer = <Block ref={footerRef} style={{width: 610 - 36, position: "absolute", top: pageHeight, color: "#909090", paddingTop: "1em", borderTop: "1px solid lightgrey"}}>
        <div style={{whiteSpace:"pre-wrap"}}>{offer.Footer}</div>
    </Block>

    const elements: any = [{
        el: footer,
        page: 1
    }]

    let toManyElements = false

    let elementIndex = 0;
    let currentPage = 1;
    let yBottom = 24
    
    const pages = [1]

    //Adds new block element to offer pdf
    const addElement = (element) => {
        if(refs.length < elementIndex + 1) {
            toManyElements = true
            return
        }

        const ref = refs[elementIndex];

        const height = ((ref.current ? ref.current.clientHeight: 0)) + (DEBUG ? 20 : 0)
        yBottom += height 

        if(yBottom > pageHeight) {
            yBottom = 24 + height;
            currentPage++;
            pages.push(currentPage)
            elements.push({
                el: footer,
                page: currentPage
            })
        }

        if(DEBUG) {        
            elements.push({
                el: <>
                <div>
                    Page: {currentPage} - Height: {height} px - Bottom Y: {yBottom} px
                </div>
                <div ref={refs[elementIndex]} style={{border: "1px solid"}}>
                    {element}
                </div>
                </>,
                page: currentPage
            })
        } else {
            elements.push({
                el: <div ref={refs[elementIndex]}>
                    {element}
                </div>,
                page: currentPage
            })
        }
        elementIndex++;
    }

    addElement(<Block style={{display:"flex", justifyContent:"space-between", alignItems:"flex-start"}}>
        <div>
            <h3 style={{margin: 0, paddingRight: "0.5em", wordBreak: "break-word"}}>
                {offer.Headline}
            </h3>
            {Number && Version && <div>
                <b>#{Number}</b> | {t("Version")} {Version}
            </div>}
        </div>
        <div>
            {isValidLogo && 
            <img 
                crossOrigin="anonymous" 
                style={{height: 60}} 
                alt=""
                src={companyLogo}
            ></img>
            }
        </div>
    </Block>)

    addElement(<Block>
        {offer.CustomerID && <div>
            {t("Customer")}: {t("C")}{offer.FortnoxCustomerNumber || offer.EaccountingCustomerNumber || offer.CustomerID} {offer.CustomerName}
        </div>}
        {offer.OfferDate && <div>
            {t("OfferDate")}: {offer.OfferDate}
        </div>}
        {offer.ExpireDate && <div>
            {t("ExpireDate")}: {offer.ExpireDate}
        </div>}
        {offer.DeliveryDate && <div>
            {t("DeliveryDate")}: {offer.DeliveryDate}
        </div>}
        {offer.YourReference && <div>
            {t("YourReference")}: {offer.YourReference}
        </div>}
        {offer.OurReference && <div>
            {t("OurReference")}: {offer.OurReference}
        </div>}
    </Block>)

    textBlocks.filter(block => block.Show === true).filter(block => block.Position === "intro").forEach(block => {
        addElement(<Block>
            <BlockTitle><b>{block.Title}</b></BlockTitle>
            <div style={{whiteSpace:"pre-wrap"}}>{block.Text}</div>
        </Block>)
    })

    if(offer.ShowOfferRows) {

        const generatePagesWithTables = (rows) => {

            const getRowSize = (row) => {
                if(row.Description.length < 40) {
                    return 1;
                } else if (row.Description.length < 80) {
                    return 2;
                } else if (row.Description.length < 120) {
                    return 3;
                } else if(row.Description.length < 160) {
                    return 4;
                } else if(row.Description.length < 200) {
                    return 5;
                } else if(row.Description.length < 240) {
                    return 6;
                } else if(row.Description.length < 280) {
                    return 7;
                } else if(row.Description.length < 320) {
                    return 8;
                } else if(row.Description.length < 360) {
                    return 9;
                } else if(row.Description.length < 400) {
                    return 10;
                } else if(row.Description.length < 440) {
                    return 11;
                } else if(row.Description.length < 480) {
                    return 12;
                } else if(row.Description.length < 520) {
                    return 13;
                } else if(row.Description.length < 560) {
                    return 14;
                } else if(row.Description.length < 600) {
                    return 15;
                } else if(row.Description.length < 640) {
                    return 16;
                } else if(row.Description.length < 680) {
                    return 17;
                } else if(row.Description.length < 720) {
                    return 18;
                } else if(row.Description.length < 760) {
                    return 19;
                } else if(row.Description.length < 800) {
                    return 20;
                } else if(row.Description.length < 840) {
                    return 21;
                } else if(row.Description.length < 880) {
                    return 22;
                } else if(row.Description.length < 920) {
                    return 23;
                } else if(row.Description.length < 960) {
                    return 24;
                } else if(row.Description.length < 1000) {
                    return 25;
                } else {
                    return 1;
                }
            }

            const getOfferRowsTitlesize = (offerTitle = "") => {

                if(offerTitle.length < 80) {
                    return 1;
                } else if (offerTitle.length < 160) {
                    return 2;
                } else if (offerTitle.length < 240) {
                    return 3;
                } else if(offerTitle.length < 320) {
                    return 4;
                } else if(offerTitle.length < 400) {
                    return 5;
                } else if(offerTitle.length < 480) {
                    return 6;
                } else if(offerTitle.length < 560) {
                    return 7;
                } else if(offerTitle.length < 640) {
                    return 8;
                } else if(offerTitle.length < 720) {
                    return 9;
                } else if(offerTitle.length < 800) {
                    return 10;
                } else if(offerTitle.length < 880) {
                    return 11;
                } else if(offerTitle.length < 960) {
                    return 12;
                } else if(offerTitle.length < 1040) {
                    return 13;
                } else if(offerTitle.length < 1120) {
                    return 14;
                } else if(offerTitle.length < 1200) {
                    return 15;
                } else if(offerTitle.length < 1280) {
                    return 16;
                } else if(offerTitle.length < 1360) {
                    return 17;
                } else if(offerTitle.length < 1440) {
                    return 18;
                } else if(offerTitle.length < 1520) {
                    return 19;
                } else if(offerTitle.length < 1600) {
                    return 20;
                } else if(offerTitle.length < 1680) {
                    return 21;
                } else if(offerTitle.length < 1760) {
                    return 22;
                } else if(offerTitle.length < 1840) {
                    return 23;
                } else if(offerTitle.length < 1920) {
                    return 24;
                } else if(offerTitle.length < 2000) {
                    return 25;
                } else {
                    return 1;
                }

            }

            const sliceData = (arr) => {
                const data:any[] = arr.filter(d => d.Show);
                let tableData:any[] = [];

                let dataChunk:any[] = [];
                let arrChunkSize = offer.OfferRowsHeadline ? getOfferRowsTitlesize(offer.OfferRowsHeadline) : 0;

                data.forEach(row => {
                    
                    arrChunkSize += getRowSize(row);

                    if (arrChunkSize <= 22) {
                        dataChunk.push(row);
                    } else {
                        tableData.push(dataChunk);
                        dataChunk = [];
                        dataChunk.push(row);
                        arrChunkSize = getRowSize(row);
                    }
                })

                if(dataChunk.length > 0) {

                    if(arrChunkSize >= 5) {
                        tableData.push(dataChunk);
                        dataChunk = [];
                        arrChunkSize = 0;
                    } else {                  
                        if(tableData.length > 0) {
                            tableData = tableData.map((arr, index, array) => {
                                if(index === array.length - 1) {
                                    return [...arr, ...dataChunk];
                                } else {
                                    return arr;
                                }
                            })
                        } else {
                            tableData.push(dataChunk);
                        }
                    }
                }

                return tableData;
            }
            
            const slicedRows = sliceData(rows)
            slicedRows.forEach((tableData, index) => {
                
                addElement(<Block>
                            {index === 0 && <BlockTitle>
                                <b>{offer.OfferRowsHeadline}</b>
                            </BlockTitle>}
                            <RowsBlock>
                                <ArticleTable>
                                    <table style={{ width: "100%" }}>
                                        <thead>
                                            <tr>
                                                <th style={{width: "80%", minWidth: '150px'}}>{t("Name2")}</th>
                                                {offer.ShowOfferRowQuantities && <th style={{textAlign:"end", whiteSpace:"nowrap"}}>{t("Quantity")}</th>}
                                                {offer.ShowOfferRowQuantities && <th>{t("Unit")}</th>}
                                                {offer.ShowOfferRowPricePerUnit && <th style={{textAlign:"end", whiteSpace:"nowrap"}}>{t("Á-Price")}</th>}
                                                {offer.ShowOfferRowDiscounts && <th style={{ textAlign:"end", whiteSpace:"nowrap"}}>{t("Discount")}</th>}
                                                {offer.ShowOfferRowTotals && <th style={{textAlign:"end", whiteSpace:"nowrap"}}>{t("Total")}</th>}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tableData.filter(row => row.Show).map(row => <>
                                                <tr>
                                                    <td style={{ wordBreak: "break-word" }}>
                                                        {row.Description}
                                                    </td>
                                                    {offer.ShowOfferRowQuantities && <td style={{textAlign:"end", whiteSpace:"nowrap"}}>
                                                        {displayNumber(row.Quantity)}
                                                    </td>}
                                                    {offer.ShowOfferRowQuantities && <td>
                                                        {(!!row.TaskID ? t("hoursShort") : row.UnitName)}
                                                    </td>}
                                                    {offer.ShowOfferRowPricePerUnit && <td style={{textAlign:"end", whiteSpace:"nowrap"}}>
                                                        {priceIncVAT ? displayNumber(row.PriceWithVAT) : displayNumber(row.Price)} kr
                                                    </td>}
                                                    {offer.ShowOfferRowDiscounts && <td style={{textAlign:"end", whiteSpace:"nowrap"}}>
                                                        {displayNumber(row.Discount)}%
                                                    </td>}
                                                    {offer.ShowOfferRowTotals && <td style={{textAlign:"end", whiteSpace:"nowrap"}}>
                                                        {(priceIncVAT ? displayNumber(row.Total) : displayNumber(row.NetTotal)) + " kr"}
                                                    </td>}
                                                </tr>
                                                {priceIncVAT && !!row.HouseWorkDeductionTypeID && offer.ShowOfferRowTotals && <tr>
                                                    <td style={{color: "red"}}>{row.HouseWorkDeductionTypeDescription} (- {displayNumber(row.HouseWorkDeductionTypeDeduction)}%)</td>    
                                                    {offer.ShowOfferRowQuantities && <td></td>}
                                                    {offer.ShowOfferRowQuantities && <td></td>}
                                                    {offer.ShowOfferRowPricePerUnit && <td></td>}
                                                    {offer.ShowOfferRowDiscounts && <td></td>}
                                                    <td style={{textAlign:"end", whiteSpace:"nowrap", color: "red"}}>
                                                        {!offer.MaxDeductionAmount && <span>- {displayNumber(row.TotalDeductionAmount)} kr</span>}
                                                    </td>    
                                                </tr>}
                                            </>
                                            )}
                                        </tbody>
                                    </table>
                                </ArticleTable>
                            </RowsBlock>
                            {slicedRows.length - 1 === index && <TotalsBlock>
                                <ArticleTable>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {t("EarRounding")}:
                                            </td>
                                            <td style={{textAlign:"right", paddingLeft:"2em"}}>
                                                {roundedDecimals > 0 && "+"} {displayNumber(roundedDecimals)} kr
                                            </td>
                                        </tr>
                                        <tr style={{fontWeight: (priceIncVAT && !!offer.TotalDeductionAmount) ? "inherit" : "bold"}}>
                                            <td>
                                                {(priceIncVAT && !!offer.TotalDeductionAmount) ? t("TotalBeforeDeduction") : t("Total")}:
                                            </td>
                                            <td style={{textAlign:"right", paddingLeft:"2em"}}>
                                                {displayNumber(roundedTotal)} kr
                                            </td>
                                        </tr>
                                        {priceIncVAT && !!offer.TotalDeductionAmount && <tr style={{color: "red"}}>
                                            <td>
                                                {t("TotalDeduction")}:
                                            </td>
                                            <td style={{textAlign:"right", paddingLeft:"2em"}}>    
                                                - {displayNumber(TotalDeductionAmount)} kr
                                            </td>
                                        </tr>}
                                        {priceIncVAT && !!offer.TotalDeductionAmount && <tr>
                                            <td>
                                                <b>
                                                    {t("TotalAfterDeduction")}:
                                                </b>
                                            </td>
                                            <td style={{textAlign:"right", paddingLeft:"2em"}}>
                                                <b>
                                                    {displayNumber(TotalAfterDeduction)} kr
                                                </b>
                                            </td>
                                        </tr>}
                                        {priceIncVAT && <tr>
                                            <td>
                                                {t("VATamount")}:
                                            </td>
                                            <td style={{textAlign:"right", paddingLeft:"2em"}}>
                                                {displayNumber(offer.TotalVAT)} kr
                                            </td>
                                        </tr>}
                                    </tbody>
                                </ArticleTable>
                            </TotalsBlock>}
                            {priceIncVAT && <div style={{color: "grey", textAlign:"end", paddingTop: 4}}>
                                {t("PricesIncludingVAT")}
                            </div>}
                            {!priceIncVAT && <div style={{color: "grey", textAlign:"end", paddingTop: 4}}>
                                {t("PricesExcludingVAT")}
                            </div>}
                        </Block>)
                    })
        }

        generatePagesWithTables(rows)
    }

    textBlocks.filter(block => block.Show === true).filter(block => block.Position === "end").forEach(block => {
        addElement(<Block>
            <BlockTitle><b>{block.Title}</b></BlockTitle>
            <div style={{whiteSpace:"pre-wrap"}}>{block.Text}</div>
        </Block>)
    })

    const applyPaymentPlanVAT = (number) => {

        if(priceIncVAT){
            return number * ((+offer.PaymentPlanVAT + 100) / 100)
        } else {
            return number
        }
        
    }

    if(offer.UsePaymentPlan && paymentPlanElements.length > 0) {
        addElement(<Block style={{ breakWord: "word-break" }}>
            <BlockTitle>
                <b>{t("PaymentPlan")}</b>
            </BlockTitle>
            <RowsBlock>
                <ArticleTable>
                    <thead>
                        <tr>
                            <th style={{textAlign:"end", whiteSpace:"nowrap"}}>#</th>
                            <th>{t("Name")}</th>
                            {offer.ShowPaymentPlanInvoiceDate && <th>{t("InvoiceDate")}</th>}
                            {/* {offer.ShowPaymentPlanDueDate && <th>{t("DueDate")}</th>} */}
                            <th style={{textAlign:"end", whiteSpace:"nowrap"}}>{t("Amount")}</th>
                            <th style={{textAlign:"end", whiteSpace:"nowrap"}}>{t("SafetyAmount")}</th>
                            <th style={{textAlign:"end", whiteSpace:"nowrap"}}>{t("ToInvoice")}</th>
                        </tr>
                    </thead>
                    <tbody >
                        {paymentPlanElements.sort((a, b) => a.SequenceIndex - b.SequenceIndex).map(paymentPlanElement => <>
                            <tr style={{ verticalAlign: "top" }}>
                                <td style={{textAlign:"end", whiteSpace:"nowrap"}}>
                                    {paymentPlanElement.SequenceIndex}
                                </td>
                                <td>
                                    {paymentPlanElement.Name}
                                </td>
                                {offer.ShowPaymentPlanInvoiceDate && <td>
                                    {paymentPlanElement.InvoiceDate}
                                </td>}
                                {/* {offer.ShowPaymentPlanDueDate && <td>
                                    {paymentPlanElement.DueDate}
                                </td>} */}
                                <td style={{textAlign:"end", whiteSpace:"nowrap"}}>
                                    {paymentPlanElement.IsFinalInvoice === "true" ? "" : `${displayNumber(paymentPlanElement.Amount)} kr`}
                                </td>
                                <td style={{textAlign:"end", whiteSpace:"nowrap"}}>
                                    {paymentPlanElement.IsFinalInvoice === "false" && `${displayNumber(paymentPlanElement.SafetyAmount)} kr`}
                                </td>
                                <td style={{textAlign:"end", whiteSpace:"nowrap"}}>
                                    {displayNumber(paymentPlanElement.ToInvoiceAmount)} kr
                                </td>
    
                            </tr>
                        </>
                        )}
                    </tbody>
                </ArticleTable>
            </RowsBlock>
            {/* {priceIncVAT && <div style={{color: "grey", textAlign:"end", paddingTop: 4}}>
                {t("PricesIncludingVAT")}
            </div>}
            {!priceIncVAT && <div style={{color: "grey", textAlign:"end", paddingTop: 4}}>
                {t("PricesExcludingVAT")}
            </div>} */}
            <div style={{color: "grey", textAlign:"end", paddingTop: 4}}>
                {t("PricesExcludingVAT")}
            </div>
        </Block>)
    }

    const getPageId = page => "document-offer-page-" + page
    const pageIds = pages.map(getPageId)
    const saveAsPdf = async () => {
        setShowingAsPdf(true)
        const pdf = await generateOfferPdf(pageIds, documents, signature, t)
        pdf.save((offer.Headline || t("Quotation")) + (Number ? "-#" + Number + (Version ? "-v" + Version : "") : "") + ".pdf");
        setShowingAsPdf(false)
    }

    if(toManyElements) {
        return <div>
            {t("OfferElementLimitExceeded")}
        </div>
    }

    const compactScaleFactor = superCompact ? 0.5 : 0.6
    const compactTranslateX = superCompact ? "-50%" : "-30%";
    const compactTranslateY = superCompact ? "-210px" : "-180px";

    const useCompactScale = superCompact || compact

    return <div style={{maxWidth: compact ? 605 * compactScaleFactor : ""}}>
        <div style={{paddingBottom:"1em"}}>
            {documents.map((document, idx) => <DocumentCard 
                viewButton={true} 
                disabled 
                document={document}
                customTitle={t("Attachment") + " #" + (idx+1)}
            />)}
        </div>
        <div style={{display: useCompactScale ? "" : "flex", justifyContent:"space-between", paddingBottom:"1em", alignItems: "center"}}>
            <Button disabled={savingAsPdf} onClick={saveAsPdf} style={{display:"flex", marginRight:"0.5em"}}>
                {savingAsPdf && <div style={{marginRight:"0.5em"}} className="loader-icon"/>} {t("SaveAsPDF")}
            </Button>{" "}
            <MgPaginate page={showPage} onPageChange={page => {setShowPage(page)}} pageCount={pages.length} />
        </div>
        <div style={{maxHeight: useCompactScale ? 850 * compactScaleFactor : "", transform: useCompactScale ? "scale(" + compactScaleFactor + ") translate(" + compactTranslateX + ", " + compactTranslateY + ")" : ""}}>
            <div style={{height: 850, width: 605, overflow:"hidden", position: "relative"}}>
                {pages.map(page => {
                    return <Card style={{minHeight: 400, padding: 0, position: "absolute", top: page === showPage ? 5 : 850, left: 5 }}>
                        <div id={getPageId(page)} style={{height: 840, width: 595, padding: "24px 18px", fontSize: 13, position: "relative"}}>
                            {elements.filter(element => element.page === page).map(element => element.el)}
                        </div>
                    </Card>
                })}
            </div>
        </div>
        <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1em", alignItems: "center"}}>
            <MgPaginate page={showPage} onPageChange={page => {setShowPage(page)}} pageCount={pages.length} />
        </div>
    </div>
}

export default DisplayOffer
