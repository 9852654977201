import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postCheckToken = (accessToken, email) => {
    return fetch(`${getApiUrl()}check_token/${accessToken}/${email}`, {
        method: "POST"
    })
}

export default postCheckToken
