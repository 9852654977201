import angular from "angular"
import documentButtonTemplate from "./documentButtonTemplate";

export default () => {
    const $ = window.jQuery

    //Custom code to make bootstrap support multiple modals visible at the same time
    // https://stackoverflow.com/questions/19305821/multiple-modals-overlay
    $(document).on('show.bs.modal', '.modal', function() {
        var zIndex = 1040 + 10 * $('.modal:visible').length;
        $(this).css('z-index', zIndex);
        setTimeout(function() {
            $('.modal-backdrop')
                .not('.modal-stack')
                .css('z-index', zIndex - 1)
                .addClass('modal-stack');
        }, 0);
    });

    /**
     * @ngdoc directive
     * @name izzyposWebApp.directive:documentButton
     * @description
     * # Displays all documents beloinging to an element of type scope.docType with id scope.id
     */
    angular
        .module('sbAdminApp')
        .directive('documentButton', function() {
            return {
                template: documentButtonTemplate,
                restrict: 'EA',
                scope: {
                    headerText: '@',
                    docType: '@',
                    id: '@',
                    lazy: '@',
                    documentCount: '@',
                    token: '@'
                },
                controller: [
                    '$scope',
                    '$attrs',
                    '$http',
                    '$rootScope',
                    'moment',
                    '$sce',
                    function(
                        $scope,
                        $attrs,
                        $http,
                        $rootScope,
                        moment,
                        $sce,
                    ) {
                        const token = $scope.token
                        const apiUrl = $scope.$parent.apiUrl;

                        $scope.Title = '';
                        $scope.Text = '';
                        $scope.documents = [];
                        $scope.documentCategories = [];
                        $scope.documentCategoryFilter = null;
                        $scope.uploading = false;
                        $scope.fetching = true;
                        $scope.fetchingCategories = true;
                        $scope.pickedDocumentToBig = false;
                        $scope.selectedCategoryID = null;
                        $scope.selectedDocumentUrl = '';
                        $scope.deleting = false;
                        $scope.wrongFileType = false;
                        $scope.updating = false;

                        $scope.readAccessLevelInput = 1;
                        $scope.editAccessLevelInput = 1;

                        $scope.viewerModalId = 'viewerModal-' + +new Date();

                        $scope.docxViewerId = 'docxViewerId-' + +new Date();
                        $scope.uploadFileInputId = 'uploadFileInput-' + +new Date();
                        $scope.documentsModalId = 'documentsModal-' + +new Date();
                        $scope.uploadDocumentModalId =
                            'uploadDocumentModal-' + +new Date();
                        $scope.editDocumentModalId =
                            'editDocumentModal-' + +new Date();

                        $scope.formatCreatedDate = (date) => {
                            if(date && moment(date).isValid()) {
                                return moment(date).format("YYYY-MM-DD HH:mm")
                            }
                        }

                        function getDocuments() {
                            return new Promise(function(resolve, reject) {
                                var KeyID = getDatabaseIdFromType($scope.docType);
                                if (KeyID && KeyID !== '') {
                                    $http({
                                        method: 'GET',
                                        url:
                                            apiUrl +
                                            'get_documents/' +
                                            KeyID +
                                            '/' +
                                            $scope.id,
                                            headers: {
                                                Authorization: `Bearer ${token}`,
                                            },
                                    })
                                        .success(function(response) {
                                            resolve(response);
                                            $scope.documents = response.documents;
                                            if (KeyID === 'CustomerID') {
                                                $scope.documents = $scope.documents.concat(
                                                    response.documentsInCustomerProjects
                                                );
                                            }
                                            if (KeyID === 'ProjectID') {
                                                $scope.documents = $scope.documents.concat(
                                                    response.customerDocuments
                                                );
                                            }
                                            $scope.fetching = false;
                                        })
                                        .error(function(response) {
                                            reject(response);
                                            $scope.fetching = false;
                                            $scope.getDocumentsError = response
                                                ? response.message
                                                : '';
                                        });
                                } else {
                                    $scope.getDocumentsError =
                                        'TypeError: no match for ' + $scope.docType;
                                    $scope.fetching = false;
                                }
                            });
                        }

                        function getDocumentCategories() {
                            $http({
                                method: 'GET',
                                url: apiUrl + 'get_document_categories',
                                cache: true,
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                                .success(function(response) {
                                    $scope.documentCategories = response;
                                    $scope.fetchingCategories = false;
                                })
                                .error(function(response) {
                                    $scope.fetchingCategories = false;
                                });
                        }

                        if ($scope.lazy !== 'true') {
                            getDocuments();
                            getDocumentCategories();
                        }

                        function handleAttributeChange() {
                            if (!$scope.fetching) {
                                $scope.fetching = true;
                                $scope.documents = [];
                                getDocuments();
                            }
                        }

                        $attrs.$observe('id', handleAttributeChange);
                        $attrs.$observe('docType', handleAttributeChange);

                        function handleLazyChange() {
                            if ($scope.lazy === 'false') {
                                getDocuments();
                                getDocumentCategories();
                            }
                        }

                        $attrs.$observe('lazy', handleLazyChange);

                        function updateDocuments() {
                            $scope.updating = true;
                            getDocuments().then(function() {
                                $scope.updating = false;
                                $scope.$apply();
                            });
                        }

                        function getDatabaseIdFromType(type) {
                            switch (type.toLowerCase()) {
                                case 'product':
                                    return 'ProductID';
                                case 'employee':
                                    return 'EmployeeID';
                                case 'salary':
                                    return 'SalaryID';
                                case 'reported-time':
                                    return 'ReportedTimeID';
                                case 'reported-product':
                                    return 'ReportedProductID';
                                case 'product-assignment':
                                    return 'ProductAssignmentID';
                                case 'customer':
                                    return 'CustomerID';
                                case 'project':
                                    return 'ProjectID';
                                case 'customer-object':
                                    return 'CustomerObjectID';
                                case 'employee-absence':
                                    return 'EmployeeAbsenceID';
                                case 'assignment':
                                    return 'AssignmentID';
                                case 'invoice':
                                    return 'InvoiceID';
                            }
                            return '';
                        }

                        $scope.setReadAccessLevel = function(level) {
                            $scope.readAccessLevelInput = level;
                        };

                        $scope.setEditAccessLevel = function(level) {
                            $scope.editAccessLevelInput = level;
                        };

                        $scope.handleFilterChange = function() {
                            //
                            if ($scope.documentCategoryFilter === 'null') {
                                $scope.documentCategoryFilterValue = null;
                            } else if ($scope.documentCategoryFilter === '') {
                                $scope.documentCategoryFilterValue = undefined;
                            } else {
                                $scope.documentCategoryFilterValue =
                                    $scope.documentCategoryFilter;
                            }
                        };

                        $scope.trustSrc = function(src) {
                            return $sce.trustAsResourceUrl(src);
                        };

                        $scope.viewDocument = function(document) {
                            const event = new CustomEvent("customViewDocumentEvent", {detail: document});
                            window.document.dispatchEvent(event)
                            
                            // $scope.selectedDocument = document;
                            // $('#' + $scope.viewerModalId).modal('toggle');
                            // if (
                            //     document.MIMEtype ===
                            //     'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                            // ) {
                            //     var xhr = new XMLHttpRequest();
                            //     xhr.open('GET', document.url, true);
                            //     xhr.responseType = 'blob';
                            //     xhr.onload = function() {
                            //         if (this.status == 200) {
                            //             var myBlob = this.response;
                            //             // myBlob is now the blob that the object URL pointed to.
                            //             docx.renderAsync(
                            //                 myBlob,
                            //                 $('#' + $scope.docxViewerId).get(0)
                            //             );
                            //         }
                            //     };
                            //     xhr.send();
                            // }
                        };

                        $scope.toggleDocumentModal = function() {
                            if ($scope.lazy === 'true' && $scope.fetching) {
                                getDocuments();
                                getDocumentCategories();
                            }
                            $('#' + $scope.documentsModalId).modal('toggle');
                        };

                        $scope.togglePickDocumentModal = function() {
                            $scope.pickedDocumentFile = undefined;
                            $scope.TitleInput = '';
                            $scope.TextInput = '';
                            $scope.selectedCategoryID = null;
                            $('#' + $scope.uploadDocumentModalId).modal('toggle');
                        };

                        $scope.openEditDocumentModal = function(document) {
                            $scope.editDocumentID = document.id;
                            $scope.TitleInput = document.Title;
                            $scope.TextInput = document.Text;
                            $scope.readAccessLevelInput = document.ReadAccessLevel;
                            $scope.editAccessLevelInput = document.EditAccessLevel;
                            $scope.selectedCategoryID = document.CategoryID
                                ? document.CategoryID.toString()
                                : null;
                            $('#' + $scope.editDocumentModalId).modal('show');
                        };

                        $scope.closeEditDocumentModal = function() {
                            $scope.TitleInput = '';
                            $scope.TextInput = '';
                            $scope.selectedCategoryID = null;
                            $('#' + $scope.editDocumentModalId).modal('hide');
                        };

                        $scope.handleDrop = function(e) {
                            function isAcceptedType(file) {
                                return (
                                    file.type === 'application/pdf' ||
                                    file.type.indexOf('image') !== -1 ||
                                    file.type === 'video/mp4' ||
                                    file.type ===
                                        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                                );
                            }

                            var file =
                                e &&
                                e.dataTransfer &&
                                e.dataTransfer.files &&
                                e.dataTransfer.files[0];

                            if (file) {
                                if (!isAcceptedType(file)) {
                                    $scope.wrongFileType = true;
                                    return;
                                }

                                if (file.size > 20000000) {
                                    $scope.pickedDocumentToBig = true;
                                    return;
                                }

                                $scope.wrongFileType = false;
                                $scope.pickedDocumentToBig = false;
                                $scope.pickedDocumentFile = file;
                                $scope.$apply();
                            }
                        };

                        $scope.removePickedDocument = () => {
                            $scope.pickedDocumentFile = undefined;
                            $scope.wrongFileType = false;
                            $scope.pickedDocumentToBig = false;
                        }

                        $scope.handleDocumentPicked = function(event) {
                            $scope.wrongFileType = false;
                            if (
                                event.target &&
                                event.target.files &&
                                event.target.files.length > 0
                            ) {
                                if (event.target.files[0].size > 20000000) {
                                    $scope.pickedDocumentToBig = true;
                                    return;
                                }

                                $scope.pickedDocumentToBig = false;
                                $scope.pickedDocumentFile = event.target.files[0];
                                $scope.$apply();
                            }
                        };

                        $scope.upload = function() {
                            var requestData = new FormData();
                            requestData.append(
                                'document',
                                $scope.pickedDocumentFile
                            );
                            requestData.append(
                                'MIMEtype',
                                $scope.pickedDocumentFile.type
                            );
                            if ($scope.selectedCategoryID) {
                                requestData.append(
                                    'CategoryID',
                                    $scope.selectedCategoryID
                                );
                            }
                            requestData.append(
                                getDatabaseIdFromType($scope.docType),
                                $scope.id
                            );
                            requestData.append(
                                'CreatedByID',
                                $rootScope.currentUser.EmployeeID
                            );
                            requestData.append(
                                'ChangedByID',
                                $rootScope.currentUser.EmployeeID
                            );
                            requestData.append(
                                'ReadAccessLevel',
                                $scope.readAccessLevelInput
                            );
                            requestData.append(
                                'EditAccessLevel',
                                $scope.editAccessLevelInput
                            );
                            requestData.append('Title', $scope.TitleInput);
                            requestData.append('Text', $scope.TextInput);
                            requestData.append(
                                'Date',
                                moment().format('YYYY-MM-DD')
                            );
                            $scope.uploading = true;
                            $http({
                                method: 'POST',
                                url: apiUrl + 'create_document',
                                data: requestData,
                                headers: { 'Content-Type': undefined, Authorization: `Bearer ${token}`},
                                transformRequest: angular.identity
                            })
                                .success(function(response) {
                                    $scope.uploading = false;
                                    $scope.togglePickDocumentModal();
                                    updateDocuments();
                                })
                                .error(function(response) {
                                    $scope.uploading = false;
                                });
                        };

                        $scope.saveDocumentEdit = function() {
                            var requestData = new FormData();
                            requestData.append('Title', $scope.TitleInput);
                            requestData.append('Text', $scope.TextInput);
                            if ($scope.selectedCategoryID) {
                                requestData.append(
                                    'CategoryID',
                                    $scope.selectedCategoryID
                                );
                            }
                            requestData.append(
                                'ChangedByID',
                                $rootScope.currentUser.EmployeeID
                            );
                            requestData.append(
                                'ReadAccessLevel',
                                $scope.readAccessLevelInput
                            );
                            requestData.append(
                                'EditAccessLevel',
                                $scope.editAccessLevelInput
                            );
                            $scope.uploading = true;
                            $http({
                                method: 'POST',
                                url:
                                    apiUrl +
                                    'edit_document/' +
                                    $scope.editDocumentID,
                                data: requestData,
                                headers: { 'Content-Type': undefined, Authorization: `Bearer ${token}` },
                                transformRequest: angular.identity
                            })
                                .success(function(response) {
                                    $scope.uploading = false;
                                    $scope.closeEditDocumentModal();
                                    updateDocuments();
                                })
                                .error(function(response) {
                                    $scope.uploading = false;
                                });
                        };

                        $scope.deleteDocument = function() {
                            $scope.deleting = true;
                            $http({
                                method: 'DELETE',
                                url:
                                    apiUrl +
                                    'delete_document/' +
                                    $scope.editDocumentID,
                                headers: { 'Content-Type': undefined, Authorization: `Bearer ${token}`}
                            })
                                .success(function(response) {
                                    $scope.deleting = false;
                                    updateDocuments();
                                    $scope.closeEditDocumentModal();
                                })
                                .error(function(response) {
                                    $scope.deleting = false;
                                });
                        };
                    }
                ]
            };
        });

}