import angular from "angular"

export default () => {
    angular.module('sbAdminApp')
        .directive('jqdatepicker', function () {
            return {
            restrict: 'A',
                    require: 'ngModel',
                    link: function (scope, element, attrs, ctrl) {

                        const $ = window.jQuery
                        
                        $(element).datepicker({
                                dateFormat: 'yy-mm-dd',
                                monthNames : [ "Januari", "Februari", "Mars", "April", "Maj", "Juni", "Juli", "Augusti", "September", "Oktober", "November", "December" ],
                                dayNamesMin: [ "Sön", "Mån", "Tis", "Ons", "Tor", "Fre", "Lör" ],
                                
                                monthNamesShort: [ "Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec" ],
                                showWeek: true,
                                weekHeader: "<u>V</u>",
                                showOtherMonths: true,
                                firstDay: 1,
                                inline: true,
                                onSelect: function (date) {
                                ctrl.$setViewValue(date);
                                ctrl.$render();
                                scope.$apply();
                                }
                        });
                        $('input:text').addClass('input-date-ui');
                        $(element).attr('autocomplete', 'off');
                    }
        };
        });
}