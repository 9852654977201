import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postTaskNew = (
    token: string,
    backend: string,
    created_by_id: number,
    title: string,
    description: string,
    task_type_id: string,
    sales_price: string,
    cost_price: string,
    active: string,
    erp_product_id,

) => {
    return fetch(
        `${getApiUrl(backend)}tasks`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                title,
                description,
                active,
                task_type_id,
                sales_price,
                cost_price,
                erp_product_id,
                created_by_id,
                changed_by_id: created_by_id
            })
        }
    );
};

export default postTaskNew;
