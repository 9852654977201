
import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postCreateProjectFromOffer = (backend: string, token: string, id, ProjectName, CreatedByID) => {
    return fetch(`${getApiUrl(backend)}create_project_from_offer/${id}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            ProjectName,
            CreatedByID
        })
    })
}

export default postCreateProjectFromOffer