import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";


const putAbsenceType = (
    backend: string,
    token: string,
    absence_code: string,
    active: string,
    changed_by_id: string,
    description: string,
    id: number,
    title: string
) => {
    
    return fetch(
        `${getApiUrl(backend)}absence/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                absence_code,
                active,
                changed_by_id,
                description,
                id,
                title
            })
        }
    );
};

export default putAbsenceType;