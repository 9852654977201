import angular from "angular"
import reportCardTemplate from "./reportCardTemplate";

function ReportCard($rootScope) {
    this.$onInit = () => {
        if (this.isRejected()) {
            const employee = this.report.attests[0].employee;
            if (employee.id === $rootScope.currentUser.EmployeeID) {
                this.rejectedBy = 'dig';
            } else {
                this.rejectedBy = employee.FirstName + ' ' + employee.LastName;
            }
        }

        const attestWithSignature = this.report.attests.find(attest => !!attest.signature);

        if(attestWithSignature) {
            this.signature = attestWithSignature.signature
            this.SignatureID = attestWithSignature.signature.id
        }
    };

    this.viewProjectDetails = () => {
        const event = new CustomEvent("customViewProjectDetailsEvent", {detail: { id: this.report.ProjectID}});
        window.document.dispatchEvent(event)
    }

    this.viewSignature = () => {
        const event = new CustomEvent("customViewSignatureEvent", {detail: this.signature});
        window.document.dispatchEvent(event)
    }

    this.isEditable = () => {
        return (
            this.report.attests.length === 0 ||
            this.report.attests[0].EmployeeID ===
                $rootScope.currentUser.EmployeeID
        );
    };

    this.isRejected = () => {
        return (
            this.report.attests.length > 0 &&
            this.report.attests[0].Status === 'rejected'
        );
    };

    this.isApproved = () => {
        return (
            this.report.attests.length > 0 &&
            this.report.attests[0].Status === 'approved'
        );
    };

    this.handleClick = () => {
        console.log(this.report);
        if (this.checkable) {
            this.report._checked = this.isEditable()
                ? !this.report._checked
                : false;
            if (this.onCheckedChange) {
                this.onCheckedChange();
            }
        }
    };

    this.style = () => {
        const report = this.report;
        if (!this.checkable) {
            return;
        }

        let opacity = 1;

        if (!this.isEditable()) {
            opacity = 0.5;
        }

        if (this.isApproved()) {
            if (report._checked) {
                return {
                    background: 'rgb(255, 247, 190)',
                    opacity
                };
            } else {
                return {
                    background: 'rgb(178, 255, 177)',
                    opacity
                };
            }
        } else {
            if (report._checked) {
                return {
                    background: 'rgb(178, 255, 177)',
                    opacity
                };
            } else {
                return {
                    background: 'rgb(255, 247, 190)',
                    opacity
                };
            }
        }
    };
}

export default () => {
    angular.module('sbAdminApp').component('reportCard', {
        controller: ["$rootScope", ReportCard],
        template: reportCardTemplate,
        bindings: {
            report: '<',
            onCheckedChange: '<',
            checkable: '=',
            useCustomSequenceId: '<'
        }
    });
}