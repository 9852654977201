import angular from "angular"
import projectCardTemplate from "./projectCardTemplate";
import moment from "moment"

function ProjectCard($rootScope) {

    $rootScope.currentUser = this.user
    this.iamProjectLeader = false
    
    const projectLeader = this.project.project_role_assignments.find(role => role.Type === "lead");
    const employee = projectLeader && projectLeader.employee;
    if(employee) {
        this.iamProjectLeader =  employee.id === $rootScope.currentUser.EmployeeID
    }


    this.formatDate = (date) => {
        if(date && moment(date).isValid()) {
            return moment(date).format("YYYY-MM-DD")
        }
        return "-"
    }

    this.openEditProject = () => {
        this.history.push("editProject/" + this.project.id)
    }

    this.displayProjectLeader = () => {
        const projectLeader = this.project.project_role_assignments.find(role => role.Type === "lead");

        const employee = projectLeader && projectLeader.employee;
        if(employee) {
            return employee.FirstName + " " + employee.LastName
        }

        return "-"
    }
}

export default () => {
    angular.module('sbAdminApp').component('projectCard', {
        controller: ["$rootScope", ProjectCard],
        template: projectCardTemplate,
        bindings: {
            project: "<",
            onProjectLeaderSettingsClick: '<',
            user: '<',
            history: '<',
            useCustomProjectSequence: '<'
        }
    });
}