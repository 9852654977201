import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postHouseWorkDeductionType = (
    token: string,
    backend: string,
    Description,
    Deduction,
    CreatedByID
) => {
    return fetch(
        `${getApiUrl(backend)}house_work_deduction_types`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Description,
                Deduction,
                CreatedByID,
                ChangedByID: CreatedByID
            })
        }
    );
};

export default postHouseWorkDeductionType;
