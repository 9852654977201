import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";
import useForceUpdate from "../utils/useForceUpdate";


const postArticleWithObject = (
    token: string,
    backend: string,
    articleId?: string,
    name?: string,
    description?: string,
    productCategory?: string,
    unit?: string,
    unit_id?: string,
    price?: string,
    cost?: string,
    onSalary?: string,
    salaryCode?: string,
    salary?: string,
    productTypeId?: string,
    product_kind?: string,
    check_box?: any,
    erp_product_id?: any,
    product_type_id?: any,
    created_by_id?: string,
    changed_by_id?: string,
) => {

    let url = ""
    if(articleId){
        url = `${getApiUrl(backend)}products/${articleId}`
    } else {
        url = `${getApiUrl(backend)}products`
    }

    return fetch(
        url,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                created_by_id,
                changed_by_id,
                name,
                description,
                productCategory,
                unit,
                unit_id,
                price,
                cost,
                onSalary,
                salaryCode,
                salary,
                productTypeId,
                product_kind,
                check_box,
                erp_product_id,
                product_type_id,
            })
        }
    );
};

export default postArticleWithObject