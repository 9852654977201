export default (Price, Quantity, VAT, Discount, Deduction) => {
    const newRow: {
        NetTotal: number,
        TotalVAT: number,
        Total: number,
        TotalDeductionAmount: number,
        PriceWithVAT: number
    } = {
        NetTotal: 0,
        TotalVAT: 0,
        Total: 0,
        TotalDeductionAmount: 0,
        PriceWithVAT: 0
    }
    
    newRow.PriceWithVAT = +Price * ((+VAT + 100) / 100)
    newRow.NetTotal = ((100 - Discount) / 100) * ((!!Price && !!Quantity) ? Price * Quantity : 0)
    newRow.TotalVAT = newRow.NetTotal * (VAT / 100)
    newRow.Total = newRow.NetTotal + newRow.TotalVAT
    newRow.TotalDeductionAmount = Math.floor((newRow.Total * (+Deduction / 100)))

    return newRow
}