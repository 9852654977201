import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getReports = (
    backend: string,
    token: string,
    filter: {
        from?: string,
        to?: string,
        employeeId?,
        projectId?,
        user?,
        customerId?,
        salaryId?
        onlyApproved?: boolean,
        timeTypeId?,
    },
    type = "all"
) => {
    return fetch(
        `${getApiUrl(backend)}filtered_attestable_reports/${type}?${filter.from ? '&from=' + filter.from : ""}${filter.to ? '&to=' + filter.to : ""}${filter.employeeId? '&employee=' + filter.employeeId : ''}${filter.user? '&user=' + filter.user : ''}${(filter.projectId ? '&project=' + filter.projectId : '')}${(filter.customerId ? '&customer=' + filter.customerId : '')}${(filter.salaryId ? '&salary=' + filter.salaryId : '')}${(filter.onlyApproved? '&onlyApproved=' + filter.onlyApproved : '')}${(filter.timeTypeId ? '&timeTypeId=' + filter.timeTypeId : '')}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getReports;
