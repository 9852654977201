import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postImportSingleSupplierInvoiceFromFortnox = (
    token: string,
    backend: string,
    created_by_id,
    id,
    financialYear,
) => {
    return fetch(
        `${getApiUrl(backend)}import_single_fortnox_supplier_invoice`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                created_by_id,
                id,
                financialYear,
            })
        }
    );
};

export default postImportSingleSupplierInvoiceFromFortnox;
