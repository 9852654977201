import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getTotalAmountToInvoice = (
    backend: string,
    token: string,
    from = "",
    to = "",
    search = "",
    customerId = "",
    onlyCountWithApproved?: boolean,
) => {
    return fetch(
        `${getApiUrl(backend)}total_amount_to_invoice?${from ? '&from=' + from : ''}${to ? '&to=' + to : ''}${search ? '&search=' + search : ''}${customerId ? '&customerId=' + customerId : ''}${onlyCountWithApproved ? '&onlyApproved=' + true : ''}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getTotalAmountToInvoice;