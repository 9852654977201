import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postOffer = (
    token: string,
    backend: string,
    OfferDate, 
    ExpireDate, 
    DeliveryDate, 
    OurReference, 
    CustomerID, 
    CustomerObjectID, 
    InternalComment, 
    ProjectID, 
    Type,
    Status,
    SignatureID,
    VAT,
    MaxDeductionAmount,
    NetTotal,
    Total,
    TotalVAT,
    TotalDeductionAmount,
    ShowOfferRows,
    ShowOfferRowTotals,
    OfferRowsHeadline,
    Headline,
    Footer,
    TemplateDescription,
    YourReference,
    ShowOfferRowDiscounts,
    ShowOfferRowQuantities,
    ShowOfferRowPricePerUnit,
    UsePaymentPlan,
    PaymentPlanMaxTotalSafetyPercentage,
    PaymentPlanSafetyPercentage,
    ShowPaymentPlanDueDate,
    ShowPaymentPlanInvoiceDate,
    rows,
    textBlocks,
    documents,
    ChangedByID: string,
    CreatedByID: string,
) => {
    return fetch(
        `${getApiUrl(backend)}offers`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                OfferDate, 
                ExpireDate, 
                DeliveryDate, 
                OurReference, 
                CustomerID, 
                CustomerObjectID, 
                InternalComment, 
                ProjectID, 
                Type,
                Status,
                SignatureID,
                VAT,
                MaxDeductionAmount,
                NetTotal,
                Total,
                TotalVAT,
                TotalDeductionAmount,
                ShowOfferRows,
                ShowOfferRowTotals,
                OfferRowsHeadline,
                Headline,
                Footer,
                TemplateDescription,
                YourReference,
                ShowOfferRowDiscounts,
                ShowOfferRowQuantities,
                ShowOfferRowPricePerUnit,
                UsePaymentPlan,
                PaymentPlanMaxTotalSafetyPercentage,
                PaymentPlanSafetyPercentage,
                ShowPaymentPlanDueDate,
                ShowPaymentPlanInvoiceDate,
                rows,
                textBlocks,
                documents,
                ChangedByID,
                CreatedByID,
            })
        }
    );
};

export default postOffer;
