import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postLookupPriceInPricelist = (
    backend: string,
    token: string,
    CustomerID,
    TaskID?,
    TimeTypeID?,
    ProductID?,
    ProjectID?
) => {
    return fetch(
        `${getApiUrl(backend)}price_list_lookup`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                CustomerID,
                TaskID: TaskID ? TaskID : null,
                TimeTypeID: TimeTypeID ? TimeTypeID : null,
                ProductID: ProductID ? ProductID : null,
                ProjectID: ProjectID ? ProjectID : null,
            })
        }
    );
};

export default postLookupPriceInPricelist;
