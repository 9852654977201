const filterUserForms = (arr, user) => {

    const userCanSignForm = (form, user) => {

        const projectLeader = form.project.project_role_assignments.find(assignment => assignment.Type === 'lead');

        switch (form.SignatureBy) {
            case 'project_leader':
                return user.EmployeeID === projectLeader.EmployeeID;
              break;
            case 'admin':
                return user.Role === 'admin';
              break;
            case 'responsee':
                return user.EmployeeID === form.EmployeeID;
              break;
            default:
                return true;
          }
    }

    const userHasProjectAccess = (form, user) => {
        const projectAccess = form.project.project_access_employee_relations.find(relation => relation.EmployeeID === user.EmployeeID);
        
        if(projectAccess) {
            return true;
        } else {
            return false;
        }
    }

    const forms = arr.filter(form => {

        if(['project_leader', 'admin', 'responsee'].includes(form.SignatureBy)) {
            if(form.EmployeeID) {
                return (userCanSignForm(form, user) || form.EmployeeID === user.EmployeeID);
            } else if(form.project.OnlyShowProjectForSelectedEmployees === 'true') {
                return (userCanSignForm(form, user) || userHasProjectAccess(form, user) || form.EmployeeID === user.EmployeeID);
            } else {
                return userCanSignForm(form, user);
            }
            

        } else if(form.SignatureBy === 'all') {
            if(form.project.OnlyShowProjectForSelectedEmployees === 'true') {
                return (userHasProjectAccess(form, user) || form.EmployeeID === user.EmployeeID);
            } else {
                return true;
            }
        }

        return false;
    });

    return forms;
}

export default filterUserForms