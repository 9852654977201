import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postProfilePicture = (
    token: string,
    backend: string,
    user_id: string,
    image: any,
) => {

    const formData = new FormData()

    formData.append("user_id", user_id)
    formData.append("image", image)
    
    return fetch(

        
        `${getApiUrl(backend)}upload_company_logo`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                
            },
            body: formData
        }
    );
};

export default postProfilePicture;
