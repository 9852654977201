
import getApiUrl from "../utils/apiUrl"
import fetch from "../utils/mgFetch";

const deleteBookingResource = (
    token: string, 
    backend: string, 
    id
    ) => {
    return fetch(`${getApiUrl(backend)}remove_booking_resource/${id}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        }
})};

export default deleteBookingResource;