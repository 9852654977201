
import getApiUrl from "../utils/apiUrl"
import fetch from "../utils/mgFetch";

const postChangePassword = (
    token: string, 
    backend: string, 
    user_id: string,
    password: string,
    ) => {
    return fetch(`${getApiUrl(backend)}user/changepassword`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            user_id,
            password,
        })
})};

export default postChangePassword;