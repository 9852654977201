import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postPriceListDiscounts = (
    token: string,
    backend: string,
    ChangedByID: string,
    CreatedByID: string,
    PriceListID: string,
    Discount: string,
    ArticleID: string,
    Type: string,
    FixedPrice: string,
) => {

    const formData = new FormData();

    formData.append('ChangedByID', ChangedByID)
    formData.append('CreatedByID', CreatedByID)
    formData.append('PriceListID', PriceListID)
    formData.append('Discount', Discount)
    formData.append('FixedPrice', FixedPrice)

    const typeKeyMap = {
        article: 'ProductID',
        task: 'TaskID',
        articleCategory: 'ProductTypeID',
        taskCategory: 'TaskTypeID',
    }
    if(typeKeyMap[Type]){
        formData.append(typeKeyMap[Type], ArticleID)
    }
    else {
        formData.append('ProductID', ArticleID)
    }
        
    return fetch(
        `${getApiUrl(backend)}price_list_discounts`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData
        }
    );
};

export default postPriceListDiscounts;
