import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getFortnoxArticles = (token: string, backend: string, page, sortBy, sortOrder, searchBy, search) => {
    return fetch(`${getApiUrl(backend)}get_fortnox_articles/${page}?sortBy=${sortBy}&sortOrder=${sortOrder}&searchBy=${searchBy}&search=${search}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getFortnoxArticles