import React from "react"
import ReactPaginate from 'react-paginate';
import useCheckMobileScreen from "../utils/useCheckMobileScreen";

const MgPaginate = ({pageCount, onPageChange, page}) => {

    const isMobileLayout = useCheckMobileScreen();

    const handleChange = (e) => {
        onPageChange(e.selected + 1)
    }

    return <ReactPaginate
        pageRangeDisplayed={isMobileLayout ? 2 : 2}
        marginPagesDisplayed={isMobileLayout ? 1 : 1}
        containerClassName={"mg-paginate-container"}
        nextLabel={<i className="fa fa-chevron-right"/>} 
        previousLabel={<i className="fa fa-chevron-left"/>} 
        onPageChange={handleChange} 
        pageCount={pageCount}
        forcePage={page ? page - 1 : undefined}
    />
}

export default MgPaginate