
import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putIntegrationItemGroup = (
    token: string,
    backend: string,
    id: any,
    newIntegrationItemGroup: any,
) => {
    return fetch(
        `${getApiUrl(backend)}integrationItemGroups/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: $.param(newIntegrationItemGroup)
        }
    );
};

export default putIntegrationItemGroup;