import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getOffers = (token: string, backend: string, type?, status?, page?, customerId?, search?, sortBy?, sortDirection?) => {
    return fetch(`${getApiUrl(backend)}offers?${status ? '&status=' + status : ''}${type ? '&type=' + type : ''}${page ? '&page=' + page : ''}${sortBy ? '&sortBy=' + sortBy : ''}${sortDirection ? '&sortDirection=' + sortDirection : ''}${search ? '&search=' + search : ''}${customerId ? '&customer=' + customerId : ''}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getOffers