import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postOfferLibraryTextBlock = (
    token: string,
    backend: string,
    Title, 
    Text, 
    ChangedByID: string,
    CreatedByID: string,
) => {
    return fetch(
        `${getApiUrl(backend)}offer_library_text_blocks`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Title, 
                Text, 
                ChangedByID,
                CreatedByID,
            })
        }
    );
};

export default postOfferLibraryTextBlock;
