import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putDocument = (
    backend: string,
    token: string,
    id: string,
    Title: string,
    Text: string,
    ReadAccessLevel: string,
    EditAccessLevel: string,
    ChangedByID: string,
    CategoryID?: string,
) => {
    const requestData = new FormData();
    if (CategoryID) {
        requestData.append('CategoryID', CategoryID);
    }
    requestData.append('ChangedByID', ChangedByID);
    requestData.append('ReadAccessLevel', ReadAccessLevel);
    requestData.append('EditAccessLevel', EditAccessLevel);
    requestData.append('Title', Title);
    requestData.append('Text', Text);

    return fetch(
        `${getApiUrl(backend)}edit_document/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`
            },
            body: requestData
        }
    );
};

export default putDocument;
