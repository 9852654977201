import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

function getArticles(token: string, backend: string) {
    return fetch(`${getApiUrl(backend)}get_all_products_with_document_count`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json, text/plain, */*'
        }
    }) 
}

export default getArticles
