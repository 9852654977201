import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putReportedAbsence = (
    backend: string,
    token: string,
    id: number,
    timeinhours: number,
    changed_by_id: number,
    comment: string,
    date?,
    employeeid?,
    absenceid?
) => {

    const jsonBody: any = {
        timeinhours,
        comment,
        changed_by_id
    }

    if(date) {
        jsonBody.date = date
    }
    
    if(employeeid) {
        jsonBody.employeeid = employeeid
    }
    
    if(absenceid) {
        jsonBody.absenceid = absenceid
    }

    return fetch(
        `${getApiUrl(backend)}employee_absence/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(jsonBody)
        }
    );
};

export default putReportedAbsence;
