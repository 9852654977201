import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putPipedriveLead = (
    newCompanyName: string,
    currentCompanyName: string,
    name: string,
    email: string,
    phone: string,
) => {
    return fetch(
        `${getApiUrl()}pipedrive-integration-update`,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                newCompanyName,
                currentCompanyName,
                name,
                email,
                phone,
            })
        }
    );
};

export default putPipedriveLead;
