import { jsPDF } from "jspdf";
import 'jspdf-autotable'
import { pdfjs } from 'react-pdf';
import { font } from "./font";

// eslint-disable-next-line import/no-anonymous-default-export
export default async (pages, attachments, signature, t) => {

    return new Promise<any>((resolve) => {

        const doc: any = new jsPDF('p', 'pt', 'a4', true);

        doc.addFileToVFS("Roboto-normal.ttf", font);
        doc.addFont("Roboto-normal.ttf", "RobotoRegular", "normal");
        doc.setFont("RobotoRegular");

        var width = doc.internal.pageSize.getWidth();
        var height = doc.internal.pageSize.getHeight();
        
        const containerElement = document.createElement("div")
        containerElement.id = "containerElement"

        pages.forEach(page => {
            const element = document.getElementById(page)
            if(element) {
                containerElement.append(element.cloneNode(true))
            }
        })

        doc.html(containerElement, {
            html2canvas: {
                onclone: (renderDoc) => {
                    // https://github.com/parallax/jsPDF/issues/3178
                    // Workaround to compress images
                    let target = renderDoc.querySelector("#containerElement");
                    // target.style.fontFamily = "Roboto"
                    // console.log("target", target)
                    let images = target.querySelectorAll("img");
        
                    let targetRect = target.getBoundingClientRect();
        
                    images.forEach((img) => {
                        let rect = img.getBoundingClientRect();
                        doc.addImage(img, "JPEG", targetRect.x - rect.x, targetRect.y - rect.y, rect.width, rect.height);
                    });
                },
            },
            callback: (doc) => {   
                if ((!attachments || attachments.length === 0) && !signature) {
                    resolve(doc);
                    return;
                }
                
                const attachmentPromises = attachments.map(attachment => new Promise(async (resolveAttachment) => {
                    const attachmentPages: string[] = [];
            
                    if (attachment.MIMEtype === "application/pdf") {
                        pdfjs.disableWorker = true; // due to CORS
                        const pdf = await pdfjs.getDocument(attachment.url).promise;
            
                        for (let i = 1; i <= pdf.numPages; i++)  {
                            const page = await pdf.getPage(i);
                            const canvas = document.createElement('canvas');
                            const context = canvas.getContext('2d');
                            const viewport = page.getViewport({ scale: 2 });
            
                            canvas.width = viewport.width;
                            canvas.height = viewport.height;
            
                            await page.render({ canvasContext: context, viewport: viewport }).promise;
                            attachmentPages.push(canvas.toDataURL("image/png"));
                        }
            
                        resolveAttachment(attachmentPages);
                    } else {
                        const img = new Image();
                        img.crossOrigin = "anonymous";
                        img.src = attachment.url;
            
                        img.onload = function() {
                            const canvas = document.createElement('canvas');
                            canvas.width = img.width;
                            canvas.height = img.height;
            
                            const context = canvas.getContext('2d');

                            if(context) {
                                context.drawImage(img, 0, 0);
                            }
            
                            attachmentPages.push(canvas.toDataURL('image/png'));
                            resolveAttachment(attachmentPages);
                        };
                    }
                }));
            
                // Add Signature
                const signaturePromise = new Promise((resolveSignature) => {
                    if (signature && signature.url) {
                        const img = new Image();
                        img.crossOrigin = "anonymous";
                        img.src = signature.url;
            
                        img.onload = function() {
                            const canvas = document.createElement('canvas');
                            canvas.width = img.width;
                            canvas.height = img.height;
            
                            const context = canvas.getContext('2d');
                            if(context) {
                                context.drawImage(img, 0, 0);
                            }
            
                            resolveSignature(canvas.toDataURL('image/png'));
                        };
                    } else {
                        resolveSignature(null);
                    }
                });
            
                doc.setTextColor(0, 0, 0);
            
                if (attachments && attachments.length > 0) {
                    doc.addPage();
                    let y = 30;
                    
                    doc.setFontSize(18);
                    doc.text(t("Attachments"), 15, y);
                    y += 30;
            
                    doc.setFontSize(12);
                    doc.autoTable({ 
                        startY: y,
                        columnStyles: {
                            0: { cellWidth: 20 },
                            1: { cellWidth: 170 },
                            2: { cellWidth: 325 }
                        },
                        head: [[
                            "#",
                            t("Title"),
                            t("Text"),
                        ]],
                        styles: {
                            font: "RobotoRegular", 
                        },
                        body: attachments.map((attachment, idx) => {
                            return [
                                idx + 1,
                                attachment.Title,
                                attachment.Text
                            ];
                        })
                    });
                }
            
                Promise.all([...attachmentPromises, signaturePromise]).then((results) => {
                    const attachmentPages = results.slice(0, -1);
                    const signatureImage = results[results.length - 1];
            
                    attachmentPages.forEach((pages, idx) => {
                        pages.forEach((page, pageIdx) => {
                            doc.addPage();
                            const imgProps = doc.getImageProperties(page);
                            const pdfWidth = doc.internal.pageSize.getWidth();
                            const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                            doc.setFontSize(12);
                            doc.text(`${t("Attachment")} #${idx + 1}${pages.length > 1 ? ` (${t("Page")} ${pageIdx + 1} ${t("of")} ${pages.length})` : ""}`, 15, 15);
                            doc.addImage(page, 'PNG', 0, 20, pdfWidth, pdfHeight - 20, "", 'FAST');
                        });
                    });
            
                    if (signatureImage) {
                        doc.addPage();
                        doc.setFontSize(24);
                        doc.text("Signatur", 15, 30)
                        const imgProps = doc.getImageProperties(signatureImage);
                        const pdfWidth = doc.internal.pageSize.getWidth();
                        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                        doc.setFontSize(18);
                        doc.addImage(signatureImage, 'PNG', (pdfWidth - imgProps.width / 2) / 2, 60, imgProps.width / 2, imgProps.height / 2);
                        doc.setFontSize(14);

                        let lineSpacing = 160;

                        if(signature.Name) {
                            doc.text(signature.Name, 15, lineSpacing);
                            lineSpacing += 20;
                        }
                        if(signature.Comment) {
                            doc.text(signature.Comment, 15, lineSpacing);
                            lineSpacing += 20;
                        }
                       
                        doc.text(signature.created_at, 15, lineSpacing);
                    }
            
                    resolve(doc);
                });
            }
            
        })
        
        
    })
}
