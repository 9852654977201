import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getTasksByTitle = (
    token: string,
    backend: string,
    title: string,
) => {
    return fetch(
        `${getApiUrl(backend)}tasks/by_title/${title}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getTasksByTitle;