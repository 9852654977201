import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getUnits = (
    backend: string,
    token: string
) => {
    return fetch(
        `${getApiUrl(backend)}units`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getUnits;
