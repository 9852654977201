import PaymentPlanElement from "../types/PaymentPlanElement";
import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postPaymentPlanElement = (
    token: string,
    backend: string,
    body: PaymentPlanElement
) => {
    return fetch(
        `${getApiUrl(backend)}payment_plan_elements`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(body)
        }
    );
};

export default postPaymentPlanElement;
