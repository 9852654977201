import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getFortnoxStatus = (token: string, backend: string) => {
    return fetch(`${getApiUrl(backend)}verify_fortnox_integration`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getFortnoxStatus