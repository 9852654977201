import React from "react"
import styled from "styled-components"


const Card = styled.div`
    padding: 0.5em;
    box-shadow: 0px 1px 4px #c5c5c5;
    -webkit-box-shadow: 0px 1px 4px #c5c5c5;
    -moz-box-shadow: 0px 1px 4px #c5c5c5;
    margin-bottom: 1em;
    border-radius: 4px;
    background: white;
`

export default Card