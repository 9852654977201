import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getBookableResources = (token: string, backend: string) => {
    return fetch(`${getApiUrl(backend)}bookable_resources`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getBookableResources