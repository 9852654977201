import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postIntegrationItemGroups = (
    token: string,
    backend: string,
    newIntegrationItemGroup: any,
) => {
    return fetch(
        `${getApiUrl(backend)}integrationItemGroups`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: newIntegrationItemGroup
        }
    );
};

export default postIntegrationItemGroups;
