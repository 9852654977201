import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getProjectsByErpProjectId = (
    token: string,
    backend: string,
    erpprojectid: any
) => {
    return fetch(
        `${getApiUrl(backend)}projects/by_erpprojectid/${erpprojectid}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*'
            },
        }
    );
};

export default getProjectsByErpProjectId;