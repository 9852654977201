import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postCheckToken = (accessToken, email, FirstName = "", LastName = "",Password = "", MobileNumber = "") => {
    return fetch(`${getApiUrl()}create_user_with_token/${accessToken}/${email}`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            FirstName,
            LastName,
            Password,
            MobileNumber
        })
    })
}

export default postCheckToken