import getApiUrl from "../utils/apiUrl"
import fetch from "../utils/mgFetch";

const postForgotPassword = (email: string) => {
    return fetch(`${getApiUrl()}forgot_password/${email}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    });
};

export default postForgotPassword;
