import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getFilteredEmployees = (
    token: string, 
    backend: string,
    active?: string,
    query?: string,
    page?,
    orderby?,
    sortDirection?,
    
    ) => {
    return fetch(`${getApiUrl(backend)}employees?${query ? '&query=' + query : ''}${active ? '&view=' + active : ''}${page ? '&page=' + page : ''}${orderby ? '&orderby=' + orderby : ''}${sortDirection ? '&sortDirection=' + sortDirection : ''}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getFilteredEmployees