import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getProductsByErpProductId = (
    token: string,
    backend: string,
    erpproductid: any
) => {
    return fetch(
        `${getApiUrl(backend)}products/by_erpproductid/${erpproductid}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*'
            },
        }
    );
};

export default getProductsByErpProductId;