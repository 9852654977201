
import getApiUrl from "../utils/apiUrl"
import fetch from "../utils/mgFetch";

const putBooking = (
    token: string, 
    backend: string, 
    id,
    StartTime, 
    EndTime, 
    EmployeeCount,
    ProjectID, 
    ProjectElementID, 
    OnWeekends,
    ChangedByID
    ) => {
    return fetch(`${getApiUrl(backend)}update_booking/${id}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            StartTime, 
            EndTime, 
            EmployeeCount,
            ProjectID, 
            ProjectElementID, 
            OnWeekends,
            ChangedByID
        })
})};

export default putBooking;