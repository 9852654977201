import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postTask = (
    backend: string,
    token: string,
    title: string,
    description: string,
    created_by_id: number
) => {
    return fetch(
        `${getApiUrl(backend)}tasks`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                title,
                description,
                active: 1,
                created_by_id,
                changed_by_id: created_by_id
            })
        }
    );
};

export default postTask;
