import angular from 'angular';
import moment from 'moment';

export default [
    "$scope",
    "$http",
    "$rootScope",
    function CalendarController($scope, $http, $rootScope) {
    const $stateParams = $scope.$parent.user;
    const token = $scope.$parent.token;
    const apiUrl = $scope.$parent.apiUrl;

    $rootScope.currentUser = $scope.$parent.user

    $scope.todayDate = moment().format("YYYY-MM-DD");
    $scope.date = new Date();
    $scope.calendarView = 'month';
    $scope.viewDate = $scope.date;
    $scope.calendarTitle = $scope.date.getMonth().toLocaleString();

    $scope.FirstName = $stateParams.FirstName;
    $scope.LastName = $stateParams.LastName;
    $scope.EmployeeID = $stateParams.EmployeeID;

    $scope.totalTimePerWeek = null;
    $scope.totalTimeInMonth = null;
    $scope.expandedRow = null;
    $scope.cellIsOpen = false;
    var expandedDate;

    $scope.getStyleBasedOnTimeAndWeek = function (time, week) {
        //Om vi vill ändra färg på bubblan med total tid per vecka
        return {
            background: time ? '#f0ad4e' : '#dcdcdc',
        };
    };

    function get_settings() {
        $http
            .get(apiUrl + 'settings/get_all_settings', {headers: {
                Authorization: 'Bearer ' + token,
            }})
            .success(function(response) {
                if (response) {
                    if (response.settings) {

                        $scope.UsersAllowedToEditReports = response.settings.UsersAllowedToEditReports

                        if (response.settings.UseAttest === 'true') {
                            $scope.useAttests = true;
                        } else {
                            $scope.useAttests = false;
                        }
                    }
                }
            });
    }
    get_settings();
    //console.log($scope.EmployeeID);
    function calculateTotalTimePerWeek() {
        var totalTimePerWeek = {};
        var totalTimeInMonth = 0;

        var firstDayOfMonth = moment($scope.date).startOf('month');
        var lastDayOfMonth = moment($scope.date).endOf('month');

        var firstWeekOfMonth = firstDayOfMonth.week();
        var lastWeekOfMonth = lastDayOfMonth.week();
        if (firstWeekOfMonth > lastWeekOfMonth) {
            var clone = moment(lastDayOfMonth);
            lastWeekOfMonth = clone.subtract(1, 'week').week() + 1;
        }

        for (var i = firstWeekOfMonth; i <= lastWeekOfMonth; i++) {
            totalTimePerWeek[i] = $scope.reportedTimes ? 0 : '';
        }

        $scope.reportedTimes &&
            $scope.reportedTimes.forEach(function (reportedTime) {
                var weekNumber = moment(reportedTime.Date).week();
                if (weekNumber < firstWeekOfMonth) {
                    var clone = moment(lastDayOfMonth);
                    weekNumber = clone.subtract(1, 'week').week() + 1;
                }

                if (
                    (moment($scope.date)
                        .startOf('month')
                        .isBefore(moment(reportedTime.Date)) ||
                        moment($scope.date)
                            .startOf('month')
                            .isSame(moment(reportedTime.Date))) &&
                    (moment($scope.date)
                        .endOf('month')
                        .isAfter(moment(reportedTime.Date)) ||
                        moment($scope.date)
                            .endOf('month')
                            .isSame(moment(reportedTime.Date)))
                ) {
                    totalTimeInMonth += parseFloat(reportedTime.TimeInHours);
                    totalTimePerWeek[weekNumber] += parseFloat(
                        reportedTime.TimeInHours
                    );
                }
            });

        $scope.employeeAbsence &&
            $scope.employeeAbsence.forEach(function (reportedAbsence) {
                var weekNumber = moment(reportedAbsence.Date).week();
                if (weekNumber < firstWeekOfMonth) {
                    var clone = moment(lastDayOfMonth);
                    weekNumber = clone.subtract(1, 'week').week() + 1;
                }

                if (
                    (moment($scope.date)
                        .startOf('month')
                        .isBefore(moment(reportedAbsence.Date)) ||
                        moment($scope.date)
                            .startOf('month')
                            .isSame(moment(reportedAbsence.Date))) &&
                    (moment($scope.date)
                        .endOf('month')
                        .isAfter(moment(reportedAbsence.Date)) ||
                        moment($scope.date)
                            .endOf('month')
                            .isSame(moment(reportedAbsence.Date)))
                ) {
                    totalTimeInMonth += parseFloat(reportedAbsence.TimeInHours);
                    totalTimePerWeek[weekNumber] += parseFloat(
                        reportedAbsence.TimeInHours
                    );
                }
            });

        var timePerWeekArr = [];
        Object.keys(totalTimePerWeek).forEach(function (key) {
            timePerWeekArr.push({ hours: totalTimePerWeek[key] });
        });

        $scope.totalTimeInMonth = totalTimeInMonth;
        $scope.totalTimePerWeek = timePerWeekArr;
    }

    function getAttestStates(date) {
        let reportedTimesNeedsAttest = false;
        let reportedTimesRejected = false;

        let reportedProductsNeedsAttest = false;
        let reportedProductsRejected = false;

        let reportedAbsenceNeedsAttest = false;
        let reportedAbsenceRejected = false;

        if ($scope.useAttests) {
            reportedTimesNeedsAttest = !!date.reportedTimes.find(
                (report) =>
                    !report.SalaryID &&
                    !report.InvoiceID &&
                    report.attests.length === 0
            );

            reportedTimesRejected = !!date.reportedTimes.find(
                (report) =>
                    !report.SalaryID &&
                    !report.InvoiceID &&
                    report.attests &&
                    report.attests.length > 0 &&
                    report.attests[0].Status === 'rejected'
            );

            reportedProductsNeedsAttest = !!date.products.find(
                (report) =>
                    !report.SalaryID &&
                    !report.InvoiceID &&
                    report.attests.length === 0
            );

            reportedProductsRejected = !!date.products.find(
                (report) =>
                    !report.SalaryID &&
                    !report.InvoiceID &&
                    report.attests &&
                    report.attests.length > 0 &&
                    report.attests[0].Status === 'rejected'
            );

            reportedAbsenceNeedsAttest = !!date.absence.find(
                (report) => !report.SalaryID && report.attests.length === 0
            );

            reportedAbsenceRejected = !!date.absence.find(
                (report) =>
                    !report.SalaryID &&
                    report.attests &&
                    report.attests.length > 0 &&
                    report.attests[0].Status === 'rejected'
            );
        }

        return {
            reportedTimesNeedsAttest,
            reportedTimesRejected,
            reportedProductsNeedsAttest,
            reportedProductsRejected,
            reportedAbsenceNeedsAttest,
            reportedAbsenceRejected,
        };
    }
    //Get reportedTimes for current employee
    $http
        .get(apiUrl + 'reportedtimes/employee/' + $stateParams.EmployeeID, {
            headers: { Authorization: 'Bearer ' + token },
        })
        .success(function (response) {
            $scope.reportedTimes = response.reported_times;
            $http
                .get(apiUrl + 'notes/employee/' + $stateParams.EmployeeID, {
                    headers: { Authorization: 'Bearer ' + token },
                })
                .success(function (response) {
                    $scope.userNotes = response.notes;
                    $http
                        .get(
                            apiUrl +
                                'employee_absence/employee/no_salary/' +
                                $stateParams.EmployeeID,
                            {
                                headers: { Authorization: 'Bearer ' + token },
                            }
                        )
                        .success(function (response) {
                            $scope.employeeAbsence = response;
                            //Get products for current employee
                            $http
                                .get(
                                    apiUrl +
                                        'reported_products/employee/' +
                                        $stateParams.EmployeeID,
                                    {
                                        headers: {
                                            Authorization: 'Bearer ' + token,
                                        },
                                    }
                                )
                                .success(function (response) {
                                    var tempProducts =
                                        response.reported_products;
                                    $scope.reportedProducts = [];
                                    $scope.reportedProductsCar = [];
                                    $scope.reportedProductsComp = [];
                                    angular.forEach(tempProducts, function (
                                        reportedProduct
                                    ) {
                                        if (
                                            reportedProduct.ProductKind ===
                                                'product' ||
                                            reportedProduct.ProductKind ===
                                                'task'
                                        ) {
                                            $scope.reportedProducts.push(
                                                reportedProduct
                                            );
                                        } else if (
                                            reportedProduct.ProductKind ===
                                            'car'
                                        ) {
                                            $scope.reportedProductsCar.push(
                                                reportedProduct
                                            );
                                        } else if (
                                            reportedProduct.ProductKind ===
                                            'comp'
                                        ) {
                                            $scope.reportedProductsComp.push(
                                                reportedProduct
                                            );
                                        }
                                    });
                                    calculateTotalTimePerWeek();
                                    $scope.createEventsFromReportedTimes(
                                        $scope.date.getFullYear(),
                                        $scope.date.getMonth()
                                    );
                                })
                                .error(function (error) {
                                    console.log(error);
                                    alert(
                                        'An error has occured. Please check the log for details'
                                    );
                                });
                        })
                        .error(function (response) {
                            console.log(response);
                            alert(
                                'An error has occured. Please check the log for details'
                            );
                        });
                })
                .error(function (response) {
                    console.log(response);
                    alert(
                        'An error has occured. Please check the log for details'
                    );
                });
        })
        .error(function (response) {
            console.log(response);
            alert('An error has occured. Please check the log for details');
        });

    $scope.incrementMonth = function () {
        $scope.date = new Date(
            $scope.date.getFullYear(),
            $scope.date.getMonth() + 1,
            $scope.date.getDate()
        );
        calculateTotalTimePerWeek();
    };

    $scope.decrementMonth = function () {
        $scope.date = new Date(
            $scope.date.getFullYear(),
            $scope.date.getMonth() - 1,
            $scope.date.getDate()
        );
        calculateTotalTimePerWeek();
    };

    $scope.initEventsFromReportedTimes = function () {
        $scope.createEventsFromReportedTimes(
            $scope.date.getFullYear(),
            $scope.date.getMonth()
        );
    };

    $scope.handleDateClicked = function (date) {
        var firstDayOfMonth = moment($scope.date).startOf('month');
        var lastDayOfMonth = moment($scope.date).endOf('month');

        if (
            moment(date).isSame(expandedDate) ||
            moment(date).isBefore(firstDayOfMonth) ||
            moment(date).isAfter(lastDayOfMonth)
        ) {
            expandedDate = undefined;
            $scope.expandedRow = -1;
        } else {
            expandedDate = moment(date);
            $scope.date = new Date(date);
            $scope.expandedRow = moment(date).week() - firstDayOfMonth.week();
        }
    };

    $scope.createEventsFromReportedTimes = function (year, month) {
        //Create new Array;
        var eventArray = [];
        var monthStart = new Date(year, month, 0);
        var monthEnd = new Date(year, month + 1, 0);
        var monthLength = (monthEnd - monthStart) / (1000 * 60 * 60 * 24);
        //Iterate through the days of this month
        for (var i = 1; i <= monthLength; i++) {
            //Create tempDayObject (Object with all the reportedTimes for one day)
            var tempDayObjectArray = [];
            tempDayObjectArray.notes = [];
            tempDayObjectArray.products = [];
            tempDayObjectArray.productsCar = [];
            tempDayObjectArray.productsComp = [];
            tempDayObjectArray.absence = [];
            //Create variable for totalTime for this day
            var totalDayTime = 0;
            var totalTimeArray = [];
            var totalAbsenceTimeArray = [];
            var totalAbsenceTime = 0;
            if (tempDayObjectArray) {
                if (!tempDayObjectArray.reportedTimes) {
                    tempDayObjectArray.reportedTimes = [];
                }
            }
            angular.forEach($scope.reportedTimes, function (reportedTime) {
                //Iterate through every reportedTime.
                //Add the reportedTimes for the current day in loop to the tempDayObjectArray.
                if (
                    reportedTime.Date === year + '-' + (month + 1) + '-' + i ||
                    reportedTime.Date === year + '-' + (month + 1) + '-0' + i ||
                    reportedTime.Date === year + '-0' + (month + 1) + '-' + i ||
                    reportedTime.Date === year + '-0' + (month + 1) + '-0' + i
                ) {
                    //console.log("date matched: " + reportedTime.Date + "year: " + year + "month: " + (month + 1) + ". and i was: " + i);
                    tempDayObjectArray.reportedTimes.push(reportedTime);
                    //Add reportedTime to todalDayTime
                    totalDayTime =
                        totalDayTime + parseFloat(reportedTime.TimeInHours);
                }
            });
            angular.forEach($scope.userNotes, function (note) {
                if (
                    note.Date === year + '-' + (month + 1) + '-' + i ||
                    note.Date === year + '-' + (month + 1) + '-0' + i ||
                    note.Date === year + '-0' + (month + 1) + '-' + i ||
                    note.Date === year + '-0' + (month + 1) + '-0' + i
                ) {
                    tempDayObjectArray.notes.push(note);
                }
            });
            angular.forEach($scope.reportedProducts, function (product) {
                if (
                    product.Date === year + '-' + (month + 1) + '-' + i ||
                    product.Date === year + '-' + (month + 1) + '-0' + i ||
                    product.Date === year + '-0' + (month + 1) + '-' + i ||
                    product.Date === year + '-0' + (month + 1) + '-0' + i
                ) {
                    tempDayObjectArray.products.push(product);
                }
            });
            angular.forEach($scope.reportedProductsComp, function (product) {
                if (
                    product.Date === year + '-' + (month + 1) + '-' + i ||
                    product.Date === year + '-' + (month + 1) + '-0' + i ||
                    product.Date === year + '-0' + (month + 1) + '-' + i ||
                    product.Date === year + '-0' + (month + 1) + '-0' + i
                ) {
                    tempDayObjectArray.productsComp.push(product);
                }
            });
            angular.forEach($scope.reportedProductsCar, function (product) {
                if (
                    product.Date === year + '-' + (month + 1) + '-' + i ||
                    product.Date === year + '-' + (month + 1) + '-0' + i ||
                    product.Date === year + '-0' + (month + 1) + '-' + i ||
                    product.Date === year + '-0' + (month + 1) + '-0' + i
                ) {
                    tempDayObjectArray.productsCar.push(product);
                }
            });
            angular.forEach($scope.employeeAbsence, function (absence) {
                if (
                    absence.Date === year + '-' + (month + 1) + '-' + i ||
                    absence.Date === year + '-' + (month + 1) + '-0' + i ||
                    absence.Date === year + '-0' + (month + 1) + '-' + i ||
                    absence.Date === year + '-0' + (month + 1) + '-0' + i
                ) {
                    tempDayObjectArray.absence.push(absence);
                    totalAbsenceTime += parseFloat(absence.TimeInHours);
                }
            });
            if (totalDayTime > 0 || totalAbsenceTime > 0) {
                //Add totalTime to dayObjectArray
                if (totalDayTime > 0 && totalAbsenceTime === 0) {
                    totalAbsenceTimeArray = {
                        totalDayTime: totalDayTime,
                        totalAbsenceTime: 0,
                        date: year + '-' + (month + 1) + '-' + i,
                    };
                } else if (totalDayTime > 0 && totalAbsenceTime > 0) {
                    totalAbsenceTimeArray = {
                        totalDayTime: totalDayTime,
                        totalAbsenceTime: totalAbsenceTime,
                        date: year + '-' + (month + 1) + '-' + i,
                    };
                } else if (totalDayTime === 0 && totalAbsenceTime > 0) {
                    totalAbsenceTimeArray = {
                        totalDayTime: 0,
                        totalAbsenceTime: totalAbsenceTime,
                        date: year + '-' + (month + 1) + '-' + i,
                    };
                } else {
                    totalAbsenceTimeArray = {
                        totalDayTime: 0,
                        totalAbsenceTime: 0,
                        date: year + '-' + (month + 1) + '-' + i,
                    };
                }
                if (totalAbsenceTimeArray) {
                    tempDayObjectArray.unshift(totalAbsenceTimeArray);
                }
            }
            eventArray.push(tempDayObjectArray); //Add dayArray to total eventArray (for this month)
        }
        $scope.createEvents(eventArray, year, month);
    };
    $scope.createEvents = function (reportedTimes, year, month) {
        //console.log(reportedTimes);
        $scope.events = [];
        var str = '';
        var number = null;
        var event = {};
        angular.forEach(reportedTimes, function (reportedTime, key) {
            const {
                reportedTimesNeedsAttest,
                reportedTimesRejected,
                reportedProductsNeedsAttest,
                reportedProductsRejected,
                reportedAbsenceNeedsAttest,
                reportedAbsenceRejected
            } = getAttestStates(reportedTime);

            str = year + '-' + (month + 1) + '-' + (key + 1);
            number = Date.parse(str.replace(/-/g, '/'));
            var actions2 = [];
            var pencil = {};
            var temp_date = new Date(number);
            temp_date = moment(temp_date).format("YYYY-MM-DD");
            var users_cant_edit = null;
            
            const yesterday = moment($scope.todayDate).subtract(1, "day");
            const tomorrow = moment($scope.todayDate).add(1, "day");

            if($rootScope.currentUser.roles === 'admin') {
                users_cant_edit = false;
            } else if (
                $scope.UsersAllowedToEditReports === "false" &&
                $scope.todayDate !== temp_date                    
            ) {
                users_cant_edit = true;
            } else if ( 
                $scope.UsersAllowedToEditReports === "week" &&
                !moment($scope.todayDate).isSame(moment(temp_date), "week")
            ) {
                users_cant_edit = true;
            } else if (
                $scope.UsersAllowedToEditReports === "adjacent" &&
                !moment(yesterday).isSame(temp_date, "day") &&
                !moment($scope.todayDate).isSame(temp_date, "day") &&
                !moment(tomorrow).isSame(temp_date, "day")
            ) {
                users_cant_edit = true;
            } else {
                users_cant_edit = false;
            }

            var pencil = {}

            if (users_cant_edit) {
                pencil = {
                    // an array of actions that will be displayed next to the event title
                    label: "<i class='glyphicon glyphicon-search'></i>", // the label of the action
                    cssClass: 'edit-action', // a CSS class that will be added to the action element so you can implement custom styling
                    onClick: function (args) {
                        // the action that occurs when it is clicked. The first argument will be an object containing the parent event
                        $scope.$parent.history.push(
                            '/timereport/' +
                                moment(args.calendarEvent.startsAt).format(
                                    'YYYY-MM-DD'
                                ) +
                                '/false/true'
                        );
                    },
                };
            } else {
                pencil = {
                    // an array of actions that will be displayed next to the event title
                    label: "<p style='color:#f0ad4e'>Redigera</p>", // the label of the action
                    cssClass: 'edit-action', // a CSS class that will be added to the action element so you can implement custom styling
                    onClick: function (args) {
                        // the action that occurs when it is clicked. The first argument will be an object containing the parent event
                        $scope.$parent.history.push(
                            '/timereport/' +
                                moment(args.calendarEvent.startsAt).format(
                                    'YYYY-MM-DD'
                                ) +
                                '/false/true'
                        );
                    },
                };
            }
            if (
                reportedTime.reportedTimes.length === 0 &&
                reportedTime.notes.length === 0 &&
                reportedTime.absence.length === 0
            ) {
                actions2 = [
                    {
                        label:
                            "<i class='fa fa-fw fa-clock-o' style='color: lightgrey; margin-left: 4px'> </i> " +
                            '00:00 ' +
                            " <i class='fa fa-minus-square' style='color: lightgrey;'></i> " +
                            '00:00 ' +
                            '<br>' +
                            " <i class='fa fa-fw fa-tags' style='color: lightgrey; margin-left: 10px'> </i> " +
                            '(' +
                            0 +
                            ')', // the label of the action, // the label of the action
                        cssClass: 'cal-slide-content-title', // a CSS class that will be added to the action element so you can implement custom styling
                    },
                ];
            } else if (
                reportedTime.reportedTimes.length > 0 &&
                reportedTime.notes.length > 0 &&
                reportedTime.absence.length > 0
            ) {
                actions2 = [
                    {
                        label:
                            "<i class='fa fa-fw fa-clock-o' style='color: #5cb85c; margin-left: 4px'> </i> " +
                            $scope.formatTotalTime(
                                reportedTime[0].totalDayTime
                            ) +
                            " <i class='fa fa-minus-square' style='color: #5cb85c;'></i> " +
                            $scope.formatTotalTime(
                                reportedTime[0].totalAbsenceTime
                            ) +
                            '<br>' +
                            "<i class='fa fa-fw fa-tags' style='color: #5cb85c; margin-left: 10px'> </i> " +
                            '(' +
                            reportedTime.notes.length +
                            ')', // the label of the action, // the label of the action
                        cssClass: 'cal-slide-content-title', // a CSS class that will be added to the action element so you can implement custom styling
                    },
                ];
            } else if (
                reportedTime.reportedTimes.length > 0 &&
                reportedTime.notes.length === 0 &&
                reportedTime.absence.length === 0
            ) {
                actions2 = [
                    {
                        label:
                            "<i class='fa fa-fw fa-clock-o' style='color: #5cb85c; margin-left: 4px'> </i> " +
                            $scope.formatTotalTime(
                                reportedTime[0].totalDayTime
                            ) +
                            " <i class='fa fa-minus-square' style='color: lightgrey;'></i> " +
                            '00:00 ' +
                            '<br>' +
                            " <i class='fa fa-fw fa-tags' style='color: lightgrey; margin-left: 10px'> </i> " +
                            '(' +
                            0 +
                            ')', // the label of the action, // the label of the action
                        cssClass: 'cal-slide-content-title', // a CSS class that will be added to the action element so you can implement custom styling
                    },
                ];
            } else if (
                reportedTime.reportedTimes.length === 0 &&
                reportedTime.notes.length > 0 &&
                reportedTime.absence.length === 0
            ) {
                var tempObject = {
                    label:
                        "<i class='fa fa-fw fa-clock-o' style='color: lightgrey; margin-left: 4px'> </i> " +
                        '00:00 ' +
                        " <i class='fa fa-minus-square' style='color: lightgrey;'></i> " +
                        '00:00' +
                        '<br>' +
                        " <i class='fa fa-fw fa-tags' style='color: #5cb85c; margin-left: 10px'> </i> " +
                        '(' +
                        reportedTime.notes.length +
                        ')', // the label of the action, // the label of the action
                    cssClass: 'cal-slide-content-title', // a CSS class that will be added to the action element so you can implement custom styling
                };
                actions2.push(tempObject);
            } else if (
                reportedTime.reportedTimes.length === 0 &&
                reportedTime.notes.length === 0 &&
                reportedTime.absence.length > 0
            ) {
                actions2 = [
                    {
                        label:
                            "<i class='fa fa-fw fa-clock-o' style='color: lightgrey; margin-left: 4px'> </i> " +
                            '00:00 ' +
                            " <i class='fa fa-minus-square' style='color: #5cb85c;'></i> " +
                            $scope.formatTotalTime(
                                reportedTime[0].totalAbsenceTime
                            ) +
                            '<br>' +
                            " <i class='fa fa-fw fa-tags' style='color: lightgrey; margin-left: 10px'> </i> " +
                            '(' +
                            0 +
                            ')', // the label of the action, // the label of the action
                        cssClass: 'cal-slide-content-title', // a CSS class that will be added to the action element so you can implement custom styling
                    },
                ];
            } else if (
                reportedTime.reportedTimes.length > 0 &&
                reportedTime.notes.length > 0 &&
                reportedTime.absence.length === 0
            ) {
                actions2 = [
                    {
                        label:
                            "<i class='fa fa-fw fa-clock-o' style='color: #5cb85c; margin-left: 4px'> </i> " +
                            $scope.formatTotalTime(
                                reportedTime[0].totalDayTime
                            ) +
                            " <i class='fa fa-minus-square' style='color: lightgrey;'></i> " +
                            '00:00' +
                            '<br>' +
                            "<i class='fa fa-fw fa-tags' style='color: #5cb85c; margin-left: 10px'> </i> " +
                            '(' +
                            reportedTime.notes.length +
                            ')', // the label of the action, // the label of the action
                        cssClass: 'cal-slide-content-title', // a CSS class that will be added to the action element so you can implement custom styling
                    },
                ];
            } else if (
                reportedTime.reportedTimes.length === 0 &&
                reportedTime.notes.length > 0 &&
                reportedTime.absence.length > 0
            ) {
                actions2 = [
                    {
                        label:
                            "<i class='fa fa-fw fa-clock-o' style='color: lightgrey; margin-left: 4px'> </i> " +
                            '00:00' +
                            " <i class='fa fa-minus-square' style='color: #5cb85c;'></i> " +
                            $scope.formatTotalTime(
                                reportedTime[0].totalAbsenceTime
                            ) +
                            '<br>' +
                            "<i class='fa fa-fw fa-tags' style='color: #5cb85c; margin-left: 10px'> </i> " +
                            '(' +
                            reportedTime.notes.length +
                            ')', // the label of the action, // the label of the action
                        cssClass: 'cal-slide-content-title', // a CSS class that will be added to the action element so you can implement custom styling
                    },
                ];
            } else if (
                reportedTime.reportedTimes.length > 0 &&
                reportedTime.notes.length === 0 &&
                reportedTime.absence.length > 0
            ) {
                actions2 = [
                    {
                        label:
                            "<i class='fa fa-fw fa-clock-o' style='color: #5cb85c; margin-left: 4px'> </i> " +
                            $scope.formatTotalTime(
                                reportedTime[0].totalDayTime
                            ) +
                            " <i class='fa fa-minus-square' style='color: #5cb85c;'></i> " +
                            $scope.formatTotalTime(
                                reportedTime[0].totalAbsenceTime
                            ) +
                            '<br>' +
                            "<i class='fa fa-fw fa-tags' style='color: lightgray; margin-left: 10px'> </i> " +
                            '(' +
                            reportedTime.notes.length +
                            ')', // the label of the action, // the label of the action
                        cssClass: 'cal-slide-content-title', // a CSS class that will be added to the action element so you can implement custom styling
                    },
                ];
            }
            var productIcon = {
                label:
                    "<i class='fa fa-fw fa-shopping-cart' style='color: #5cb85c; margin-right: 7px'></i>", // the label of the action, // the label of the action
                cssClass: 'cal-slide-content-title', // a CSS class that will be added to the action element so you can implement custom styling
            };
            var productIconGrey = {
                label:
                    "<i class='fa fa-fw fa-shopping-cart' style='color: lightgrey; margin-right: 7px'></i>", // the label of the action, // the label of the action
                cssClass: 'cal-slide-content-title', // a CSS class that will be added to the action element so you can implement custom styling
            };
            var carIcon = {
                label:
                    "<i class='fa fa-fw fa-car' style='color: #5cb85c;'></i>", // the label of the action, // the label of the action
                cssClass: 'cal-slide-content-title', // a CSS class that will be added to the action element so you can implement custom styling
            };
            var carIconGrey = {
                label:
                    "<i class='fa fa-fw fa-car' style='color: lightgrey;'></i>", // the label of the action, // the label of the action
                cssClass: 'cal-slide-content-title', // a CSS class that will be added to the action element so you can implement custom styling
            };
            var compIcon = {
                label:
                    "<i class='fa fa-fw fa-dollar' style='color: #5cb85c;'></i>", // the label of the action, // the label of the action
                cssClass: 'cal-slide-content-title', // a CSS class that will be added to the action element so you can implement custom styling
            };
            var compIconGrey = {
                label:
                    "<i class='fa fa-fw fa-dollar' style='color: lightgrey;'></i>", // the label of the action, // the label of the action
                cssClass: 'cal-slide-content-title', // a CSS class that will be added to the action element so you can implement custom styling
            };

            if (reportedTime.products.length > 0) {
                actions2.push(productIcon);
            } else {
                actions2.push(productIconGrey);
            }

            if (reportedTime.productsCar.length > 0) {
                actions2.push(carIcon);
            } else {
                actions2.push(carIconGrey);
            }

            if (reportedTime.productsComp.length > 0) {
                actions2.push(compIcon);
            } else {
                actions2.push(compIconGrey);
            }

            var cssClass2 = '';
            if (
                reportedTime.reportedTimes.length === 0 &&
                reportedTime.notes.length === 0 &&
                reportedTime.products.length === 0 &&
                reportedTime.productsCar.length === 0 &&
                reportedTime.absence.length === 0 &&
                reportedTime.productsComp.length === 0
            ) {
                cssClass2 = 'event-unchecked';
            } else {
                cssClass2 = 'event-checked';

                if ($scope.useAttests) {
                    if (
                        reportedTimesNeedsAttest ||
                        reportedProductsNeedsAttest ||
                        reportedAbsenceNeedsAttest ||
                        reportedTimesRejected ||
                        reportedProductsRejected ||
                        reportedAbsenceRejected
                    ) {
                        cssClass2 += ' event-checked-need-attest';
                        actions2.push({
                            label:
                                "<p style='color: orange; margin: 0;'>Väntar på attestering</p>",
                        });
                    } else {
                        cssClass2 += ' event-checked-attested';
                    }
                }
            }
            actions2.push(pencil);
            event = {
                title: 'Tidrapport', // The title of the event
                //Add the reported time for this date in the last to numbers. eg: Date(YYYY, mm, dd, hh, mm)
                startsAt: new Date(number), // A javascript date object for when the event starts
                //endsAt: new Date(2016, 9, 10, 23), // Optional - a javascript date object for when the event ends
                color: {
                    // can also be calendarConfig.colorTypes.warning for shortcuts to the deprecated event types
                    primary: 'transparent', // the primary event color (should be darker than secondary)
                    secondary: '#fdf1ba', // the secondary event color (should be lighter than primary)
                },
                actions: actions2,
                draggable: false, //Allow an event to be dragged and dropped
                resizable: false, //Allow an event to be resizable
                incrementsBadgeTotal: true, //If set to false then will not count towards the badge total amount on the month and year view
                recursOn: 'year', // If set the event will recur on the given period. Valid values are year or month
                cssClass: cssClass2, //A CSS class (or more, just separate with spaces) that will be added to the event when it is displayed on each view. Useful for marking an event as selected / active etc
                allDay: true, // set to true to display the event as an all day event on the day view
            };
            $scope.events.push(event);
        });
    };
    $scope.formatTotalTime = function (time) {
        if (
            !angular.isNumber(time) ||
            angular.isUndefined(time) ||
            time === null ||
            time === '0.00'
        ) {
            //console.log('ISSUE: ignored time: ' + time);
            return;
        } else {
            //console.log('ISSUE: Added time: ' + time);
        }
        var timeString = time.toString();
        var index = timeString.indexOf('.'); //  the first index where a dot occours
        if (index < 0) {
            //If even numbers, e.g. hours without minutes
            return time + ':00';
        } else {
            var hoursInText = timeString.substr(0, index); // Gets the first part
            var minutesInText = timeString.substr(index + 1); // Gets the second part
            var decimalTime = hoursInText + '.' + minutesInText;
            if (!decimalTime) {
                //If no time are reported
                return '';
            }
            var someString = decimalTime.toString(); //Convert decimalTime to String
            //Split string to hours and minutes
            index = someString.indexOf('.'); //  the first index where a dot occours
            hoursInText = someString.substr(0, index); // Gets the first part
            minutesInText = someString.substr(index + 1); // Gets the second part
            //Convert hours to integer.
            var integerHours = parseInt(hoursInText);
            //Convert decimalMinutes to minutes.
            var decimalMinutes = parseFloat(minutesInText / 100);
            var totalTime = (integerHours + decimalMinutes).toFixed(2);
            //Split string to hours and minutes
            var index2 = totalTime.indexOf('.'); //  the first index where a dot occours
            var hoursInText2 = totalTime.substr(0, index2); // Gets the first part
            var minutesInText2 = totalTime.substr(index2 + 1); // Gets the second part
            var decimalMinutes2 = parseFloat(
                ((minutesInText2 * 60) / 10).toFixed(2)
            );
            if (decimalMinutes2 > 100) {
                decimalMinutes2 = decimalMinutes2 / 10;
            }
            //If there are no minutes; add 00
            if (decimalMinutes2 === 0) {
                decimalMinutes2 = '00';
            }
            //add minutes to hours
            return hoursInText2 + ':' + decimalMinutes2;
        }
    };
}
]