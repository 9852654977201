import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postProjectElement = (
    backend: string,
    token: string,
    Description,
    ColorCode,
    EstimatedTimeInHours,
    FixedPrice,
    ProjectID,
    TaskID,
    ParentProjectElementID,
    CreatedByID
) => {
    return fetch(
        `${getApiUrl(backend)}project_elements`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Description,
                ColorCode,
                EstimatedTimeInHours,
                FixedPrice,
                ProjectID,
                TaskID,
                ParentProjectElementID,
                ChangedByID: CreatedByID,
                CreatedByID 
            })
        }
    );
};

export default postProjectElement;
