import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getSupplierInvoices = (
    token: string,
    backend: string,
    page: number,
    id?: number,
    search?,
    sortBy?,
    sortDirection?
) => {
    return fetch(
        `${getApiUrl(backend)}supplier_invoices/${id || ""}?${page ? "page=" + page : ""}${search ? "&search=" + search : ""}${sortBy ? "&sortby=" + sortBy : ""}${sortDirection ? "&sortdirection=" + sortDirection : ""}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                Accept: 'application/json, text/plain, */*'
            },
        }
    );
};

export default getSupplierInvoices;
