import moment from 'moment';
import angular from 'angular';

export default ["$scope",
    "$http",
    "$rootScope", 
    function MyBookingsController(
        $scope,
        $http,
        $rootScope
    ) {
    const user = $scope.$parent.user;
    const token = $scope.$parent.token;
    const apiUrl = $scope.$parent.apiUrl;

    $scope.currentCalendarEmployeeID = user.EmployeeID;

    const $ = window.jQuery;
    $rootScope.currentUser = user;

    $scope.currentDayBookings = [];
    $scope.selectedBooking = undefined;

    $scope.currentDay = moment();
    $scope.currentDayLabel = 'Idag';

    $scope.loadingMyBookings = true;

    $scope.openingProject = false;

    function sameDay(d1, d2) {
        return moment(d1).isSame(d2, 'day');
    }

    function formatDate(date) {
        return moment(date).format('YYYY-MM-DD');
    }

    $scope.generateLabelsFromCurrentDay = function () {
        const today = moment();

        if (sameDay($scope.currentDay, today)) {
            $scope.currentDayLabel = 'Idag';
            return;
        }

        $scope.currentDayLabel = formatDate($scope.currentDay);
    };

    $scope.prevDay = function () {
        $scope.currentDay.subtract(1, 'day');
        $scope.assignCurrentDayBookings();
        $scope.generateLabelsFromCurrentDay();
    };

    $scope.nextDay = function () {
        $scope.currentDay.add(1, 'day');
        $scope.assignCurrentDayBookings();
        $scope.generateLabelsFromCurrentDay();
    };

    $scope.goToSelectedProject = function () {
        function initReportedTimes() {
            const promises = [];

            promises.push(
                $http
                    .get(
                        apiUrl +
                            'reportedtimes/employee/' +
                            $scope.currentCalendarEmployeeID +
                            '/date/' +
                            moment($scope.currentDay).format('YYYY-MM-DD'),
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    ) // Reported Assignments
                    .success(function (response) {
                        const array = response.reported_time;
                        const newArray = [];
                        angular.forEach(array, function (reportedTime) {
                            var timeFields = reportedTime.TimeInHours.split(
                                '.'
                            );
                            var hours = parseInt(timeFields[0]);
                            var minutes = (parseInt(timeFields[1]) / 100) * 60;
                            reportedTime.hours = hours;
                            reportedTime.minutes = minutes;
                            reportedTime.dbHours = hours;
                            reportedTime.dbMinutes = minutes;
                            newArray.push(reportedTime);
                        });
                        window.localStorage.setItem(
                            'reportedAssignments',
                            angular.toJson(newArray)
                        );
                    })
            );

            promises.push(
                $http
                    .get(
                        apiUrl +
                            'notes/employee/' +
                            $scope.currentCalendarEmployeeID +
                            '/date/' +
                            moment($scope.currentDay).format('YYYY-MM-DD'),
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .success(function (response) {
                        const array = response.notes;
                        var tempArray = [];
                        angular.forEach(array, function (note) {
                            var tempNote = {
                                id: note.id,
                                customerName: note.CustomerName,
                                customerID: note.CustomerID,
                                customerObjectName: note.CustomerObjectName,
                                projectName: note.ProjectName,
                                isDeleted: false,
                                Title: note.Title,
                                Text: note.Text,
                                ProjectID: note.ProjectID,
                                CreatedByID: note.CreatedByID,
                                ChangedByID: note.ChangedByID,
                            };
                            tempArray.push(tempNote);
                        });

                        window.localStorage.setItem(
                            'notes',
                            angular.toJson(tempArray)
                        );
                    })
            );

            promises.push(
                $http
                    .get(
                        apiUrl +
                            'reported_products/employee/' +
                            $scope.currentCalendarEmployeeID +
                            '/date/' +
                            moment($scope.currentDay).format('YYYY-MM-DD'),
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .success(function (response) {
                        $scope.alreadyReportedProducts =
                            response.reported_products;
                        //console.log("$scope.alreadyReportedProducts");
                        //console.log($scope.alreadyReportedProducts);
                        angular.forEach(
                            $scope.alreadyReportedProducts,
                            function (product_assignment) {
                                $http
                                    .get(
                                        apiUrl +
                                            'product_type/product/' +
                                            product_assignment.product_id,
                                        {
                                            headers: {
                                                Authorization: `Bearer ${token}`,
                                            },
                                        }
                                    )
                                    .success(function (response) {
                                        if (response.product_type !== null) {
                                            product_assignment.product_type_id =
                                                response.product_type;
                                        }
                                    });
                            }
                        );
                    })
                    .then(function () {
                        if (!$scope.alreadyReportedProducts) {
                            return;
                        }
                        // Pull from cookie if it exists
                        // var tempCookie = $cookies.getObject('reportedAssignments');
                        var tempCookie = angular.fromJson(
                            window.localStorage.getItem('reportedProducts')
                        );
                        var tempArray = [];
                        angular.forEach(
                            $scope.alreadyReportedProducts,
                            function (reportedProduct) {
                                var quantityFields = reportedProduct.quantity.split(
                                    '.'
                                );
                                var quantity = parseInt(quantityFields[0]);
                                var quantity_decimals = parseInt(
                                    quantityFields[1]
                                );
                                var wasDuplicatesAdded = false;
                                if (tempArray.length > 0) {
                                    angular.forEach(tempArray, function (
                                        tempReportedProduct
                                    ) {
                                        if (
                                            tempReportedProduct.product_assignment_id ===
                                            reportedProduct.product_assignment_id
                                        ) {
                                            tempReportedProduct.quantity += quantity;
                                            tempReportedProduct.quantity_decimals += quantity_decimals;
                                            tempReportedProduct.duplicates = [];
                                            tempReportedProduct.duplicates.push(
                                                reportedProduct.reported_product_id
                                            );
                                            wasDuplicatesAdded = true;
                                        }
                                    });
                                }
                                if (wasDuplicatesAdded) {
                                    return;
                                }
                                var tempProduct = reportedProduct;
                                tempProduct.quantity = quantity;
                                tempProduct.quantity_decimals = quantity_decimals;
                                tempProduct.customer_name =
                                    tempProduct.customer_first_name +
                                    ' ' +
                                    tempProduct.customer_last_name;
                                if (
                                    reportedProduct.product_type_id !== null &&
                                    reportedProduct.product_type_id !==
                                        undefined
                                ) {
                                    tempProduct.product_type_id =
                                        reportedProduct.product_type_id;
                                }
                                tempArray.push(reportedProduct);
                                // Todo: So far: all good!
                            }
                        );

                        if (tempCookie) {
                            window.localStorage.removeItem('reportedProducts');
                        }
                        if (tempArray.length > 0) {
                            window.localStorage.setItem(
                                'reportedProducts',
                                angular.toJson(tempArray)
                            );
                        }
                    })
            );

            Promise.all(promises).then(() => {
                $('#bookingDetailsModal').modal('hide');
                const selectedBooking = $scope.selectedBooking;
                const date = moment($scope.currentDay).format('YYYY-MM-DD');
                $scope.$parent.history.push(
                    '/timereport/' +
                        date +
                        '/' +
                        selectedBooking.ProjectID +
                        '/' +
                        selectedBooking.CustomerID +
                        '/' +
                        selectedBooking.CustomerName +
                        '/false/true'
                );
            });
        }

        initReportedTimes();
    };

    $scope.employeeFilter = function (value) {
        return !!value.EmployeeID;
    };

    $scope.vehicleFilter = function (value) {
        return !!value.ProductID;
    };

    $scope.closeBookingDetailsModal = function () {
        $('#bookingDetailsModal').modal('hide');
    };

    $scope.openBookingDetails = function (booking) {
        $scope.selectedBooking = booking;

        if (booking.project && booking.project.project_role_assignments) {
            const projectLeader = booking.project.project_role_assignments.find(
                (r) => r.Type === 'lead'
            );

            if (projectLeader) {
                const employee = projectLeader.employee;
                $scope.selectedBooking.ProjectLeader =
                    employee.FirstName + ' ' + employee.LastName;
            }
        }

        $('#bookingDetailsModal').modal('show');
    };

    $scope.getBookingStartEndTime = function (booking) {
        if (!booking) {
            return '';
        }

        if (
            !moment(booking.StartTime).isSame($scope.currentDay, 'day') &&
            !moment(booking.EndTime).isSame($scope.currentDay, 'day')
        ) {
            return 'Löpande bokning';
        }

        return (
            (moment(booking.StartTime).isSame($scope.currentDay, 'day')
                ? booking.StartTime.slice(11, 16)
                : 'Löpande') +
            ' - ' +
            (moment(booking.EndTime).isSame($scope.currentDay, 'day')
                ? booking.EndTime.slice(11, 16)
                : 'Löpande')
        );
    };

    $scope.assignCurrentDayBookings = function () {
        if (Array.isArray($scope.myBookings)) {
            $scope.currentDayBookings = $scope.myBookings
                .filter((booking) => {
                    const today = $scope.currentDay;
                    const bookingStartTime = moment(booking.StartTime).startOf(
                        'day'
                    );
                    const bookingEndTime = moment(booking.EndTime).endOf('day');
                    return (
                        bookingStartTime.isBefore(today) &&
                        today.isBefore(bookingEndTime)
                    );
                })
                .sort((a, b) => {
                    const aStartTime = moment(a.StartTime);
                    const bStartTime = moment(b.StartTime);
                    if (bStartTime.isBefore(aStartTime)) {
                        return 1;
                    } else if (aStartTime.isBefore(bStartTime)) {
                        return -1;
                    }

                    return 0;
                });
        }
    };

    $scope.getMyBookings = function () {
        $scope.currentDayBookings = [];
        $scope.myBookings = [];
        $scope.loadingMyBookings = true;

        $http
            .get(
                apiUrl +
                    'bookings?employee=' +
                    $rootScope.currentUser.EmployeeID,
                {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                }
            )
            .success((res) => {
                const bookings = res;

                $scope.myBookings = bookings.map((booking) => ({
                    ...booking,
                    resources: booking.booking_resources,
                }));

                $scope.assignCurrentDayBookings();
                $scope.loadingMyBookings = false;
            });
    };

    $scope.getMyBookings();
}
]