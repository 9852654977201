import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getBookings = (token: string, backend: string, StartTime, EndTime) => {
    return fetch(`${getApiUrl(backend)}bookings/${StartTime}/${EndTime}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getBookings