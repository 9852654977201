import fetch from "../utils/mgFetch";

const getGadgetQuotationSendingUrl = (dbName, offerId) => {

    const baseUrl = process.env.REACT_APP_GADGET_BASE_URL;
    const token = process.env.REACT_APP_GADGET_SECRET_KEY;

    return fetch(`${baseUrl}/api/quotation-sending-url/${dbName}/${offerId}`, {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        }
    });
}

export default getGadgetQuotationSendingUrl
