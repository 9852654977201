import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getDoesEmployeesEmailExist = (token: string, backend: string, email: string) => {
    return fetch(`${getApiUrl(backend)}employees/does_email_exist/${email}`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    }) 
}

export default getDoesEmployeesEmailExist