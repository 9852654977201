import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";


const postAbsenceType = (
    token: string,
    backend: string,
    absence_code: string,
    active: string,
    created_by_id: string,
    changed_by_id: string,
    deleted_at: null,
    description: string,
    title: string
) => {

    return fetch(
        `${getApiUrl(backend)}absence`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                absence_code,
                active,
                created_by_id,
                changed_by_id,
                deleted_at,
                description,
                title
            })
        }
    );
};

export default postAbsenceType;