import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postEmployee = (
    token: string,
    backend: string,
    FirstName: string,
    LastName: string,
    Email: string,
    EmployeeNumber?: boolean,
    PhoneNumber?: any,
    Role?: string,

    
) => {
    return fetch(
        `${getApiUrl(backend)}employees`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                Email,
                EmployeeNumber: EmployeeNumber || null,
                FirstName,
                LastName,
                PhoneNumber: PhoneNumber || null,

            })
        }
    );
};

export default postEmployee;
