import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getProductCounts = (
    token: string,
    backend: string,
    search: string,
    // customerId?: number
) => {
    // `${getApiUrl(backend)}project_counts/?search=${search}${customerId ? '&customer=' + customerId : ''}`,
    return fetch(
        `${getApiUrl(backend)}product_counts/?search=${search}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getProductCounts
