import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getEmployeeBookings = (backend: string, token: string, from: string, to:string, employeeId: number) => {
    return fetch(`${getApiUrl(backend)}bookings/${from}/${to}?employee=` + employeeId, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export default getEmployeeBookings;
