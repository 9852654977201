import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putUsersAllowedToEditReports = (
    token: string,
    backend: string,
    ChangedByID: string,
    UsersAllowedToEditReports: string, 
) => {

    let formData = new FormData();
    formData.append('ChangedByID', ChangedByID);
    formData.append('UsersAllowedToEditReports', UsersAllowedToEditReports);

    return fetch(
        `${getApiUrl(backend)}settings/update_users_allowed_to_edit_reports`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData
        }
    );
};

export default putUsersAllowedToEditReports