import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postFortnoxArticleUnitGroups = (
    backend: string,
    token: string,
    UnitProductKinds,
    created_by_id: number
) => {
    return fetch(
        `${getApiUrl(backend)}store_fortnox_article_unit_groups`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                UnitProductKinds,
                created_by_id
            })
        }
    );
};

export default postFortnoxArticleUnitGroups;
