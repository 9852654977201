

const downloadFile = (url, fileName, isMobileScreen = false) => {

  fetch(url).then(resp => resp.blob()).then(blob => {

      let blobUrl = window.URL.createObjectURL(blob);

      let a = document.createElement('a');
      a.download = fileName || url.replace(/^.*[\\\/]/, '');
      a.href = !isMobileScreen ? blobUrl : url;
      document.body.appendChild(a);
      a.click();
      a.remove();
  }) 
};

  export default downloadFile