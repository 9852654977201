import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getUnhandledSalaryDetailsCount = (
    token: string,
    backend: string,
) => {
    return fetch(
        `${getApiUrl(backend)}unhandled_salary_details_count`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getUnhandledSalaryDetailsCount;