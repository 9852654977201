import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getProjects = (
    backend: string,
    token: string,
    filter: string,
    search: string,
    page: number,
    from?: string,
    to?: string,
    orderBy?: string,
    customerId?: string,
    projectLeader?: string,
    onlyCountWithApproved?: boolean,
) => {
    return fetch(
        `${getApiUrl(backend)}projects?filter=${filter}&search=${search || ""}&from=${from || ""}&to=${to || ""}&page=${page}&orderBy=${orderBy || ""}${customerId ? '&customerId=' + customerId : ""}${projectLeader ? '&projectLeader=' + projectLeader : ""}${onlyCountWithApproved ? '&onlyApproved=' + true : ""}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getProjects;
