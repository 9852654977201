import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const putCustomer = (
    backend: string,
    token: string,
    id,
    type,
    name,
    organisation_number,
    middle_name,
    last_name, 
    e_mail,
    phone_number,
    adress_line1,
    adress_line2,
    adress_line3,
    adress_line4,
    postal_code,
    city,
    country,
    contact_person,
    erp_customer_id,
    fortnox_customer_number,
    created_by_id,
    price_list_id,
    lat,
    long,
) => {
    return fetch(
        `${getApiUrl(backend)}customers/${id}`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                type,
                name,
                organisation_number,
                middle_name,
                last_name, 
                e_mail,
                phone_number,
                adress_line1,
                adress_line2,
                adress_line3,
                adress_line4,
                postal_code,
                city,
                country,
                contact_person,
                erp_customer_id,
                fortnox_customer_number,
                created_by_id,
                changed_by_id: created_by_id,
                price_list_id,
                lat,
                long,
            })
        }
    );
};

export default putCustomer;
