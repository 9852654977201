import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getUnitByName = (
    backend: string,
    token: string,
    unitName: number,
) => {
    return fetch(
        `${getApiUrl(backend)}unit/by_name/${unitName}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getUnitByName;