import React from "react"
import Card from "../components/Card"
import DocumentPreview from "./DocumentPreview"
import EditDocumentButton from "./EditDocumentButton"
import {useSelector} from "react-redux"
import { useTranslation } from "react-i18next"
import { Button } from "react-bootstrap"
import downloadFile from "../utils/downloadFile"
import useCheckMobileScreen from "../utils/useCheckMobileScreen";

const DocumentCard = ({showAccessLevels = true, customTitle = "", viewButton = false, disabled = false, document, onChange = () => {}}) => {
    const user = useSelector(store => store.app.user)
    const [t] = useTranslation()
    const isMobileScreen = useCheckMobileScreen();

    const handleOpenDocument = (e) => {
        e.stopPropagation()
        const event = new CustomEvent("customViewDocumentEvent", {detail: document});
        window.document.dispatchEvent(event)
    }

    return <Card
        key={document.id} 
        style={{display: "flex"}}
    >
        <DocumentPreview document={document} onClick={handleOpenDocument} style={{marginRight: 8}} />
        <div style={{flex: 1, color: "initial"}}>
            <div style={{display: "flex"}}>
                <div style={{flex: 1, marginRight: "0.5em", cursor: "pointer"}} onClick={handleOpenDocument}>
                    {customTitle && <div>
                        <i>{customTitle}</i>
                    </div>}
                    <div>
                        <b>{document.Title}</b>
                    </div>
                    {document.CategoryID && <div>
                        <i style={{color: "initial"}}>{t("Category")}: {document.CategoryName}</i>
                    </div>}
                    <div style={{whiteSpace: "pre-wrap"}}>
                        {document.Text}
                    </div>
                </div>
                <div>
                    <Button style={{ padding: "0px 5px", width: "24px", marginRight: "8px" }} onClick={() => downloadFile(document.url, document.Title, isMobileScreen)}><i style={{ fontSize: "11px", color: "#333" }} className="fa fa-download"/></Button>
                    {viewButton && <Button onClick={handleOpenDocument}><i className="fa fa-search"/></Button>}
                    {!disabled && (document.EditAccessLevel === 0 || document.CreatedByID === user.EmployeeID) && <EditDocumentButton showAccessLevels={showAccessLevels} disabled={disabled} document={document} onEditDocument={onChange}/>}
                </div>
            </div>
            {(document.created_by) && <div style={{color: "#78858e", display: "flex", justifyContent: "space-between", marginTop: "1em"}}>
                <span><i className="fa fa-upload"/> {document.created_at}</span>
                <span><i className="fa fa-user"/> {document.created_by.FirstName} {document.created_by.MiddleName} {document.created_by.LastName}</span>
            </div>}
        </div>
    </Card>
}

export default DocumentCard