import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getProductAssignmentStats = (
    backend: string,
    token: string,
    productAssignmentId: number
) => {
    return fetch(
        `${getApiUrl(backend)}product_assignment_stats/${productAssignmentId}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getProductAssignmentStats;
