import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ShowModalPayload {
    title: string,
    text: string,
    variant?: 'error' | 'success'
}

const modalSlice = createSlice({
    name: 'modal',
    initialState: { visible: false, title: '', text: '', variant: 'error' },
    reducers: {
        showModal(state, action: PayloadAction<ShowModalPayload>) {
            const { title, text, variant } = action.payload;
            state.visible = true;
            state.title = title;
            state.text = text;
            state.variant = variant ?? state.variant;
        },
        hideModal(state) {
            state.visible = false;
            state.title = '';
            state.text = '';
            state.variant = 'error';
        },
    },
});

export const { showModal, hideModal } = modalSlice.actions;
export default modalSlice.reducer;
