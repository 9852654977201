import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getAllTasks = (
    token: string,
    backend: string,

) => {
    return fetch(
        `${getApiUrl(backend)}getalltasks`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getAllTasks;
