import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postPipedriveLead = (
    company: string,
    email: string,
) => {
    return fetch(
        `${getApiUrl()}pipedrive-integration-create`,
        {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                company,
                email,
            })
        }
    );
};

export default postPipedriveLead;
