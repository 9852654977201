import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postInvoice = (
    backend: string,
    token: string,
    BreakOnDate,
    ShowTimeType,
    ShowPriceOnSpecification,
    ShowComment,
    CustomerID,
    CustomerObjectID,
    ProjectID,
    InvoiceStartDate,
    InvoiceEndDate,
    ReportedTimes,
    ReportedProducts,
    Notes,
    SupplierInvoiceProjectRelations,
    ProjectVariations,
    PaymentPlanElements,
    invoiceLines,
    CreatedByID: number,
) => {

    return fetch(
        `${getApiUrl(backend)}invoices`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                BreakOnDate,
                ShowTimeType,
                ShowPriceOnSpecification,
                ShowComment: ShowComment ? "true" : "false",
                CustomerID,
                CustomerObjectID,
                ProjectID,
                InvoiceStartDate: InvoiceStartDate ? InvoiceStartDate : null,
                InvoiceEndDate: InvoiceEndDate ? InvoiceEndDate : null,
                ReportedTimes,
                ReportedProducts,
                Notes,
                SupplierInvoiceProjectRelations,
                ProjectVariations,
                PaymentPlanElements,
                invoiceLines,
                CreatedByID,
                ChangedByID: CreatedByID
            })
        }
    );
};

export default postInvoice;
