
import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const postCopyOfferToTemplate = (backend: string, token: string, id, TemplateDescription,  CreatedByID) => {
    return fetch(`${getApiUrl(backend)}copy_offer_to_template/${id}`, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            TemplateDescription,
            CreatedByID
        })
    })
}

export default postCopyOfferToTemplate