import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";


const postArticle = (
    token: string,
    backend: string,
    created_by_id: string,
    changed_by_id: string,
    name: string,
    description: string,
    product_kind: string,
    unit_id: string,
    price: string,
    cost_price: string,
    on_salary: string,
    salary_code: string,
    on_salary_amount: string,
    product_type_id: string,
    check_box = false
) => {

    return fetch(
        `${getApiUrl(backend)}products`,
        {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                product_kind,
                name,
                description,
                unit_id,
                price,
                cost_price,
                on_salary,
                salary_code,
                on_salary_amount,
                created_by_id,
                changed_by_id,
                product_type_id,
                check_box      
            })
        }
    );
};

export default postArticle
