import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getProductStats = (
    backend: string,
    token: string,
    id: number,
    fromDate: string,
    toDate: string,

) => {
    return fetch(
        `${getApiUrl(backend)}product_stats/${id}?from=${fromDate}&to=${toDate}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );
};

export default getProductStats;
