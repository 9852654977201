import getApiUrl from "../utils/apiUrl";
import fetch from "../utils/mgFetch";

const getTenantMigrationRedirectUrl = (token, backend) => {

    return fetch(`${getApiUrl(backend)}tenant-migration-redirect-url`, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${token}`
        }
    });
}

export default getTenantMigrationRedirectUrl