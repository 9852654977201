import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import setMomentLocale from "./utils/setMomentLocale";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  sv: {
    translation: {
      "TotalTimeMonth": "Total tid (månad):",
      "TotalAbsenceMonth": "Total frånvaro (månad):",
      "TotalTimeWeek": "Total tid (vecka):",
      "TotalAbsenceWeek": "Total frånvaro (vecka):",
      "Reported": "Inrapporterat:",
      "Absence": "Frånvaro",
      "AbsenceCode": "Frånvarokod",
      "Booking": "Bokning:",
      "ProjectLeader": "Projektledare:",
      "OpenProject": "Öppna projekt",
      "ExportInvoiceDetailsOption": "Välj alternativ för export av fakturaunderlag",
      "ProjectNameAsYourReference": "Projektnamn som Er referens",
      "ProjectNameAsYourOrdernumber": "Projektnamn som Ert ordernummer",
      "ProjectNameDescriptionAndInternalInformationAsDeliveryDetails": "Projektnamn, beskrivning och intern information som Leveransuppgifter",
      "None2": "Inget",
      "Close": "Stäng",
      "RunningBooking": "Löpande bokning",
      "Running": "Löpande",
      "Cancel": "Avbryt",
      "Save": "Spara",
      "Delete": "Radera",
      "RecentProjects": "Senaste använda projekt",
      "OtherProjects": "Övriga projekt",
      "SentReports": "Inskickade rapporter",
      "Time": "Tid:",
      "Articles": "Artiklar:",
      "Hours": "Timmar",
      "PiecesShort": "st",
      "SearchProjectCustomer": "Sök efter projekt / kund",
      "Tasks": "Arbetsuppgifter",
      "AddTask": "Lägg till arbetsuppgift",
      "NoReportedTime": "Ingen rapporterad tid",
      "ReportTime": "Rapportera tid",
      "Material": "Material",
      "AddMaterial": "Lägg till material",
      "ReportMaterial": "Rapportera",
      "Vehicles": "Fordon",
      "AddVehicle": "Lägg till fordon",
      "ReportVehicle": "Rapportera",
      "Comp": "Ersättning",
      "AddComp": "Lägg till ersättning",
      "ReportComp": "Rapportera",
      "NoReported": "Inget rapporterat",
      "Search": "Sök",
      "Add": "Lägg till",
      "SearchFreetext": "Sök / Fritext",
      "FreetextDescription": "Fritextbeskrivning",
      "AddFreetext": "Lägg till fritext",
      "AddTaskToProject": "Lägg till arbetsuppgift på projekt",
      "AddMaterialToProject": "Lägg till material på projekt",
      "AddVehicleToProject": "Lägg till fordon på projekt",
      "AddCompToProject": "Lägg till ersättning på projekt",
      "Fetching": "Hämtar..",
      "FetchMore": "Hämta fler",
      "FetchingMore": "Hämtar fler..",
      "NoResults": "Inga resultat",
      "ThereAreMoreResultsPleaseRefineSearchOr": "Det finns fler resultat. Vänligen förfina din sökning eller",
      "ProjectNoticeboard": "Projektets anslagstavla",
      "EditTimeReport": "Redigera tidsrapport",
      "EditMaterialReport": "Redigera materialrapport",
      "EditVehicleReport": "Redigera fordonsrapport",
      "EditCompReport": "Redigera ersättningsrapport",
      "LastSaveAt": "Senast sparad ",
      "Return": "Retur",
      "ReportedOnProject": "Inrapporterat på projektet: ",
      "HoursShort": "Tim",
      "hoursShort": "tim",
      "PickCustomer": "Välj kund",
      "MinutesShort": "Min",
      "MarkWithTask": "Markera med arbetsuppgift",
      "AreYouSureYouWantToDeleteTheReport": "Är du säker på att du vill radera rapporten? Denna handling går inte att ångra",
      "AreYouSureYouWantToDeleteTheReports": "Är du säker på att du vill radera rapporterna? Denna handling går inte att ångra",
      "Document": "Dokument",
      "MarkProjectAsDone": "Klarmarkera projekt",
      "Notes": "Anteckningar",
      "Comment": "Kommentar",
      "FetchingProject": "Hämtar projekt..",
      "AddDocument": "Lägg till dokument",
      "AddNote": "Lägg till anteckning",
      "Title": "Titel",
      "OfferTemplate": "Offertmall",
      "PickOfferTemplate": "Välj offertmall",
      "Text": "Text",
      "Category": "Kategori",
      "Access": "Behörighet",
      "CanRead": "Kan se",
      "CanEdit": "Kan redigera",
      "All": "Alla",
      "Admin": "Admin",
      "DropFilesHereOrClickToPickFile": "Släpp fil här eller tryck för att välja fil",
      "AreYouSureDeleteMessage": "Är du säker? Denna handling går inte att ångra",
      "EditDocument": "Redigera dokument",
      "EditNote": "Redigera anteckning",
      "EditSupplierInvoice": "Redigera leverantörsfaktura",
      "ToBig": "För stor",
      "AcceptetFileTypes": "Video, bild, .pdf eller .docx  (Max 20MB)",
      "PickHere": "Välj här",
      "Today": "Idag",
      "AddDocument": "Lägg till dokument",
      "AddNote": "Lägg till anteckning",
      "AddToNoticeboardNoticeMessage": "Anteckningen kommer läggas till i projektets anslagstavla och vara synlig för alla användare i er organisation.",
      "AddToNoticeboardCheckboxMessage": "Lägg till i projektets anslagstavla? (Anteckningen kommer vara synlig för alla användare i er organisation).",
      "VisibleInNoticeboardMessage": "Synlig i anslagstavlan",
      "ShowDoneProjects": "Visa klarmarkerade projekt",
      "MarkedAsDone": "Klarmarkerat",
      "by": "av",
      "Register": "Registrera",
      "CopyYesterdays": "Kopiera gårdagens",
      "report": "rapport",
      "absence": "frånvaro",
      "AllProjects": "Alla projekt",
      "ReportAbsence": "Rapportera frånvaro",
      "EditAbsence": "Redigera frånvarorapport",
      "CopyTimes": "Kopiera tider",
      "CopyProducts": "Kopiera material",
      "CopyCars": "Kopiera fordon",
      "CopyComps": "Kopiera ersättning",
      "CopyAbsences": "Kopiera frånvaro",
      "CopyNotes": "Kopiera anteckningar",
      "CopyReports": "Kopiera rapporter",
      "MarkAll": "Markera alla",
      "Review": "Granska",
      "dayreport": "dagsrapport",
      "CantEditReportMessage": "Rapporten har hanterats av admin och kan därför inte redigeras",
      "NotAuthorizedToEditDate": "Du har inte behörighet att redigera rapporter på det här datumet. Var vänlig kontakta din administratör för att göra ändringar.",
      "TaskAlreadyExistsOnProjectMessage": "Arbetsuppgiften är redan tillagd på projektet.",
      "ProductAlreadyExistsOnProjectMessage": "Artikeln är redan tillagt på projektet.",
      "LoggedInAs": "Inloggad som:",
      "Admin": "Administratör",
      "User": "Användare",
      "CreateCustomer": "Skapa kund",
      "CreateProject": "Skapa projekt",
      "ActiveProjects": "Aktiva projekt",
      "Attesting": "Attestering",
      "Forms": "Formulär",
      "Menu": "Meny",
      "ToDo": "Att göra",
      "Home": "Hem",
      "Company": "Företag",
      "Profile": "Profil",
      "Done": "Klar",
      "AbsenceTypes": "Frånvarotyper",
      "AbsenceType": "Frånvarotyp",
      "reportsToApprove": "rapporter att godkänna",
      "demandsCustomerSignature": "kräver kundsignatur",
      "unsignedProjectForms": "osignerade projektformulär",
      "Show": "Visa",
      "UnderConstruction": "Under konstruktion",
      "CompanyViewVision": "Vår vision med företagsvyn är att ni ska få all den information ni behöver för att utföra ert jobb på ett enkelt och smidigt sätt, direkt i MyGizmo!",
      "WhatDoYouWantToSeeInThisView": "Vilken information/funktioner hade du velat se i företagsvyn?",
      "SendYourRequest": "Skicka in dina önskemål",
      "here": "här",
      "WhatDoThinkAboutOurNewDesign": "Vad tycker du om vår nya design?",
      "LeaveFeedback": "Lämna feedback",
      "Products": "Artikelregister",
      "YourCustomer": "Din kund",
      "CreateNew": "Skapa ny",
      "ProjectDetails": "Projektdetaljer",
      "Name": "Namn",
      "NameClarification": "Namnförtydligande",
      "Description": "Beskrivning",
      "InternalInformation": "Intern information",
      "Adress": "Adress",
      "UseCustomerAdress": "Använd kundens huvudaress",
      "CUSTOMERMAINADRESS": "KUNDENS HUVUDADRESS",
      "UseExistingCustomerObject": "Använd befintligt kundobjekt",
      "ThereAreNoOtherBookingsInTheInterval": "Det finns inga andra bokningar i intervallet",
      "PickCustomerObject": "Välj kundobjekt",
      "ZipCode": "Postkod",
      "City": "Stad",
      "Country": "Land",
      "Duration": "Löptid",
      "StartDate": "Startdatum",
      "EndDate": "Slutdatum",
      "Size": "Omfattning",
      "ProjectType": "Projekttyp",
      "Running": "Löpande",
      "ShowOtherBookingsInTheInterval": "Visa andra bokningar i intervallet",
      "HideOtherBookingsInTheInterval": "Dölj andra bokningar i intervallet",
      "FixedPrice": "Fastpris",
      "EstimatedManHours": "Estimerat antal mantimmar",
      "Articles2": "Artiklar",
      "ProjectRoles": "Projektroller",
      "TheProjectElementHasBeenUpdated": "Delmomentet har uppdaterats",
      "TheProjectElementHasBeenCreated": "Delmomentet har skapats",
      "TheProjectElementHasBeenDeleted": "Delmomentet har raderats",
      "PickEmployee": "Välj anställd",
      "CreateMoreProjects": "Skapa fler projekt",
      "GoHome": "Gå hem",
      "GoToProject": "Gå till projektet",
      "Optional": "Frivilligt",
      "SearchCustomer": "Sök kund",
      "indicatesRequiredField": "indikerar obligatoriskt fält",
      "PrivateCustomer": "Privatkund",
      "CompanyCustomer": "Företagskund",
      "FirstName": "Förnamn",
      "SocialSecurityNumber": "Personnummer",
      "MiddleName": "Mellannamn",
      "LastName": "Efternamn",
      "OrgNumber": "Org.nummer",
      "ContactPerson": "Kontaktperson",
      "Email": "Email",
      "PhoneNumber": "Telefonnummer",
      "EnterAdress": "Fyll i adress",
      "ProjectCreated": "Projektet har skapats",
      "HowToYouWantToProceed": "Hur vill du fortsätta?",
      "EditBooking": "Redigera bokning",
      "AddBooking": "Lägg till bokning",
      "ProjectElement": "Delmoment",
      "PickProjectElement": "Välj delmoment",
      "StartTime": "Starttid",
      "EndTime": "Sluttid",
      "EmployeeCount": "Kräver antal anställda",
      "Employees": "Anställda",
      "PickVehicle": "Välj fordon",
      "NothingToDo": "Inget att göra",
      "OpenAdminView": "Öppna gamla adminwebben",
      "NoteAdminSiteNotMobileAdapted": "OBS: Adminwebben är ej mobilanpassad",
      "Customer": "Kund",
      "Project": "Projekt",
      "MissingRequiredFields": "Var vänlig och fyll i alla obligatoriska fält",
      "HasValidInput": "Alla obligatoriska fält är ifyllda!",
      "HasExternalProjectLeader": "Har extern projektledare",
      "EmplyoyeeCanApproveWithExternalProjectLeaderSignature": "Anställda kan godkänna rapporter med extern projektledares signatur",
      "ExternalProjectLeader": "Extern projektledare",
      "PropertyCode": "Fastighetsbeteckning",
      "PropertyCodeShort": "Fastighetsbet.",
      "demandsExternalProjectLeaderSignature": "kräver signatur från extern projektledare",
      "DemandCustomerSignatureOnAttestByInternalProjectLeader": "Kräv kundsignatur vid attest av projektledare",
      "Added": "Tillagd",
      "LatestCreatedProjectFirst": "Senast skapade projekt först",
      "LatestCreatedProjectLast": "Senast skapade projekt sist",
      "ProjectNameAsc": "Projektnamn A till Ö",
      "ProjectNameDesc": "Projektnamn Ö till A",
      "LatestCreatedCustomerFirst": "Senast skapade kund först",
      "LatestCreatedCustomerLast": "Senast skapade kund sist",
      "CustomerNameAsc": "Kundnamn A till Ö",
      "CustomerNameDesc": "Kundnamn Ö till A",
      "ListOptions": "Listalternativ",
      "ShowMyRecentProjects": "Visa mina senast använda projekt",
      "SortProjectsBy": "Sortera projekt på",
      "AddFormTemplate": "Hämta formulärmall",
      "PickFreetextUnit": "Välj fritextenhet",
      "ToProject": "Till projekt",
      "FormTemplate": "Formulärmall",
      "PickTemplate": "Välj formulärmall",
      "AddFormTemplateToProject": "Hämta formulärmall till projekt",
      "ProjectStats": "Projektstatistik",
      "EstimatedHours": "Estimerat antal timmar",
      "TotalReportedHours": "Antal rapporterade timmar",
      "NumberOfTimeReports": "Antal tidsrapporter",
      "NumberOfProductReports": "Antal produktrapporter",
      "ReloadApplication": "Ladda om applikation",
      "CreateNewProduct": "Skapa ny produkt",
      "ProductName": "Produktnamn",
      "ProductDescription": "Produktbeskrivning",
      "ThisVersionIsLockedDueToHavingAnActiveLink": "Versionen är låst på grund av att den har en aktiv länk",
      "PickUnit": "Välj enhet",
      "CreateProduct": "Skapa produkt",
      "CreateNewTask": "Skapa ny arbetsuppgift",
      "Select": "Välj",
      "CreateTask": "Skapa arbetsuppgift",
      "CreateAbsenceType": "Skapa ny frånvarotyp",
      "DailyReportSummary": "Dagsrapportsummering",
      "NothingReportedMessage": "Det finns inget inrapporterat på det här datumet",
      "Calendar": "Kalender",
      "Startpage": "Startsida",
      "Reports": "Rapporter",
      "InvoiceDetails": "Fakturaunderlag",
      "SalaryDetails": "Löneunderlag",
      "Customers": "Kunder",
      "Projects": "Projekt",
      "ResourcePlanner": "Planering",
      "Suppliers": "Inköp",
      "AbsenceTypes": "Frånvarotyper",
      "Settings": "Inställningar",
      "Integration": "Integration",
      "FortnoxNumberShort": "Fortnox Nr.",
      "Active+": "Aktiva",
      "MarkedAsDone+": "Klarmarkerade",
      "Inactive+": "Inaktiva",
      "Result": "Resultat",
      "SearchProject": "Sök projekt",
      "SearchAbsence": "Sök frånvaro",
      "AddAbsence": "Lägg till frånvaro",
      "SyncProjectsWithFortnox": "Synkronsiera projekt med Fortnox",
      "Timeplan": "Tidsplan",
      "DownloadPageAsExcelFile": "Ladda ner sida som Excelfil",
      "MyGizmoAccount": "MyGizmo-konto",
      "Filter": "Filter",
      "FreetextSearch": "Fritextsökning",
      "NumberOfSearchResults": "Antal sökresultat",
      "Page": "Sida",
      "ShowFrom": "Visar från",
      "ShowTo": "Visar till",
      "ProjectName": "Projektnamn",
      "ProjectDescription": "Projektbeskrivning",
      "CustomerNumber": "Kundnummer",
      "CustomerName": "Kundnamn",
      "ProjectNumber": "Projektnummer",
      "DownloadedAt": "Nedladdad vid",
      "SaveChanges": "Spara ändringar",
      "Attachment": "Bilaga",
      "Overview": "Översikt",
      "CreateCustomerObject": "Skapa kundobjekt",
      "PickedCustomerObject": "Valt kundobjekt",
      "CreateNew2": "Skapa nytt",
      "Edit": "Redigera",
      "AbsencePerAbsenceType": "Frånvaro per frånvarotyp",
      "TimeSpecification": "Tidsspecifikation",
      "SalaryProductSpecification": "Lönegrundande produktspecifikation",
      "DetailedAbsence": "Frånvaro i detalj",
      "AreYouSureYouWantToLeaveMessage": "Du har osparade ändringar. Är säker på att du vill lämna sidan?",
      "LabourCosts": "Personalkostnader",
      "labourCosts": "personalkostnader",
      "Resourceplanner": "Resursplanering",
      "Labour": "Personal",
      "Comps": "Utlägg",
      "PricesIncludingVAT": "Priserna anges inklusive moms",
      "NewQuotation": "Ny offert",
      "SaveAsDefaultHeadline": "Spara som standardrubrik",
      "SaveAsDefaultFooter": "Spara som standardfotnot",
      "TheHeadlineHasBeenSavedAsDefaultHeadline": "Rubriken har sparats som standardrubrik",
      "TheFooterHasBeenSavedAsDefaultFooter": "Fotnoten har sparats som standardfotnot",
      "AddTextBlockFromLibrary": "Lägg till textblock från bibliotek",
      "TheTextBlockHasBeenStoredInTheLibrary": "Textblocket har sparats i biblioteket",
      "TheTextBlockHasBeenAddedFromTheLibrary": "Textblocket har lagts till från biblioteket",
      "TextBlockLibrary": "Textblockbibliotek",
      "SupplierInvoices": "Leverantörsfakturor",
      "Costs": "Kostnader",
      "Date": "Datum",
      "Employee": "Anställd",
      "Type": "Typ",
      "TotalDeduction": "Totalt avdrag",
      "SortBy": "Sortera på",
      "TotalAfterDeduction": "Totalt efter avdrag",
      "Quantity": "Antal",
      "Á-Price": "Á-Pris",
      "Á-Cost": "Á-Kostnad",
      "ToInvoice": "Till faktura",
      "ToSalary": "Till Lön",
      "Unit": "Enhet",
      "ReportHasUpdated": "Rapporten har uppdaterats",
      "ToInvoiceShort": "Till fakt.",
      "QuantityToInvoice": "Antal till faktura",
      "QuantityToInvoiceShort": "Antal till fakt.",
      "QuantityToSalary": "Antal till lön",
      "TheReportWasApprovedBy": "Rapporten har godkänts av",
      "TheReportWasRejectedBy": "Rapporten har felmarkerats av",
      "ClickForAttestHistory": "Tryck för att se attesteringshistorik",
      "BelongsToCustomerProject": "Tillhör kundprojekt",
      "MarkAsWrong": "Felmarkera",
      "Approve": "Godkänn",
      "NewQuotationTemplate": "Ny offertmall",
      "CreateQuotationTemplate": "Skapa offertmall",
      "OfferTemplateNumber": "Offertmall nummer",
      "QuotationTemplates": "Offertmallar",
      "ReportsHasBeenMarkedAsWrong": "Rapporterna har felmarkerats",
      "ReportsHasBeenApproved": "Rapporterna har godkänts",
      "pickedReports": "valda rapporter",
      "Note": "Anteckning",
      "Car": "Fordon",
      "Product": "Produkt",
      "Time2": "Tid",
      "NoProjects": "Det finns inga projekt",
      "NotHandledOnInvoiceDetails": "Ej hanterad som fakturaunderlag",
      "NotHandledOnSalaryDetails": "Ej hanterad som löneunderlag",
      "HandledOnInvoiceDetails": "Hanterad på fakturaunderlag",
      "HandledOnSalaryDetails": "Hanterad på löneunderlag",
      "ShowingReportsInInterval": "Visar rapporter i intervallet",
      "ApplyReportFilter": "Filtrera rapporter",
      "VisibleBookings": "Synliga bokningar",
      "ClearFilter": "Rensa filter",
      "TheCustomerHasBeenCreated": "Kunden har skapats",
      "From": "Från",
      "To": "Till",
      "ProjectShort": "Pr",
      "AttestHistory": "Attesteringshistorik",
      "CreatedAt": "Skapad vid",
      "CustomerSignature": "Kundsignatur",
      "approved": "Godkänd",
      "rejected": "Felmarkerad",
      "MarkAsDone": "Klarmarkera",
      "NoProjectHasBeenSelected": "Inget projekt har valts",
      "TotalExcludingVat": "Totalt exklusive moms",
      "CompanyCustomerPricesWillExcludeVATAndDeductionTypesAreHidden": "Företagskund: Priser anges exklusive moms och avdragstyper är dolda.",
      "PricesExcludingVAT": "Priser anges exkl. moms",
      "Inactivate": "Inaktivera",
      "Inactivated": "Inaktiverat",
      "Inactivated2": "Inaktiverad",
      "ProjectHasBeenDeleted": "Projektet har raderats",
      "Article": "Artikel",
      "Sorting": "Sortering",
      "FallingBookings": "Fallande bokningar",
      "Custom": "Anpassad",
      "PickType": "Välj typ",
      "UseProjectVariations": "Använd ÄTA arbeten",
      "UsersCanCreateProjectVariations": "Användare kan skapa ÄTA arbeten",
      "PickVAT": "Välj moms",
      "VAT": "Moms",
      "AreYouSureYouWantToDeleteProject": "Är du säker på att du vill radera projektet? Denna handling går inte att ångra.",
      "TheProjectHasBeenMarkedAsNotDone": "Projektet har markerats som ej klarmarkerat",
      "TheProjectHasBeenMarkedAsDone": "Projektet har klarmarkerats",
      "TheProjectHasBeenMarkedAsActive": "Projektet har markerats som ej inaktivt",
      "TheProjectHasBeenMarkedAsInactive": "Projektet har markerats som inaktivt",
      "TheProjectFormHasBeenUpdated": "Projektformuläret har uppdaterats.",
      "Responsee": "Ansvarig",
      "NotStarted": "Ej påbörjat",
      "GetStarted": "Kom igång",
      "StartQuotationNumberSeriesAt": "Börja offernummerserie på",
      "TimePlanIsNotAvaliableOnInactivatedProjects": "Tidsplan är ej tillgängligt på inaktiverade projekt",
      "Signed": "Signerat",
      "Ongoing": "Påbörjat",
      "ShowAnswers": "Visa svar",
      "EditProjectForm": "Redigera projektformulär",
      "TheProjectHasBeenUpdated": "Projektet har uppdaterats",
      "VisibleForAllUsers": "Synligt för alla användare",
      "ActiveView": "Aktiv vy",
      "TotalReported": "Totalt inrapporterat",
      "ImportToProject": "Hämta till projekt",
      "WeekShort": "v",
      "FormTemplatesImportedToProject": "Formulärmallar har hämtats till projektet",
      "ApprovedTimeFormat": "Godkänt tidsformat",
      "ProjectFormWasDeleted": "Projektformuläret har raderats",
      "Question": "Fråga",
      "PickOneAnswer": "Välj ett svar",
      "MaxDeduction": "Maximalt avdrag",
      "RadioButtons": "Radioknappar",
      "MultipleFreetextAnswers": "Flera fritextsvar",
      "PickMultipleAnswers": "Välj flera svar",
      "Checkboxes": "Checkboxar",
      "TheEmployeeHasNonAttestedReportsInTheIntervalClickHereToShow": "Den anställde har lämnat ej attesterade rapporter i intervallet. Klick här för att visa.",
      "NonAttestedReportsInTheIntervalForEmployee": "Ej attesterade rapporter i intervallet för anställd",
      "AttestedReportsInTheIntervalForEmployee": "Attesterade rapporter i intervallet för anställd",
      "ReportsInTheIntervalForEmployee": "Rapporter i intervallet för anställd",
      "NonAttestedReportsInTheProject": "Ej attesterade rapporter i projektet",
      "ResourcePlanning": "Planering",
      "FreetextAnswer": "Fritextsvar",
      "TitleAndDescription": "Titel och beskrivning",
      "AddComponent": "Lägg till komponent",
      "BackToProjectForms": "Tillbaka till projektformulär",
      "StopEdit": "Sluta redigera",
      "Components": "Komponenter",
      "AnswerRequired": "Svar obligatoriskt",
      "ShowAnswerDate": "Visa svarsdatum",
      "AddProjectElement": "Lägg till delmoment",
      "EditProjectElement": "Redigera delmoment",
      "TheProjectFormHasBeenCreated": "Projektformuläret har skapats",
      "IncludeTimes": "Inkl. tider",
      "IncludeProducts": "Inkl. produkter",
      "IncludeCars": "Inkl. fordon",
      "IncludeComp": "Inkl. ersättning",
      "IncludeSupplierInvoices": "Inkl. leverantörsfakturor",
      "IncludeNotes": "Inkl. anteckningar",
      "BreakOnDate": "Bryt på datum",
      "ShowTimeTypes": "Visa tidstyper",
      "ShowPrices": "Visa priser",
      "SentBy": "Skickad av",
      "AnswerBySelectingOneOfTheFollowingOptionsAndPressSend": "Svara genom att välja ett av följande alternativ och skicka in ditt svar",
      "SendResponse": "Skicka in svar",
      "SendingResponse": "Skickar in svar",
      "Accept": "Acceptera",
      "Reject": "Neka",
      "TheQuotationWasAcceptedByCustomer": "Offerten har accepterats av kund",
      "TheQuotationWasRejectedByCustomer": "Offerten har nekats av kund",
      "TheQuotationLinkIsUsed": "Offertlänken är förbrukad",
      "MyCalendar": "Min kalender",
      "Notifications": "Notiser",
      "NoNotifications": "Inga notiser",
      "YourAnswerHasBeenSubmitted": "Ditt svar har skickats in",
      "YouCanCloseThisWindowNow": "Du kan stänga det här fönstret nu",
      "Price/Unit": "Pris/Enhet",
      "Price": "Pris",
      "rows": "rader",
      "MarkAsHandled": "Markera som hanterat",
      "HandleAndExportToFortnox": "Hantera och exportera till Fortnox",
      "Proceed": "Fortsätt",
      "TheInvoiceDetailsHasBeenHandled": "Fakturaunderlagen har hanterats",
      "Handled": "Hanterade",
      "PickMonth": "Välj månad",
      "withATotalValueOf": "med ett totalt värde av",
      "Pick": "Välj",
      "EmployeesInSalaryPeriod": "Anställda i löneperioden",
      "SalaryDetailsInSalaryPeriod": "Löneunderlag i löneperioden",
      "Max3RowsAreRecommended": "Max 3 rader rekommenderas",
      "Unhandled": "Ohanterade",
      "FortnoxDocumentNumber": "Fortnox Dokumentnr.",
      "SaveAsPDF": "Spara som PDF",
      "Print": "Skriv ut",
      "ShowOfferRowDiscounts": "Visa rabatter",
      "ShowOfferRowQuantities": "Visa antal",
      "ShowOfferRowPricePerUnit": "Visa priser per enhet",
      "ShowOfferRowTotals": "Visa totalsummor",
      "YourReference": "Er referens",
      "BackHandling": "Backa hantering",
      "RefersToThePeriod": "Avser perioden",
      "UnhandledInvoiceDetails": "Ohanterade fakturaunderlag",
      "HandledInvoiceDetails": "Hanterade fakturaunderlag",
      "TheInvoiceDetailsHasBeenHandledOnInvoiceDetails": "Rapporterna har hanterats på fakturaunderlag",
      "InvoiceDetailsWasBackedToUnhandled": "Fakturaunderlaget har backats till ohanterade",
      "ShowSpecification": "Visa specifikation",
      "HideSpecification": "Dölj specifikation",
      "ApprovedBy": "Godkänd av",
      "ThisWeek": "Nuvarande vecka",
      "LastWeek": "Föregående vecka",
      "ThisMonth": "Nuvarande månad",
      "LastMonth": "Föregående månad",
      "Last12Months": "Senaste 12 månaderna",
      "employees": "anställda",
      "EditReport": "Redigera rapport",
      "PickProject": "Välj projekt",
      "Task": "Arbetsuppgift",
      "TimeType": "Tidstyp",
      "EditOfferRow": "Redigera offertrad",
      "SalesPrice": "Á-Pris",
      "CostPrice": "Á-Kostnad",
      "ProductKind": "Produkttyp",
      "PickTask": "Välj arbetsuppgift",
      "PickTimeType": "Välj tidstyp",
      "PickProduct": "Välj produkt",
      "PickProductKind": "Välj produkttyp",
      "ThereAre": "Det finns",
      "nonApprovedReportsOnTheProject": "ej godkända rapporter på projektet",
      "ValueOfTimeReports": "Värde av tider",
      "ValueOfProductReports": "Värde av produkter",
      "ValueOfCarReports": "Värde av fordon",
      "ValueOfCompReports": "Värde av ersättningar",
      "ValueOfSupplierInvoiceReports": "Värde av lev.fakturor",
      "TotalValue": "Totalt värde",
      "EarRounding": "Öresavrundning",
      "TotalBeforeDeduction": "Totalt innan avdrag",
      "AdressLine1": "Adressrad 1",
      "AdressLine2": "Adressrad 2",
      "AdressLine3": "Adressrad 3",
      "AdressLine4": "Adressrad 4",
      "PostalCode": "Postkod",
      "EditCustomerObject": "Redigera kundobjekt",
      "SomethingWentWrong": "Något gick fel",
      "CustomerObjectUpdated": "Kundobjektet har uppdaterats",
      "CustomerObjectCreated": "Kundobjektet har skapats",
      "BackToProjects": "Tillbaka till projekt",
      "DownloadAsPAXML": "Ladda ner som PAXml",
      "AssignedAsProjectLeader": "Tilldelad som projektledare",
      "Active": "Aktivt",
      "ReportHasBeenHandledOnInvoiceDetails": "Rapporten har hanterats på fakturaunderlag",
      "ReportHasBeenHandledOnSalaryDetails": "Rapporten har hanterats på löneunderlag",
      "ReportHasBeenHandledOnSalaryDetails": "Rapporten har hanterats på löneunderlag",
      "andSalaryDetails": "och löneunderlag",
      "AreYouSureYouWantToEditHandledReportMessage": "Ändringarna kommer återspeglas i det hanterade underlaget. Är du säker på att du redigera rapporten?",
      "ReportMatrix": "Rapportmatris",
      "ScrollHorizontallyWithMouseWheelByHolding": "Scrolla horisontellt med mushjulet genom att hålla in",
      "ApplyFilter": "Applicera filter",
      "Apply": "Applicera",
      "PlannedHours": "Planerade timmar",
      "PlannedOfEstimate": "Planerat av estimat",
      "selected": "valda",
      "none": "ingen",
      "ShowColorCodes": "Visa färgkoder",
      "BlueOver8Hours": "Blått över 8 timmar",
      "RedUnder8Hours": "Rött under 8 timmar",
      "CreatedByYou": "Skapat av dig",
      "ActiveAbsence": "Aktiv",
      "InactiveAbsence": "Inaktiv",
      "TemplateDescription": "Mallbeskrivning",
      "TheQuotationHasBeenSavedAsTemplate": "Offerten har sparats som mall",
      "YourUnsavedChangesWillNotBeAddedToTheTemplate": "Dina osparade ändringar kommer inte läggas till på mallen",
      "YourUnsavedChangesWillBeLostIfYouShareTheQuotation": "Du kommer förlora dina osparade ändringar om du delar offerten utan att spara först",
      "EditQuotationTemplate": "Redigera offertmall",
      "ShowQuotation": "Visa offert",
      "Preview": "Förhandsgranska",
      "CreateQuotationTemplate": "Skapa offertmall",
      "TheQuotationTemplateHasBeenCreated": "Offertmallen har skapats",
      "TheQuotationTemplateHasBeenUpdated": "Offertmallen har uppdaterats",
      "TheQuotationTemplateHasBeenDeleted": "Offertmallen har raderats",
      "EditAbsenceType": "Redigera frånvarotyp",
      "AbsenceTypeDeleteErr": "Det går inte att ta bort en frånvarotyp med rapporterad frånvaro.",
      "AbsenceTypeUpdatedSuccess": "Frånvarotyp har uppdaterats.",
      "AbsenceTypeDeleteSuccess": "Frånvarotyp har tagits bort.",
      "AbsenceTypeCreateError": "Frånvarotyp med samma namn finns redan.",
      "AbsenceTypeCreateSuccess": "Frånvarotyp skapad.",
      "PersonalNumberShort": "Personnr.",
      "PhoneNumberShort": "Telefonr.",
      "BackToCustomers": "Tillbaka till kunder",
      "Adresses": "Adresser",
      "Documents": "Dokument",
      "CustomerUpdated": "Kunden har uppdaterats",
      "UsedOn": "Används på",
      "projects": "projekt",
      "MainAdress": "Huvudadress",
      "New": "Ny",
      "AreYouSureYouWantToDeleteTheCustomerObject": "Är du säker på att du vill radera kundobjektet? Denna handling går inte att ångra",
      "PrivatePerson": "Privatperson",
      "ProjectVariations": "ÄTA arbeten",
      "CustomerObjectHasBeenDeleted": "Kundobjektet har raderats",
      "Name2": "Benämning",
      "BackTo": "Tillbaka till",
      "PriceAdjustmentsBasedOnCustomerPriceList": "Prisjusteringar baserat på kundens prislista",
      "PickTheAdjustmentsYouWantToApply": "Välj de justeringar som du vill applicera",
      "reports": "rapporter",
      "ProjectsWithUnhandledInvoiceDetails": "Projekt med ohanterade fakturaunderlag",
      "CustomerHasBeenMarkedAsInactive": "Kunden har markerats som inaktiv",
      "CustomerHasBeenMarkedAsActive": "Kunden har markerats som aktiv",
      "andThereforCannotBeEdited": "och kan därmed inte redigeras",
      "CreateReport": "Skapa rapport",
      "SaveAsTemplate": "Spara som mall",
      "TimeReport": "Tidsrapport",
      "MaterialReport": "Materialrapport",
      "PickProduct": "Välj produkt",
      "PickVehicle": "Välj fordon",
      "PickComp": "Välj ersättning",
      "PickArticle": "Välj artikel",
      "TheReportHasBeenCreated": "Rapporten har skapats",
      "FieldIsMandatory": "Fältet är obligatoriskt",
      "DeleteLibraryTextBlock": "Radera textblock fråm bibliotek",
      "CreateNewOfferVersionConfirmMessage": "Vill du kopiera offerten till en ny version?",
      "ANewOfferVersionHasBeenCreated": "En ny version av offerten har skapats",
      "DeleteVersion": "Radera version",
      "TheProjectVariationHasBeenSentTo": "ÄTA arbetet har skickats för signering till",
      "TheProjectVariationLinkIsConsumed": "Länken till ÄTA arbetet har förbrukats",
      "TheProjectVariationIsLockedDueToHavingAnActiveLink": "ÄTA arbetet är låst på grund av att den har en aktiv länk",
      "TheProjectVariationIsLockedDueToHavingBeenMarkedAsSent": "ÄTA arbetet är låst på grund av att den har markerats som skickad",
      "TheProjectVariationIsLockedDueToHavingBeenMarkedAsAccepted": "ÄTA arbetet är låst på grund av att den har markerats som accepterad",
      "TheProjectVariationIsLockedDueToHavingBeenMarkedAsRejected": "ÄTA arbetet är låst på grund av att den har markerats som nekad",
      "TheProjectVariationIsLockedDueToHavingBeenAcceptedByCustomer": "ÄTA arbetet är låst på grund av att den har accepterats av kund",
      "TheProjectVariationIsLockedDueToHavingBeenRejectedByCustomer": "ÄTA arbetet är låst på grund av att den har nekats av kund",
      "Created+": "Skapade",
      "Sent+": "Skickade",
      "Accepted+": "Accepterade",
      "Rejected+": "Nekade",
      "OfferNumber": "Offertnummer",
      "PaymentPlan": "Lyftplan",
      "PaymentPlanElement": "Lyft",
      "specification": "specifikation",
      "InvoiceByPaymentPlan": "Fakturera enligt lyftplan",
      "MaxTotalSafetyPercentage": "Max total säkerhetsprocent",
      "SafetyPercentage": "Säkerhetsprocent",
      "ShowPaymentPlanDueDate": "Visa förfallodatum",
      "ShowPaymentPlanInvoiceDate": "Visa fakturadatum",
      "SafetyAmount": "Innehållet",
      "AccumulatedSafetyAmount": "Ackumulerat innehållet",
      "ToInvoiceAmount": "Belopp till faktura", 
      "ActivatePaymentPlan": "Aktivera lyftplan",
      "AddRow": "Lägg till rad",
      "ThePaymentPlanElementHasBeenEdited": "Raden har redigerats",
      "AddPaymentPlanElement": "Lägg till rad",
      "EditPaymentPlanElement": "Redigera rad",
      "ThePaymentPlanElementHasBeenCreated": "Raden har skapats",
      "ThePaymentPlanElementHasBeenDeleted": "Raden har raderats",
      "GoToMonth": "Gå till månad",
      "CreateBookings": "Skapa bokningar",
      "ShowBookingsWithResources": "Visa bokningar med resurser",
      "ShowSettings": "Visa inställningar",
      "HideSettings": "Dölj inställningar",
      "ShowWeekends": "Visa helger",
      "ShowWorkday": "Visa arbetsdag",
      "ShowOnlyRowsWithBookings": "Visa endast rader med bokningar",
      "Workload": "Arbetsbelastning",
      "BookingStatus": "Bokningsstatus",
      "DailyCapacity": "Daglig kapacitet",
      "StandardValue8HoursPerEmployee": "Standardvärde 8 timmar per anställd",
      "ManHours": "Mantimmar",
      "ofDailyCapacity": "av daglig kapacitet",
      "CalculateWorloadFromPredefinedWorkday": "Beräkna arbetsbelastning utifrån fördefinerad arbetsdag",
      "EmployeesStartToWork": "Anställda börjar jobba",
      "EmployeesStopWorking": "Anställda slutar jobba",
      "LunchFrom": "Lunch från",
      "LunchTo": "Lunch till",
      "ColorLevels": "Färgnivåer",
      "ContainsDoubleBookings": "Innehåller dubbelbokningar",
      "ContainsEmptyBookingSpots": "Innehåller tomma bokningsplatser",
      "ContainsBookingsWithToManyBookedResources": "Innehåller bokningar med för många bokade resurser",
      "ContainsEmptyBookings": "Innehåller tomma bokningar",
      "AllBookingsHasTheRightNumberOfBookedResources": "Alla bokningar har rätt antal bokade resurser",
      "Versions": "Versioner",
      "ToHandle": "Att hantera",
      "MarkedAsWrong": "Felmarkerade",
      "Approved": "Godkända",
      "NotFinished": "Ej färdighanterade",
      "Finished": "Färdighanterade",
      "RequiresAdminScope": "Kräver admin behörigheter",
      "DeleteReports": "Radera rapporter",
      "TheReportsHasBeenDeleted": "Rapporterna har raderats",
      "TheReportHasBeenDeleted": "Rapporten har raderats",
      "AddReport": "Lägg till rapport",
      "TheReportsHasBeenCreated": "Rapporterna har skapats",
      "FromRegister": "Från artikelregistret",
      "Freetext": "Fritext",
      "PleaseSignHereToAccept": "Var vänlig signera här för att acceptera",
      "Signature": "Signatur",
      "ClearSignature": "Rensa signatur",
      "ShowSignature": "Visa signatur",
      "Answer": "Svar",
      "TheQuotationLinkHasBeenSentTo": "Offertlänken har skickats till",
      "TheLinkHasBeenOpened": "Länken har öppnats",
      "AddBookings": "Skapa bokningar",
      "ClearFields": "Rensa fält",
      "Resources": "Resurser",
      "TheBookingsHasBeenCreated": "Bokningarna har skapats",
      "times": "gånger",
      "UnhandledSalaryDetails": "Ohanterade löneunderlag",
      "SendQuotation": "Skicka offert",
      "InactivateLink": "Inaktivera länk",
      "TheQuotationLinkHasBeenInactivated": "Offertlänken har inaktiverats",
      "SendToEmail": "Skicka till email",
      "NotAValidEmailAdress": "Email adressen är inte giltig",
      "Created": "Skapad",
      "Sent": "Skickad",
      "Accepted": "Accepterad",
      "Rejected": "Nekad",
      "HandledSalaryDetails": "Hanterade löneunderlag",
      "GetUnhandledSalaryDetails": "Hämta ohanterade löneunderlag",
      "TheQuotationHasBeenSentTo": "Offerten har skickats till",
      "ReportedTime": "Rapporterad tid",
      "TheCustomerIsLockedDueToProjectBeingCreatedFromOffer": "Kunden är låst på grund av att projektet skapades från en offert",
      "TheCustomerIsLockedDueToTheProjectHasSignedReports": "Kunden är låst på grund av att projektet har signerade rapporter",
      "ThisVersionIsLockedDueToHavingANewerVersion": "Versionen är låst på grund av att det finns en nyare version",
      "ThisVersionIsLockedDueToHavingBeenMarkedAsSent": "Versionen är låst på grund av att den har markerats som skickad",
      "ThisVersionIsLockedDueToHavingBeenMarkedAsAccepted": "Versionen är låst på grund av att den har markerats som accepterad",
      "ThisVersionIsLockedDueToHavingBeenMarkedAsRejected": "Versionen är låst på grund av att den har markerats som nekad",
      "ThisVersionIsLockedDueToHavingBeenAcceptedByCustomer": "Versionen är låst på grund av att den har accepterats av kund",
      "ThisVersionIsLockedDueToHavingBeenRejectedByCustomer": "Versionen är låst på grund av att den har nekats av kund",
      "ThisVersionIsLockedDueToHavingCreatedAProject": "Versionen är låst på grund av att den har skapat ett projekt",
      "CreatedFromQuotation": "Skapat från offert",
      "ReportedAbsence": "Rapporterad frånvaro",
      "Total": "Totalt",
      "DateSpecification": "Datumspecifikation",
      "ArticleRegistry": "Artikelregister",
      "AddArticle": "Lägg till artikel",
      "SearchArticle": "Sök artikel",
      "DeductionType": "Avdragstyp",
      "PickDeductionType": "Välj avdragstyp",
      "ProductCategory": "Produkt kategori",
      "SalaryBasis": "Lönegrundande",
      "CompensationCode": "Ersättningskod",
      "Cost": "Kostnad",
      "PriceInSEK": "Pris i SEK",
      "CostInSEK": "Kostnad i SEK",
      "ShowAdvancedSearch": "Visa avancerad sökning",
      "HideAdvancedSearch": "Dölj avancerad sökning",
      "ArticleCreatedSuccessfully": "Artikel skapad",
      "ArticleUpdatedSuccessfully": "Arikel updaterad",
      "ThereAreNonAttestedReportsInTheIntervalAreYouSureYouWantToProceed?": "Det finns ej godkända rapporter i intervallet. Är du säker på att du vill fortsätta?",
      "ArticleAlreadyExist": "Artikel finns redan",
      "ArticleDeletedSuccessfully": "Artikel har raderats",
      "Products+": "Produkter",
      "CompensationTypes": "Ersättningstyper",
      "ProductInformation": "Produktuppgifter",
      "ColorCode": "Färgkod",
      "ResetColor": "Återställ färgkod",
      "ArticleName": "Artikelnamn",
      "BackToArticles": "Tillbaka till artiklar",
      "AddCar": "Lägg till fordon",
      "AddProduct": "Lägg till produkt",
      "AddTask": "Lägg till arbetsuppgift",
      "NameRequired": "Namn är obligatoriskt, vänligen skriv in ett namn.",
      "UnitRequired": "Enhet är obligatoriskt, vänligen välj en enhet.",
      "Salary": "Lön",
      "ProductDocument": "Produktdokument",
      "CheckOff": "Checka av",
      "Yes": "Ja",
      "No": "Nej",
      "Inactive++": "Inaktiv",
      "Variations": "ÄTA-arbeten",
      "CreatedBy": "Skapad av",
      "TheProjectVariationLinkHasBeenSentTo": "Länken till ÄTA-arbetet har skickats till",
      "TheProjectVariationLinkHasBeenInactivated": "Länken till ÄTA-arbetet har inaktiverats",
      "TheProjectVariationWasAcceptedByCustomer": "ÄTA arbetet har accepterats av kund",
      "TheProjectVariationWasRejectedByCustomer": "ÄTA arbetet har nekats av kund",
      "Consumption": "Förbrukning",
      "CreateProjectVariation": "Skapa ÄTA",
      "EditProjectVariation": "Redigera ÄTA",
      "FixedPriceInSEK": "Fastpris i SEK",
      "TheProjectVariationHasBeenUpdated": "ÄTA arbetet har uppdaterats",
      "SelectAllReports": "Markera alla raporter",
      "DeselectAllReports": "Avmarkera alla raporter",
      "TheProjectVariationHasBeenCreated": "ÄTA arbetet har skapats",
      "TheProjectVariationHasBeenDeleted": "ÄTA arbetet har raderats",
      "AddProductInformation": "Lägg till produktinformation",
      "UnitCreated": "Enhet skapad",
      "NotProjectVariation": "Ej ÄTA arbete",
      "ProjectVariation": "ÄTA",
      "UnitName": "Enhets namn",
      "NewUnitName": "Nytt enhetsnamn",
      "UnitNameAlreadyExists": "Enhet med samma namn finns redan",
      "NewDescription": "Ny beskrivning",
      "UnitDescription": "Enhets beskrivning",
      "UnitUpdated": "Enhet uppdaterad",
      "ProductTypeUpdated": "Produktkategori uppdaterad",
      "ProductTypeName": "Produktkategori namn",
      "NewProductTypeName": "Nytt produktkategori namn",
      "ProductTypeDescription": "Produktkategori beskrivning",
      "NewProductTypeDescription": "Ny produktkategori beskrivning",
      "ProductCategoryNameAlreadyExists": "Produktkategori med samma namn finns redan",
      "CategoryCreated": "Kategori skapad",
      "AddUnit": "Lägg till enhet",
      "NoDescriptionEntered": "Beskrivning ej ifyllt",
      "NoPriceEntered": "Pris ej ifyllt",
      "NoCostEntered": "Kostnad ej ifyllt",
      "TaskDeletedSuccessfully": "Arbetsuppgift har raderats",
      "AllReports" : "Alla rapporter",
      "OnlyNonVariation": "Ej ÄTA arbete",
      "OnlyVariation": "Endast ÄTA arbete",
      "Total": "Totalt",
      "TaskUpdatedSuccessfully": "Arbetsuppgift uppdaterad",
      "TaskNameAlreadyExists": "Arbetsuppgift med samma namn finns redan",
      "Back": "Tillbaka",
      "TheStartTimeHasToBeBeforeTheEndTime": "Starttiden måste vara före sluttiden",
      "TaskCategoryName": "Arbetsuppgift kategori namn",
      "TaskCategoryDescription": "Arbetsuppgift kategori beskrivning",
      "TaskTypeUpdatedSuccessfully": "Arbetsuppgift kategori uppdaterad",
      "TaskTypeAlreadyExist": "Arbetsuppgift kategori med samma namn finns redan",
      "ProductCategories": "Produkt kategorier",
      "TaskCategories": "Arbetsuppgift kategorier",
      "CantDeleteCategoryOnRegisteredTask": "Kan inte ta bort kategori som är registrerad på en arbetsuppgift",
      "CategoryDeleted": "Kategori har raderats",
      "CantDeleteCategoryOnRegisteredProduct": "Kan inte ta bort kategori som är registrerad på en produkt",
      "UnitDeleted": "Enhet har raderats",
      "CantDeleteUnitOnRegisteredProduct": "Kan inte ta bort enhet som är registrerad på en produkt",
      "TheReportsHasBeenHandledOnSalaryDetails": "Rapporterna har hanterats på löneunderlag",
      "ActionsAreAppliedToAllSalaryDetailsInThePeriod": "Handlingarna appliceras på alla löneunderlag i perioden",
      "DownloadAsXML": "Ladda ner som XML",
      "DownloadAsXMLToVisma": "Ladda ner som XML till Visma",
      "SalaryDetailsWasBackedToUnhandled": "Löneunderlagen har backats till ohanterat",
      "Unsorted": "Osorterat",
      "SalaryPeriod": "Löneperiod",
      "to": "till",
      "TheReportHasBeenHandledOnInvoiceDetails": "Rapporten har hanterats på fakturaunderlag",
      "TheReportHasBeenHandledOnSalaryDetails": "Rapporten har hanterats på löneunderlag",
      "andSalaryDetails":  "och löneunderlag",
      "andThereforeHasLimitedEditability": "och har därför begränsade redigeringsmöjligheter",
      "AbsenceReport": "Frånvarorapport",
      "PickAbsenceType": "Välj frånvarotyp",
      "H": "Tim",
      "C": "K",
      "TimeReport": "Tidrapport",
      "AreYouSureYouWantToDeleteArticle": "Är du säker på att du vill ta bort artikel?",
      "AreYouSureYouWantToDeleteTask": "Är du säker på att du vill ta bort arbetsuppgift?",
      "AreYouSureYouWantToDeleteUnit": "Är du säker på att du vill ta bort enhet?",
      "AreYouSureYouWantToDeleteCategory": "Är du säker på att du vill ta bort kategori?",
      "Logout": "Logga ut",
      "Username": "Användarnamn",
      "Email+": "E-post",
      "Password": "Lösenord",
      "UserInformation": "Användaruppgifter",
      "PersonalData": "Personuppgifter",
      "NotAdded": "Inte tillagt",
      "EmailTaken": "E-post upptagen",
      "RepeatPassword": "Upprepa lösenord",
      "EmailUpdated": "E-post uppdaterad",
      "ChangePassword": "Byt lösenord",
      "PasswordUpdated": "Lösenord uppdaterat",
      "PasswordsDoNotMatch": "Lösenorden matchar inte",
      "UserDetails": "Användardetaljer",
      "EnterFirstName": "Fyll i förnamn",
      "EnterLastName": "Fyll i efternamn",
      "UserDetailsUpdated": "Användardetaljer uppdaterade",
      "ProfilePictureUpdated": "Profilbild uppdaterad",
      "UploadImage": "Ladda upp bild",
      "ChoosePicture": "Välj bild",
      "UploadProfileImage": "Ladda upp profilbild",
      "PasswordToShort": "Lösenord för kort",
      
      "InvoiceDetailsShort": "FU",
      "SalaryDetailsShort": "LU",
      "InvoiceSuccessfullyExportedToFortnoxInvoice": "Fakturaunderlaget har exporterats till Fortnox faktura",
      "CreateInvoiceInFortnox": "Skapa faktura i Fortnox",
      "perPage": "per sida",
      "ExternalProjectLeaderShort": "Extern PL",
      "CountWithUnhandledDetails": "Räkna med ohanterade underlag",
      "h": "tim",
      "RevokeSoftDeleteProduct": "Det finns redan en produkt med det namnet och den enheten (som är inaktiverad). Vill du återaktivera produkten?",
      "RevokeSoftDeleteTask": "Det finns redan en arbetsuppgift med det namnet (som är inaktiverad). Vill du återaktivera arbetsuppgiften?",
      "ArticleReactivated": "Artikel återaktiverad",
      "ApplyOnWeekends": "Applicera på helger",
      "TheCustomerWasDeleted": "Kunden har raderats",
      "TheCustomerHasProjectsAndThereforeCannotBeDeleted": "Kunden har tillhörande projekt och kan därför inte tas bort.",
      "TheCustomerHasDocumentsAndThereforeCannotBeDeleted": "Kunden har tillhörande dokument och kan därför inte tas bort.",
      "NoPhoneNumberEntered": "Telefonnummer ej ifyllt",
      "NoEmailEntered": "E-post ej ifyllt",
      "EmploymentNumber": "Anställningsnummer",
      "EditDailyReports": "Redigera dagsrapporter",
      "File+": "Arkivera",
      "UserInactivated": "Användare inaktiverad",
      "UserRegistry": "Användarregister",
      "SearchUser": "Sök användare",
      "UserActivated": "Användare aktiverad",
      "BackToUserRegistry": "Tillbaka till användarregister",
      "AccountType": "Kontotyp",
      "InviteSent": "Inbjudan skickad",
      "Activated": "Aktiverad",
      "Users+": "Användare",
      "FinancialYear": "Räkenskapsår",
      "VoucherSeriesCollection": "Verifikatserie",
      "FromDate": "Från datum",
      "ToDate": "Till datum",
      "VoucherNumber": "Verifikatnummer",
      "TransactionDate": "Transaktionsdatum",
      "ReferenceType": "Referenstyp", 
      "TheFormTemplateHasBeenUpdated": "Formulärmallen har uppdaterats",
      "TheFormTemplateHasBeenCreated": "Formulärmallen har skapats",
      "TheFormTemplateHasBeenDeleted": "Formulärmallen har raderats",
      "TheCustomersPrice": "Kundens pris enligt prislista",
      "TheProjectVariationCouldNotBeSent": "ÄTA arbetet kunde inte skickas",
      "Sending": "Skickar",

      "Loading": "Laddar",
      "TotalAbsenceAndTime": "Total frånvaro och tid",
      "TotalAbsence": "Total frånvaro",
      "TotalComp": "Totalt utlagt",
      "Summary": "Summering",
      "DetailSpecification": "Detaljspecifikation",
      "RequiredField": "Obligatoriskt fält",
      "Week": "Vecka",
      "Current": "Nuvarande",
      "ThePriceWillBeFetchedFromTheReportTimeType": "Priset kommer hämtas från rapportens tidstyp",
      "TheCostWillBeFetchedFromTheReportTimeType": "Kostnaden kommer hämtas från rapportens tidstyp",
      "Quotations": "Offerter",
      "Quotation": "Offert",
      "CreateQuotation": "Skapa offert",
      "Year": "År",

      "ActivateTheIntegration": "Aktivera integrationen",
      "TheIntegrationIsActive": "Integrationen är aktiv",
      "RedoActivation": "Gör om aktivering",
      "ArticleNumberShort": "Artikelnr",
      "ImportArticlesFromFortnox": "Importera artiklar från Fortnox",
      "ArticlesInFortnox": "Artiklar i Fortnox",
      "Imported": "Importerad",
      "Import/Update": "Importera / uppdatera",
      "Import/UpdateAll": "Importera / uppdatera alla",
      "selectedArticles": "markerade artiklar",
      "TheArticlesHasBeenImported": "Artiklarna har importerats",
      "ImportAs": "Importa som",
      "NotImported": "Ej importerad",
      "QuantityInFortnox": "Antal i Fortnox",
      "ArticleUnit": "Artikelenhet",
      "FortnoxArticleGroupMessage": "Fortnox stödjer inte artikelgruppering. Därför används artikelenheterna för att bestämma vad artiklarna ska sparas som i MyGizmo. Du kan skapa egna enheter i Fortnox och på så sätt anpassa importeringen av artiklar ytterligare.",
      "ImportArticleSettings": "Inställningar för import av Artiklar",
      "MoreInfoHere": "Mer information här",
      "SaveTheQuotationToUpload": "Spara offerten för att ladda upp",
      "TheChangesHasBeenSaved": "Ändringarna har sparats",
      "TheProjectsHasBeenSyncedWithFortnox": "Projekten har synkroniserats med Fortnox",
      "YourIntegrationCanNotBeAdministratedFromHereYet": "Din integration kan inte administreras härifrån ännu.",
      "NoActiveIntegrationMessage": "Använder du Fortnox, Visma eEkonomi, Visma Administration eller Visma Lön? Då kan du enkelt integrera det med MyGizmo. Kontakta oss så hjälper vi dig att komma igång!",
      "IWantToIntegrateMyAccountingSoftware": "Jag vill integrera mitt ekonomi- /lönesystem",
      "VismaEaccounting": "Visma eEkonomi",
      "EaccountingNumberShort": "Visma e-Ekonomi Nr.",
      "ImportArticlesFromEaccounting": "Importera artiklar från eEkonomi",
      "ArticlesInEaccounting": "Artiklar i eEkonomi",
      "QuantityInEaccounting": "Antal i eEkonomi",
      "EaccountingArticleGroupMessage": "Visma eEkonomi stödjer inte artikelgruppering. Därför används artikelenheterna för att bestämma vad artiklarna ska sparas som i MyGizmo. Du kan skapa egna enheter i Visma eEkonomi och på så sätt anpassa importeringen av artiklar ytterligare.",
      "LoadingStatus": "Hämtar status",
      "TheEaccountingIntegrationIsInactive": "Visma eEkonomi integrationen är inaktiv",
      "TheFortnoxIntegrationIsInactive": "Fortnox integrationen är inaktiv",
      "OpenIntegrationSettings": "Öppna integrationsinställningar",
      "TheIntegrationIsInactiveDoYouWantToHeadToTheIntegrationSettings": "Integrationen är inaktiv. Vill du gå till integrationsinställningarna?",
      "Eaccounting": "E-EKONOMI",
      "CreateInvoiceInEaccounting": "Skapa faktura i eEkonomi",
      "InvoiceSuccessfullyExportedToEaccounting": "Fakturaunderlaget har exporterats till Visma eEkonomi",
      "MarkAsHandledAndInactivateProject": "Markera som hanterat och inaktivera projekt",
      "ProjectMarkedAsInactive": "Projeketet har markerats som inaktivt",
      "FinalInvoice": "Slutfaktura",
      "CreateUser": "Skapa användare",
      "Activate": "Aktivera",
      "FirstNameRequired": "Vänligen skriv in förnamn",
      "LastNameRequired": "Vänligen skriv in efternamn",
      "AProjectHasBeenCreatedFromTheOffer": "Ett projekt har skapats från offerten",
      "wasCreatedFromThisQuotation": "skapades från den här offerten",
      "EmailRequired": "Vänligen skriv in e-post",
      "EmployeeNumberTaken": "Anställningsnummer uppaget",
      "UserCreated": "Användare skapad",
      "InviteSentTo": "Inbjudan skickad till",
      "CustomerNumberShort": "Kundnr",
      "ImportCustomersFromEaccounting": "Importera kunder från eEkonomi",
      "selectedCustomers": "markerade kunder",
      "TheCustomersHasBeenImported": "Kunderna har importerats",
      "CustomersInEaccounting": "Kunder i eEkonomi",
      "ImportOrdersFromEaccounting": "Importera ordrar från eEkonomi",
      "selectedOrders": "markerade ordrar",
      "OrdersInEaccounting": "Ordrar i eEkonomi",
      "OrderNumberShort": "Order Nr.",
      "TheOrdersHasBeenImported": "Ordrarna har importerats",
      "TheCustomersHasBeenImported": "Kunderna har importerats",
      "CustomersInFortnox": "Kunder i Fortnox",
      "ImportCustomersFromFortnox": "Importera kunder från Fortnox",
      "OrdersInFortnox": "Ordrar i Fortnox",
      "ImportOrdersFromFortnox": "Importera ordrar från Fortnox",
      "FortnoxOrderNumber": "Fortnox ordernummer",
      "eAccountingOrderNumber": "Visma eEkonomi ordernummer",
      "FortnoxCustomerNumber": "Fortnox kundnummer",
      "eAccountingCustomerNumber": "Visma eEkonomi kundnummer",
      "FortnoxProjectNumberShort": "Fortnox projekt",
      "FortnoxCustomerNumberShort": "Fortnox kund",
      "EaccountingCustomerNumberShort": "e-Ekonomi kund",
      "projectsHasBeenHandled": "projekt har hanterats",
      "of": "av",
      "Purchases": "Inköp",
      "CreateSupplierInvoice": "Skapa leverantörsfaktura",
      "MainSettings": "Huvudinställningar",
      "FormTemplates": "Formulärmallar",
      "AddOption": "Lägg till alternativ",
      "PriceLists": "Prislistor",
      "SettingsSaved": "Inställningar sparade",
      "ShortName": "Kort namn",
      "TimeCodeSalary": "Tidskod lön",
      "Sequence": "Sekvens",
      "TimeTypeNameAlreadyExists": "Tidstyp med samma namn finns redan",
      "TimeTypeCreated": "Tidstyp skapad",
      "AddTimeType": "Lägg till tidstyp",
      "BackToSettings": "Tillbaka till inställningar",
      "TimeTypeUpdated": "Tidstyp uppdaterad",
      "TimeTypeDeleted": "Tidstyp har raderats",
      "AreYouSureYouWantToDeleteTimeType": "Är du säker på att du vill ta bort tidstyp?",
      "DocumentCategoryCreated": "Dokument kategori skapad",
      "AddDocumentCategory": "Lägg till dokument kategori",
      "OfferDate": "Offertdatum",
      "ExpireDate": "Giltig till",
      "DeliveryDate": "Leveransdatum",
      "OurReference": "Vår referens",
      "Header": "Sidhuvud",
      "Footer": "Fotnot",
      "NetTotal": "Totalt (ex. moms)",
      "TotalVAT": "Moms",
      "Introduction": "Inledning",
      "Ending": "Avslutning",
      "Attachments": "Bilagor",
      "TheQuotationHasBeenUpdated": "Offerten har uppdaterats",
      "TheQuotationHasBeenCreated": "Offerten har skapats",
      "TheQuotationHasBeenDeleted": "Offerten har raderats",
      "AddTextBlock": "Lägg till textblock",
      "AddOfferRow": "Lägg till offertrad",

      
      "DeletedInFortnox": "Makulerad i Fortnox",
      "OrderDate": "Orderdatum",
      "DueDate": "Förfallodatum",
      "InvoiceDate": "Fakturadatum",
      "TotalAmount": "Totalt belopp",
      "BackToPurchases": "Tillbaka till inköp",
      "TheSupplierInvoiceHasBeenUpdated": "Leverantörsfakturan har uppdaterats",
      "TheSupplierInvoiceHasBeenDeleted": "Leverantörsfakturan har raderats",
      "IncludeAsAttachment": "Inkl. som bilaga",
      "Amount": "Belopp",
      "Charge": "Påslag",
      "AddProject":"Lägg till projekt",
      "leftToAssign": "kvar att fördela",
      "TheWholeAmountHasBeenAssigned": "Hela beloppet har fördelats",
      "aboveTheTotalAmountHasBeenAssigned": "utöver det totala beloppet har fördelats",
      "Download": "Ladda ner",
      "RemoveDocument": "Ta bort dokument",
      "TheSupplierInvoiceHasBeenCreated": "Leverantörsfakturan har skapats",
      "CreateSupplierInvoiceShort": "Skapa lev.faktura",
      "ImportSupplierInvoicesFromFortnox": "Importera leverantörsfakturor från Fortnox",
      "SupplierInvoicesInFortnox": "Leverantörsfakturor i Fortnox",
      "DemandsManualHandlingInFortnox": "Kräver manuell hantering in Fortnox.",
      "SupplierInvoice": "Leverantörsfaktura",
      "ImportSupplierInvoices": "Importera leverantörsfakturor",
      "ReadyForImportToProject": "Redo för import till projekt",
      "TheSupplierInvoicesHasBeenImported": "Leverantörsfakturorna har importerats",
      "UseAttest": "Använd attestering",
      "ShowTaskDescriptionInDocumentation": "Visa arbetsuppgiftbeskrivning i underlag",
      "StartSeriesAt": "Anpassad projektnummerserie, starta serie från",
      "ResetSequence": "Återställ sekvens",
      "UploadCompanyLogo": "Ladda upp företags logo",
      "TimeTypes": "Tidstyper",
      "UnitCategories": "Enhets kategorier",
      "ProductCategories": "Produkt kategorier",
      "AddTaskCategory": "Lägg till arbetsuppgift kategori",
      "AddUnitCategory": "Lägg till enhets kategori",
      "AddProductCategory": "Lägg till produkt kategori",
      "EmployeesAreAllowedToEditReportsDuring": "Anställda tillåts redigera dagrapporter under",
      "SameDate": "Samma datum",
      "SameAndAdjacentDates": "Samma och angränsade datum",
      "SameWeek": "Samma vecka",
      "UnlimitedTime": "Obegränsad tid",
      "EmployeesAllowedToSeePriceOnArticles": "Anställda tillåts se pris på artiklar",
      "AddFormTemplate": "Lägg till formulärmall",
      "DocumentCategories": "Dokument kategorier",
      "NumberOfPriceAdjustments": "Antal prisjusteringar",
      "CompanyLogoUpdated": "Företags logo uppdaterad",
      "Headline": "Rubrik",
      "SaveTextBlockInLibrary": "Spara textblock i bibliotek",
      "EditTaskCategory": "Redigera arbetsuppgift kategori",
      "TimeTypeUpdated": "Tidstyp uppdaterad",
      "EditTimeType": "Redigera tid typ",
      "TimeTypeRemoved": "Tidstyp har raderats",
      "DocumentCategoryUpdated": "Dokument kategori uppdaterad",
      "AreYouSureYouWantToDeleteCategory": "Är du säker på att du vill ta bort kategorin?",
      "DocumentCategoryDeleted": "Dokument kategori har raderats",
      "AreYouSureYouWantToDeleteTimeType": "Är du säker på att du vill ta bort tidstyp?",
      "ShowTaskFirst": "Välj arbetsuppgift som ska hamna överst (om den finns på ett projekt)",
      "NoneSelected": "Ingen vald",
      "Active": "Aktiv",
      "VismaArticleID": "Visma-Artikel ID",
      "AddPriceAdjustment": "Lägg till prisjustering",
      "PriceAdjustment": "Prisjustering",
      "Discount": "Rabatt",
      "SurCharge": "Påslag",
      "PriceAdjsutmentCreated": "Prisjustering skapad",
      "AreYouSureYouWantToDeletePriceAdjustment": "Är du säker på att du vill ta bort prisjustering?",
      "PriceAdjustmentDeleted": "Prisjustering har raderats",
      "PriceAdjustmenteAlreadyDeleted": "Prisjustering har redan raderats",
      "PriceAdjustmentUpdated": "Prisjustering uppdaterad",
      "PriceAdjustments": "Prisjusteringar",
      "Units": "Enheter",
      "WorkingHours": "Arbetstid",
      "ArticleCategories": "Artikel kategorier",
      "AddArticleCategory": "Lägg till artikelkategori",
      "TasksCategories": "Arbetsuppgifts kategorier",
      "PriceAdjustmentCreated": "Prisjustering skapad",
      "AddressAndReference": "Adress och referens",
      "Reference": "Referens",
      "TaskNameRequired": "Arbetsuppgift måste vara ifyllt, vänligen fyll i arbetsuppgifts namn",
      "NameIsRequired": "Namn måste vara ifyllt, vänligen fyll i namn",
      "AbsenceTypeDeleteErr": "Det går inte att ta bort en frånvarotyp med rapporterad frånvaro.",
      "ReportedAttendance": "Rapporterad närvaro",
      "Started": "Påbörjade",
      "EndingSoon": "Snart slut",
      "MarkedAsDone+": "Klarmarkerade",
      "Current+": "Löpande",
      "VATamount": "Varav moms",
      "ShowAll": "Visa alla",
      "OngoingProjects": "Pågående projekt",
      "NumberOfUnhandledReports": "Antal ej hanterade rapporter",
      "SumUnhandledInvoiceDocumentation": "Summa ohanterade fakturaunderlag",
      "ReportedHours": "Rapporterade timmar",
      "UnhandledSalaryDetails": "Ohanterade löneunderlag",
      "January": "Januari",
      "February": "Februari",
      "March": "Mars",
      "April": "April",
      "May": "Maj",
      "June": "Juni",
      "July": "Juli",
      "August": "Augusti",
      "September": "September",
      "October": "Oktober",
      "Oct": "Okt",
      "November": "November",
      "December": "December",
      "UnitNameToLong": "Enhets namn är för långt, vänligen välj ett kortare namn",
      "NumberOfPercentDiscountOnOrdinaryPrice": "antal procent rabatt på ordinarie pris",
      "NumberOfPercentSurchargeOnOrdinaryPrice": "antal procent påslag på ordinarie pris",
      "CompanyLogo": "Företags logga",
      "PriceList": "Prislista",
      "None": "Ingen",
      "DeductionTypes": "Avdragstyper",
      "SendInviteAgain": "Skicka inbjudan igen",
      "ToEmail": "Till email",
      "ToPhoneNumber": "Till telefonnummer",
      "Send": "Skicka",
      "NotValidEmail": "Email är inte giltig",
      "SendAgain": "Skicka igen",
      "InviteSentTo": "Inbjudan skickad till",
      "DeductionTypeCreated": "Avdragstyp skapad",
      "Deduction": "Avdrag",
      "AddDeductionType": "Lägg till avdragstyp",
      "EditDeductionType": "Redigera avdragstyp",
      "DeductionTypeUpdated": "Avdragstyp uppdaterad",
      "AreYouSureYouWantToDeleteDeductionType": "Är du säker på att du vill ta bort avdragstyp?",
      "DeductionTypeDeleted": "Avdragstyp har raderats",
      "Offer": "Offert",
      "DefaultOfferHeadline": "Standard offert rubrik",
      "DefaultOfferFooter": "Standard offert sidfot",
      "Attendance": "Närvaro",
      "Exceeded": "Överskridna",
      "Not reported": "Ej rapporterat",
      "BackToInvoiceDetails": "Tillbaka till fakturaunderlag",
      "FortnoxBilling": "Fortnox Fakturering",
      "VismaSalary": "Visma Lön",
      "VismaAdmin": "Visma Administration",
      "VismaImport": "Visma Import",
      "VismaExport": "Visma Export",
      "ImportCustomersFromVisma": "Importera kunder från Visma",
      "ImportProjectFromVismaToProjectInMyGizmo": "Importera projekt från Visma till projekt i MyGizmo",
      "ImportOrdersFromVismaToProjectInMyGizmo": "Importera ordrar från Visma till projekt i MyGizmo",
      "ImportArticlesFromVisma": "Importera artiklar från Visma",
      "ImportFromImportFile": "Importera från importfil",
      "AnalyseImportFile": "Analysera importfil",
      "MyGizmoCustomersListForExportToVisma": "Lista på MyGizmo kunder för export till Visma",
      "MyGizmoArticlesListForExportToVisma": "Lista på MyGizmo artiklar för export till Visma",
      "MyGizmoInvoiceDetailsListForExportToVisma": "Lista på MyGizmo leverantörsfakturor för export till Visma",
      "View/Edit": "Visa / Redigera",
      "AnalyseExportFile": "Analysera exportfil",
      "ExportToExportFile": "Exportera till export fil",
      "NumberOfPostsInExportFileForExportToVisma": "Antal poster som finns i exportfil för export till Visma",
      "InvoiceProposalNumber": "Fakturaförslagsnummer",
      "VismaOrderNumber": "Visma ordernummer",
      "ChooseWhatToExportToVisma": "Välj vad som ska exporteras till Visma",
      "ExportCustomersToVisma": "Exportera kunder till Visma",
      "ExportProductsToVisma": "Exportera produkter till Visma",
      "ExportInvoiceProposalToVisma": "Exportera fakturaunderlag till Visma",
      "BreakInvoiceProposalOnDate": "Bryt fakturaförslagsrader på datum",
      "BreakInvoiceProposalOnAll": "Bryt på alla fakturaförslagsrader",
      "DisplayComment": "Visa kommentar",
      "MyGizmoInvoiceDetailsListForExportToVisma": "Lista på MyGizmo-fakturaförslagrader för export till Visma",
      "TheIntegrationIsNotActivated": "Integrationen är inte aktiverad",
      "ExportInProgress": "Export pågår",
      "ExportDone": "Export klar",
      "ImportCreatedAndChangedPosts": "Importera skapade och ändrade poster",
      "ChooseWhatToImport": "Välj vad som ska importeras",
      "CreateAnAssociatedReportingProject": "Skapa ett tillhörande rapporteringsprojekt",
      "PreviewAndEditExportFile": "Förhandsgranska och ändra export fil",
      "PreviewAndEditImportFile": "Förhandsgranska och ändra import fil",
      "ArticleGroupsInVisma": "Artikelgrupper i Visma",
      "ArticleType": "Artikeltyp",
      "ImportDone": "Import klar",
      "ImportInProgress": "Import pågår",
      "VismaIntegration": "Visma integration",
      "AreYouSureYouWantToDeleteArticleGroupFromList": "Är du säker på att du vill ta bort denna artikelgrupp ifrån listan?",
      "ArticleGroupDetails": "Artikelgrupp detaljer",
      "CompensationType": "Ersättningstyp",
      "ArticleGroupUpdated": "Artikelgrupp uppdaterad",
      "MyGizmoProjectsListForExportToVisma": "Lista på MyGizmo projekt för export till Visma",
      "MyGizmoOrdersListForExportToVisma": "Lista på MyGizmo ordrar för export till Visma",
      "AreYouSureYouWantToDeleteThisImportRecordFromTheList": "Är du säker på att du vill ta bort denna importpost ifrån listan?",
      "AreYouSureYouWantToDeleteThisExportRecordFromTheList": "Är du säker på att du vill ta bort denna exportpost ifrån listan?",
      "WeekShort": "v",
      "TheCustomerHasBeenImported": "Kunden har importerats",
      "TheProjectHasBeenImported": "Projektet har importerats",
      "TheArticleHasBeenImported": "Artikeln har importerats",
      "SequenceDeterminesInWhatOrderTimeTypesWillBeDisplayed": "Sekvens avgör i vilken ordning tidstyperna kommer visas",
      "SendInvite": "Skicka inbjudan",
      "OnlyPostedSupplierInvoiceCanBeImported": "endast bokförda levfakturor kan importeras",
      "StartProjectNumberSeriesAt": "Börja projektnummerserie på",
      "ProjectNumberSeriesSetsFromWhatProjectNumberProjectsStartFrom": "Projektnummerserie avgör vilket projektnummer skapade projekt börjar från",
      "SearchOrder": "Sök order",
      "SearchSupplierInvoice": "Sök leverantörsfaktura",
      "Number": "Nummer",
      "ProjectMap": "Projektkarta",
      "Import": "Importera",
      "VismaCustomerID": "Visma-Kund ID",
      "VismaOrderNR": "Visma-Order Nr",
      "OurRef": "Vår ref",
      "CustomerRef": "Kundens ref",
      "Invoiced": "Fakturerad",
      "AddArticleType": "Lägg till artikeltyp",
      "ArticleGroup": "Artikelgrupp",
      "VismaCustomersListForImportToMyGizmo": "Lista på Visma kunder för import till MyGizmo",
      "VismaArticleListForImportToMyGizmo": "Lista på Visma artiklar för import till MyGizmo",
      "VismaOrderListForImportToMyGizmo": "Lista på Visma ordrar för import till MyGizmo",
      "VismaProjectListForImportToMyGizmo": "Lista på Visma projekt för import till MyGizmo",
      "PickExistingTimeType": "Välj befintlig tidstyp",
      "TheTimeTypeHasBeenImported": "Tidstypen har importerats",
      "TimeTypeWithSameNameAlreadyExist": "Det finns redan en tidstyp med samma namn",
      "TheTaskHasBeenImported": "Arbetsuppgiften har importerats",
      "SearchTask": "Sök arbetsuppgift",
      "TheCompensationTypeHasBeenImported": "Ersättningstypen har importerats",
      "CompTypeWithTheSameNameAndUnitAlreadyExistAndHasBeenInactivated": "Det finns redan en ersättningstyp med det namnet och den enheten som inaktiverad.",
      "ArticleWithTheSameNameAndUnitAlreadyExistAndHasBeenInactivated": "Det finns redan en artikel med det namnet och den enheten som inaktiverad.",
      "TheOrderHasBeenImportedAsAProject": "Ordern har importerats som ett projekt",
      "AddArticleTypeToArticleGroup": "Lägg till artikeltyp till artikelgrupp",
      "BeforeImporting": "innan importering",
      "ImportAll": "Importera alla",
      "VismaProjectID": "Visma-Projekt ID",
      "PreviewAndImport": "Förhandsgranska och importera",
      "Orders": "Ordrar",
      "ImportEverything": "Importera allt",
      "FailedToImportPleaseImportTheseIndividually": "Misslyckade importer, vänligen importera dessa enskilt.",
      "CouldntImport": "Kunde inte importeras",
      "ExportAll": "Exportera allt",
      "PreviewAndExport": "Förhandsgranska och exportera",
      "RevokeSoftDeleteTimeType": "Det finns redan en tidstyp med det namnet (som är inaktiverad). Vill du återaktivera tidstypen?",
      "TimeTypeReactivated": "Tidstyp återaktiverad",
      "Directions": "Vägbeskrivning",
      "ContinueWithoutStartLoactionOrAllowAccessToLocationInBrowser": "Forsätt utan start position eller tillåt tillgång till din plats i webläsaren",
      "ExportAndPreviewExportFile": "Exportera och förhandsgranska exportfil",
      "PreviewExportFile": "Förhandsgranska exportfil",
      "InvoiceProposalDoesNotBreakOnDate": "Fakturaförslag bryts inte på datum",
      "InvoiceProposalBreaksOnDate": "Fakturaförslag bryt på datum",
      "InvoiceProposalDoesNotBreakOnAll": "Fakturaförslag bryts inte på alla transaktioner",
      "InvoiceProposalBreaksOnAll": "Fakturaförslag bryts på alla transaktioner",
      "ThisProductIsConnectedTo": "Den här produkten är kopplad till",
      "ThisTaskIsConnectedTo": "Den här arbetsuppgiften är kopplad till",
      "ByDeletingThisProductYouWillAlsoRemoveTheseConnections": "Genom att radera denna produkten kommer du också ta bort dessa kopplingar",
      "ByDeletingThisTaskYouWillAlsoRemoveTheseConnections": "Genom att radera denna arbetsuppgiften kommer du också ta bort dessa kopplingar",
      "LastExportWasMade": "Senaste export gjordes",
      "LastImportFileWasCreated": "Senaste importfil skapades",
      "Longitude": "Longitud",
      "Latitude": "Latitud",
      "EnterValidCoordinates": "Skriv in giltiga koordinater",
      "YouCanFindCoordinatesAt": "Du kan hitta koordinater på",
      "OnlyProjectsWithCoordinatesSetWillBeShownInMap": "Endast projekt med sparade koordinater i adressen kommer visas i kartan",
      "Code": "Kod",
      "LongitudeMustBeANumber": "Longitud måste vara ett nummer",
      "LatitudeMustBeANumber": "Latitud måste vara ett nummer",
      "NoResultsMatchedYourSearch": "Inga resultat matchade din sökning",
      "SearchForEmployee": "Sök efter anställd",
      "AllowAccessToYourLocationInBrowserIfYouWantStartPositionInDirections": "Tillåt åtkomst till din position i webläsaren om du vill ha din start position i vägbeskrivningen.",
      "SortEmployeesByName": "Sortera anställda efter namn",
      "SortEmployeesByEmployeeNumber": "Sortera anställda efter anställningsnummer",
      "ShowSortingOptions": "Visa sorteringsalternativ",
      "HideSortingOptions": "Dölj sorteringsalternativ",
      "SortUsersBy": "Sortera användare efter",
      "PickSorting": "Välj sortering",
      "Default": "Standard",
      "LatestCreatedUserFirst": "Senast skapade användare först",
      "LatestCreatedUserLast": "Senast skapade användare sist",
      "NameA-Ö": "Namn A till Ö",
      "NameÖ-A": "Namn Ö till A",
      "HighestEmploymentNumberFirst": "Högst anställningsnummer först",
      "HighestEmploymentNumberLast": "Högst anställningsnummer sist",
      "OnlyUncategorised": "Endast okategoriserade",
      "ShowProjectsLocationInMap": "Visa projektets position i kartan",
      "ShowProjectsLocationInMapOnOverviewPage": "Visa projektets position på kartan i översikten",
      "IncludeReportsAsSpecification": "Inkludera rapporter som specifikation",
      "PaymentPlanIsOnlyAvailableForProjectWithFixedPrice": "Lyftplan är bara tillgängligt för projekt med fastpris",
      "ChangeProjectToFixedPrice": "Ändra projekt till fastpris",
      "CountWithHandledDetails": "Räkna med hanterade underlag",
      "PaymentPlanSettings": "Lyftplan inställningar",
      "DefaultMaxTotalSafetyPercentage": "Standard max total säkerhetsprocent",
      "DefaultSafetyPercentage": "Standard säkerhetsprocent",
      "DefaultDaysBeforeDueDate": "Standard dagar före förfallodag",
      "FortnoxArticleNumber": "Fortnox artikel nr",
      "FortnoxVoucherSeries": "Fortnox verifikatserie",
      "FortnoxVoucherNumber": "Fortnox verifikat nr",
      "FortnoxNumber": "Fortnox nummer",
      "IncludedAsAppendixToInvoiceDetails": "Inkluderas som bilaga till fakturaunderlaget",
      "CantEditPaymentPlanElementBecauseItHasBeenAcceptedOnOffer": "Kan inte redigera lyft för att det har accepterats på offert",
      "CantEditPaymentPlanElementBecauseItHasBeenHandledOnInvoiceDetails": "Kan inte redigera lyft för att det har hanterats på fakturaunderlag",
      "andHandledOnInvoiceDetails": "och har hanterats på fakturaunderlag",
      "MaxSafetyAmountExceeded": "Max totalt säkerhetsbelopp överskridet",
      "ThesePaymentPlanElementsAreCloseToExceedingTheInvociceDate": "Följande lyft är påväg att passera faktura datum",
      "OnlyCountWithApporovedReports": "Räkna endast med godkända rapporter",
      "MobileNumber": "Mobilnummer",
      "UpdateUserInformation": "Uppdatera användaruppgifter",
      "WatchTutorial": "Se instruktionsfilm",
      "ContactUs": "Kontakta oss",
      "WhenChangingStatusToCreatedTheLinkWillAlsoBeInactivated": "När man ändrar status till skapad kommer även länken att inaktiveras",
      "SaveProjectNumberSeries": "Spara projektnummerserie",
      "selectedSupplierInvoices": "markerade leverantörsfakturor",
      "DaysBeforePaymentPlanDueDateWarning": "Dagar före lyftplan förfallodatum varning",
      "Vat": "Moms",
      "InactivateProject": "Inaktivera projekt",
      "UsePaymentPlans": "Använd lyftplaner",
      "SafetyAmountPerPaymentPlanElement": "Säkerhetsbelopp per lyft",
      "MaxTotalSafetyAmount": "Max totalt säkerhetsbelopp",
      "UpdateSafetyAmountPerPaymentPlanElement": "Uppdatera säkerhetsbelopp per lyft",
      "DoYouAlsoWantToChangeTheSecurityAmountOnYourHandledPaymentPlanElements": "Vill du även ändra säkerhetsbeloppen dina hanterade lyft?",
      "DefaultPercentageForSafetyAmountPerPaymentPlanElement": "Standard procent för säkerhetsbelopp per lyft",
      "DefaultPercentageForMaxTotalSafetyAmount": "Standard procent för max totalt säkerhetsbelopp",
      "OfferRows": "Offertrader",
      "Specification": "Specifikation",
      "ThereAreUnattestedReportsThatBelongToThePaymentPlanElementsPleaseApproveTheReportsBeforeContinuing": "Det finns ej attesterade rapporter som tillhör lyftplanen, vänligen godkänn rapporterna innan du fortsätter.",
      "ReportedQuantity": "Rapporterad antal",
      "AddArticleRow": "Lägg till artikelrad",
      "QuantityToInvoiceIsBasedOnTheQuantityEnteredOnTheArticle": "Antal till faktura baseras på antalet som angetts på artikeln",
      "QuantityToInvoiceIsBasedOnReportedQuantity": "Antal till faktura baseras på rapporterat antal",
      "ArticleRows": "Artikelrader",
      "EditArticleRow": "Redigera artikelrad",
      "PartialInvoice": "Delfakturera",
      "TheProjectTypeIsLockedDueToTheProjectHavingHandledInvoices": "Projekttyp är låst på grund av att projektet har hanterade fakturaunderlag",
      "CantInvoiceMoreThanTheTotalQuantityOnTheArticle": "Kan inte fakturera mer än det totala antalet som satts på artikeln",
      "TheArticleRowHasBeenDeleted": "Artikelraden har raderats",
      "CantDeleteArticleRowWithHandledInvoices": "Kan inte radera artikelrader med hanterade fakturaunderlag",
      "CantDeleteArticleRowWithReports": "Kan inte radera artikelrader som har rapporter",
      "TheInvoiceDetailsIsBasedOnTheQuantityEnteredOnTheArticleUnderSizeInProject": "Fakturaunderlaget baseras på antalet som angetts på artikelraden",
      "TheInvoiceDetailsIsBasedOnTheReports": "Fakturaunderlaget baseras på rapporterna",
      "TheArticlesPrice": "Artikelns pris enligt artikelregister",
      "CustomerIsRequired": "Kund är obligatoriskt, vänligen välj en kund",
      "ProjectNameIsRequired": "Projektnamn är obligatoriskt, vänligen fyll i ett projektnamn",
      "AddressIsRequired": "Adress är obligatoriskt, vänligen fyll i en adress",
      "TheSupplierInvoiceHasBeenRemovedFromThisProject": "Leverantörsfakturan har tagits bort från detta projekt",
      "RemainingQuantity": "Kvarvarande antal",
      "OrderedQuantity": "Beställt antal",
      "CantInvoiceMoreThanTheRemainingQuantityOnTheArticleRow": "Kan inte fakturera mer än det kvarvarande antalet på artikelraden",
      "Handled2": "Hanterat",
      "HandledProjectVariations": "Hanterade ÄTOR",
      "Remaining": "Kvarvarande",
      "UnhandledArticleRows": "Ohanterade artikelrader",
      "HandledArticleRows": "Hanterade artikelrader",
      "UnhandledProjectVariations": "Ohanterade ÄTOR",
      "TheNoteHasBeenDeleted": "Anteckningen har raderats",
      "ProjectVariationsWorks": "ÄTA arbeten",
      "ProjectBalance": "Projektbalans",
      "Unhandled2": "Ohanterat",
      "UnhandledQuantity": "Ohanterat antal",
      "LeftToAssign2": "Kvar att fördela",
      "Exceeded2": "Överskridit",
      "CantInvoiceMoreThanTheUnhandledQuantityOnTheArticleRow": "Kan inte fakturera mer än det ohanterade antalet på artikelraden",
      "Veichles": "Fordon",
      "TaskCategory": "Arbetsuppgift kategori",
      "Compensation": "Ersättning",
      "FixedPriceWithArticleRows": "Fastpris med artikelrader",
      "DownloadExcelFile": "Ladda ner Excel fil",
      "ShowComments": "Visa kommentarer",
      "DateInterval": "Datum intervall",
      "WeekInterval": "Veckointervall",
      "CustomInterval": "Anpassat intervall",
      "TimeIsBeforePredefinedWorkday": "Tiden är före fördefinerad arbetstid",
      "TimeIsAfterPredefinedWorkday": "Tiden är efter fördefinerad arbetstid",
      "PriceAdjustmentsAreMissingValues": "Prisjusteringar saknar värden",
      "WorkingTimeInSelectedInterval": "Arbetstid i valt intervall",
      "PriceAdjustmentsBasedOnArticlePrices": "Prisjusteringar baserat på artikel priser",
      "PriceAccordingToTimeType": "Pris enligt tidstyp",
      "InvoiceRows": "Fakturarader",
      "SendConfirmationEmailUponCustomerSignature": "Skicka bekräftelsemail vid kundsignatur till",
      "WhenAttestingReportsThisEmailWillBeAutoFilledAsConfirmationEmail": "När man attesterar rapporter kommer denna e-post fyllas i automatiskt som mottagare för bekräftelsemail.",
      "ConfirmationEmail": "Bekräftelsemail",
      "OnlyShowTheProjectForSelectedEmployees": "Visa endast projektet för valda anställda",
      "ProjectVisibility": "Projekt synlighet",
      "CantDeleteSupplierInvoiceWithHandledInvoices": "Kan inte radera leverantörsfakturor med hanterade fakturaunderlag",
      "ReportPeriod": "Rapport period",
      "ProjectAccess": "Projekt åtkomst",
      "ProjectAccesses": "Projekt åtkomster",
      "UseProjectAccessSettings": "Använd projekt åtkomst inställningar",
      "HandledReportsWontBeDeleted": "Rapporter som tillhör hanterade fakturaunderlag eller löneunderlag kommer inte att raderas.",
      "YouHaveOnlySelectedHandledReports": "Du har bara valt rapporter som tillhör fakturaunderlag eller löneunderlag, dessa rapporter kan inte raderas",
      "SignedBy": "Signeras av",
      "Admins": "Administratörer",
      "ProjectLeader2": "Projektledare",
      "PleaseSelectAProjectLeaderFirst": "Vänligen välj en projekt ledare först",
      "PleaseSelectAResponseeFirst": "Vänligen välj en ansvarig först",
      "EditForm": "Redigera formulär",
      "EditAnswers": "Redigera svar",
      "Anyone": "Vem som helst",
      "FilledInBy": "Ifylles av",
      "Update": "Uppdatering",
      "PleaseWaitWhileTheUpdateIsInProgress": "Vänligen vänta medan uppdateringen pågår...",
      "OnceTheUpdateIsCompleteYouNeedToDoTheFollowing": "När uppdatering är klar behöver du göra följande:",
      "UninstallExistingMyGizmoApp": "Avinstallera befintlig MyGizmo-app.",
      "OpenGoogleChromeSafariAndGoTo": "Öppna Google Chrome/Safari och gå till",
      "andLogin": "och logga in",
      "InstallMyGizmoApp": "Installera MyGizmo-app igen genom att:",
      "ClickOn": "Klicka på",
      "TheBoxWithTheArrow": "lådan med pilen",
      "InTheMenuAndChooseAddToHomeScreen": "i menyn i Safari och välj lägg till på hemskärmen",
      "ThreeDots": "tre prickar",
      "AtTheTopOrAtTheBottomRightAndSelect": "högst upp eller längst ner till höger och välj",
      "InstallApp": "installera app",
      "Install": "installera",
      "And": "och",
      "CanAlsoSay": "Kan också stå",
      "AddToStartScreen": "lägg till på startskärm",
      "TheUpdateIsCompleted": "Uppdateringen är klar",
    }
  },
  en: {
    translation: {
      "TheUpdateIsCompleted": "The update is completed",
      "AddToStartScreen": "add to start screen",
      "CanAlsoSay": "Can also say",
      "And": "and",
      "InstallApp": "install app",
      "Install": "install",
      "AtTheTopOrAtTheBottomRightAndSelect": "at the top or at the bottom right and select",
      "ThreeDots": "three dots",
      "InTheMenuAndChooseAddToHomeScreen": "in the Safari menu and then choose add to homescreen",
      "ClickOn": "Click on",
      "TheBoxWithTheArrow": "the box with the arrow",
      "InstallMyGizmoApp": "Install MyGizmo-app again by:",
      "OpenGoogleChromeSafariAndGoTo": "Open Google Chrome/Safari and go to",
      "andLogin": "and login",
      "UninstallExistingMyGizmoApp": "Uninstall existing MyGizmo-app.",
      "OnceTheUpdateIsCompleteYouNeedToDoTheFollowing": "Once the update is complete, you need to do the following:",
      "PleaseWaitWhileTheUpdateIsInProgress": "Please wait while the update is in progress...",
      "Update": "Update",
      "FilledInBy": "Filled in by",
      "Anyone": "Anyone",
      "EditAnswers": "Edit answers",
      "EditForm": "Edit form",
      "PleaseSelectAResponseeFirst": "Please select a responsee first",
      "PleaseSelectAProjectLeaderFirst": "Please select a project leader first",
      "ProjectLeader2": "Project leader",
      "Admins": "Admins",
      "SignedBy": "Signed by",
      "YouHaveOnlySelectedHandledReports": "You have only selcted reports that belong to invoice details or salary details, these reports cant be deleted",
      "HandledReportsWontBeDeleted": "Reports that belong to handled invoice details or salary details won't be deleted.",
      "UseProjectAccessSettings": "Use project access settings",
      "ProjectAccesses": "Project accesses",
      "ProjectAccess": "Project access",
      "ProjectVisibility": "Project visibility",
      "OnlyShowTheProjectForSelectedEmployees": "Only show the project for selected employees",
      "CantDeleteSupplierInvoiceWithHandledInvoices": "Cant delete supplier invoice with handled invoices",
      "InvoiceRows": "Invoice rows",
      "PriceAdjustmentsAreMissingValues": "Price adjustments are missing values",
      "TimeIsBeforePredefinedWorkday": "Time is before predefined workday",
      "CustomInterval": "Custom interval",
      "WeekInterval": "Week interval",
      "DateInterval": "Date interval",
      "DownloadExcelFile": "Download Excel file",
      "ShowComments": "Show comments",
      "FixedPriceWithArticleRows": "Fixed price with article rows",
      "Compensation": "Compensation",
      "TaskCategory": "Task category",
      "Veichles": "Vehicles",
      "CantInvoiceMoreThanTheUnhandledQuantityOnTheArticleRow": "Cant invoice more than the unhandled quantity on the article row",
      "Exceeded2": "Exceeded",
      "LeftToAssign2": "Left to assign",
      "UnhandledQuantity": "Unhandled quantity",
      "Unhandled2": "Unhandled",
      "ProjectBalance": "Project balance",
      "ProjectVariationsWorks": "Project variations",
      "TheNoteHasBeenDeleted": "The note has been deleted",
      "UnhandledProjectVariations": "Unhandled project variations",
      "HandledArticleRows": "Handled article rows",
      "UnhandledArticleRows": "Unhandled article rows",
      "Remaining": "Remaining",
      "HandledProjectVariations": "Handled project variations",
      "Handled2": "Handled",
      "CantInvoiceMoreThanTheRemainingQuantityOnTheArticleRow": "Cant invoice more than the remaining quantity on the article row",
      "RemainingQuantity": "Remaining quantity",
      "OrderedQuantity": "Ordered quantity",
      "TheSupplierInvoiceHasBeenRemovedFromThisProject": "The supplier invoice has been removed from this project",
      "AddressIsRequired": "Address is required, please enter a address",
      "ProjectNameIsRequired": "Project name is required, please enter a project name",
      "CustomerIsRequired": "Customer is required, please select a customer",
      "PleaseSelectACustomer": "Vänligen välj en kund",
      "TheArticlesPrice": "Price according to article registry",
      "TheInvoiceDetailsIsBasedOnTheQuantityEnteredOnTheArticleUnderSizeInProject": "The invoice details is based on the quantity entered on the article under “size” in project",
      "TheInvoiceDetailsIsBasedOnTheReports": "The invoice details is based on the reports",
      "CantDeleteArticleRowWithReports": "Cant delete article row with reports",
      "CantDeleteArticleRowWithHandledInvoices": "Cant delete article row with handled invoices",
      "TheArticleRowHasBeenDeleted": "The article row has been removed",
      "CantInvoiceMoreThanTheTotalQuantityOnTheArticle": "Cant invoice more than the total quantity on the article",
      "TheProjectTypeIsLockedDueToTheProjectHavingHandledInvoices": "The project type is locked due to the project having handled invoices",
      "PartialInvoice": "Partial invocie",
      "EditArticleRow": "Edit article row",
      "ArticleRows": "Article rows",
      "QuantityToInvoiceIsBasedOnReportedQuantity": "Quantity to invoice is based on the reported quantity",
      "QuantityToInvoiceIsBasedOnTheQuantityEnteredOnTheArticle":  "Quantity to invoice is based on the quantity entered on the article",
      "AddArticleRow": "Add article row",
      "ReportedQuantity": "Reported quantity",
      "ThereAreUnattestedReportsThatBelongToThePaymentPlanElementsPleaseApproveTheReportsBeforeContinuing": "There are unattested reports that belong to the payment plan elements, please approve the reports before continuing.",
      "Specification": "Specification",
      "OfferRows": "Offer rows",
      "DefaultPercentageForSafetyAmountPerPaymentPlanElement": "Default percentage for safety amount per payment plan element",
      "DefaultPercentageForMaxTotalSafetyAmount": "Default percentage for max total safety amount",
      "DoYouAlsoWantToChangeTheSecurityAmountOnYourHandledPaymentPlanElements": "Do you also want to change the security amount on your handled payment plan elements?",
      "UpdateSafetyAmountPerPaymentPlanElement": "Update safety amount per payment plan element",
      "MaxTotalSafetyAmount": "Max total safety amount",
      "SafetyAmountPerPaymentPlanElement": "Safety amount per payment plan element",
      "UsePaymentPlans": "Use payment plans",
      "InactivateProject": "Inactivate project",
      "Vat": "Vat",
      "DaysBeforePaymentPlanDueDateWarning": "Days before payment plan due date warning",
      "ThesePaymentPlanElementsAreCloseToExceedingTheInvociceDate": "These payment plan elements are close to exceeding the invocice date",
      "MaxSafetyAmountExceeded": "Max safety amount exceeded",
      "andHandledOnInvoiceDetails": "and handled on invoice details",
      "CantEditPaymentPlanElementBecauseItHasBeenHandledOnInvoiceDetails": "Cant edit payment plan element because it has been handled on invoice details",
      "CantEditPaymentPlanElementBecauseItHasBeenAcceptedOnOffer": "Cant edit payment plan element because it has been accepted on offer",
      "DefaultDaysBeforeDueDate": "Default days before due date",
      "DefaultSafetyPercentage": "Default safety percentage",
      "DefaultnMaxTotalSafetyPercentage": "Default max total safety percentage",
      "PaymentPlanSettings": "Payment plan settings",
      "CountWithHandledDetails": "Count with handled details",
      "ChangeProjectToFixedPrice": "Change project to fixed price",
      "PaymentPlanIsOnlyAvailableForProjectWithFixedPrice": "Payment plan is only available for project with fixed price",
      "IncludeReportsAsSpecification": "Include reports as specification",
      "OnlyCountWithApporovedReports": "Only count with apporoved reports",
      "selectedSupplierInvoices": "selected supplier invoices",
      "SaveProjectNumberSeries": "Save project number series",
      "ContactUs": "Contact us",
      "WatchTutorial": "Watch tutorial",
      "WhenChangingStatusToCreatedTheLinkWillAlsoBeInactivated": "When changing status to created the link will also be inactivated",
      "UpdateUserInformation": "Update user information",
      "MobileNumber": "Mobile number",
      "IncludedAsAppendixToInvoiceDetails": "Included as appendix to invoice details",
      "FortnoxNumber": "Fortnox number",
      "FortnoxVoucherSeries": "Fortnox voucher series",
      "FortnoxVoucherNumber": "Fortnox voucher number",
      "FortnoxArticleNumber": "Fortnox article nr",
      "OnlyUncategorised": "Only uncategorised",
      "SearchForEmployee": "Search for employee",
      "NoResultsMatchedYourSearch": "No results matched your search",
      "ShowProjectsLocationInMapOnOverviewPage": "Show projects location in map on overview page",
      "ShowProjectsLocationInMap": "Show projects location in map",
      "HighestEmploymentNumberLast": "Highest employment number last",
      "HighestEmploymentNumberFirst": "Highest employment number first",
      "NameÖ-A": "Name Z to A",
      "NameA-Ö": "Name A to Z",
      "LatestCreatedUserLast": "Latest created user last",
      "LatestCreatedUserFirst": "Latest created user first",
      "Default": "Default",
      "PickSorting": "Pick sorting",
      "SortUsersBy": "Sort users by",
      "HideSortingOptions": "Hide sorting options",
      "ShowSortingOptions": "Show sorting options",
      "SortEmployeesByEmployeeNumber": "Sort employees by employee number",
      "SortEmployeesByName": "Sort employees by name",
      "AllowAccessToYourLocationInBrowserIfYouWantStartPositionInDirections": "Allow access to your location in browser if you want start position in directions.",
      "Code": "Code",
      "LatitudeMustBeANumber": "Latitude must be a number",
      "LongitudeMustBeANumber": "Longitude must be a number",
      "YouCanFindCoordinatesAt": "You can find coordinates at",
      "EnterValidCoordinates": "Enter valid coordinates",
      "Longitude": "Longitude",
      "Latitude": "Latitude",
      "OnlyProjectsWithCoordinatesSetWillBeShownInMap": "Only projects with saved coordinates in the adress will be shown in map",
      "LastImportFileWasCreated": "Last import file was created",
      "LastExportWasMade": "Last export was made",
      "InvoiceProposalBreaksOnDate": "Invoice proposal breaks on date",
      "InvoiceProposalDoesNotBreakOnDate": "Invoice proposal does not break on date",
      "InvoiceProposalBreaksOnAll": "Invoice proposal breaks on all transactions",
      "InvoiceProposalDoesNotBreakOnAll": "Invoice proposal does not break on all transactions",
      "PreviewExportFile": "Preview export file",
      "ExportAndPreviewExportFile": "Export and preview export file",
      "ContinueWithoutStartLoactionOrAllowAccessToLocationInBrowser": "Continue without start position or allow access to your location in browser",
      "TimeTypeReactivated": "Time type reactivated successfully",
      "RevokeSoftDeleteTimeType": "A time type with the same name already exsist, (which is inactivated). Do you want to reactivate the time type",
      "Directions": "Directions",
      "PreviewAndExport": "Preview and export",
      "ExportAll": "Export all",
      "CouldntImport": "Couldn´t import",
      "FailedToImportPleaseImportTheseIndividually": "Failed imports, please import these individually.",
      "ImportEverything": "Import all",
      "Orders": "Orders",
      "PreviewAndImport": "Preview and import",
      "VismaProjectID": "Visma-Project ID",
      "ImportAll": "Import all",
      "BeforeImporting": "before importing",
      "AddArticleTypeToArticleGroup": "Add article type to article group",
      "TheOrderHasBeenImportedAsAProject": "The Order has been importes as a project",
      "ArticleWithTheSameNameAndUnitAlreadyExistAndHasBeenInactivated": "Article with the same name and unit already exist and has been inactivated.",
      "CompTypeWithTheSameNameAndUnitAlreadyExistAndHasBeenInactivated": "Compensation type with the same name and unit already exist and has been inactivated.",
      "TheCompensationTypeHasBeenImported": "The compensation type has been imported",
      "ProjectMap": "Project map",
      "SearchTask": "Searach task",
      "TheTaskHasBeenImported": "The task has been imported",
      "TimeTypeWithSameNameAlreadyExist": "Time type with same name already exist",
      "TheTimeTypeHasBeenImported": "The time type has been imported",
      "PickExistingTimeType": "Pick existing time type",
      "VismaProjectListForImportToMyGizmo": "Visma projects list for import to MyGizmo",
      "VismaOrderListForImportToMyGizmo": "Visma orders list for import to MyGizmo",
      "VismaArticleListForImportToMyGizmo": "Visma articles list for import to MyGizmo",
      "VismaCustomersListForImportToMyGizmo": "Visma customers list for import to MyGizmo",
      "ArticleGroup": "Article group",
      "AddArticleType": "Add article type",
      "Invoiced": "Invoiced",
      "CustomerRef": "Customer ref",
      "OurRef": "Our ref",
      "VismaOrderNR": "Visma-Order Nr",
      "VismaCustomerID": "Visma-Customer ID",
      "Import": "Import",
      "Number": "Number",
      "TheArticleHasBeenImported": "The article has been imported",
      "TheProjectHasBeenImported": "The project has been imported",
      "TheCustomerHasBeenImported": "The customer has been imported",
      "AreYouSureYouWantToDeleteThisImportRecordFromTheList": "Are you sure you want to delete this import post from the list?",
      "AreYouSureYouWantToDeleteThisExportRecordFromTheList": "Are you sure you want to delete this export post from the list?",
      "MyGizmoOrdersListForExportToVisma": "Mygizmo orders list for export to Visma",
      "MyGizmoProjectsListForExportToVisma": "Mygizmo projects list for export to Visma",
      "ArticleGroupUpdated": "Article group updated",
      "CompensationType": "Compensation type",
      "ArticleGroupDetails": "Article group details",
      "AreYouSureYouWantToDeleteArticleGroupFromList": "Are you sure you want to delete article group from list?",
      "VismaIntegration": "Visma integration",
      "ImportInProgress": "Import in progress",
      "ImportDone": "Import done",
      "ArticleType": "Article type",
      "ArticleGroupsInVisma": "Article groups in Visma",
      "PreviewAndEditImportFile": "Preview and edit import file",
      "PreviewAndEditExportFile": "Preview and edit export file",
      "CreateAnAssociatedReportingProject": "Create an associated reporting project",
      "ChooseWhatToImport": "Choose what to import",
      "ImportCreatedAndChangedPosts": "Import created and changed posts",
      "ExportDone": "Export done",
      "ExportInProgress": "Export in progress",
      "TheIntegrationIsNotActivated": "The integration is not activated",
      "MyGizmoInvoiceDetailsListForExportToVisma": "Mygizmo invoice details list for export to Visma",
      "BreakInvoiceProposalOnDate": "Break invoice proposal rows on date",
      "BreakInvoiceProposalOnAll": "Break invoice proposal on all rows",
      "DisplayComment": "Display comment",
      "ExportInvoiceProposalToVisma": "Export invoice proposal to Visma",
      "ExportProductsToVisma": "Export products to Visma",
      "ExportCustomersToVisma": "Export customers to Visma",
      "ChooseWhatToExportToVisma": "Choose what to export to Visma",
      "VismaOrderNumber": "Visma order number",
      "InvoiceProposalNumber": "Invoice proposal number",
      "NumberOfPostsInExportFileForExportToVisma": "Number of posts in export file for export to visma",
      "ExportToExportFile": "Export to export file",
      "AnalyseExportFile": "Analyse export file",
      "View/Edit": "View / Edit",
      "MyGizmoCustomersListForExportToVisma": "MyGizmo customers list for export to Visma",
      "MyGizmoArticlesListForExportToVisma": "MyGizmo articles list for export to Visma",
      "MyGizmoInvoiceDetailsListForExportToVisma": "MyGizmo invoice details list for export to Visma",
      "AnalyseImportFile": "Analyse import file",
      "ImportFromImportFile": "Import from import file",
      "ImportArticlesFromVisma": "Import articles from Visma",
      "ImportOrdersFromVismaToProjectInMyGizmo": "ImportOrdersFromVismaToProjectInMyGizmo",
      "ImportProjectFromVismaToProjectInMyGizmo": "Import project from Visma to project in MyGizmo",
      "ImportCustomersFromVisma": "Import customers from Visma",
      "VismaExport": "Visma Export",
      "VismaImport": "Visma Import",
      "VismaAdmin": "Visma Administration",
      "VismaSalary": "Visma Salary",
      "FortnoxBilling": "Fortnox Billing",
      "SearchSupplierInvoice": "Search supplier invoice",
      "SearchOrder": "Search order",
      "ProjectNumberSeriesSetsFromWhatProjectNumberProjectsStartFrom": "Project number series sets from what project number created projects starts from",
      "StartProjectNumberSeriesAt": "Start project number series at",
      "DefaultOfferFooter": "Default offer footer",
      "DefaultOfferHeadline": "Default offer headline",
      "Offer": "Offer",
      "DeductionTypeDeleted": "Deduction type has been removed successfully",
      "AreYouSureYouWantToDeleteDeductionType": "Are you sure you want to delete deduction type?",
      "DeductionTypeUpdated": "Deduction type updated successfully",
      "EditDeductionType": "Edit deduction type",
      "AddDeductionType": "Add deduction type",
      "Deduction": "Deduction",
      "DeductionTypeCreated": "Deductiontype created",
      "DeductionTypes": "Deduction types",
      "Not reported": "Not reported",
      "Exceeded": "Exceeded",
      "Attendance": "Attendance",
      "January": "January",
      "February": "February",
      "March": "March",
      "April": "April",
      "May": "May",
      "June": "June",
      "July": "July",
      "August": "August",
      "September": "September",
      "October": "October",
      "Oct": "Oct",
      "November": "November",
      "December": "December",
      "UnhandledSalaryDetails": "Unhandled salary details",
      "ReportedHoursThis": "Reported hours this",
      "SumUnhandledInvoiceDocumentation": "Sum of unhandled invoice documentation",
      "NumberOfUnhandledReports": "Number of unhandled reports",
      "OngoingProjects": "Ongoing projects",
      "ShowAll": "Show all",
      "Current+": "Current",
      "MarkedAsDone+": "Marked as done",
      "EndingSoon": "Ending soon",
      "Started": "Started",
      "ReportedAttendance": "Reported attendance",
      "OnlyPostedSupplierInvoiceCanBeImported": "only posted supplier invoice can be imported",
      "SendInvite": "Send invite",
      "SequenceDeterminesInWhatOrderTimeTypesWillBeDisplayed": "Sequence determines in what order time types will be displayed",
      "WeekShort": "w",
      "BackToInvoiceDetails": "Back to invoice details",
      "InviteSentTo": "Invite sent to",
      "SendAgain": "Send again",
      "NotValidEmail": "Not a valid email",
      "PleaseEnterAValidEmail": "Vänligen fyll i en korrekt email adress",
      "Send": "Send",
      "ToPhoneNumber": "To phone number",
      "ToEmail": "To email",
      "SendInviteAgain": "Send invite again",
      "None": "None",
      "PriceList": "Price list",
      "CompanyLogo": "Company logo",
      "NumberOfPercentDiscountOnOrdinaryPrice": "number of percent discount on ordinary price",
      "NumberOfPercentSurchargeOnOrdinaryPrice": "number of percent surcharge on ordinary price",
      "UnitNameToLong": "Unit name to long, please choose a shorter name",
      "PriceAdjustmentCreated": "Price adjustment created successfully",
      "TasksCategories": "Task categories",
      "AddArticleCategory": "Add article category",
      "ArticleCategories": "Article categories",
      "WorkingHours": "Working hours",
      "Units": "Units",
      "PriceAdjustments": "Price adjustments",
      "PriceAdjustmentUpdated": "Price adjustment updated successfully",
      "PriceAdjustmenteAlreadyDeleted": "Price adjustment has already been removed",
      "PriceAdjustmentDeleted": "Price adjustment had been removed successfully",
      "AreYouSureYouWantToDeletePriceAdjustment": "Are you sure you want to delete price adjsutment?",
      "PriceAdjsutmentCreated": "Price adjsutment created successfully",
      "SurCharge": "Surcharge",
      "Discount": "Discount",
      "PriceAdjustment": "Price adjustment",
      "AddPriceAdjustment": "Add price adjustment",
      "VismaArticleID": "Visma-Article ID",
      "Active": "Active",
      "NoneSelected": "None selected",
      "ShowTaskFirst": "Choose task to end up at the top (if it exist on a project)",
      "AreYouSureYouWantToDeleteTimeType": "Are you sure you want to delete time type?",
      "DocumentCategoryDeleted": "Document category removed successfully",
      "DocumentCategoryUpdated": "Document category updated successfully",
      "TimeTypeRemoved": "Time type removed successfully",
      "EditTimeType": "Edit time type",
      "TimeTypeUpdated": "Time type updated successfully",
      "EditTaskCategory": "Edit task category",
      "CompanyLogoUpdated": "Company logo updated successfully",
      "NumberOfPriceAdjustments": "Number of price adjustments",
      "DocumentCategories": "Document categories",
      "AddFormTemplate": "Add form template",
      "EmployeesAllowedToSeePriceOnArticles": "Employees allowed to see price on articles",
      "UnlimitedTime": "Unlimited time",
      "SameWeek": "Same week",
      "SameDate": "Same date",
      "SameAndAdjacentDates": "Same and adjacent dates",
      "EmployeesAreAllowedToEditReportsDuring": "Employees are allowed to edit reports during",
      "AddProductCategory": "Add product category",
      "AddUnitCategory": "Add unit category",
      "AddTaskCategory": "Add task category",
      "ProductCategories": "Product categories",
      "UnitCategories": "Unit categories",
      "TimeTypes": "Time types",
      "UploadCompanyLogo": "Upload company logo",
      "ResetSequence": "Reset sequence",
      "StartSeriesAt": "Custom project number series, start series from",
      "ShowTaskDescriptionInDocumentation": "Show task description in the documentation",
      "UseAttest": "Use certification",
      "AddDocumentCategory": "Add document category",
      "DocumentCategoryCreated": "Document category created successfully",
      "AreYouSureYouWantToDeleteTimeType": "Are you sure you want to delete time type?",
      "TimeTypeDeleted": "Time type deleted successfully",
      "TimeTypeUpdated": "Time type updated successfully",
      "BackToSettings": "Back to settings",
      "AddTimeType": "Add time type",
      "TimeTypeCreated": "Time type created",
      "TimeTypeNameAlreadyExists": "Time type with same name already exist",
      "Sequence": "Sequence",
      "TimeCodeSalary": "Time code salary",
      "ShortName": "Short name",
      "SettingsSaved": "Settings saved successfully",
      "PriceLists": "Price lists",
      "FormTempaltes": "Form templates",
      "DeleteLibraryTextBlock": "Delete text block from library",
      "MainSettings": "Main settings",
      "TaskNameRequired": "Task is required, please enter at task name",
      "NameIsRequired": "Name is required, please enter a name",
      "TaskNameRequired": "Task is required, please enter a task name",
      "Reference": "Reference",
      "AddressAndReference": "Address and reference",
      "InviteSentTo": "Invite sent to",
      "UserCreated": "User created",
      "EmployeeNumberTaken": "Employee number already in use",
      "EmailRequired": "Please enter email",
      "LastNameRequired": "Please enter lastname",
      "FirstNameRequired": "Please enter firstname",
      "Activate": "Activate",
      "CreateUser": "Create user",
      "Users+": "Users",
      "Activated": "Activated",
      "InviteSent": "Invite sent",
      "AccountType": "Account type",
      "BackToUserRegistry": "Back to user registry",
      "UserActivated": "User activated",
      "SearchUser": "Search user",
      "UserRegistry": "User registry",
      "UserInactivated": "User inactivated",
      "File+": "File",
      "EditDailyReports": "Edit daily reports",
      "EmploymentNumber": "Employment number",
      "NoEmailEntered": "Email not entered",
      "NoPhoneNumberEntered": "Phone number not entered",
      "FinalInvoice": "Final invoice",
      "ProjectMarkedAsInactive": "Project has been marked as inactive",
      "MarkAsHandledAndInactivateProject": "Mark as handled and inactivate project",
      "PasswordToShort": "Password to short",
      "ChoosePicture": "Choose picture",
      "UploadProfileImage": "Upload profile picture",
      "UploadImage": "Upload image",
      "ProfilePictureUpdated": "Profile picture updated",
      "UserDetailsUpdated": "User details updated",
      "EnterFirstName": "Enter first name",
      "EnterLastName": "Enter last name",
      "UserDetails": "User details",
      "PasswordsDoNotMatch": "Passwords do not match",
      "PasswordUpdated": "Password updated successfully",
      "ChangePassword": "Change password",
      "EmailUpdated": "Email updated successfully",
      "RepeatPassword": "Repeat password",
      "EmailTaken": "Email already in use",
      "NotAdded": "Not added",
      "PersonalData": "Personal data",
      "UserInformation": "User information",
      "Username": "Username",
      "Email+": "Email",
      "Password": "Password",
      "Logout": "Logout",
      "ArticleReactivated": "Article reactivated successfully",
      "RevokeSoftDeleteTask": "A task with the same name already exsist, (which is inactivated). Do you want to reactivate the task?",
      "RevokeSoftDeleteProduct": "A product with the same name and unit already exsist, (which is inactivated). Do you want to reactivate the product?",
      "h": "h",
      "AreYouSureYouWantToDeleteCategory": "Are you sure you want to delete category?",
      "AreYouSureYouWantToDeletUnit": "Are you sure you want to delete unit?",
      "AreYouSureYouWantToDeletTask": "Are you sure you want to delete task?",
      "AreYouSureYouWantToDeleteArticle": "Are you sure you want to delete article?",
      "TimeReport": "Time report",
      "C": "C",
      "H": "h",
      "CantDeleteUnitOnRegisteredProduct": "Cant delete unit that are registered on a product",
      "UnitDeleted": "Unit removed successfully",
      "CantDeleteCategoryOnRegisteredProduct": "Cant delete category that are registered on a product",
      "CategoryDeleted": "Category removed successfully",
      "CantDeleteCategoryOnRegisteredTask": "Cant delete category that are registered on a task",
      "TaskCategories": "Task categories",
      "ProductCategories": "Product categories",
      "TaskTypeAlreadyExist": "Task category with same name already exist",
      "TaskTypeUpdatedSuccessfully": "Task category updated successfully",
      "TaskCategoryDescription": "Task category description",
      "TaskCategoryName": "Task category name",
      "Back": "Back",
      "TaskNameAlreadyExists": "Task with same name already exist",
      "TaskUpdatedSuccessfully": "Task updated successfully",
      "Total": "Total",
      "TaskDeletedSuccessfully": "Task deleted successfully",
      "NoDescriptionEntered": "Description not entered",
      "NoPriceEntered": "Price not entered",
      "NoCostEntered": "Cost not entered",
      "AddUnit": "Add unit",
      "CategoryCreated": "Category created successfully",
      "ProductCategoryNameAlreadyExists": "ProductCategory with same name already exist",
      "NewProductTypeDescription": "New product category description",
      "ProductTypeDescription": "Product category description",
      "NewProductTypeName": "New product category name",
      "ProductTypeName": "Product category name",
      "ProductTypeUpdated": "Product category updated successfully",
      "UnitUpdated": "Unit updated successfully",
      "UnitDescription": "Unit description",
      "NewDescription": "New description",
      "UnitNameAlreadyExists": "Unit with same name already exists",
      "NewUnitName": "New unit name",
      "UnitName": "Unit name",
      "UnitCreated": "Unit created successfully",
      "AddProductInformation": "Add product information",
      "Consumption": "Consumption",
      "Inactive++": "Inactive",
      "No": "No",
      "Yes": "Yes",
      "CheckOff": "Check off",
      "ProductDocument": "Product document",
      "Salary": "Salary",
      "UnitRequired": "Unit is required, please choose a unit.",
      "NameRequired": "Name is required, please enter a name.",
      "AddCar": "Add car",
      "AddProduct": "Add product",
      "AddTask": "Add task",
      "ArticleUpdatedSuccessfully": "Aricle updated Successfully",
      "BackToArticles": "Back to articles",
      "ResourcePlanning": "Planning",
      "ArticleName": "Article name",
      "ProductInformation": "Product Information",
      "CompensationTypes": "Compensation types",
      "Products+": "Products",
      "ArticleDeletedSuccessfully": "Article deleted successfully",
      "ArticleAlreadyExist": "Article already exist",
      "ArticleCreatedSuccessfully": "Article created successfully",
      "PriceInSEK": "Price in SEK",
      "CostInSEK": "Cost in SEK",
      "Cost": "Cost",
      "CompensationCode": "Compensation code",
      "SalaryBasis": "Salary basis",
      "ProductCategory": "Product category",
      "SearchArticle": "Search article",
      "AddArticle": "Add article",
      "ScrollHorizontallyWithMouseWheelByHolding": "Scroll horizontally with mouse wheel by holding",
      "ArticleRegistry": "Article registry",
      "AbsenceTypeCreateSuccess": "Absence type created successfully.",
      "AbsenceTypeCreateError": "Absence type with same name already exists.",
      "AbsenceTypeDeleteSuccess": "Successfully deleted absence type.",
      "AbsenceTypeUpdatedSuccess": "Absence type has successfully updated.",
      "AbsenceTypeDeleteErr": "Can´t delete absence types with reported absences.",
      "ActiveAbsence": "Active",
      "ProjectShort": "Pr",
      "InactiveAbsence": "Inactive",
      "PickCustomer": "Pick customer",
      "TotalTimeMonth": "Total time (month):",
      "TotalAbsenceMonth": "Total absence (month):",
      "TotalTimeWeek": "Total time (week):",
      "TotalAbsenceWeek": "Total absence (week):",
      "Reported": "Reported:",
      "Absence": "Absence",
      "AbsenceCode": "Absence code",
      "Booking": "Booking:",
      "Timeplan": "Timeplan",
      "ProjectLeader": "Project leader:",
      "ShowAdvancedSearch": "Show advanced search",
      "HideAdvancedSearch": "Hide advanced search",
      "OpenProject": "Open project",
      "Close": "Close",
      "RunningBooking": "Running booking",
      "MyCalendar": "My calendar",
      "Notifications": "Notifications",
      "NoNotifications": "No notifications",
      "AddBookings": "Add bookings",
      "ClearFields": "Clear fields",
      "Resources": "Resources",
      "TheBookingsHasBeenCreated": "The bookings has been created",
      "Running": "Löpande",
      "Cancel": "Cancel",
      "New": "New",
      "Save": "Save",
      "NoProjects": "There are no projects",
      "Delete": "Delete",
      "RecentProjects": "Recently used projekt",
      "VisibleBookings": "Visible bookings",
      "SortBy": "Sort by",
      "Sorting": "Sorting",
      "FallingBookings": "Falling bookings",
      "Custom": "Custom",
      "OtherProjects": "Other projekt",
      "ApprovedTimeFormat": "Approved time format",
      "SentReports": "Sent reports",
      "employees": "employees",
      "Time": "Time:",
      "MaxDeduction": "Maximum deduction",
      "Articles": "Articles:",
      "Hours": "Hours",
      "PiecesShort": "pcs",
      "SearchProjectCustomer": "Search for project / customer",
      "Tasks": "Tasks",
      "NoProjectHasBeenSelected": "No project has been selected",
      "AddTask": "Add task",
      "NoReportedTime": "No reported time",
      "ReportTime": "Report time",
      "Material": "Material",
      "AddMaterial": "Add material",
      "ReportMaterial": "Add report",
      "QuantityToInvoice": "Qty to invoice",
      "QuantityToInvoiceShort": "Qty to invoice",
      "QuantityToSalary": "Qty to salary",
      "Created+": "Created",
      "Sent+": "Sent",
      "Accepted+": "Accepted",
      "Rejected+": "Rejected",
      "OfferNumber": "Offer number",
      "WeekShort": "w",
      "Versions": "Versions",
      "Vehicles": "Vehicles",
      "EditBooking": "Edit booking",
      "AddBooking": "Add booking",
      "ProjectElement": "Project element",
      "PickProjectElement": "Pick project element",
      "StartTime": "Start time",
      "EndTime": "End time",
      "EmployeeCount": "Required employee count",
      "Employees": "Employees",
      "PickVehicle": "Pick vehicle",
      "AddVehicle": "Add vehicle",
      "TheCustomerIsLockedDueToProjectBeingCreatedFromOffer": "The customer is locked due to the project was created from a quotation",
      "TheCustomerIsLockedDueToTheProjectHasSignedReports": "The customer is locked due to the project contains signed reports",
      "ReportVehicle": "Add report",
      "Comp": "Compensation",
      "CreatedByYou": "Created by you",
      "AddComp": "Add compensation",
      "ReportComp": "Add report",
      "NoReported": "Nothing reported",
      "Search": "Search",
      "Add": "Add",
      "SomethingWentWrong": "Something went wrong",
      "SearchFreetext": "Search / Freetext",
      "FreetextDescription": "Freetext description",
      "AddFreetext": "Add freetext",
      "AddTaskToProject": "Add task to project",
      "AddMaterialToProject": "Add material to project",
      "AddVehicleToProject": "Add vehicle to project",
      "ProjectsWithUnhandledInvoiceDetails": "Projects with unhandled invoice details",
      "withATotalValueOf": "with a total value of",
      "AddCompToProject": "Add compensation to project",
      "Fetching": "Fetching..",
      "FetchMore": "Fetch more",
      "FetchingMore": "Fetching more..",
      "NoResults": "No results",
      "ThereAreMoreResultsPleaseRefineSearchOr": "There are more results. Please refine your search or ",
      "ProjectNoticeboard": "Project noticeboard",
      "EditTimeReport": "Edit time report",
      "EditMaterialReport": "Edit material report",
      "EditVehicleReport": "Edit vehicle report",
      "TimePlanIsNotAvaliableOnInactivatedProjects": "Time plan is not available on inactivated projects",
      "EditCompReport": "Edit compensation report",
      "LastSaveAt": "Last saved at ",
      "Return": "Return",
      "AbsencePerAbsenceType": "Absence per absence type",
      "TimeSpecification": "Time specification",
      "SalaryProductSpecification": "Salary product specification",
      "DetailedAbsence": "Absence in detail",
      "ReportedOnProject": "Reported on the project: ",
      "HoursShort": "Hrs",
      "hoursShort": "hrs",
      "MinutesShort": "Min",
      "ShowOtherBookingsInTheInterval": "Show other bookings in the interval",
      "HideOtherBookingsInTheInterval": "Hide other bookings in the interval",
      "MarkWithTask": "Mark with task",
      "AreYouSureYouWantToDeleteTheReport": "Are you sure you want to delete the report. This action can not be undone.",
      "AreYouSureYouWantToDeleteTheReports":  "Are you sure you want to delete the reports. This action can not be undone.",
      "Document": "Document",
      "MarkProjectAsDone": "Mark project as done",
      "ThereAreNoOtherBookingsInTheInterval": "There are no other bookings in the interval",
      "Notes": "Notes",
      "Variations": "Variations",
      "CreatedBy": "Created by",

      "OfferDate": "Offer date",
      "ExpireDate": "Expire date",
      "DeliveryDate": "Delivery date",
      "OurReference": "Our reference",
      "Header": "Header",
      "Footer": "Footer",
      "NetTotal": "Subtotal",
      "TotalVAT": "VAT",
      "Introduction": "Introduction",
      "Ending": "Ending",
      "Attachments": "Attachments",
      "TheQuotationHasBeenUpdated": "The quotation has been updated",
      "TheQuotationHasBeenCreated": "The quotation has been created",
      "TheQuotationHasBeenDeleted": "The quotation has been deleted",
      "AddTextBlock": "Add text block",

      "Comment": "Comment",
      "FetchingProject": "Fetching project..",
      "AddDocument": "Add document",
      "AddNote": "Add note",
      "Title": "Title",
      "OfferTemplate": "Offer template",
      "PickOfferTemplate": "Pick offer template",
      "Text": "Text",
      "Category": "Category",
      "Access": "Access",
      "CanRead": "Can read",
      "CanEdit": "Can edit",
      "All": "All",
      "Admin": "Admin",
      "DropFilesHereOrClickToPickFile": "Drop file here or click to pick file",
      "AreYouSureDeleteMessage": "Are you sure? This action can not be undone.",
      "EditDocument": "Edit document",
      "EditNote": "Edit note",
      "EditSupplierInvoice": "Edit supplier invoice",
      "ToBig": "To big",
      "AcceptetFileTypes": "Video, bild, .pdf or .docx  (Max 20MB)",
      "PickHere": "Pick here",
      "Today": "Today",
      "AddDocument": "Add document",
      "AddNote": "Add note",
      "AddToNoticeboardNoticeMessage": "The note will be added to the projects noticeboard and be visible to all users in your organisation",
      "AddToNoticeboardCheckboxMessage": "Add to the project noticeboard? (The note will be visible to all users in your organisation)",
      "VisibleInNoticeboardMessage": "Visible in noticeboard",
      "ShowDoneProjects": "Show projects marked as done",
      "MarkedAsDone": "Marked as done",
      "by": "by",
      "Register": "Register",
      "CopyYesterdays": "Copy yesterdays",
      "report": "report",
      "absence": "absence",
      "PickProduct": "Pick product",
      "PickVehicle": "Pick vehicle",
      "PickComp": "Pick compensation",
      "PickArticle": "Pick article",
      "AllProjects": "All projects",
      "ThisWeek": "This week",
      "LastWeek": "Last week",
      "ThisMonth": "This month",
      "LastMonth": "Last month",
      "Last12Months": "Last 12 months",
      "ReportAbsence": "Report absence",
      "DownloadAsPAXML": "Download as PAXml",
      "AProjectHasBeenCreatedFromTheOffer": "A project has been created from the offer",
      "wasCreatedFromThisQuotation": "was created from this quotation",
      "CreatedFromQuotation": "Created from quotation",
      "EditAbsence": "Redigera frånvarorapport",
      "TheStartTimeHasToBeBeforeTheEndTime": "The start time has to be before the end time",
      "CopyTimes": "Copy times",
      "CopyProducts": "Copy material",
      "CopyCars": "Copy cars",
      "CopyComps": "Copy comensation",
      "CopyAbsences": "Copy absence",
      "TheProjectVariationCouldNotBeSent": "The project variation could not be sent",
      "CopyNotes": "Copy notes",
      "CopyReports": "Copy reports",
      "MarkAll": "Mark all",
      "Review": "Review",
      "dayreport": "dayreport",
      "CantEditReportMessage": "This report have been handled by admin and therefore can not be edited.",
      "NotAuthorizedToEditDate": "You don't have access to edit reports on this date. Please contact your admin to make any changes.",
      "TaskAlreadyExistsOnProjectMessage": "This task is already added to the project.",
      "ProductAlreadyExistsOnProjectMessage": "This article is already added to the project.",
      "LoggedInAs": "Logged in as:",
      "Admin": "Admin",
      "User": "User",
      "CreateCustomer": "Create customer",
      "CreateProject": "Create project",
      "ActiveProjects": "Active projects",
      "Attesting": "Attesting",
      "Forms": "Forms",
      "Menu": "Menu",
      "ToDo": "To do",
      "Home": "Home",
      "Company": "Company",
      "Profile": "Profile",
      "Done": "Done",
      "TotalExcludingVat": "Total excluding VAT",
      "CompanyCustomerPricesWillExcludeVATAndDeductionTypesAreHidden": "Corporate customer: Prices are stated excluding VAT and deduction types are hidden.",
      "PricesExcludingVAT": "Prices excluding VAT",
      "AbsenceTypes": "Absence types",
      "reportsToApprove": "reports to approve",
      "BelongsToCustomerProject": "Belongs to customer project",
      "demandsCustomerSignature": "demands customer signature",
      "unsignedProjectForms": "unsigned project forms",
      "Show": "Show",
      "UnderConstruction": "Under construction",
      "CompanyViewVision": "Our vision with the company view is that you should get all the information you need to do your work in a simple and fast way, without leaving MyGizmo!",
      "WhatDoYouWantToSeeInThisView": "What information/features do you want to see in the company view?",
      "SendYourRequest": "Send us your ideas",
      "here": "here",
      "WhatDoThinkAboutOurNewDesign": "Do you like our new design?",
      "LeaveFeedback": "Leave feedback",
      "Products": "Article registry",
      "YourCustomer": "Your customer",
      "CreateNew": "Create new",
      "ProjectDetails": "Project details",
      "Name": "Name",
      "Description": "Description",
      "Resourceplanner": "Resource planner",
      "GoToMonth": "Go to month",
      "CreateBookings": "Create bookings",
      "ShowBookingsWithResources": "Show bookings with resources",
      "ShowSettings": "Show settings",
      "HideSettings": "Hide settings",
      "ShowWeekends": "Show weekends",
      "ShowWorkday": "Show workday",
      "ShowOnlyRowsWithBookings": "Show only rows with bookings",
      "Workload": "Workload",
      "BookingStatus": "Booking status",
      "DailyCapacity": "Daily capacity",
      "StandardValue8HoursPerEmployee": "Standard value 8 hours per employee",
      "ManHours": "Man hours",
      "ofDailyCapacity": "of daily capacity",
      "CalculateWorloadFromPredefinedWorkday": "Calculate workload from predefined workday",
      "EmployeesStartToWork": "Employees start to work",
      "EmployeesStopWorking": "Employees stop working",
      "LunchFrom": "Lunch from",
      "LunchTo": "Lunch to",
      "ColorLevels": "Color levels",
      "ProjectVariations": "Project Variations",
      "ContainsDoubleBookings": "Contains double bookings",
      "ContainsEmptyBookingSpots": "Contains empty booking spots",
      "ContainsBookingsWithToManyBookedResources": "Contains bookings with to many booked resources",
      "ContainsEmptyBookings": "Contains empty bookings",
      "SelectAllReports": "Select all reports",
      "DeselectAllReports": "Deselect all reports",
      "AllBookingsHasTheRightNumberOfBookedResources": "All bookings has the right number of booked resources",
      "InternalInformation": "Internal information",
      "ApplyOnWeekends": "Apply on weekends",
      "Adress": "Adress",
      "UseCustomerAdress": "Use customer main adress",
      "CUSTOMERMAINADRESS": "CUSTOMER MAIN ADRESS",
      "EmployeesInSalaryPeriod": "Employees in salary period",
      "SalaryDetailsInSalaryPeriod": "Salary details in salary period",
      "UseExistingCustomerObject": "Use existing customer object",
      "PickCustomerObject": "Pick customer object",
      "ZipCode": "Zip code",
      "City": "City",
      "Country": "Country",
      "SaveTheQuotationToUpload": "Save the quotation to upload",
      "Duration": "Duration",
      "StartDate": "Start date",
      "EndDate": "End date",
      "Size": "Scope",
      "ProjectType": "Project type",
      "Max3RowsAreRecommended": "Max 3 rows are recommended",
      "Running": "Open",
      "PickMonth": "Pick month",
      "Pick": "Pick",
      "FixedPrice": "Fixed price",
      "TheEmployeeHasNonAttestedReportsInTheIntervalClickHereToShow": "The employee has non attested reports in the interval. Click here to show.",
      "NonAttestedReportsInTheIntervalForEmployee": "Non attested reports in the interval for employee",
      "AttestedReportsInTheIntervalForEmployee": "Attested reports in the interval for employee",
      "ReportsInTheIntervalForEmployee": "Reports in the interval for employee",
      "NonAttestedReportsInTheProject": "Non attested reports in the project",
      "EstimatedManHours": "Estimated man hours",
      "Articles2": "Articles",
      "ProjectRoles": "Project roles",
      "PickEmployee": "Pick employee",
      "CreateMoreProjects": "Create more projects",
      "GoHome": "Go home",
      "GoToProject": "Go to project",
      "Optional": "Optional",
      "SearchCustomer": "Search customer",
      "indicatesRequiredField": "indikerar obligatoriskt fält",
      "PrivateCustomer": "Private customer",
      "CompanyCustomer": "Company customer",
      "FirstName": "First name",
      "SocialSecurityNumber": "Social security number",
      "MiddleName": "Middle name",
      "LastName": "Last name",
      "OrgNumber": "Org.number",
      "ContactPerson": "Contact person",
      "Email": "Email",
      "PhoneNumber": "Phone number",
      "EnterAdress": "Enter adress",
      "ProjectCreated": "The project has been created!",
      "HowToYouWantToProceed": "How do you want to proceed?",
      "NothingToDo": "Nothing to do",
      "OpenAdminView": "Open adminwebb",
      "NoteAdminSiteNotMobileAdapted": "Note: Adminwebb is not mobile adapted",
      "Customer": "Customer",
      "TheProjectVariationLinkHasBeenSentTo": "The project variation link has been sent to",
      "TheProjectVariationLinkHasBeenInactivated": "The project variation link has been inactivated",
      "Project": "Project",
      "MissingRequiredFields": "Please fill-in all the required fields",
      "HasValidInput": "All required fields are filled in!",
      "HasExternalProjectLeader": "Has external project leader",
      "EmplyoyeeCanApproveWithExternalProjectLeaderSignature": "Employees can approve reports with external project leader signature",
      "ExternalProjectLeader": "External project leader",
      "PropertyCode": "Property code",
      "PaymentPlanElement": "Payment plan element",
      "specification": "specification",
      "PropertyCodeShort": "Property code",
      "DemandCustomerSignatureOnAttestByInternalProjectLeader": "Demand customer signature on attest by internal project leader",
      "Added": "Added",
      "LatestCreatedProjectFirst": "Last created project first",
      "LatestCreatedProjectLast": "Last created project last",
      "ProjectNameAsc": "Project name A to Z",
      "ProjectNameDesc": "Project name Z to A",
      "LatestCreatedCustomerFirst": "Last created customer first",
      "LatestCreatedCustomerLast": "Last created customer last",
      "CustomerNameAsc": "Customer name A to Z",
      "AllReports" : "All reports",
      "OnlyNonVariation": "Non project variation",
      "OnlyVariation": "Only project variation",
      "CustomerNameDesc": "Customer name Z to A",
      "ListOptions": "List options",
      "ShowMyRecentProjects": "Show my recent projects",
      "CreateProjectVariation": "Create project variation",
      "EditProjectVariation": "Edit project variation",
      "FixedPriceInSEK": "Fixed price in SEK",
      "TheProjectVariationHasBeenUpdated": "The project variation has been updated",
      "TheProjectVariationHasBeenCreated": "The project variation has been created",
      "TheProjectVariationHasBeenDeleted": "The project variation has been deleted",
      "SortProjectsBy": "Sort projects by",
      "AddFormTemplate": "Add form template",
      "PickFreetextUnit": "Pick freetext unit",
      "ToProject": "To project",
      "FormTemplate": "Form template",
      "TheProjectVariationHasBeenSentTo": "The project variation has been sent to",
      "TheProjectVariationLinkIsConsumed": "The project variation link has been consumed",
      "TheProjectVariationIsLockedDueToHavingAnActiveLink": "The project variation is locked due to having an active link",
      "TheProjectVariationIsLockedDueToHavingBeenMarkedAsSent": "The project variation is locked due to having been marked as sent",
      "TheProjectVariationIsLockedDueToHavingBeenMarkedAsAccepted": "The project variation is locked due to having been marked as accepted",
      "TheProjectVariationIsLockedDueToHavingBeenMarkedAsRejected": "The project variation is locked due to having been marked as rejected",
      "TheProjectVariationIsLockedDueToHavingBeenAcceptedByCustomer": "The project variation is locked due to having been accepted by customer",
      "TheProjectVariationIsLockedDueToHavingBeenRejectedByCustomer": "The project variation is locked due to having been rejected by customer",
      "TheProjectVariationWasAcceptedByCustomer": "The project variation was accepted by customer",
      "TheProjectVariationWasRejectedByCustomer": "The project variation was rejected by customer",
      "PickTemplate": "Pick form template",
      "NotProjectVariation": "Not project variation",
      "ProjectVariation": "Project variation",
      "AddFormTemplateToProject": "Add form template to project",
      "ThisVersionIsLockedDueToHavingAnActiveLink": "This version is locked due to having an active link",
      "ProjectStats": "Project stats",
      "EstimatedHours": "Estimated hours",
      "TotalReportedHours": "Reported hours",
      "AddProjectElement": "Add project element",
      "EditProjectElement": "Edit project element",
      "TheProjectElementHasBeenUpdated": "The project element has been updated",
      "TheProjectElementHasBeenCreated": "The project element has been created",
      "TheProjectElementHasBeenDeleted": "The project element has been deleted",
      "NumberOfTimeReports": "Number of time reports",
      "NumberOfProductReports": "Number of product reports",
      "ReloadApplication": "Reload application",
      "CreateNewProduct": "Create new product",
      "Article": "Article",
      "ProductName": "Product name",
      "ProductDescription": "Product description",
      "PickUnit": "Pick unit",
      "CreateProduct": "Create product",
      "CreateNewTask": "Create new task",
      "CreateTask": "Create task",
      "DailyReportSummary": "Daily report summary",
      "NothingReportedMessage": "No reports on this date",
      "Calendar": "Calendar",
      "Startpage": "Startpage",
      "Reports": "Reports",
      "ThereAreNonAttestedReportsInTheIntervalAreYouSureYouWantToProceed?": "There are non attested reports in the interavl. Are you sure you want to proceed?",
      "InvoiceDetails": "Invoice details",
      "SalaryDetails": "Salary details",
      "Customers": "Customers",
      "Projects": "Projects",
      "ResourcePlanner": "Planning",
      "PaymentPlan": "Payment plan",
      "InvoiceByPaymentPlan": "Invoice by payment plan",
      "MaxTotalSafetyPercentage": "Max total safety percentage",
      "SafetyPercentage": "Safety percentage",
      "ShowPaymentPlanDueDate": "Show due date",
      "ShowPaymentPlanInvoiceDate": "Show invoice date",
      "SafetyAmount": "Safety amount",
      "AccumulatedSafetyAmount": "Accumulated safety amount",
      "ToInvoiceAmount": "To invoice amount", 
      "ActivatePaymentPlan": "Activate payment plan",
      "AddRow": "Add row",
      "ThePaymentPlanElementHasBeenEdited": "The row has been edited",
      "AddPaymentPlanElement": "Add row",
      "EditPaymentPlanElement": "Edit row",
      "ThePaymentPlanElementHasBeenCreated": "The row has been created",
      "ThePaymentPlanElementHasBeenDeleted": "The row has been deleted",
      "Suppliers": "Suppliers",
      "PriceAdjustmentsBasedOnCustomerPriceList": "Price adjustments based on the customers price list",
      "Apply": "Apply",
      "selected": "selected",
      "none": "none",
      "PickTheAdjustmentsYouWantToApply": "Pick the adjustments you want to apply",
      "AbsenceTypes": "Absence types",
      "AbsenceType": "Absence type",
      "Settings": "Settings",
      "Integration": "Integration",
      "FortnoxNumberShort": "Fortnox Nr.",
      "Active+": "Active",
      "MarkedAsDone+": "Marked as done",
      "Inactive+": "Inactive",
      "Result": "Result",
      "SearchProject": "Search project",
      "SearchAbsence": "Search absence",
      "AddAbsence": "Add absence",
      "EditAbsenceType": "Edit absence type",
      "SyncProjectsWithFortnox": "Sync projects with Fortnox",
      "DownloadAsExcelFile": "Download as Excel file",
      "MyGizmoAccount": "MyGizmo account",
      "Filter": "Filter",
      "FreetextSearch": "Freetext search",
      "NumberOfSearchResults": "Number of search results",
      "PlannedHours": "Planned hours",
      "PlannedOfEstimate": "Planned of estimate",
      "Page": "Page",
      "ShowFrom": "Show from",
      "ShowTo": "Show to",
      "ProjectName": "Project name",
      "ProjectDescription": "Project description",
      "CustomerNumber": "Customer number",
      "CustomerName": "Customer name",
      "ProjectNumber": "Project number",
      "DownloadedAt": "Downloaded at",
      "SaveChanges": "Save changes",
      "Overview": "Overview",
      "CreateCustomerObject": "Create customer object",
      "PickedCustomerObject": "Picked customer object",
      "CreateNew2": "Create new",
      "Edit": "Edit",
      "AreYouSureYouWantToLeaveMessage": "You have unsaved changes. Are you sure that you want to leave the page?",
      "LabourCosts": "Labour costs",
      "labourCosts": "labour costs",
      "Labour": "Labour",
      "Comps": "Outlays",
      "NewQuotation": "New quotation",
      "SaveAsDefaultHeadline": "Save as default headline",
      "NewQuotationTemplate": "New quotation template",
      "CreateQuotationTemplate": "Create quotation template",
      "OfferTemplateNumber": "Quotation template number",
      "QuotationTemplates": "Quotation templates",
      "SaveAsDefaultFooter": "Save as default footer",
      "TheHeadlineHasBeenSavedAsDefaultHeadline": "The headline has been saved as default headline",
      "TheFooterHasBeenSavedAsDefaultFooter": "The footer has been saved as default footer",
      "AddTextBlockFromLibrary": "Add text block from library",
      "TheTextBlockHasBeenStoredInTheLibrary": "The text block has been stored in the library",
      "TheTextBlockHasBeenAddedFromTheLibrary": "The text block has been added from the library",
      "TextBlockLibrary": "Text block library",
      "SupplierInvoices": "Supplier invoices",
      "Costs": "Costs",
      "Date": "Date",
      "Employee": "Employee",
      "Type": "Type",
      "Quantity": "Quantity",
      "Á-Price": "Price/unit",
      "Attachment": "Attachment",
      "Á-Cost": "Cost/unit",
      "ToInvoice": "To Invoice",
      "ToSalary": "To Salary",
      "Unit": "Unit",
      "ReportHasUpdated": "The report has updated!",
      "ToInvoiceShort": "To inv.",
      "TheQuotationLinkHasBeenSentTo": "The quotation link has been sent to",
      "TheLinkHasBeenOpened": "The link has been opened",
      "times": "times",
      "SendQuotation": "Send quotation",
      "InactivateLink": "Inaktivera länk",
      "TheQuotationLinkHasBeenInactivated": "The quotation link has been inactivated",
      "SendToEmail": "Send to email",
      "NotAValidEmailAdress": "Not a valid email adress",
      "Created": "Created",
      "Sent": "Sent",
      "Accepted": "Accepted",
      "Rejected": "Rejected",
      "TheQuotationHasBeenSentTo": "The quotation has been sent to",
      "ThisVersionIsLockedDueToHavingANewerVersion": "This version is locked due to having a newer version",
      "TheReportWasApprovedBy": "The report was approved by",
      "TheReportWasRejectedBy": "The report was rejected by",
      "ClickForAttestHistory": "Click for attest history",
      "MarkAsWrong": "Mark as wrong",
      "Approve": "Approve",
      "NoActiveIntegrationMessage": "Are you using Fortnox, Visma eAccounting, Visma Administration or Visma Salary? In that case you can easily integrate it with MyGizmo. Please contact us so that we can help you get started!",
      "IWantToIntegrateMyAccountingSoftware": "I want to integrate my accounting- /salary system",
      "ReportsHasBeenMarkedAsWrong": "The reports has been marked as wrong",
      "ReportsHasBeenApproved": "The reports has been approved",
      "pickedReports": "pricked reports",
      "Note": "Note",
      "Car": "Car",
      "Product": "Product",
      "Time2": "Time",
      "NotHandledOnInvoiceDetails": "Not handled on invoice details",
      "NotHandledOnSalaryDetails": "Not handled on salary details",
      "HandledOnInvoiceDetails": "Handled on invoice details",
      "HandledOnSalaryDetails": "Handled on salary details",
      "ShowingReportsInInterval": "Showing reports in interval",
      "ApplyReportFilter": "Apply report filter",
      "ClearFilter": "Clear filter",
      "From": "From",
      "To": "To",
      "AttestHistory": "Attest history",
      "CreatedAt": "Created at",
      "CustomerSignature": "Customer signature",
      "approved": "approved",
      "rejected": "rejected",
      "MarkAsDone": "Mark as done",
      "Inactivate": "Inactivate",
      "Inactivated": "Inactivated",
      "Inactivated2": "Inactivated",
      "ProjectHasBeenDeleted": "The project has been deleted",
      "AreYouSureYouWantToDeleteProject": "Are you sure you want to delete the project. This action can not be undone.",
      "TheProjectHasBeenMarkedAsNotDone": "The project has been marked as not done.",
      "TheProjectHasBeenMarkedAsDone": "The project has been marked as done.",
      "TheProjectHasBeenMarkedAsActive": "The project has been marked as active.",
      "TheProjectHasBeenMarkedAsInactive": "The project has been marked as inactive.",
      "TheProjectFormHasBeenUpdated": "The project form has been updated.",
      "Responsee": "Responsee",
      "NotStarted": "Not started",
      "Signed": "Signed",
      "Ongoing": "Ongoing",
      "ShowAnswers": "Show answeres",
      "EditProjectForm": "Edit project form",
      "TheProjectHasBeenUpdated": "The project has been updated",
      "VisibleForAllUsers": "Visible to all users",
      "ActiveView": "Active view",
      "TotalReported": "Total reported",
      "SentBy": "Sent by",
      "AnswerBySelectingOneOfTheFollowingOptionsAndPressSend": "Answer by selecting one of the following options and submit your answer",
      "SendResponse": "Send response",
      "SendingResponse": "Sending response",
      "Accept": "Accept",
      "Reject": "Reject",
      "TheQuotationWasAcceptedByCustomer": "The quotation has been accepted by customer",
      "TheQuotationWasRejectedByCustomer": "The quotation has been rejected by customer",
      "ThisVersionIsLockedDueToHavingBeenMarkedAsSent": "This version is locked due to having been marked as sent",
      "ThisVersionIsLockedDueToHavingBeenMarkedAsAccepted": "This version is locked due to having been marked as accepted",
      "ThisVersionIsLockedDueToHavingBeenMarkedAsRejected": "The version is locked due to having been marked as rejected",
      "ThisVersionIsLockedDueToHavingBeenAcceptedByCustomer": "The version is locked due to having been accepted by customer",
      "ThisVersionIsLockedDueToHavingBeenRejectedByCustomer": "The version is locked due to having been rejected by customer",
      "ThisVersionIsLockedDueToHavingCreatedAProject": "This version is locked due to having created a project",
      "TheQuotationLinkIsUsed": "The quotation link has been used",
      "YourAnswerHasBeenSubmitted": "Your answer has been submitted",
      "YouCanCloseThisWindowNow": "You can close this window now",
      "ImportToProject": "Import to project",
      "FormTemplatesImportedToProject": "Form templates imported to project",
      "ProjectFormWasDeleted": "The project form was deleted",
      "Question": "Question",
      "PickOneAnswer": "Pick one answer",
      "RadioButtons": "Radiobuttons",
      "MultipleFreetextAnswers": "Multiple freetext answers",
      "PickMultipleAnswers": "Pick multiple answers",
      "Checkboxes": "Checkboxes",
      "FreetextAnswer": "Freetext answer",
      "TitleAndDescription": "Title and description",
      "AddComponent": "Add component",
      "BackToProjectForms": "Back to project forms",
      "StopEdit": "Stop editing",
      "PleaseSignHereToAccept": "Please sign here to accept",
      "Signature": "Signature",
      "ClearSignature": "Clear signature",
      "ShowSignature": "Show signature",
      "Answer": "Answer",
      "Components": "Components",
      "TheProjectFormHasBeenCreated": "The project form has been created",
      "IncludeTimes": "Incl. times",
      "IncludeProducts": "Incl. products",
      "IncludeCars": "Incl. cars",
      "IncludeComp": "Incl. comps",
      "IncludeSupplierInvoices": "Incl. supplier invoices",
      "IncludeNotes": "Incl. notes",
      "BreakOnDate": "Break on date",
      "ShowTimeTypes": "Show time types",
      "ShowPrices": "Show prices",
      "Price/Unit": "Price/Unit",
      "Price": "Price",
      "rows": "rows",
      "MarkAsHandled": "Mark as handled",
      "HandleAndExportToFortnox": "Handle and export to Fortnox",
      "Proceed": "Proceed",
      "TheInvoiceDetailsHasBeenHandled": "The invoice details has been handled",
      "Handled": "Handled",
      "Unhandled": "Unhandled",
      "FortnoxDocumentNumber": "Fortnox Documentnum.",
      "SaveAsPDF": "Save as PDF",
      "ColorCode": "Color code",
      "ResetColor": "Reset color code",
      "Print": "Print",
      "BackHandling": "Back handling",
      "RefersToThePeriod": "Refers to period",
      "UnhandledInvoiceDetails": "Unhandled invoice details",
      "HandledInvoiceDetails": "Handled invocie details",
      "TheInvoiceDetailsHasBeenHandledOnInvoiceDetails": "The reports has been handled on invoice details",
      "InvoiceDetailsWasBackedToUnhandled": "The invoice details was backed to unhandled",
      "ShowSpecification": "Show specification",
      "HideSpecification": "Hide specification",
      "ApprovedBy": "Approved by",
      "EditReport": "Edit report",
      "PickProject": "Pick project",
      "Task": "Task",
      "TimeType": "Time type",
      "SalesPrice": "Price per unit",
      "CostPrice": "Cost per unit",
      "ProductKind": "Product kind",
      "PickTask": "Pick task",
      "PickTimeType": "Pick time type",
      "Select": "Select",
      "PickProduct": "Pick product",
      "PickProductKind": "Pick product kind",
      "ThereAre": "There are",
      "nonApprovedReportsOnTheProject": "non approved reports on the project",
      "ValueOfTimeReports": "Value of times",
      "ValueOfProductReports": "Value of products",
      "ValueOfCarReports": "Value of cars",
      "ValueOfCompReports": "Value of compensations",
      "ValueOfSupplierInvoiceReports": "Value of supplier invoices",
      "TotalValue": "Total value",
      "AdressLine1": "Adress line 1",
      "AdressLine2": "Adress line 2",
      "AdressLine3": "Adress line 3",
      "AdressLine4": "Adress line 4",
      "PostalCode": "Postal code",
      "EditCustomerObject": "Edit customer object",
      "CustomerObjectUpdated": "Customer object updated",
      "CustomerObjectCreated": "Customer object created",
      "BackToProjects": "Back to projects",
      "AssignedAsProjectLeader": "Assigned as project leader",
      "Active": "Active",
      "ReportHasBeenHandledOnInvoiceDetails": "The report has been handled on invoice details",
      "ReportHasBeenHandledOnSalaryDetails": "The report has been handled on salary details",
      "andSalaryDetails": "and salary details",
      "AreYouSureYouWantToEditHandledReportMessage": "The changes will be reflected in the handled details. Are you sure you want to edit the report?",
      "ReportMatrix": "Report matrix",
      "ApplyFilter": "Apply filter",
      "ShowColorCodes": "Show color codes",
      "BlueOver8Hours": "Blue over 8 hours",
      "RedUnder8Hours": "Red under 8 hours",
      "CreateAbsenceType": "Create new absence type",
      "PersonalNumberShort": "Personalnum.",
      "PhoneNumberShort": "Phonenum.",
      "VATamount": "VAT amount",
      "BackToCustomers": "Back to customers",
      "Adresses": "Adresses",
      "Documents": "Documents",
      "CustomerUpdated": "The customer has been updated",
      "UsedOn": "Used on",
      "projects": "projects",
      "ThisProductIsConnectedTo": "This product is connected to",
      "ThisTaskIsConnectedTo": "This task is connected to",
      "ByDeletingThisProductYouWillAlsoRemoveTheseConnections": "By deleting this product you will also remove these connections",
      "ByDeletingThisTaskYouWillAlsoRemoveTheseConnections": "By deleting this task you will also remove these connections",
      "MainAdress": "Main adress",
      "AreYouSureYouWantToDeleteTheCustomerObject": "Are you sure you want to delete the customer object. This action can not be undone.",
      "PrivatePerson": "Private person",
      "CustomerObjectHasBeenDeleted": "Customer object deleted",
      "Name2": "Name",
      "BackTo": "Back to",
      "reports": "reports",
      "CustomerHasBeenMarkedAsInactive": "Customer marked as inactive",
      "CustomerHasBeenMarkedAsActive": "Customer marked as active",
      "andThereforCannotBeEdited": "and therefore they can't be edited",
      "CreateReport": "Create report",
      "TimeReport": "Time report",
      "MaterialReport": "Material report",
      "TheReportHasBeenCreated": "The report has been created",
      "FieldIsMandatory": "The field is mandatory",
      "ToHandle": "To handle",
      "MarkedAsWrong": "Marked as wrong",
      "Approved": "Approved",
      "NotFinished": "Not finished",
      "Finished": "Finished",
      "RequiresAdminScope": "Requires admin access",
      "TheReportsHasBeenDeleted": "The reports has been deleted",
      "TheReportHasBeenDeleted": "The report has been deleted",
      "AddReport": "Add report",
      "TheReportsHasBeenCreated": "The reports has been created",
      "FromRegister": "From article register",
      "Freetext": "Freetext",
      "UnhandledSalaryDetails": "Unhandled salary details",
      "HandledSalaryDetails": "Handled salary details",
      "GetUnhandledSalaryDetails": "Get unhandled salary",
      "ReportedTime": "Reported time",
      "ReportedAbsence": "Reported absence",
      "Total": "Total",
      "DateSpecification": "Date specification",
      "TheReportsHasBeenHandledOnSalaryDetails": "The reports has been handled on salary details",
      "ActionsAreAppliedToAllSalaryDetailsInThePeriod": "The actions are applied to all salary details in the period",
      "DownloadAsXML": "Download as XML",
      "DownloadAsXMLToVisma": "Download as XML to Visma",
      "TheCustomerHasBeenCreated": "The customer has been created",
      "SalaryDetailsWasBackedToUnhandled": "The salary details has been backed to unhandled",
      "Unsorted": "Unsorted",
      "SalaryPeriod": "Salary period",
      "to": "to",
      "DeductionType": "Deduction type",
      "PickDeductionType": "Pick deduction type",
      "TotalDeduction": "Total deduction",
      "TotalAfterDeduction": "Total after deduction",
      "PickVAT": "Pick VAT",
      "TheReportHasBeenHandledOnInvoiceDetails": "The report has been handled on invoice details",
      "TheReportHasBeenHandledOnSalaryDetails": "The report has been handled on salary details",
      "andSalaryDetails":  "and salary details",
      "ExportInvoiceDetailsOption": "Pick export invoice details option",
      "ProjectNameAsYourReference": "Project name as your reference",
      "ProjectNameAsYourOrdernumber": "Project name as your order number",
      "ProjectNameDescriptionAndInternalInformationAsDeliveryDetails": "Project name, description and internal information as delivery details",
      "None2": "None",
      "andThereforeHasLimitedEditability": "and therefore has limited editablity",
      "AbsenceReport": "Absence report",
      "PickAbsenceType": "Pick absence type",
      "InvoiceDetailsShort": "ID",
      "SalaryDetailsShort": "SD",
      "perPage": "per page",
      "ExternalProjectLeaderShort": "External PL",
      "CountWithUnhandledDetails": "Count with unhandled details",
      "TheCustomerWasDeleted": "The customer was deleted",
      "TheCustomerHasProjectsAndThereforeCannotBeDeleted": "The customer has belonging projects and therefore cannot be deleted.",
      "TheCustomerHasDocumentsAndThereforeCannotBeDeleted": "The customer has belonging documents and therefore cannot be deleted.",
      "ActivateTheIntegration": "Activate the integration",
      "TheIntegrationIsActive": "The integration is active",
      "RedoActivation": "Redo activation",
      "ArticleNumberShort": "Article Nr.",
      "ImportArticlesFromFortnox": "Import articles from Fortnox",
      "ArticlesInFortnox": "Articles in Fortnox",
      "Imported": "Imported",
      "Import/Update": "Import / update",
      "Import/UpdateAll": "Import / update all",
      "selectedArticles": "selected articles",
      "TheArticlesHasBeenImported": "The articles has been imported",
      "ImportAs": "Import as",
      "NotImported": "Not imported",
      "QuantityInFortnox": "Quantity in Fortnox",
      "ArticleUnit": "Article unit",
      "FortnoxArticleGroupMessage": "Fortnox does not support article grouping. Therefore the article units are used to decide what the articles should be stored as in MyGizmo. You can create your own units in Fortnox and in that way further customize the import of articles further.",
      "ImportArticleSettings": "Import article settings",
      "MoreInfoHere": "More information here",
      "TheChangesHasBeenSaved": "The changes has been saved",
      "TheProjectsHasBeenSyncedWithFortnox": "The projects has been synced with Fortnox",
      "YourIntegrationCanNotBeAdministratedFromHereYet": "Your integration can not be administrated from here yet.",
      "VismaEaccounting": "Visma eAccounting",
      "EaccountingNumberShort": "Visma eAccounting Nr.",
      "ImportArticlesFromEaccounting": "Import articles from eAccounting",
      "ArticlesInEaccounting": "Articles in eAccounting",
      "QuantityInEaccounting": "Quantity in eAccounting",
      "EaccountingArticleGroupMessage": "eAccounting does not support article grouping. Therefore the article units are used to decide what the articles should be stored as in MyGizmo. You can create your own units in eAccounting and in that way further customize the import of articles further.",
      "LoadingStatus": "Loading status",
      "TheEaccountingIntegrationIsInactive": "The Visma eAccounting integration is active",
      "TheFortnoxIntegrationIsInactive": "The Fortnox integration is inactive",
      "OpenIntegrationSettings": "Open integration settings",
      "TheIntegrationIsInactiveDoYouWantToHeadToTheIntegrationSettings": "The integration is inactive. Do you want to open the integration settings?",
      "Eaccounting": "E-ACCOUNTING",
      "CreateInvoiceInEaccounting": "Create invoice in eAccounting",
      "InvoiceSuccessfullyExportedToEaccounting": "The invoice details was exported to Visma eAccounting",
      "CustomerNumberShort": "Customer Nr",
      "ImportCustomersFromEaccounting": "Import customers from eAccounting",
      "selectedCustomers": "selected customers",
      "TheCustomersHasBeenImported": "The customers has been imported",
      "CustomersInEaccounting": "Customers in eAccounting",
      "ImportOrdersFromEaccounting": "Import orders from eAccounting",
      "selectedOrders": "selected orders",
      "OrdersInEaccounting": "Orders in eAccounting",
      "OrderNumberShort": "Order Nr.",
      "Sending": "Sending",
      "TheOrdersHasBeenImported": "The orders has been imported",
      "TheCustomersHasBeenImported": "The customers has been imported",
      "CustomersInFortnox": "Customers in Fortnox",
      "Headline": "Headline",
      "SaveTextBlockInLibrary": "Save textblock in library",
      "ImportCustomersFromFortnox": "Import customers from Fortnox",
      "OrdersInFortnox": "Orders in Fortnox",
      "ImportOrdersFromFortnox": "Import orders from Fortnox",
      "FortnoxOrderNumber": "Fortnox order number",
      "eAccountingOrderNumber": "Visma eAccounting order number",
      "FortnoxCustomerNumber": "Fortnox customer number",
      "eAccountingCustomerNumber": "Visma eAccounting customer number",
      "FortnoxProjectNumberShort": "Fortnox project",
      "FortnoxCustomerNumberShort": "Fortnox customer",
      "EaccountingCustomerNumberShort": "e-Accounting customer",
      "CreateNewOfferVersionConfirmMessage": "Do you want to copy this offer to a new version?",
      "ANewOfferVersionHasBeenCreated": "A new offer version has been created",
      "DeleteVersion": "Delete version",
      "projectsHasBeenHandled": "projekt has been handled",
      "AddOfferRow": "Add offer row",
      "EditOfferRow": "Edit offer row",
      "TheCustomersPrice": "Price according to price list",
      "of": "of",
      "NameClarification": "Name clarification",
      "DeletedInFortnox": "Deleted in Fortnox",
      "OrderDate": "Order date",
      "DueDate": "Due date",
      "InvoiceDate": "Invoice date",
      "TotalAmount": "Total amount",
      "Loading": "Loading",
      "BackToPurchases": "Back to purchases",
      "TheSupplierInvoiceHasBeenUpdated": "The supplier invoice has been updated",
      "TheSupplierInvoiceHasBeenDeleted": "The supplier invoice has been deleted",
      "IncludeAsAttachment": "Incl. as attachment",
      "TotalIncVAT": "Total inc. VAT",
      "PricesIncludingVAT": "The prices are including VAT",
      "Amount": "Amount",
      "Charge": "Charge",
      "AddProject":"Add project",
      "leftToAssign": "left to assign",
      "TheWholeAmountHasBeenAssigned": "The whole amount has been assigned",
      "aboveTheTotalAmountHasBeenAssigned": "above the total amount has been assigned",
      "Download": "Download",
      "SaveAsTemplate": "Save as template",
      "TemplateDescription": "Template description",
      "TheQuotationHasBeenSavedAsTemplate": "The quotation has been saved as template",
      "YourUnsavedChangesWillNotBeAddedToTheTemplate": "Your unsaved changes will not be added to the template",
      "YourUnsavedChangesWillBeLostIfYouShareTheQuotation": "Your unsaved changes will be lost if you share the quotations without saving first",
      "ShowQuotation": "Show quotation",
      "Preview": "Preview",
      "CreateQuotationTemplate": "Create quotation template",
      "TheQuotationTemplateHasBeenCreated": "The quotation template has been created",
      "TheQuotationTemplateHasBeenUpdated": "The quotation template has been updated",
      "EditQuotationTemplate": "Edit quotation template",
      "TheQuotationTemplateHasBeenDeleted": "The quotation template has been deleted",
      "RemoveDocument": "Remove document",
      "TheSupplierInvoiceHasBeenCreated": "The supplier invocie has been created",
      "CreateSupplierInvoiceShort": "Create suppl. invoice",
      "CreateSupplierInvoice": "Create supplier invoice",
      "GetStarted": "Get started",
      "ImportSupplierInvoicesFromFortnox": "Import supplier invoices from Fortnox",
      "AddOption": "Add option",
      "SupplierInvoicesInFortnox": "Supplier invoices in Fortnox",
      "FinancialYear": "Financial year",
      "VoucherSeriesCollection": "Voucher series",
      "FromDate": "From date",
      "ToDate": "To date",
      "VoucherNumber": "Voucher number",
      "TransactionDate": "Transaction date",
      "ReferenceType": "Reference type",
      "EarRounding": "Ear rounding",
      "TotalBeforeDeduction": "Total before deduction",
      "SupplierInvoice": "Supplier invoice",
      "ImportSupplierInvoices": "Import supplier invoices",
      "ReadyForImportToProject": "Ready for import to project",
      "TheSupplierInvoicesHasBeenImported": "The supplier invoices has been imported",
      "TheFormTemplateHasBeenUpdated": "The form template has been updated",
      "TheFormTemplateHasBeenCreated": "The form template has been created",
      "TheFormTemplateHasBeenDeleted": "The form template has been deleted",
      "TotalAbsenceAndTime": "Total absence and time",
      "ShowOfferRowTotals": "Show offer row totals",
      "ShowOfferRowDiscounts": "Show discounts",
      "ShowOfferRowQuantities": "Show quantities",
      "ShowOfferRowPricePerUnit": "Show prices per unit",
      "ShowOfferRowTotals": "Show totals",
      "TotalAbsence": "Total absence",
      "TotalComp": "Total compensation",
      "StartQuotationNumberSeriesAt": "Start quotation number series from",
      "Summary": "Summary",
      "DetailSpecification": "Detail specification",
      "RequiredField": "Required field",
      "Week": "Week",
      "Current": "Current",
      "ThePriceWillBeFetchedFromTheReportTimeType": "The price will be fetched from the report time type",
      "TheCostWillBeFetchedFromTheReportTimeType": "The cost will be fetched from the report time type",
      "Quotations": "Quotations",
      "Quotation": "Offert",
      "CreateQuotation": "Create quotation",
      "Year": "Year",
    }
  }
};

const localStorageLanguage = window.localStorage.language

const language = localStorageLanguage === "sv" ? "sv" : localStorageLanguage === "en" ? "en" : "sv"

setMomentLocale(language)

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: language,

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
