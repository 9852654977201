import moment from "moment"

import postAuthenticateUser from '../../api/postAuthenticateUser';
import getCurrentUser from '../../api/getCurrentUser';
import postForgotPassword from '../../api/postForgotPassword';

import getEmployeeBookings from '../../api/getEmployeeBookings';
import getReports from '../../api/getReports';
import getBackend from '../../api/getBackend';
import getActivateAccount from '../../api/getActivateAccount';
import getSignature from '../../api/getSignature';
import postSendSignatureConfirmation from '../../api/postSendSignatureConfirmation';

import { showModal } from '../../components/GenericModal/ModalSlice';
import { clearForm } from '../../login/LoginSlice';
import translateResetPasswordStatus from '../../utils/translateResetPasswordStatus';
import { push } from 'connected-react-router';

import { setEmail, setPassword } from '../../login/LoginSlice';
import getEmployee from '../../api/getEmployee';
import getProject from '../../api/getProject';
import getProjects from '../../api/getProjects';
import getActiveTimeTypes from '../../api/getActiveTimeTypes';
import postReportedTime from '../../api/postReportedTime';
import putReportedTime from '../../api/putReportedTime';
import deleteReportedTime from '../../api/deleteReportedTime';
import postReportedProduct from '../../api/postReportedProducts';
import putReportedProduct from '../../api/putReportedProduct';
import deleteReportedProduct from '../../api/deleteReporteProduct';
import getTasks from '../../api/getTasks';
import getProducts from '../../api/getProducts';
import postProjectAssignment from '../../api/postProjectAssignment';
import postProjectProductAssignment from '../../api/postProjectProductAssignment';
import getProductAssignmentStats from '../../api/getProductAssignmentStats';
import postTask from '../../api/postTask';
import postDocument from '../../api/postDocument';
import putDocument from '../../api/putDocument';
import deleteDocument from '../../api/deleteDocument';
import postNote from '../../api/postNote';
import putNote from '../../api/putNote';
import deleteNote from '../../api/deleteNote';
import getDocumentCategories from '../../api/getDocumentCategories';
import postMarkProjectAsDone from '../../api/postMarkProjectAsDone';
import postMarkProjectAsUndone from '../../api/postMarkProjectAsUndone';
import postReportedAbsence from '../../api/postReportedAbsence';
import putReportedAbsence from '../../api/putReportedAbsence';
import deleteReportedAbsence from '../../api/deleteReportedAbsence';
import getActiveAbsenceTypes from '../../api/getActiveAbsenceTypes';
import getSettings from '../../api/getSettings';

import getProjectForms from "../../api/getProjectForms";
import getCustomerObjects from "../../api/getCustomerObjects";
import getCustomers from "../../api/getCustomers";
import postCustomer from "../../api/postCustomer";
import postCustomerObject from "../../api/postCustomerObject";
import postProject from "../../api/postProject";
import getEmployees from "../../api/getEmployees";
import getActivateCustomer from "../../api/getActivateCustomer";
import getCurrentUserBackends from "../../api/getCurrentUserBackends";
import getInvoiceLines from "../../api/getInvoiceLines";
import postTenantProspect from "../../api/postTenantProspect";
import postSendToken from "../../api/postSendToken"
import postCheckToken from "../../api/postCheckToken"
import postDoesEmailExist from "../../api/postDoesEmailExist"
import postCreateUserWithToken from "../../api/postCreateUserWithToken"
import errorToast from "../../utils/errorToast";
import postCheckPasswordResetToken from "../../api/postCheckPasswordResetToken";
import postResetPassword from "../../api/postResetPassword";
import successToast from "../../utils/successToast";
import postProduct from "../../api/postProduct";
import getUnits from "../../api/getUnits";
import getFormTemplates from "../../api/getFormTemplates";
import postProjectFormFromTemplate from "../../api/postProjectFormFromTemplate";
import getFeatureFlags from "../../api/getFeatureFlags";
import getProjectCounts from "../../api/getProjectCounts";
import postExportProjectsToFortnox from "../../api/postExportProjectsToFortnox";
import putProject from "../../api/putProject";
import getCustomer from "../../api/getCustomer";
import postAttest from "../../api/postAttest";
import postAttests from "../../api/postAttests";
import userViewController from "../../angularjs/userView/userViewController";
import getAttests from "../../api/getAttests";
import deleteAttest from "../../api/deleteAttest";
import postUnmarkProjectAsInactive from "../../api/postMarkProjectAsInactive";
import postUnmarkProjectAsUninactive from "../../api/postMarkProjectAsUninactive";
import deleteProject from "../../api/deleteProject";
import putProjectForm from "../../api/putProjectForm";
import deleteProjectProductAssignment from "../../api/deleteProjectProductAssignment";
import deleteProjectAssignment from "../../api/deleteProjectAssignment";
import deleteProjectForm from "../../api/deleteProjectForm";
import postProjectForm from "../../api/postProjectForm";
import postInvoice from "../../api/postInvoice";
import getInvoices from "../../api/getInvoices";
import getInvoice from "../../api/getInvoice";
import deleteInvoice from "../../api/deleteInvoice";
import getCompanyLogo from "../../api/getCompantLogo";
import getReportedTime from "../../api/getReportedTime";
import getReportedProduct from "../../api/getReportedProduct";
import getReportedNote from "../../api/getReportedNote";
import getSupplierInvoiceProjectRelations from "../../api/getSupplierInvoiceProjectRelations";
import getCustomerObject from "../../api/getCustomerObject";
import putCustomerObject from "../../api/putCustomerObject";
import getAbsence from "../../api/getAbsence";
import postAbsenceType from "../../api/postAbsenceType";
import putAbsenceType from "../../api/putAbsenceType";
import deleteAbsenceType from "../../api/deleteAbsenceType";
import getCustomerCounts from "../../api/getCustomerCounts";
import putCustomer from "../../api/putCustomer";
import deleteCustomerObject from "../../api/deleteCustomerObject";
import getDocuments from "../../api/getDocuments";
import getAreCustomerProjectsDone from "../../api/getAreCustomerProjectsDone";
import postMarkCustomerAsInactive from "../../api/postMarkCustomerAsInactive";
import postMarkCustomerAsActive from "../../api/postMarkCustomerAsActive";
import getArticles from "../../api/getArticles";
import postArticle from "../../api/postArticle";
import deleteArticle from "../../api/deleteArticle";
import putArticle from "../../api/putArticle";
import getProductCounts from "../../api/getProductCounts";
import getProduct from "../../api/getProduct";
import getProductTypes from "../../api/getProductTypes";
import getAllTasks from "../../api/getAllTasks";
import getProductStats from "../../api/getProductStats";
import getFilteredAttestableReports from "../../api/getFilteredAttestableReports";
import postUnit from "../../api/postUnit";
import putUnit from "../../api/putUnit";
import postProductType from "../../api/postProductType";
import putProductType from "../../api/putProductType";
import getTaskWithTaskType from "../../api/getTaskWithTaskType";
import getTaskTypes from "../../api/getTaskTypes";
import deleteTask from "../../api/deleteTask";
import putTask from "../../api/putTask";
import putTaskType from "../../api/putTaskType";
import postTaskType from "../../api/postTaskType";
import postTaskNew from "../../api/postTaskNew";
import deleteTaskType from "../../api/deleteTaskType";
import deleteProductType from "../../api/deleteProductType";
import deleteUnit from "../../api/deleteUnit";
import postUpdateUserDetails from "../../api/postUpdateUserDetails";
import postSalary from "../../api/postSalary";
import getSalaries from "../../api/getSalaries";
import deleteSalary from "../../api/deleteSalary";
import postInvoiceInFortnox from "../../api/postInvoiceInFortnox";
import getReportedAbsence from "../../api/getReportedAbsence";
import getTaskStats from "../../api/getTaskStats";
import postChangePassword from "../../api/postChangePassword";
import putEmployee from "../../api/putEmployee";
import postProfilePicture from "../../api/postProfilePicture";
import getEmployeeReportedHours from "../../api/getEmployeeReportedHours";
import getRevokeSoftDeletedProduct from "../../api/getRevokeSoftDeletedProduct";
import postRevokeSoftDeletedTask from "../../api/postRevokeSoftDeletedTask";
import deleteCustomer from "../../api/deleteCustomer";
import deleteEmployeeSoftDelete from "../../api/deleteEmployeeSoftDelete";
import getFilteredEmployees from "../../api/getFilteredEmployees";
import getEmployeeCount from "../../api/getEmployeeCount";
import getRevokeSoftDeletedEmployee from "../../api/getRevokeSoftDeletedEmployee";
import getFortnoxArticles from "../../api/getFortnoxArticles";
import postImportSingleDataToMyGizmo from "../../api/postImportSingleFortnoxDataToMyGizmo";
import postImportDataToMyGizmo from "../../api/postImportFortnoxDataToMyGizmo";
import getFortnoxArticleUnits from "../../api/getFortnoxArticleUnits";
import postFortnoxArticleUnitGroups from "../../api/postFortnoxArticleUnitGroups";
import getFortnoxArticleUnitGroups from "../../api/getFortnoxArticleUnitGroups";
import postImportSingleEaccountingDataToMyGizmo from "../../api/postImportSingleEaccountingDataToMyGizmo";
import postImportEaccountingDataToMyGizmo from "../../api/postImportEaccountingDataToMyGizmo";
import getEaccountingArticles from "../../api/getEaccountingArticles";
import getEaccountingArticleUnits from "../../api/getEaccountingArticleUnits";
import getEaccountingArticleUnitGroups from "../../api/getEaccountingArticleUnitGroups";
import postEaccountingArticleUnitGroups from "../../api/postEaccountingArticleUnitGroups";
import getFortnoxStatus from "../../api/getFortnoxStatus";
import getEaccountingStatus from "../../api/getEaccountingStatus";
import postInvoiceInEaccounting from "../../api/postInvoiceInEaccounting";
import postEmployee from "../../api/postEmployee";
import getDoesEmployeesEmailExist from "../../api/getDoesEmployeesEmailExist";
import getDoesEmployeeNumberExist from "../../api/getDoesEmployeeNumberExist";
import postSendUserInviteToCurrentTennant from "../../api/postSendUserInviteToCurrentTennant";

import getEaccountingOrders from "../../api/getEaccountingOrders";
import getEaccountingCustomers from "../../api/getEaccountingCustomers";
import getFortnoxOrders from "../../api/getFortnoxOrders";
import getFortnoxCustomers from "../../api/getFortnoxCustomers";
import getOffers from "../../api/getOffers";
import getOffer from "../../api/getOffer";


import getSupplierInvoices from "../../api/getSupplierInvoices";
import deleteSupplierInvoice from "../../api/deleteSupplierInvoice";
import putSupplierInvoice from "../../api/putSupplierInvoice";
import postSupplierInvoice from "../../api/postSupplierInvoice";
import postSupplierInvoiceProjectRelation from "../../api/postSupplierInvoiceProjectRelation";
import putSupplierInvoiceProjectRelation from "../../api/putSupplierInvoiceProjectRelation";
import deleteSupplierInvoiceProjectRelation from "../../api/deleteSupplierInvoiceProjectRelation";
import getFortnoxSupplierInvoices from "../../api/getFortnoxSupplierInvoices";
import getFortnoxVoucherSeries from "../../api/getFortnoxVoucherSeries";
import getFortnoxFinancialYears from "../../api/getFortnoxFinancialYears";
import getProjectStats from "../../api/getProjectStats";
import getUnhandledSalaryDetailsCount from "../../api/getUnhandledSalaryDetailsCount";


import getAllSettings from "../../api/getAllSettings";
import getAllTimeTypes from "../../api/getAllTimeTypes";
import postUsersAllowedToSeePrice  from "../../api/postUsersAllowedToSeePrice";
import getPriceLists from "../../api/getPriceLists";
import postSettings from "../../api/postSettings";
import postUploadCompanyLogo from "../../api/postUploadCompanyLogo";
import postCustomProjectSequence from "../../api/postCustomProjectSequence";
import postTimeType from "../../api/postTimeType";
import getAreTimeTypesHandled from "../../api/getAreTimeTypesHandled";
import getTimeType from "../../api/getTimeType";
import deleteTimeType from "../../api/deleteTimeType";
import postDocumentCategory from "../../api/postDocumentCategory";
import postSyncSupplierInvoicesFromFortnox from "../../api/postSyncSupplierInvoicesFromFortnox";
import putTimeType from "../../api/putTimeType";
import putDocumentCategory from "../../api/putDocumentCategory";
import deleteDocumentCategory from "../../api/deleteDocumentCategory";
import putUsersAllowedToEditReports from "../../api/putUsersAllowedToEditReports";
import putShowTaskFirst from "../../api/putShowTaskFirst";
import postPriceAdjustment from "../../api/postPriceAdjustment";
import postFormTemplate from "../../api/postFormTemplate";
import putFormTemplate from "../../api/putFormTemplate";
import deleteFormTemplate from "../../api/deleteFormTemplate";
import deletePriceAdjustment from "../../api/deletePriceAdjustment";
import postPriceListDiscounts from "../../api/postPriceListDiscounts";
import deletePriceListDiscounts from "../../api/deletePriceListDiscounts";
import putPriceAdjustment from "../../api/putPriceAdjustment";
import postOffer from "../../api/postOffer";
import { Offer } from "../../offer/OfferModels";
import putOffer from "../../api/putOffer";
import deleteOffer from "../../api/deleteOffer";
import getOpenProjectsWithCustomerName from "../../api/getOpenProjectsWithCustomerName";
import getUnhandledReports from "../../api/getUnhandledReports";
import getUnhandledReportedTimeBetweenDates from "../../api/getUnhandledReportedTimeBetweenDates";
import getUnhandledInvoiceAmountSum from "../../api/getUnhandledInvoiceAmountSum";
import putPriceListDiscount from "../../api/putPriceListDiscount";
import postLookupPriceInPricelist from "../../api/postLookupPriceInPricelist";
import getOfferLibraryTextBlocks from "../../api/getOfferLibraryTextBlocks";
import postOfferLibraryTextBlock from "../../api/postOfferLibraryTextBlock";
import putOfferLibraryTextBlock from "../../api/putOfferLibraryTextBlock";
import deleteOfferLibraryTextBlock from "../../api/deleteOfferLibraryTextBlock";
import getHouseWorkDeductionTypes from "../../api/getHouseWorkDeductionTypes";
import putHouseWorkDeductionType from "../../api/putHouseWorkDeductionType";
import postHouseWorkDeductionType from "../../api/postHouseWorkDeductionType";
import deleteHouseWorkDeductionType from "../../api/deleteHouseWorkDeductionType";
import postSendUserInviteToCurrentTennantAgain from "../../api/postSendUserInviteToCurrentTennantAgain";
import postJsonFileSave from "../../api/postJsonFileSave";
import postJsonFileRead from "../../api/postJsonFileRead";
import getCustomersWithDates from "../../api/getCustomersWithDates";
import getProductsWithDates from "../../api/getProductsWithDates";
import getInvoicesWithDates from "../../api/getInvoicesWithDates";
import getAllIntegrationItemGroups from "../../api/getAllIntegrationItemGroups";
import getProjectsByErpProjectId from "../../api/getProjectsByErpProjectId";
import getCustomersByErpCustomerId from "../../api/getCustomersByErpCustomerId";
import postProjects from "../../api/postProjects";
import getCustomersByName from "../../api/getCustomersByName";
import getProjectByProjectName from "../../api/getProjectByProjectName";
import getProjectsByErpOrderNumber from "../../api/getProjectsByErpOrderNumber";
import getTasksByErpProductId from "../../api/getTasksByErpProductId";
import getTimeTypesByErpProductId from "../../api/getTimeTypesByErpProductId";
import getProductsByErpProductId from "../../api/getProductsByErpProductId";
import postIntegrationItemGroups from "../../api/postIntegrationItemGroups";
import getTimeTypesByProductName from "../../api/getTimeTypesByProductName";
import getTasksByTitle from "../../api/getTasksByTitle";
import getProductsByName from "../../api/getProductsByName";
import deleteIntegrationItemGroup from "../../api/deleteIntegrationItemGroup";
import getIntegrationItemGroups from "../../api/getIntegrationItemGroups";
import putIntegrationItemGroup from "../../api/putIntegrationItemGroup";
import postCopyOfferToNewVersion from "../../api/postCopyOfferToNewVersion";
import getOfferCounts from "../../api/getOfferCounts";
import postOfferLink from "../../api/postOfferLink";
import deleteOfferLink from "../../api/deleteOfferLink";
import getUseOfferLink from "../../api/getUseOfferLink";
import postSendOfferLinkAnswer from "../../api/postSendOfferLinkAnswer";
import postCopyOfferToTemplate from "../../api/postCopyOfferToTemplate";
import postCreateProjectFromOffer from "../../api/postCreateProjectFromOffer";

import getReportedHoursBetweenDates from "../../api/getReportedHoursBetweenDates";
import postProjectElement from "../../api/postProjectElement";
import putProjectElement from "../../api/putProjectElement";
import deleteProjectElement from "../../api/deleteProjectElement";
import postBooking from "../../api/postBooking";
import putBooking from "../../api/putBooking";
import deleteBooking from "../../api/deleteBooking";
import postBookingResource from "../../api/postBookingResource";
import putBookingResource from "../../api/putBookingResource";
import deleteBookingResource from "../../api/deleteBookingResource";
import getBookings from "../../api/getBookings";
import getBookableResources from "../../api/getBookableResources";
import getBookableVehicles from "../../api/getBookableVehicles";
import postArticleWithObject from "../../api/postArticleWithObject";
import sortByMultipleKeys from "../../utils/sortByMultipleKeys";
import getProjectsAdressInformation from "../../api/getProjectsAdressInformation";
import getProductTypeByName from "../../api/getProductTypeByName";
import getUnitByName from "../../api/getUnitByName";
import postRevokeSoftDeletedTimeType from "../../api/postRevokeSoftDeletedTimeType";
import getTotalAmountToInvoice from "../../api/getTotalAmountToInvoice";
import getAllActiveTasksWithDates from "../../api/getAllActiveTasksWithDates";
import ProjectVariation from "../../types/ProjectVariation";
import postProjectVariation from "../../api/postProjectVariation";
import getProjectVariations from "../../api/getProjectVariations";
import getProjectVariation from "../../api/getProjectVariation";
import ProjectVariationLink from "../../types/ProjectVariationLink";
import postProjectVariationLink from "../../api/postProjectVariationLink";
import putProjectVariation from "../../api/putProjectVariation";
import deleteProjectVariation from "../../api/deleteProjectVariation";
import deleteProjectVariationLink from "../../api/deleteProjectVariationLink";
import getUseProjectVariationLink from "../../api/getUseProjectVariationLink";
import postSendProjectVariationLinkAnswer from "../../api/postSendProjectVariationLinkAnswer";
import getProjectVariationCounts from "../../api/getProjectVariationCounts";
import getPaymentPlanElements from "../../api/getPaymentPlanElements";
import PaymentPlanElement from "../../types/PaymentPlanElement";
import postPaymentPlanElement from "../../api/postPaymentPlanElement";
import putPaymentPlanElement from "../../api/putPaymentPlanElement";
import deletePaymentPlanElement from "../../api/deletePaymentPlanElement";
import postImportSingleSupplierInvoiceFromFortnox from "../../api/postImportSingleSupplierInvoiceFromFortnox"
import getProjectAssignments from "../../api/getProjectAssignments";
import getProjectAccessEmployeeRelations from "../../api/getProjectAccessEmployeeRelations";
import postProjectAccessEmployeeRelation from "../../api/postProjectAccessEmployeeRelation";
import deleteProjectAccessEmployeeRelation from "../../api/deleteProjectAccessEmployeeRelation";
import filterUserForms from "../../utils/filterUserForms";
import postImportSelectedEaccountingDataToMyGizmo from "../../api/postImportSelectedEaccountingDataToMyGizmo";
import getTenantMigrationStatus from "../../api/getTenantMigrationStatus";
import getTenantMigrationRedirectUrl from "../../api/getTenantMigrationRedirectUrl";
import postPipedriveLead from "../../api/postPipedriveLead";
import putPipedriveLead from "../../api/putPipedriveLead";
import getGadgetQuotationSendingUrl from "../../api/getGadgetQuotationSendingUrl";
import getGadgetVariationSendingUrl from "../../api/getGadgetVariationSendingUrl";
import getDBMigrationStatus from "../../api/getDBMigrationStatus";

export const types = {
    DELETE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_BEGIN: 'DELETE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_BEGIN',
    DELETE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_SUCCESS: 'DELETE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_SUCCESS',
    CREATE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_BEGIN: 'CREATE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_BEGIN',
    CREATE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_SUCCESS: 'CREATE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_SUCCESS',
    LOAD_PROJECT_ACCESS_EMPLOYEE_RELATIONS_BEGIN: "LOAD_PROJECT_ACCESS_EMPLOYEE_RELATIONS_BEGIN",
    LOAD_PROJECT_ACCESS_EMPLOYEE_RELATIONS_SUCCESS: "LOAD_PROJECT_ACCESS_EMPLOYEE_RELATIONS_SUCCESS",
    LOAD_PROJECTS_ADRESS_INFORMATION_BEGIN: "LOAD_PROJECTS_ADRESS_INFORMATION_BEGIN",
    LOAD_PROJECTS_ADRESS_INFORMATION_SUCCESS: "LOAD_PROJECTS_ADRESS_INFORMATION_SUCCESS",
    CREATE_INVOICE_IN_FORTNOX_BEGIN: "CREATE_INVOICE_IN_FORTNOX_BEGIN",
    CREATE_INVOICE_IN_FORTNOX_SUCCESS: "CREATE_INVOICE_IN_FORTNOX_SUCCESS",
    CREATE_INVOICE_IN_EACCOUNTING_BEGIN: "CREATE_INVOICE_IN_EACCOUNTING_BEGIN",
    CREATE_INVOICE_IN_EACCOUNTING_SUCCESS: "CREATE_INVOICE_IN_EACCOUNTING_SUCCESS",
    LOAD_REPORTED_ABSENCE_BEGIN: "LOAD_REPORTED_ABSENCE_BEGIN",
    LOAD_REPORTED_ABSENCE_SUCCESS: "LOAD_REPORTED_ABSENCE_SUCCESS",
    SIGN_IN_USER: 'SIGN_IN_USER',
    SIGN_IN_USER_SUCCESS: 'SIGN_IN_USER_SUCCESS',
    SIGN_IN_USER_SUCCESS_BUT_NEED_TO_PICK_BACKEND: 'SIGN_IN_USER_SUCCESS_BUT_NEED_TO_PICK_BACKEND',
    PICK_USER_CONNECTED_BACKEND: 'PICK_USER_CONNECTED_BACKEND',
    SIGN_IN_USER_FAILED: 'SIGN_IN_USER_FAILED',
    LOAD_USER_DATA_SUCCESS: 'LOAD_USER_DATA_SUCCESS',
    LOAD_USER_DATA_FAILED: 'LOAD_USER_DATA_FAILED',
    SIGN_OUT: 'SIGN_OUT',
    LOAD_EMPLOYEE_BOOKINGS_COMPLETE: 'LOAD_EMPLOYEE_BOOKINGS_COMPLETE',
    LOAD_EMPLOYEE_BOOKINGS_BEGIN: 'LOAD_EMPLOYEE_BOOKINGS_BEGIN',
    LOAD_REPORTS_BEGIN: 'LOAD_REPORTS_BEGIN',
    LOAD_REPORTS_COMPLETE: 'LOAD_REPORTS_COMPLETE',
    RESET_USER_PASSWORD: 'RESET_USER_PASSWORD',
    RESET_USER_PASSWORD_SUCCESS: 'RESET_USER_PASSWORD_SUCCESS',
    RESET_USER_PASSWORD_FAILED: 'RESET_USER_PASSWORD_FAILED',
    REQUEST_STARTED: 'REQUEST_STARTED',
    REQUEST_COMPLETED: 'REQUEST_COMPLETED',
    SHOW_INACTIVE_ACCOUNT_MODAL: 'SHOW_INACTIVE_ACCOUNT_MODAL',
    HIDE_INACTIVE_ACCOUNT_MODAL: 'HIDE_INACTIVE_ACCOUNT_MODAL',
    ACTIVATE_ACCOUNT_COMPLETED: 'ACTIVATE_ACCOUNT_COMPLETED',
    LOAD_MY_RECENT_PROJECTS_BEGIN: 'LOAD_MY_RECENT_PROJECTS_BEGIN',
    LOAD_MY_RECENT_PROJECTS_COMPLETE: 'LOAD_MY_RECENT_PROJECTS_COMPLETE',
    CLEAR_BOOKING_PROJECT: "CLEAR_BOOKING_PROJECT",
    LOAD_BOOKING_PROJECT_BEGIN: "LOAD_BOOKING_PROJECT_BEGIN",
    LOAD_BOOKING_PROJECT_COMPLETE: "LOAD_BOOKING_PROJECT_COMPLETE",
    LOAD_PROJECTS_BEGIN: 'LOAD_PROJECTS_BEGIN',
    LOAD_PROJECTS_COMPLETE: 'LOAD_PROJECTS_COMPLETE',
    LOAD_MORE_PROJECTS_BEGIN: 'LOAD_MORE_PROJECTS_BEGIN',
    LOAD_MORE_PROJECTS_COMPLETE: 'LOAD_MORE_PROJECTS_COMPLETE',
    LOAD_PROJECT_BEGIN: 'LOAD_PROJECT_BEGIN',
    LOAD_PROJECT_COMPLETE: 'LOAD_PROJECT_COMPLETE',
    LOAD_ACTIVE_TIME_TYPES_BEGIN: 'LOAD_ACTIVE_TIME_TYPES_BEGIN',
    LOAD_ACTIVE_TIME_TYPES_COMPLETE: 'LOAD_ACTIVE_TIME_TYPES_COMPLETE',
    CREATE_REPORTED_TIME_BEGIN: 'CREATE_REPORTED_TIME_BEGIN',
    CREATE_REPORTED_TIME_COMPLETE: 'CREATE_REPORTED_TIME_COMPLETE',
    UPDATE_REPORTED_TIME_BEGIN: 'UPDATE_REPORTED_TIME_BEGIN',
    UPDATE_REPORTED_TIME_COMPLETE: 'UPDATE_REPORTED_TIME_COMPLETE',
    DELETE_REPORTED_TIME_BEGIN: 'DELETE_REPORTED_TIME_BEGIN',
    DELETE_REPORTED_TIME_COMPLETE: 'DELETE_REPORTED_TIME_COMPLETE',
    CREATE_REPORTED_PRODUCT_BEGIN: 'CREATE_REPORTED_PRODUCT_BEGIN',
    CREATE_REPORTED_PRODUCT_COMPLETE: 'CREATE_REPORTED_PRODUCT_COMPLETE',
    UPDATE_REPORTED_PRODUCT_BEGIN: 'UPDATE_REPORTED_PRODUCT_BEGIN',
    UPDATE_REPORTED_PRODUCT_COMPLETE: 'UPDATE_REPORTED_PRODUCT_COMPLETE',
    DELETE_REPORTED_PRODUCT_BEGIN: 'DELETE_REPORTED_PRODUCT_BEGIN',
    DELETE_REPORTED_PRODUCT_COMPLETE: 'DELETE_REPORTED_PRODUCT_COMPLETE',
    LOAD_TASKS_BEGIN: 'LOAD_TASKS_BEGIN',
    LOAD_TASKS_COMPLETE: 'LOAD_TASKS_COMPLETE',
    CREATE_PROJECT_ASSIGNMENT_BEGIN: 'CREATE_PROJECT_ASSIGNMENT_BEGIN',
    CREATE_PROJECT_ASSIGNMENT_COMPLETE: 'CREATE_PROJECT_ASSIGNMENT_COMPLETE',
    LOAD_PRODUCTS_BEGIN: 'LOAD_PRODUCTS_BEGIN',
    LOAD_PRODUCTS_COMPLETE: 'LOAD_PRODUCTS_COMPLETE',
    LOAD_MORE_PRODUCTS_BEGIN: 'LOAD_MORE_PRODUCTS_BEGIN',
    LOAD_MORE_PRODUCTS_COMPLETE: 'LOAD_MORE_PRODUCTS_COMPLETE',
    CREATE_PROJECT_PRODUCT_ASSIGNMENT_BEGIN: 'CREATE_PROJECT_PRODUCT_ASSIGNMENT_BEGIN',
    CREATE_PROJECT_PRODUCT_ASSIGNMENT_COMPLETE: 'CREATE_PROJECT_PRODUCT_ASSIGNMENT_COMPLETE',
    LOAD_PRODUCT_ASSIGNMENT_STATS_BEGIN: 'LOAD_PRODUCT_ASSIGNMENT_STATS_BEGIN',
    LOAD_PRODUCT_ASSIGNMENT_STATS_COMPLETE: 'LOAD_PRODUCT_ASSIGNMENT_STATS_COMPLETE',
    CREATE_FREETEXT_PROJECT_ASSIGNMENT_BEGIN: 'CREATE_FREETEXT_PROJECT_ASSIGNMENT_BEGIN',
    CREATE_FREETEXT_PROJECT_ASSIGNMENT_COMPLETE: 'CREATE_FREETEXT_PROJECT_ASSIGNMENT_COMPLETE',
    CREATE_DOCUMENT_BEGIN: 'CREATE_DOCUMENT_BEGIN',
    UPDATE_DOCUMENT_BEGIN: 'UPDATE_DOCUMENT_BEGIN',
    DELETE_DOCUMENT_BEGIN: 'DELETE_DOCUMENT_BEGIN',
    CREATE_DOCUMENT_COMPLETE: 'CREATE_DOCUMENT_COMPLETE',
    UPDATE_DOCUMENT_COMPLETE: 'UPDATE_DOCUMENT_COMPLETE',
    DELETE_DOCUMENT_COMPLETE: 'DELETE_DOCUMENT_COMPLETE',
    CREATE_NOTE_BEGIN: 'CREATE_NOTE_BEGIN',
    UPDATE_NOTE_BEGIN: 'UPDATE_NOTE_BEGIN',
    DELETE_NOTE_BEGIN: 'DELETE_NOTE_BEGIN',
    CREATE_NOTE_COMPLETE: 'CREATE_NOTE_COMPLETE',
    UPDATE_NOTE_COMPLETE: 'UPDATE_NOTE_COMPLETE',
    DELETE_NOTE_COMPLETE: 'DELETE_NOTE_COMPLETE',
    LOAD_DOCUMENT_CATEGORIES_BEGIN: 'LOAD_DOCUMENT_CATEGORIES_BEGIN',
    LOAD_DOCUMENT_CATEGORIES_COMPLETE: 'LOAD_DOCUMENT_CATEGORIES_COMPLETE',
    MARK_PROJECT_AS_DONE_BEGIN: 'MARK_PROJECT_AS_DONE_BEGIN',
    MARK_PROJECT_AS_DONE_COMPLETE: 'MARK_PROJECT_AS_DONE_COMPLETE',
    MARK_PROJECT_AS_UNDONE_BEGIN: 'MARK_PROJECT_AS_UNDONE_BEGIN',
    MARK_PROJECT_AS_UNDONE_COMPLETE: 'MARK_PROJECT_AS_UNDONE_COMPLETE',
    CREATE_REPORTED_ABSENCE_BEGIN: 'CREATE_REPORTED_ABSENCE_BEGIN',
    CREATE_REPORTED_ABSENCE_COMPLETE: 'CREATE_REPORTED_ABSENCE_COMPLETE',
    UPDATE_REPORTED_ABSENCE_BEGIN: 'UPDATE_REPORTED_ABSENCE_BEGIN',
    UPDATE_REPORTED_ABSENCE_COMPLETE: 'UPDATE_REPORTED_ABSENCE_COMPLETE',
    DELETE_REPORTED_ABSENCE_BEGIN: 'DELETE_REPORTED_ABSENCE_BEGIN',
    DELETE_REPORTED_ABSENCE_COMPLETE: 'DELETE_REPORTED_ABSENCE_COMPLETE',
    LOAD_ACTIVE_ABSENCE_TYPES_BEGIN: 'LOAD_ACTIVE_ABSENCE_TYPES_BEGIN',
    LOAD_ACTIVE_ABSENCE_TYPES_COMPLETE: 'LOAD_ACTIVE_ABSENCE_TYPES_COMPLETE',
    LOAD_SETTINGS_BEGIN: 'LOAD_SETTINGS_BEGIN',
    LOAD_SETTINGS_COMPLETE: 'LOAD_SETTINGS_COMPLETE',
    LOAD_PROJECT_FORMS_BEGIN: 'LOAD_PROJECT_FORMS_BEGIN',
    LOAD_PROJECT_FORMS_COMPLETE: 'LOAD_PROJECT_FORMS_COMPLETE',
    LOAD_CUSTOMER_OBJECTS_BEGIN: 'LOAD_CUSTOMER_OBJECTS_BEGIN',
    LOAD_CUSTOMER_OBJECTS_COMPLETE: 'LOAD_CUSTOMER_OBJECTS_COMPLETE',
    LOAD_CUSTOMERS_BEGIN: 'LOAD_CUSTOMERS_BEGIN',
    LOAD_CUSTOMERS_COMPLETE: 'LOAD_CUSTOMERS_COMPLETE',
    CREATE_CUSTOMER_BEGIN: 'CREATE_CUSTOMER_BEGIN',
    CREATE_CUSTOMER_COMPLETE: 'CREATE_CUSTOMER_COMPLETE',
    CREATE_CUSTOMER_OBJECT_BEGIN: 'CREATE_CUSTOMER_OBJECT_BEGIN',
    CREATE_CUSTOMER_OBJECT_COMPLETE: 'CREATE_CUSTOMER_OBJECT_COMPLETE',
    CREATE_PROJECT_BEGIN: 'CREATE_PROJECT_BEGIN',
    CREATE_PROJECT_COMPLETE: 'CREATE_PROJECT_COMPLETE',
    LOAD_EMPLOYEES_BEGIN: 'LOAD_EMPLOYEES_BEGIN',
    LOAD_EMPLOYEES_COMPLETE: 'LOAD_EMPLOYEES_COMPLETE',
    ACTIVATE_CUSTOMER_BEGIN: 'ACTIVATE_CUSTOMER_BEGIN',
    ACTIVATE_CUSTOMER_COMPLETE: 'ACTIVATE_CUSTOMER_COMPLETE',
    HIDE_FOOTER: 'HIDE_FOOTER',
    SHOW_FOOTER: 'SHOW_FOOTER',

    CLEAR_PROJECT_VARIATION: "CLEAR_PROJECT_VARIATION",
    CLEAR_PROJECT_VARIATIONS: "CLEAR_PROJECT_VARIATIONS",
    CLEAR_PROJECT_ACCESS_EMPLOYEE_RELATIONS: "CLEAR_PROJECT_ACCESS_EMPLOYEE_RELATIONS",

    LOAD_PROJECT_VARIATIONS_BEGIN: "LOAD_PROJECT_VARIATIONS_BEGIN",
    LOAD_PROJECT_VARIATIONS_SUCCESS: "LOAD_PROJECT_VARIATIONS_SUCCESS",
    LOAD_PROJECT_VARIATION_BEGIN: "LOAD_PROJECT_VARIATION_BEGIN",
    LOAD_PROJECT_VARIATION_SUCCESS: "LOAD_PROJECT_VARIATION_SUCCESS",
    CREATE_PROJECT_VARIATION_BEGIN: "CREATE_PROJECT_VARIATION_BEGIN",
    CREATE_PROJECT_VARIATION_SUCCESS: "CREATE_PROJECT_VARIATION_SUCCESS",
    UPDATE_PROJECT_VARIATION_BEGIN: "UPDATE_PROJECT_VARIATION_BEGIN",
    UPDATE_PROJECT_VARIATION_SUCCESS: "UPDATE_PROJECT_VARIATION_SUCCESS",
    DELETE_PROJECT_VARIATION_BEGIN: "DELETE_PROJECT_VARIATION_BEGIN",
    DELETE_PROJECT_VARIATION_SUCCESS: "DELETE_PROJECT_VARIATION_SUCCESS",

    CREATE_PROJECT_VARIATION_LINK_BEGIN: "CREATE_PROJECT_VARIATION_LINK_BEGIN",
    CREATE_PROJECT_VARIATION_LINK_SUCCESS: "CREATE_PROJECT_VARIATION_LINK_SUCCESS",
    DELETE_PROJECT_VARIATION_LINK_BEGIN: "DELETE_PROJECT_VARIATION_LINK_BEGIN",
    DELETE_PROJECT_VARIATION_LINK_SUCCESS: "DELETE_PROJECT_VARIATION_LINK_SUCCESS",

    LOAD_USE_PROJECT_VARIATION_LINK_BEGIN: "LOAD_USE_PROJECT_VARIATION_LINK_BEGIN",
    LOAD_USE_PROJECT_VARIATION_LINK_SUCCESS: "LOAD_USE_PROJECT_VARIATION_LINK_SUCCESS",

    SEND_PROJECT_VARIATION_LINK_ANSWER_BEGIN: "SEND_PROJECT_VARIATION_LINK_ANSWER_BEGIN",
    SEND_PROJECT_VARIATION_LINK_ANSWER_SUCCESS: "SEND_PROJECT_VARIATION_LINK_ANSWER_SUCCESS",

    CREATE_PROJECT_ELEMENT_BEGIN: "CREATE_PROJECT_ELEMENT_BEGIN",
    CREATE_PROJECT_ELEMENT_SUCCESS: "CREATE_PROJECT_ELEMENT_SUCCESS",
    UPDATE_PROJECT_ELEMENT_BEGIN: "UPDATE_PROJECT_ELEMENT_BEGIN",
    UPDATE_PROJECT_ELEMENT_SUCCESS: "UPDATE_PROJECT_ELEMENT_SUCCESS",
    REMOVE_PROJECT_ELEMENT_BEGIN: "REMOVE_PROJECT_ELEMENT_BEGIN",
    REMOVE_PROJECT_ELEMENT_SUCCESS: "REMOVE_PROJECT_ELEMENT_SUCCESS",

    CREATE_PROJECT_FROM_OFFER_BEGIN: "CREATE_PROJECT_FROM_OFFER_BEGIN",
    CREATE_PROJECT_FROM_OFFER_SUCCESS: "CREATE_PROJECT_FROM_OFFER_SUCCESS",

    LOAD_OFFER_LIBRARY_TEXT_BLOCKS_BEGIN: "LOAD_OFFER_LIBRARY_TEXT_BLOCKS_BEGIN",
    LOAD_OFFER_LIBRARY_TEXT_BLOCKS_SUCCESS: "LOAD_OFFER_LIBRARY_TEXT_BLOCKS_SUCCESS",
    CREATE_OFFER_LIBRARY_TEXT_BLOCK_BEGIN: "CREATE_OFFER_LIBRARY_TEXT_BLOCK_BEGIN",
    CREATE_OFFER_LIBRARY_TEXT_BLOCK_SUCCESS: "CREATE_OFFER_LIBRARY_TEXT_BLOCK_SUCCESS",    
    UPDATE_OFFER_LIBRARY_TEXT_BLOCK_BEGIN: "UPDATE_OFFER_LIBRARY_TEXT_BLOCK_BEGIN",
    UPDATE_OFFER_LIBRARY_TEXT_BLOCK_SUCCESS: "UPDATE_OFFER_LIBRARY_TEXT_BLOCK_SUCCESS",
    DELETE_OFFER_LIBRARY_TEXT_BLOCK_BEGIN: "DELETE_OFFER_LIBRARY_TEXT_BLOCK_BEGIN",
    DELETE_OFFER_LIBRARY_TEXT_BLOCK_SUCCESS: "DELETE_OFFER_LIBRARY_TEXT_BLOCK_SUCCESS",

    LOAD_MORE_CUSTOMERS_BEGIN: 'LOAD_MORE_CUSTOMERS_BEGIN',
    LOAD_MORE_CUSTOMERS_COMPLETE: 'LOAD_MORE_CUSTOMERS_COMPLETE',
    LOAD_TODOS_BEGIN: 'LOAD_TODOS_BEGIN',
    LOAD_TODOS_COMPLETE: 'LOAD_TODOS_COMPLETE',
    SUBMIT_REGISTER_USER_DETAILS: "SUBMIT_REGISTER_USER_DETAILS",
    SEND_ACCESS_TOKEN_BEGIN: "SEND_ACCESS_TOKEN_BEGIN",
    SEND_ACCESS_TOKEN_SUCCESS: "SEND_ACCESS_TOKEN_SUCCESS",
    CHECK_ACCESS_TOKEN_BEGIN: "CHECK_ACCESS_TOKEN_BEGIN",
    CHECK_ACCESS_TOKEN_SUCCESS: "CHECK_ACCESS_TOKEN_SUCCESS",
    CHECK_LOGIN_DETAILS_BEGIN: "CHECK_LOGIN_DETAILS_BEGIN",
    CHECK_LOGIN_DETAILS_SUCCESS: "CHECK_LOGIN_DETAILS_SUCCESS",
    CHECK_IF_EMAIL_EXISTS_BEGIN: "CHECK_IF_EMAIL_EXISTS_BEGIN",
    CLEAR_DOCUMENTS: "CLEAR_DOCUMENTS", 
    CHECK_IF_EMAIL_EXISTS_SUCCESS: "CHECK_IF_EMAIL_EXISTS_SUCCESS",
    CREATE_USER_WITH_TOKEN_BEGIN: "CREATE_USER_WITH_TOKEN_BEGIN",
    CREATE_USER_WITH_TOKEN_SUCCESS: "CREATE_USER_WITH_TOKEN_SUCCESS",
    CREATE_TENANT_PROSPECT_BEGIN: "CREATE_TENANT_PROSPECT_BEGIN",
    CREATE_TENANT_PROSPECT_SUCCESS: "CREATE_TENANT_PROSPECT_SUCCESS",
    CHECK_PASSWORD_RESET_BEGIN: "CHECK_PASSWORD_RESET_BEGIN",
    CHECK_PASSWORD_RESET_SUCCESS: "CHECK_PASSWORD_RESET_SUCCESS",
    RESET_PASSWORD_BEGIN: "RESET_PASSWORD_BEGIN",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    CREATE_FREETEXT_PRODUCT_ASSIGNMENT_BEGIN: "CREATE_FREETEXT_PRODUCT_ASSIGNMENT_BEGIN",
    CREATE_FREETEXT_PRODUCT_ASSIGNMENT_SUCCESS: "CREATE_FREETEXT_PRODUCT_ASSIGNMENT_SUCCESS",
    LOAD_UNITS_BEGIN: "LOAD_UNITS_BEGIN",
    LOAD_UNITS_SUCCESS: "LOAD_UNITS_SUCCESS",
    LOAD_FORM_TEMPLATES_BEGIN: "LOAD_FORM_TEMPLATES_BEGIN",
    LOAD_FORM_TEMPLATES_SUCCESS: "LOAD_FORM_TEMPLATES_SUCCESS",
    CREATE_PROJECT_FORM_FROM_TEMPLATE_BEGIN: "CREATE_PROJECT_FORM_FROM_TEMPLATE_BEGIN",
    CREATE_PROJECT_FORM_FROM_TEMPLATE_SUCCESS: "CREATE_PROJECT_FORM_FROM_TEMPLATE_SUCCESS",
    LOAD_SIGNATURE_BEGIN: "LOAD_SIGNATURE_BEGIN",
    LOAD_SIGNATURE_SUCCESS: "LOAD_SIGNATURE_SUCCESS",
    SEND_SIGNATURE_CONFIRMATION_BEGIN: "SEND_SIGNATURE_CONFIRMATION_BEGIN",
    SEND_SIGNATURE_CONFIRMATION_SUCCESS: "SEND_SIGNATURE_CONFIRMATION_SUCCESS",
    LOAD_DAILY_REPORTS_BEGIN: "LOAD_DAILY_REPORTS_BEGIN",
    LOAD_DAILY_REPORTS_SUCCESS: "LOAD_DAILY_REPORTS_SUCCESS",
    CLEAR_REPORTS: "CLEAR_REPORTS",
    CLEAR_DAILY_REPORTS: "CLEAR_DAILY_REPORTS",
    CLEAR_REPORTS_TO_ATTEST: "CLEAR_REPORTS_TO_ATTEST",
    LOAD_CURRENT_TENANT_FEATURE_FLAGS_BEGIN: "LOAD_CURRENT_TENANT_FEATURE_FLAGS_BEGIN",
    LOAD_CURRENT_TENANT_FEATURE_FLAGS_SUCCESS: "LOAD_CURRENT_TENANT_FEATURE_FLAGS_SUCCESS",
    LOAD_REPORTS_FOR_ME_TO_ATTEST_BEGIN: "LOAD_REPORTS_FOR_ME_TO_ATTEST_BEGIN",
    LOAD_REPORTS_FOR_ME_TO_ATTEST_SUCCESS: "LOAD_REPORTS_FOR_ME_TO_ATTEST_SUCCESS",
    LOAD_REPORTS_TO_ATTEST_BEGIN: "LOAD_REPORTS_TO_ATTEST_BEGIN",
    LOAD_REPORTS_TO_ATTEST_SUCCESS: "LOAD_REPORTS_TO_ATTEST_SUCCESS",
    LOAD_PROJECT_COUNTS_BEGIN: "LOAD_PROJECT_COUNTS_BEGIN",
    LOAD_PROJECT_COUNTS_SUCCESS: "LOAD_PROJECT_COUNTS_SUCCESS",
    EXPORT_PROJECTS_TO_FORTNOX_BEGIN: "EXPORT_PROJECTS_TO_FORTNOX_BEGIN",
    EXPORT_PROJECTS_TO_FORTNOX_SUCCESS: "EXPORT_PROJECTS_TO_FORTNOX_SUCCESS",
    UPDATE_PROJECT_BEGIN: "UPDATE_PROJECT_BEGIN",
    UPDATE_PROJECT_SUCCESS: "UPDATE_PROJECT_SUCCESS",
    LOAD_CUSTOMER_BEGIN: "LOAD_CUSTOMER_BEGIN",
    LOAD_CUSTOMER_SUCCESS: "LOAD_CUSTOMER_SUCCESS",
    CREATE_ATTEST_BEGIN: "CREATE_ATTEST_BEGIN",
    CREATE_ATTEST_SUCCESS: "CREATE_ATTEST_SUCCESS",
    CREATE_ATTESTS_BEGIN: "CREATE_ATTESTS_BEGIN",
    CREATE_ATTESTS_SUCCESS: "CREATE_ATTESTS_SUCCESS",
    LOAD_ATTESTS_BEGIN: "LOAD_ATTESTS_BEGIN",
    LOAD_ATTESTS_SUCCESS: "LOAD_ATTESTS_SUCCESS",
    DELETE_ATTEST_BEGIN: "DELETE_ATTEST_BEGIN",
    DELETE_ATTEST_SUCCESS: "DELETE_ATTEST_SUCCESS",
    MARK_PROJECT_AS_INACTIVE_BEGIN: "MARK_PROJECT_AS_INACTIVE_BEGIN",
    MARK_PROJECT_AS_INACTIVE_SUCCESS: "MARK_PROJECT_AS_INACTIVE_SUCCESS",
    MARK_PROJECT_AS_UNINACTIVE_BEGIN: "MARK_PROJECT_AS_UNINACTIVE_BEGIN",
    MARK_PROJECT_AS_UNINACTIVE_SUCCESS: "MARK_PROJECT_AS_UNINACTIVE_SUCCESS",
    DELETE_PROJECT_BEGIN: "DELETE_PROJECT_BEGIN",
    DELETE_PROJECT_SUCCESS: "DELETE_PROJECT_SUCCESS",
    CLEAR_PROJECT: "CLEAR_PROJECT",
    CLEAR_PROJECT_FORMS: "CLEAR_PROJECT_FORMS",
    UPDATE_PROJECT_FORM_BEGIN: "UPDATE_PROJECT_FORM_BEGIN",
    UPDATE_PROJECT_FORM_SUCCESS: "UPDATE_PROJECT_FORM_SUCCESS",
    DELETE_PROJECT_ASSIGNMENT_BEGIN: "DELETE_PROJECT_ASSIGNMENT_BEGIN",
    DELETE_PROJECT_ASSIGNMENT_SUCCESS: "DELETE_PROJECT_ASSIGNMENT_SUCCESS",
    DELETE_PROJECT_PRODUCT_ASSIGNMENT_BEGIN: "DELETE_PROJECT_PRODUCT_ASSIGNMENT_BEGIN",
    DELETE_PROJECT_PRODUCT_ASSIGNMENT_SUCCESS: "DELETE_PROJECT_PRODUCT_ASSIGNMENT_SUCCESS",
    DELETE_PROJECT_FORM_BEGIN: "DELETE_PROJECT_FORM_BEGIN",
    DELETE_PROJECT_FORM_SUCCESS: "DELETE_PROJECT_FORM_SUCCESS",
    CREATE_PROJECT_FORM_BEGIN: "CREATE_PROJECT_FORM_BEGIN",
    CREATE_PROJECT_FORM_SUCCESS: "CREATE_PROJECT_FORM_SUCCESS",
    CREATE_FORM_TEMPLATE_BEGIN: "CREATE_FORM_TEMPLATE_BEGIN",
    CREATE_FORM_TEMPLATE_SUCCESS: "CREATE_FORM_TEMPLATE_SUCCESS",
    UPDATE_FORM_TEMPLATE_BEGIN: "UPDATE_FORM_TEMPLATE_BEGIN",
    UPDATE_FORM_TEMPLATE_SUCCESS: "UPDATE_FORM_TEMPLATE_SUCCESS",
    DELETE_FORM_TEMPLATE_BEGIN: "DELETE_FORM_TEMPLATE_BEGIN",
    DELETE_FORM_TEMPLATE_SUCCESS: "DELETE_FORM_TEMPLATE_SUCCESS",
    CREATE_INVOICE_BEGIN: "CREATE_INVOICE_BEGIN",
    CREATE_INVOICE_SUCCESS: "CREATE_INVOICE_SUCCESS",
    LOAD_INVOICES_BEGIN: "LOAD_INVOICES_BEGIN",
    LOAD_INVOICES_SUCCESS: "LOAD_INVOICES_SUCCESS",
    LOAD_USE_OFFER_LINK_BEGIN: "LOAD_USE_OFFER_LINK_BEGIN",
    LOAD_USE_OFFER_LINK_SUCCESS: "LOAD_USE_OFFER_LINK_SUCCESS",
    SEND_OFFER_LINK_ANSWER_BEGIN: "SEND_OFFER_LINK_ANSWER_BEGIN",
    SEND_OFFER_LINK_ANSWER_SUCCESS: "SEND_OFFER_LINK_ANSWER_SUCCESS",
    CLEAR_INVOICES: "CLEAR_INVOICES",
    CLEAR_INVOICE: "CLEAR_INVOICE",
    LOAD_INVOICE_BEGIN: "LOAD_INVOICE_BEGIN",
    LOAD_INVOICE_SUCCESS: "LOAD_INVOICE_SUCCESS",
    DELETE_INVOICE_BEGIN: "DELETE_INVOICE_BEGIN",
    DELETE_INVOICE_SUCCESS: "DELETE_INVOICE_SUCCESS",
    LOAD_COMPANY_LOGO_BEGIN: "LOAD_COMPANY_LOGO_BEGIN",
    LOAD_COMPANY_LOGO_SUCCESS: "LOAD_COMPANY_LOGO_SUCCESS",
    LOAD_REPORTED_TIME_BEGIN: "LOAD_REPORTED_TIME_BEGIN",
    LOAD_REPORTED_TIME_SUCCESS: "LOAD_REPORTED_TIME_SUCCESS",
    LOAD_REPORTED_PRODUCT_BEGIN: "LOAD_REPORTED_PRODUCT_BEGIN",
    LOAD_REPORTED_PRODUCT_SUCCESS: "LOAD_REPORTED_PRODUCT_SUCCESS",
    LOAD_REPORTED_NOTE_BEGIN: "LOAD_REPORTED_NOTE_BEGIN",
    LOAD_REPORTED_NOTE_SUCCESS: "LOAD_REPORTED_NOTE_SUCCESS",
    LOAD_SUPPLIER_INVOICE_PROJECT_RELATIONS_BEGIN: "LOAD_SUPPLIER_INVOICE_PROJECT_RELATIONS_BEGIN",
    LOAD_SUPPLIER_INVOICE_PROJECT_RELATIONS_SUCCESS: "LOAD_SUPPLIER_INVOICE_PROJECT_RELATIONS_SUCCESS",
    CLEAR_REPORTED_TIME: "CLEAR_REPORTED_TIME",
    CLEAR_REPORTED_PRODUCT: "CLEAR_REPORTED_PRODUCT",
    CLEAR_REPORTED_NOTE: "CLEAR_REPORTED_NOTE",
    CLEAR_SUPPLIER_INVOICE_PROJECT_RELATION: "CLEAR_SUPPLIER_INVOICE_PROJECT_RELATION",
    LOAD_CUSTOMER_OBJECT_BEGIN: "LOAD_CUSTOMER_OBJECT_BEGIN",
    LOAD_CUSTOMER_OBJECT_SUCCESS: "LOAD_CUSTOMER_OBJECT_SUCCESS",
    CLEAR_CUSTOMER_OBJECT: "CLEAR_CUSTOMER_OBJECT",
    UPDATE_CUSTOMER_OBJECT_BEGIN: "UPDATE_CUSTOMER_OBJECT_BEGIN",
    UPDATE_CUSTOMER_OBJECT_SUCCESS: "UPDATE_CUSTOMER_OBJECT_SUCCESS",
    LOAD_ABSENCE_TYPES_BEGIN: "LOAD_ABSENCE_TYPES_BEGIN",
    LOAD_ABSENCE_TYPES_SUCCESS: "LOAD_ABSENCE_TYPES_SUCCESS",
    CREATE_ABSENCE_TYPE_BEGIN: "CREATE_ABSENCE_TYPE_BEGIN",
    CREATE_ABSENCE_TYPE_SUCCESS: "CREATE_ABSENCE_TYPE_SUCCESS",
    UPDATE_ABSENCE_TYPE_BEGIN: "UPDATE_ABSENCE_TYPE_BEGIN",
    UPDATE_ABSENCE_TYPE_SUCCESS: "UPDATE_ABSENCE_TYPE_SUCCESS",
    DELETE_ABSENCE_TYPE_BEGIN: "DELETE_ABSENCE_TYPE_BEGIN",
    DELETE_ABSENCE_TYPE_SUCCESS: "DELETE_ABSENCE_TYPE_SUCCESS",
    CLEAR_CUSTOMERS: "CLEAR_CUSTOMERS",
    CLEAR_CUSTOMER: "CLEAR_CUSTOMER",
    LOAD_CUSTOMER_COUNTS_BEGIN: "LOAD_CUSTOMER_COUNTS_BEGIN",
    LOAD_CUSTOMER_COUNTS_SUCCESS: "LOAD_CUSTOMER_COUNTS_SUCCESS",
    UPDATE_CUSTOMER_BEGIN: "UPDATE_CUSTOMER_BEGIN",
    UPDATE_CUSTOMER_SUCCESS: "UPDATE_CUSTOMER_SUCCESS",
    DELETE_CUSTOMER_OBJECT_BEGIN: "DELETE_CUSTOMER_OBJECT_BEGIN",
    DELETE_CUSTOMER_OBJECT_SUCCESS: "DELETE_CUSTOMER_OBJECT_SUCCESS",
    CLEAR_CUSTOMER_OBJECTS: "CLEAR_CUSTOMER_OBJECTS",
    CLEAR_CUSTOMER_COUNTS: "CLEAR_CUSTOMER_COUNTS",
    CLEAR_PROJECTS: "CLEAR_PROJECTS",
    CLEAR_PROJECT_COUNTS: "CLEAR_PROJECT_COUNTS",
    LOAD_DOCUMENTS_BEGIN: "LOAD_DOCUMENTS_BEGIN",
    LOAD_DOCUMENTS_SUCCESS: "LOAD_DOCUMENTS_SUCCESS",
    LOAD_ARE_CUSTOMER_PROJECTS_DONE_BEGIN: "LOAD_ARE_CUSTOMER_PROJECTS_DONE_BEGIN",
    LOAD_ARE_CUSTOMER_PROJECTS_DONE_SUCCESS: "LOAD_ARE_CUSTOMER_PROJECTS_DONE_SUCCESS",
    POST_MARK_CUSTOMER_AS_INACTIVE_BEGIN: "POST_MARK_CUSTOMER_AS_INACTIVE_BEGIN",
    POST_MARK_CUSTOMER_AS_INACTIVE_SUCCESS: "POST_MARK_CUSTOMER_AS_INACTIVE_SUCCESS",
    POST_MARK_CUSTOMER_AS_ACTIVE_BEGIN: "POST_MARK_CUSTOMER_AS_ACTIVE_BEGIN",
    POST_MARK_CUSTOMER_AS_ACTIVE_SUCCESS: "POST_MARK_CUSTOMER_AS_ACTIVE_SUCCESS",
    LOAD_ARTICLES_REGISTRY_BEGIN: "LOAD_ARTICLES_REGISTRY_BEGIN",
    LOAD_ARTICLES_REGISTRY_SUCCESS: "LOAD_ARTICLES_REGISTRY_SUCCESS",
    CREATE_ARTICLE_BEGIN: "CREATE_ARTICLE_BEGIN",
    CREATE_ARTICLE_SUCCESS: "CREATE_ARTICLE_SUCCESS",
    DELETE_ARTICLE_BEGIN: "DELETE_ARTICLE_BEGIN",
    DELETE_ARTICLE_SUCCESS: "DELETE_ARTICLE_SUCCESS",
    UPDATE_ARTICLE_BEGIN: "UPDATE_ARTICLE_BEGIN",
    UPDATE_ARTICLE_SUCCESS: "UPDATE_ARTICLE_SUCCESS",
    LOAD_PRODUCT_COUNTS_BEGIN: "LOAD_PRODUCT_COUNTS_BEGIN",
    LOAD_PRODUCT_COUNTS_SUCCESS: "LOAD_PRODUCT_COUNTS_SUCCESS",
    LOAD_PRODUCT_BEGIN: "LOAD_PRODUCT_BEGIN",
    LOAD_PRODUCT_SUCCESS: "LOAD_PRODUCT_SUCCESS",
    LOAD_PRODUCT_TYPES_BEGIN: "LOAD_PRODUCT_TYPES_BEGIN",
    LOAD_PRODUCT_TYPES_SUCCESS: "LOAD_PRODUCT_TYPES_SUCCESS",
    LOAD_ALL_TASKS_BEGIN: "LOAD_ALL_TASKS_BEGIN",
    LOAD_ALL_TASKS_SUCCESS: "LOAD_ALL_TASKS_SUCCESS",
    LOAD_PRODUCT_STATS_BEGIN: "LOAD_PRODUCT_STATS_BEGIN",
    LOAD_PRODUCT_STATS_SUCCESS: "LOAD_PRODUCT_STATS_SUCCESS",
    CLEAR_PRODUCT_STATS: "CLEAR_PRODUCT_STATS",
    LOAD_FILTERED_ATTESTABLE_REPORTS_BEGIN: "LOAD_FILTERED_ATTESTABLE_REPORTS_BEGIN",
    LOAD_FILTERED_ATTESTABLE_REPORTS_SUCCESS: "LOAD_FILTERED_ATTESTABLE_REPORTS_SUCCESS",
    CREATE_UNIT_BEGIN: "CREATE_UNIT_BEGIN",
    CREATE_UNIT_SUCCESS: "CREATE_UNIT_SUCCESS",
    UPDATE_UNIT_BEGIN: "UPDATE_UNIT_BEGIN",
    UPDATE_UNIT_SUCCESS: "UPDATE_UNIT_SUCCESS",
    CREATE_PRODUCT_TYPE_BEGIN: "CREATE_PRODUCT_TYPE_BEGIN",
    CREATE_PRODUCT_TYPE_SUCCESS: "CREATE_PRODUCT_TYPE_SUCCESS",
    UPDATE_PRODUCT_TYPE_BEGIN: "UPDATE_PRODUCT_TYPE_BEGIN",
    UPDATE_PRODUCT_TYPE_SUCCESS: "UPDATE_PRODUCT_TYPE_SUCCESS",
    LOAD_TASK_WITH_TASK_TYPE_BEGIN: "LOAD_TASK_WITH_TASK_TYPE_BEGIN",
    LOAD_TASK_WITH_TASK_TYPE_SUCCESS: "LOAD_TASK_WITH_TASK_TYPE_SUCCESS",
    LOAD_TASK_TYPES_BEGIN: "LOAD_TASK_TYPES_BEGIN",
    LOAD_TASK_TYPES_SUCCESS: "LOAD_TASK_TYPES_SUCCESS",
    DELETE_TASK_BEGIN: 'DELETE_TASK_BEGIN',
    DELETE_TASK_SUCCESS: 'DELETE_TASK_SUCCESS',
    CLEAR_TASK: "CLEAR_TASK",
    UPDATE_UPDATE_TASK_BEGIN: "UPDATE_UPDATE_TASK_BEGIN",
    UPDATE_UPDATE_TASK_SUCCESS: "UPDATE_UPDATE_TASK_SUCCESS",
    UPDATE_TASK_TYPE_BEGIN: 'UPDATE_TASK_TYPE_BEGIN',
    UPDATE_TASK_TYPE_SUCCESS: 'UPDATE_TASK_TYPE_SUCCESS',
    CREATE_TASK_TYPE_BEGIN: 'CREATE_TASK_TYPE_BEGIN',
    CREATE_TASK_TYPE_SUCCESS: 'CREATE_TASK_TYPE_SUCCESS',
    CREATE_TASK_BEGIN: "CREATE_TASK_BEGIN",
    CREATE_TASK_SUCCESS: "CREATE_TASK_SUCCESS",
    DELETE_TASK_TYPE_BEGIN: "DELETE_TASK_TYPE_BEGIN",
    DELETE_TASK_TYPE_SUCCESS: "DELETE_TASK_TYPE_SUCCESS",
    DELETE_PRODUCT_TYPE_BEGIN: "DELETE_PRODUCT_TYPE_BEGIN",
    DELETE_PRODUCT_TYPE_SUCCESS: "DELETE_PRODUCT_TYPE_SUCCESS",
    DELETE_UNIT_BEGIN: "DELETE_UNIT_BEGIN",
    DELETE_UNIT_SUCCESS: "DELETE_UNIT_SUCCESS",
    LOAD_REVOKE_SOFT_DELETED_PRODUCT_BEGIN: "LOAD_REVOKE_SOFT_DELETED_PRODUCT_BEGIN",
    LOAD_REVOKE_SOFT_DELETED_PRODUCT_SUCCESS: "LOAD_REVOKE_SOFT_DELETED_PRODUCT_SUCCESS",
    UPDATE_REVOKE_SOFT_DELETED_TASK_BEGIN: "UPDATE_REVOKE_SOFT_DELETED_TASK_BEGIN",
    UPDATE_REVOKE_SOFT_DELETED_TASK_SUCCESS: "UPDATE_REVOKE_SOFT_DELETED_TASK_SUCCESS",
    UPDATE_REVOKE_SOFT_DELETED_TIMETYPE_BEGIN: "UPDATE_REVOKE_SOFT_DELETED_TIMETYPE_BEGIN",
    UPDATE_REVOKE_SOFT_DELETED_TIMETYPE_SUCCESS: "UPDATE_REVOKE_SOFT_DELETED_TIMETYPE_SUCCESS",
    DELETE_CUSTOMER_BEGIN: "DELETE_CUSTOMER_BEGIN",
    DELETE_CUSTOMER_SUCCESS: "DELETE_CUSTOMER_SUCCESS",
    LOAD_FORTNOX_ARTICLES_BEGIN: "LOAD_FORTNOX_ARTICLES_BEGIN",
    LOAD_FORTNOX_ARTICLES_SUCCESS: "LOAD_FORTNOX_ARTICLES_SUCCESS",
    IMPORT_SINGLE_FORTNOX_DATA_TO_MYGIZMO_BEGIN: "IMPORT_SINGLE_FORTNOX_DATA_TO_MYGIZMO_BEGIN",
    IMPORT_SINGLE_FORTNOX_DATA_TO_MYGIZMO_SUCCESS: "IMPORT_SINGLE_FORTNOX_DATA_TO_MYGIZMO_SUCCESS",
    IMPORT_FORTNOX_DATA_TO_MYGIZMO_BEGIN: "IMPORT_FORTNOX_DATA_TO_MYGIZMO_BEGIN",
    IMPORT_FORTNOX_DATA_TO_MYGIZMO_SUCCESS: "IMPORT_FORTNOX_DATA_TO_MYGIZMO_SUCCESS",
    LOAD_FORTNOX_ARTICLE_UNITS_BEGIN: "LOAD_FORTNOX_ARTICLE_UNITS_BEGIN",
    LOAD_FORTNOX_ARTICLE_UNITS_SUCCESS: "LOAD_FORTNOX_ARTICLE_UNITS_SUCCESS",
    LOAD_FORTNOX_ARTICLE_UNIT_GROUPS_BEGIN: "LOAD_FORTNOX_ARTICLE_UNIT_GROUPS_BEGIN",
    LOAD_FORTNOX_ARTICLE_UNIT_GROUPS_SUCCESS: "LOAD_FORTNOX_ARTICLE_UNIT_GROUPS_SUCCESS",
    UPDATE_FORTNOX_ARTICLE_UNIT_GROUPS_BEGIN: "UPDATE_FORTNOX_ARTICLE_UNIT_GROUPS_BEGIN",
    UPDATE_FORTNOX_ARTICLE_UNIT_GROUPS_SUCCESS: "UPDATE_FORTNOX_ARTICLE_UNIT_GROUPS_SUCCESS",
    LOAD_EACCOUNTING_ARTICLES_BEGIN: "LOAD_EACCOUNTING_ARTICLES_BEGIN",
    LOAD_EACCOUNTING_ARTICLES_SUCCESS: "LOAD_EACCOUNTING_ARTICLES_SUCCESS",
    IMPORT_SINGLE_EACCOUNTING_DATA_TO_MYGIZMO_BEGIN: "IMPORT_SINGLE_EACCOUNTING_DATA_TO_MYGIZMO_BEGIN",
    IMPORT_SINGLE_EACCOUNTING_DATA_TO_MYGIZMO_SUCCESS: "IMPORT_SINGLE_EACCOUNTING_DATA_TO_MYGIZMO_SUCCESS",
    IMPORT_EACCOUNTING_DATA_TO_MYGIZMO_BEGIN: "IMPORT_EACCOUNTING_DATA_TO_MYGIZMO_BEGIN",
    IMPORT_EACCOUNTING_DATA_TO_MYGIZMO_SUCCESS: "IMPORT_EACCOUNTING_DATA_TO_MYGIZMO_SUCCESS",
    LOAD_EACCOUNTING_ARTICLE_UNITS_BEGIN: "LOAD_EACCOUNTING_ARTICLE_UNITS_BEGIN",
    LOAD_EACCOUNTING_ARTICLE_UNITS_SUCCESS: "LOAD_EACCOUNTING_ARTICLE_UNITS_SUCCESS",
    LOAD_EACCOUNTING_ARTICLE_UNIT_GROUPS_BEGIN: "LOAD_EACCOUNTING_ARTICLE_UNIT_GROUPS_BEGIN",
    LOAD_EACCOUNTING_ARTICLE_UNIT_GROUPS_SUCCESS: "LOAD_EACCOUNTING_ARTICLE_UNIT_GROUPS_SUCCESS",
    UPDATE_EACCOUNTING_ARTICLE_UNIT_GROUPS_BEGIN: "UPDATE_EACCOUNTING_ARTICLE_UNIT_GROUPS_BEGIN",
    UPDATE_EACCOUNTING_ARTICLE_UNIT_GROUPS_SUCCESS: "UPDATE_EACCOUNTING_ARTICLE_UNIT_GROUPS_SUCCESS",
    LOAD_FORTNOX_STATUS_BEGIN: "LOAD_FORTNOX_STATUS_BEGIN",
    LOAD_FORTNOX_STATUS_SUCCESS: "LOAD_FORTNOX_STATUS_SUCCESS",
    LOAD_EACCOUNTING_STATUS_BEGIN: "LOAD_EACCOUNTING_STATUS_BEGIN",
    LOAD_EACCOUNTING_STATUS_SUCCESS: "LOAD_EACCOUNTING_STATUS_SUCCESS",
    LOAD_EACCOUNTING_ORDERS_BEGIN: "LOAD_EACCOUNTING_ORDERS_BEGIN",
    LOAD_EACCOUNTING_ORDERS_SUCCESS: "LOAD_EACCOUNTING_ORDERS_SUCCESS",
    LOAD_EACCOUNTING_CUSTOMERS_BEGIN: "LOAD_EACCOUNTING_CUSTOMERS_BEGIN",
    LOAD_EACCOUNTING_CUSTOMERS_SUCCESS: "LOAD_EACCOUNTING_CUSTOMERS_SUCCESS",

    COPY_OFFER_TO_TEMPLATE_BEGIN: "COPY_OFFER_TO_TEMPLATE_BEGIN",
    COPY_OFFER_TO_TEMPLATE_SUCCESS: "COPY_OFFER_TO_TEMPLATE_SUCCESS",
    COPY_OFFER_TO_NEW_VERSION_BEGIN: "COPY_OFFER_TO_NEW_VERSION_BEGIN",
    COPY_OFFER_TO_NEW_VERSION_SUCCESS: "COPY_OFFER_TO_NEW_VERSION_SUCCESS",
    
    LOAD_FORTNOX_ORDERS_BEGIN: "LOAD_FORTNOX_ORDERS_BEGIN",
    LOAD_FORTNOX_ORDERS_SUCCESS: "LOAD_FORTNOX_ORDERS_SUCCESS",
    LOAD_FORTNOX_CUSTOMERS_BEGIN: "LOAD_FORTNOX_CUSTOMERS_BEGIN",
    LOAD_FORTNOX_CUSTOMERS_SUCCESS: "LOAD_FORTNOX_CUSTOMERS_SUCCESS",
    LOAD_FORTNOX_SUPPLIER_INVOCIES_BEGIN: "LOAD_FORTNOX_SUPPLIER_INVOCIES_BEGIN",
    LOAD_FORTNOX_SUPPLIER_INVOCIES_SUCCESS: "LOAD_FORTNOX_SUPPLIER_INVOCIES_SUCCESS",
    LOAD_FORTNOX_VOUCHER_SERIES_BEGIN: "LOAD_FORTNOX_VOUCHER_SERIES_BEGIN",
    LOAD_FORTNOX_VOUCHER_SERIES_SUCCESS: "LOAD_FORTNOX_VOUCHER_SERIES_SUCCESS",
    LOAD_FORTNOX_FINANCIAL_YEARS_BEGIN: "LOAD_FORTNOX_FINANCIAL_YEARS_BEGIN",
    LOAD_FORTNOX_FINANCIAL_YEARS_SUCCESS: "LOAD_FORTNOX_FINANCIAL_YEARS_SUCCESS",

    IMPORT_FORTNOX_SUPPLIER_INVOICES_BEGIN: "IMPORT_FORTNOX_SUPPLIER_INVOICES_BEGIN",
    IMPORT_FORTNOX_SUPPLIER_INVOICES_SUCCESS: "IMPORT_FORTNOX_SUPPLIER_INVOICES_SUCCESS",

    CLEAR_SUPPLIER_INVOICE: "CLEAR_SUPPLIER_INVOICE",
    CLEAR_SUPPLIER_INVOICES: "CLEAR_SUPPLIER_INVOICES",
    LOAD_SUPPLIER_INVOICE_BEGIN: "LOAD_SUPPLIER_INVOICE_BEGIN",
    LOAD_SUPPLIER_INVOICE_SUCCESS: "LOAD_SUPPLIER_INVOICE_SUCCESS",
    LOAD_SUPPLIER_INVOICES_BEGIN: "LOAD_SUPPLIER_INVOICES_BEGIN",
    LOAD_SUPPLIER_INVOICES_SUCCESS: "LOAD_SUPPLIER_INVOICES_SUCCESS",
    CREATE_SUPPLIER_INVOICE_BEGIN: "CREATE_SUPPLIER_INVOICE_BEGIN",
    CREATE_SUPPLIER_INVOICE_SUCCESS: "CREATE_SUPPLIER_INVOICE_SUCCESS",
    UPDATE_SUPPLIER_INVOICE_BEGIN: "UPDATE_SUPPLIER_INVOICE_BEGIN",
    UPDATE_SUPPLIER_INVOICE_SUCCESS: "UPDATE_SUPPLIER_INVOICE_SUCCESS",
    DELETE_SUPPLIER_INVOICE_BEGIN: "DELETE_SUPPLIER_INVOICE_BEGIN",
    DELETE_SUPPLIER_INVOICE_SUCCESS: "DELETE_SUPPLIER_INVOICE_SUCCESS",

    LOAD_HOUSE_WORK_DEDUCTION_TYPES_BEGIN: "LOAD_HOUSE_WORK_DEDUCTION_TYPES_BEGIN",
    LOAD_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS: "LOAD_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS",
    CREATE_HOUSE_WORK_DEDUCTION_TYPES_BEGIN: "CREATE_HOUSE_WORK_DEDUCTION_TYPES_BEGIN",
    CREATE_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS: "CREATE_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS",
    UPDATE_HOUSE_WORK_DEDUCTION_TYPES_BEGIN: "UPDATE_HOUSE_WORK_DEDUCTION_TYPES_BEGIN",
    UPDATE_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS: "UPDATE_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS",
    DELETE_HOUSE_WORK_DEDUCTION_TYPES_BEGIN: "DELETE_HOUSE_WORK_DEDUCTION_TYPES_BEGIN",
    DELETE_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS: "DELETE_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS",

    LOOKUP_PRICE_IN_PRICELIST_BEGIN: "LOOKUP_PRICE_IN_PRICELIST_BEGIN",
    LOOKUP_PRICE_IN_PRICELIST_SUCCESS: "LOOKUP_PRICE_IN_PRICELIST_SUCCESS",
    CLEAR_PRICE_IN_PRICE_LIST: "CLEAR_PRICE_IN_PRICE_LIST",

    CREATE_SUPPLIER_INVOICE_PROJECT_RELATION_BEGIN: "CREATE_SUPPLIER_INVOICE_PROJECT_RELATION_BEGIN",
    CREATE_SUPPLIER_INVOICE_PROJECT_RELATION_SUCCESS: "CREATE_SUPPLIER_INVOICE_PROJECT_RELATION_SUCCESS",
    UPDATE_SUPPLIER_INVOICE_PROJECT_RELATION_BEGIN: "UPDATE_SUPPLIER_INVOICE_PROJECT_RELATION_BEGIN",
    UPDATE_SUPPLIER_INVOICE_PROJECT_RELATION_SUCCESS: "UPDATE_SUPPLIER_INVOICE_PROJECT_RELATION_SUCCESS",
    DELETE_SUPPLIER_INVOICE_PROJECT_RELATION_BEGIN: "DELETE_SUPPLIER_INVOICE_PROJECT_RELATION_BEGIN",
    DELETE_SUPPLIER_INVOICE_PROJECT_RELATION_SUCCESS: "DELETE_SUPPLIER_INVOICE_PROJECT_RELATION_SUCCESS",

    LOAD_OFFERS_BEGIN: "LOAD_OFFERS_BEGIN",
    LOAD_OFFERS_SUCCESS: "LOAD_OFFERS_SUCCESS",
    LOAD_OFFER_BEGIN: "LOAD_OFFER_BEGIN",
    LOAD_OFFER_SUCCESS: "LOAD_OFFER_SUCCESS",
    LOAD_OFFER_COUNTS_BEGIN: "LOAD_OFFER_COUNTS_BEGIN",
    LOAD_OFFER_COUNTS_SUCCESS: "LOAD_OFFER_COUNTS_SUCCESS",
    LOAD_ALL_OFFER_COUNTS_BEGIN: "LOAD_ALL_OFFER_COUNTS_BEGIN",
    LOAD_ALL_OFFER_COUNTS_SUCCESS: "LOAD_ALL_OFFER_COUNTS_SUCCESS",
    CLEAR_OFFER_COUNTS: "CLEAR_OFFER_COUNTS",

    LOAD_BOOKABLE_RESOURCES_BEGIN: "LOAD_BOOKABLE_RESOURCES_BEGIN",
    LOAD_BOOKABLE_RESOURCES_SUCCESS: "LOAD_BOOKABLE_RESOURCES_SUCCESS",
    LOAD_BOOKABLE_VEHICLES_BEGIN: "LOAD_BOOKABLE_VEHICLES_BEGIN",
    LOAD_BOOKABLE_VEHICLES_SUCCESS: "LOAD_BOOKABLE_VEHICLES_SUCCESS",

    CLEAR_BOOKINGS: "CLEAR_BOOKINGS",
    LOAD_BOOKINGS_BEGIN: "LOAD_BOOKINGS_BEGIN",
    LOAD_BOOKINGS_SUCCESS: "LOAD_BOOKINGS_SUCCESS",
    CREATE_BOOKING_BEGIN: "CREATE_BOOKING_BEGIN",
    CREATE_BOOKING_SUCCESS: "CREATE_BOOKING_SUCCESS",
    UPDATE_BOOKING_BEGIN: "UPDATE_BOOKING_BEGIN",
    UPDATE_BOOKING_SUCCESS: "UPDATE_BOOKING_SUCCESS",
    DELETE_BOOKING_BEGIN: "DELETE_BOOKING_BEGIN",
    DELETE_BOOKING_SUCCESS: "DELETE_BOOKING_SUCCESS",

    CREATE_BOOKING_RESOURCE_BEGIN: "CREATE_BOOKING_RESOURCE_BEGIN",
    CREATE_BOOKING_RESOURCE_SUCCESS: "CREATE_BOOKING_RESOURCE_SUCCESS",
    UPDATE_BOOKING_RESOURCE_BEGIN: "UPDATE_BOOKING_RESOURCE_BEGIN",
    UPDATE_BOOKING_RESOURCE_SUCCESS: "UPDATE_BOOKING_RESOURCE_SUCCESS",
    DELETE_BOOKING_RESOURCE_BEGIN: "DELETE_BOOKING_RESOURCE_BEGIN",
    DELETE_BOOKING_RESOURCE_SUCCESS: "DELETE_BOOKING_RESOURCE_SUCCESS",

    CLEAR_OFFERS: "CLEAR_OFFERS", 
    CLEAR_OFFER: "CLEAR_OFFER", 

    CREATE_OFFER_BEGIN: "CREATE_OFFER_BEGIN",
    CREATE_OFFER_SUCCESS: "CREATE_OFFER_SUCCESS",
    
    UPDATE_OFFER_BEGIN: "UPDATE_OFFER_BEGIN",
    UPDATE_OFFER_SUCCESS: "UPDATE_OFFER_SUCCESS",

    DELETE_OFFER_BEGIN: "DELETE_OFFER_BEGIN",
    DELETE_OFFER_SUCCESS: "DELETE_OFFER_SUCCESS",

    CREATE_OFFER_LINK_BEGIN: "CREATE_OFFER_LINK_BEGIN",
    CREATE_OFFER_LINK_SUCCESS: "CREATE_OFFER_LINK_SUCCESS",
    DELETE_OFFER_LINK_BEGIN: "DELETE_OFFER_LINK_BEGIN",
    DELETE_OFFER_LINK_SUCCESS: "DELETE_OFFER_LINK_SUCCESS",

    LOAD_TOTAL_AMOUNT_TO_INVOICE_BEGIN: "LOAD_TOTAL_AMOUNT_TO_INVOICE_BEGIN",
    LOAD_TOTAL_AMOUNT_TO_INVOICE_SUCCESS: "LOAD_TOTAL_AMOUNT_TO_INVOICE_SUCCESS",

    LOAD_PROJECT_VARIATION_COUNTS_BEGIN: "LOAD_PROJECT_VARIATION_COUNTS_BEGIN",
    LOAD_PROJECT_VARIATION_COUNTS_SUCCESS: "LOAD_PROJECT_VARIATION_COUNTS_SUCCESS",

    CLEAR_PAYMENT_PLAN_ELEMENTS: "CLEAR_PAYMENT_PLAN_ELEMENTS",

    LOAD_PAYMENT_PLAN_ELEMENTS_BEGIN: "LOAD_PAYMENT_PLAN_ELEMENTS_BEGIN",
    LOAD_PAYMENT_PLAN_ELEMENTS_SUCCESS: "LOAD_PAYMENT_PLAN_ELEMENTS_SUCCESS",
    CREATE_PAYMENT_PLAN_ELEMENT_BEGIN: "CREATE_PAYMENT_PLAN_ELEMENT_BEGIN",
    CREATE_PAYMENT_PLAN_ELEMENT_SUCCESS: "CREATE_PAYMENT_PLAN_ELEMENT_SUCCESS",
    UPDATE_PAYMENT_PLAN_ELEMENT_BEGIN: "UPDATE_PAYMENT_PLAN_ELEMENT_BEGIN",
    UPDATE_PAYMENT_PLAN_ELEMENT_SUCCESS: "UPDATE_PAYMENT_PLAN_ELEMENT_SUCCESS",
    DELETE_PAYMENT_PLAN_ELEMENT_BEGIN: "DELETE_PAYMENT_PLAN_ELEMENT_BEGIN",
    DELETE_PAYMENT_PLAN_ELEMENT_SUCCESS: "DELETE_PAYMENT_PLAN_ELEMENT_SUCCESS",

    CREATE_SALARY_BEGIN: "CREATE_SALARY_BEGIN",
    CREATE_SALARY_SUCCESS: "CREATE_SALARY_SUCCESS",
    LOAD_SALARIES_BEGIN: "LOAD_SALARIES_BEGIN",
    LOAD_SALARIES_SUCCESS: "LOAD_SALARIES_SUCCESS",
    CLEAR_SALARIES: "CLEAR_SALARIES",
    DELETE_SALARY_BEGIN: "DELETE_SALARY_BEGIN",
    DELETE_SALARY_SUCCESS: "DELETE_SALARY_SUCCESS",
    LOAD_TASK_STATS_BEGIN: "LOAD_TASK_STATS_BEGIN",
    LOAD_TASK_STATS_SUCCESS: "LOAD_TASK_STATS_SUCCESS",
    CLEAR_TASK_STATS: "CLEAR_TASK_STATS",
    UPDATE_EMPLOYEE_BEGIN: "UPDATE_EMPLOYEE_BEGIN",
    UPDATE_EMPLOYEE_SUCCESS: "UPDATE_EMPLOYEE_SUCCESS",
    UPDATE_PASSWORD_BEGIN: "UPDATE_PASSWORD_BEGIN",
    UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS",
    UPDATE_USER_DETAILS_BEGIN: "UPDATE_USER_DETAILS_BEGIN",
    UPDATE_USER_DETAILS_SUCCESS: "UPDATE_USER_DETAILS_SUCCESS",
    UPDATE_PROFILE_PICTURE_BEGIN: "UPDATE_PROFILE_PICTURE_BEGIN",
    UPDATE_PROFILE_PICTURE_SUCCESS: "UPDATE_PROFILE_PICTURE_SUCCESS",
    LOAD_EMPLOYEE_REPORTED_HOURS_BEGIN: "LOAD_EMPLOYEE_REPORTED_HOURS_BEGIN",
    LOAD_EMPLOYEE_REPORTED_HOURS_SUCCESS: "LOAD_EMPLOYEE_REPORTED_HOURS_SUCCESS",
    CLEAR_PRODUCT: "CLEAR_PRODUCT",
    CLEAR_PRODUCTS: "CLEAR_PRODUCTS",
    LOAD_ALL_EMPLOYEES_BEGIN: "LOAD_ALL_EMPLOYEES_BEGIN",
    LOAD_ALL_EMPLOYEES_SUCCESS: "LOAD_ALL_EMPLOYEES_SUCCESS",
    LOAD_EMPLOYEE_BEGIN: "LOAD_EMPLOYEE_BEGIN",
    LOAD_EMPLOYEE_SUCCESS: "LOAD_EMPLOYEES_SUCCES",
    CLEAR_EMPLOYEE_DATA: "CLEAR_EMPLOYEE_DATA",
    DELETE_EMPLOYEE_SOFT_DELETE_BEGIN: "DELETE_EMPLOYEE_SOFT_DELETE_BEGIN",
    DELETE_EMPLOYEE_SOFT_DELETE_SUCCESS: "DELETE_EMPLOYEE_SOFT_DELETE_SUCCESS",
    LOAD_REGISTER_EMPLOYEES_BEGIN: "LOAD_REGISTER_EMPLOYEES_BEGIN",
    LOAD_REGISTER_EMPLOYEES_SUCCESS: "LOAD_REGISTER_EMPLOYEES_SUCCESS",
    LOAD_EMPLOYEE_COUNT_BEGIN: "LOAD_EMPLOYEE_COUNT_BEGIN",
    LOAD_EMPLOYEE_COUNT_SUCCESS: "LOAD_EMPLOYEE_COUNT_SUCCESS",
    LOAD_REVOKE_SOFT_DELETED_EMPLOYEE_BEGIN: "LOAD_REVOKE_SOFT_DELETED_EMPLOYEE_BEGIN",
    LOAD_REVOKE_SOFT_DELETED_EMPLOYEE_SUCCESS: "LOAD_REVOKE_SOFT_DELETED_EMPLOYEE_SUCCESS",
    CLEAR_EMPLOYEE_REPORTED_TIME_SUM: "CLEAR_EMPLOYEE_REPORTED_TIME_SUM",
    CRAETE_EMPLOYEE_BEGIN: "CRAETE_EMPLOYEE_BEGIN",
    CRAETE_EMPLOYEE_SUCCESS: "CRAETE_EMPLOYEE_SUCCESS",
    LOAD_DOES_EMPLOYEES_EMAIL_EXIST_BEGIN: "LOAD_DOES_EMPLOYEES_EMAIL_EXIST_BEGIN",
    LOAD_DOES_EMPLOYEES_EMAIL_EXIST_SUCCESS: "LOAD_DOES_EMPLOYEES_EMAIL_EXIST_SUCCESS",
    LOAD_DOES_EMPLOYEE_NUMBER_EXIST_BEGIN: "LOAD_DOES_EMPLOYEE_NUMBER_EXIST_BEGIN",
    LOAD_DOES_EMPLOYEE_NUMBER_EXIST_SUCCESS: "LOAD_DOES_EMPLOYEE_NUMBER_EXIST_SUCCESS",
    SEND_USER_INVITE_TO_CURRENT_TENNANT_BEGIN: "SEND_USER_INVITE_TO_CURRENT_TENNANT_BEGIN",
    SEND_USER_INVITE_TO_CURRENT_TENNANT_SUCCESS: "SEND_USER_INVITE_TO_CURRENT_TENNANT_SUCCESS",
    LOAD_ALL_TIME_TYPES_BEGIN: " LOAD_ALL_TIME_TYPES_BEGIN",
    LOAD_ALL_TIME_TYPES_SUCCESS: " LOAD_ALL_TIME_TYPES_SUCCESS",
    UPDATE_USERS_ALLOWED_TO_SEE_PRICE_BEGIN: "UPDATE_USERS_ALLOWED_TO_SEE_PRICE_BEGIN",
    UPDATE_USERS_ALLOWED_TO_SEE_PRICE_SUCCESS: "UPDATE_USERS_ALLOWED_TO_SEE_PRICE_SUCCESS",
    LOAD_PRICE_LISTS_BEGIN: "LOAD_PRICE_LISTS_BEGIN",
    LOAD_PRICE_LISTS_SUCCESS: "LOAD_PRICE_LISTS_SUCCESS",
    UPDATE_SETTINGS_BEGIN: "UPDATE_SETTINGS_BEGIN",
    UPDATE_SETTINGS_SUCCESS: "UPDATE_SETTINGS_SUCCESS",
    UPDATE_COMPANY_LOGO_BEGIN: "UPDATE_COMPANY_LOGO_BEGIN",
    UPDATE_COMPANY_LOGO_SUCCESS: "UPDATE_COMPANY_LOGO_SUCCESS",
    UPDATE_CUSTOM_PROJECT_SEQUENCE_BEGIN: "UPDATE_CUSTOM_PROJECT_SEQUENCE_BEGIN",
    UPDATE_CUSTOM_PROJECT_SEQUENCE_SUCCESS: "UPDATE_CUSTOM_PROJECT_SEQUENCE_SUCCESS",
    CREATE_TIME_TYPE_BEGIN: "CREATE_TIME_TYPE_BEGIN",
    CREATE_TIME_TYPE_SUCCESS: "CREATE_TIME_TYPE_SUCCESS",
    LOAD_ARE_TIME_TYPE_HANDLED_BEGIN: "LOAD_ARE_TIME_TYPE_HANDLED_BEGIN",
    LOAD_ARE_TIME_TYPE_HANDLED_SUCCESS: "LOAD_ARE_TIME_TYPE_HANDLED_SUCCESS",
    LOAD_TIME_TYPE_BEGIN: "LOAD_TIME_TYPE_BEGIN",
    LOAD_TIME_TYPE_SUCCESS: "LOAD_TIME_TYPE_SUCCESS",
    CLEAR_TIME_TYPE: "CLEAR_TIME_TYPE",
    DELETE_TIME_TYPE_BEGIN: "DELETE_TIME_TYPE_BEGIN",
    DELETE_TIME_TYPE_SUCCESS: "DELETE_TIME_TYPE_SUCCESS",
    CREATE_DOCUMENT_CATEGORY_BEGIN: "CREATE_DOCUMENT_CATEGORY_BEGIN",
    CREATE_DOCUMENT_CATEGORY_SUCCESS: "CREATE_DOCUMENT_CATEGORY_SUCCESS",
    UPDATE_TIME_TYPE_BEGIN: "UPDATE_TIME_TYPE_BEGIN",
    UPDATE_TIME_TYPE_SUCCESS: "UPDATE_TIME_TYPE_SUCCESS",
    UPDATE_DOCUMENT_CATEGORY_BEGIN: "UPDATE_DOCUMENT_CATEGORY_BEGIN",
    UPDATE_DOCUMENT_CATEGORY_SUCCESS: "UPDATE_DOCUMENT_CATEGORY_SUCCESS",
    DELETE_DOCUMENT_CATEGORY_BEGIN: "DELETE_DOCUMENT_CATEGORY_BEGIN",
    DELETE_DOCUMENT_CATEGORY_SUCCESS: "DELETE_DOCUMENT_CATEGORY_SUCCESS",
    UPDATE_USERS_ALLOWED_TO_EDIT_REPORTS_BEGIN: "UPDATE_USERS_ALLOWED_TO_EDIT_REPORTS_BEGIN",
    UPDATE_USERS_ALLOWED_TO_EDIT_REPORTS_SUCCESS: "UPDATE_USERS_ALLOWED_TO_EDIT_REPORTS_SUCCESS",
    UPDATE_SHOW_TASK_FIRST_BEGIN: "UPDATE_SHOW_TASK_FIRST_BEGIN",
    UPDATE_SHOW_TASK_FIRST_SUCCESS: "UPDATE_SHOW_TASK_FIRST_SUCCESS",
    CREATE_PRICE_ADJUSTMENT_BEGIN: "CREATE_PRICE_ADJUSTMENT_BEGIN",
    CREATE_PRICE_ADJUSTMENT_SUCCESS: "CREATE_PRICE_ADJUSTMENT_SUCCESS",
    DELETE_PRICE_ADJUSTMENT_BEGIN: "DELETE_PRICE_ADJUSTMENT_BEGIN",
    DELETE_PRICE_ADJUSTMENT_SUCCESS: "DELETE_PRICE_ADJUSTMENT_SUCCESS",
    LOAD_PRICE_LISTS_ITEM_DISCOUNTS_BEGIN: "LOAD_PRICE_LISTS_ITEM_DISCOUNTS_BEGIN",
    LOAD_PRICE_LISTS_ITEM_DISCOUNTS_SUCCESS: "LOAD_PRICE_LISTS_ITEM_DISCOUNTS_SUCCESS",
    CREATE_PRICE_LIST_DISCOUNTS_BEGIN: "CREATE_PRICE_LIST_DISCOUNTS_BEGIN",
    CREATE_PRICE_LIST_DISCOUNTS_SUCCESS: "CREATE_PRICE_LIST_DISCOUNTS_SUCCESS",
    DELETE_PRICE_LIST_DISCOUNTS_BEGIN: "DELETE_LIST_DISCOUNTS_BEGIN",
    DELETE_PRICE_LIST_DISCOUNTS_SUCCESS: "DELETE_PRICE_LIST_DISCOUNTS_SUCCESS",
    UPDATE_PRICE_ADJUSTMENT_BEGIN: "UPDATE_PRICE_ADJUSTMENT_BEGIN",
    UPDATE_PRICE_ADJUSTMENT_SUCCESS: "UPDATE_PRICE_ADJUSTMENT_SUCCESS",
    CLEAR_PRICE_LISTS_ITEM_DISCOUNTS: "CLEAR_PRICE_LISTS_ITEM_DISCOUNTS",
    LOAD_ONGOING_PROJECTS_BEGIN: "LOAD_ONGOING_PROJECTS_BEGIN",
    LOAD_ONGOING_PROJECTS_SUCCESS: "LOAD_ONGOING_PROJECTS_SUCCESS",
    LOAD_OPON_PROJECTS_WITH_CUSTOMER_NAME_BEGIN: "LOAD_OPON_PROJECTS_WITH_CUSTOMER_NAME_BEGIN",
    LOAD_OPON_PROJECTS_WITH_CUSTOMER_NAME_SUCCESS: "LOAD_OPON_PROJECTS_WITH_CUSTOMER_NAME_SUCCESS",
    LOAD_PROJECT_STATS_BEGIN: "LOAD_PROJECT_STATS_BEGIN",
    LOAD_PROJECT_STATS_SUCCESS: "LOAD_PROJECT_STATS_SUCCESS",
    LOAD_UNHANDLED_REPORTS_BEGIN: "LOAD_UNHANDLED_REPORTS_BEGIN",
    LOAD_UNHANDLED_REPORTS_SUCCESS: "LOAD_UNHANDLED_REPORTS_SUCCESS",
    LOAD_UNHANDLED_REPORTED_TIME_BETWEEN_DATES_BEGIN: "LOAD_UNHANDLED_REPORTED_TIME_BETWEEN_DATES_BEGIN",
    LOAD_UNHANDLED_REPORTED_TIME_BETWEEN_DATES_SUCCESS: "LOAD_UNHANDLED_REPORTED_TIME_BETWEEN_DATES_SUCCESS",
    LOAD_UNHANDLED_INVOICE_AMOUNT_SUM_BEGIN: "LOAD_UNHANDLED_INVOICE_AMOUNT_SUM_BEGIN",
    LOAD_UNHANDLED_INVOICE_AMOUNT_SUM_SUCCESS: "LOAD_UNHANDLED_INVOICE_AMOUNT_SUM_SUCCESS",
    UPDATE_PRICE_LIST_DISCOUNTS_BEGIN: "UPDATE_PRICE_LIST_DISCOUNTS_BEGIN",
    UPDATE_PRICE_LIST_DISCOUNTS_SUCCESS: "UPDATE_PRICE_LIST_DISCOUNTS_SUCCESS",
    SEND_USER_INVITE_TO_CURRENT_TENNANT_AGAIN_BEGIN: "SEND_USER_INVITE_TO_CURRENT_TENNANT_AGAIN_BEGIN",
    SEND_USER_INVITE_TO_CURRENT_TENNANT_AGAIN_SUCCESS: "SEND_USER_INVITE_TO_CURRENT_TENNANT_AGAIN_SUCCESS",
    POST_JSON_FILE_SAVE_BEGIN: "POST_JSON_FILE_SAVE_BEGIN",
    POST_JSON_FILE_SAVE_SUCCESS: "POST_JSON_FILE_SAVE_SUCCESS",
    POST_JSON_FILE_READ_BEGIN: "POST_JSON_FILE_READ_BEGIN",
    POST_JSON_FILE_READ_SUCCESS: "POST_JSON_FILE_READ_SUCCESS",
    LOAD_CUSTOMERS_WITH_DATES_BEGIN: "LOAD_CUSTOMERS_WITH_DATES_BEGIN",
    LOAD_CUSTOMERS_WITH_DATES_SUCCESS: "LOAD_CUSTOMERS_WITH_DATES_SUCCESS",
    LOAD_PRODUCTS_WITH_DATES_BEGIN: "LOAD_PRODUCTS_WITH_DATES_BEGIN",
    LOAD_PRODUCTS_WITH_DATES_SUCCESS: "LOAD_PRODUCTS_WITH_DATES_SUCCESS",
    LOAD_INVOICES_WITH_DATES_BEGIN: "LOAD_INVOICES_WITH_DATES_BEGIN",
    LOAD_INVOICES_WITH_DATES_SUCCESS: "LOAD_INVOICES_WITH_DATES_SUCCESS",
    LOAD_ALL_INTEGRATION_ITEM_GROUPS_BEGIN: "LOAD_ALL_INTEGRATION_ITEM_GROUPS_BEGIN",
    LOAD_ALL_INTEGRATION_ITEM_GROUPS_SUCCESS: "LOAD_ALL_INTEGRATION_ITEM_GROUPS_SUCCESS",
    LOAD_PROJECTS_BY_ERP_PROJECT_ID_BEGIN: "LOAD_PROJECTS_BY_ERP_PROJECT_ID_BEGIN",
    LOAD_PROJECTS_BY_ERP_PROJECT_ID_SUCCESS: "LOAD_PROJECTS_BY_ERP_PROJECT_ID_SUCCESS",
    LOAD_CUSTOMERS_BY_ERP_CUSTOMER_ID_BEGIN: "LOAD_CUSTOMERS_BY_ERP_CUSTOMER_ID_BEGIN",
    LOAD_CUSTOMERS_BY_ERP_CUSTOMER_ID_SUCCESS: "LOAD_CUSTOMERS_BY_ERP_CUSTOMER_ID_SUCCESS",
    UPDATE_PROJECTS_BEGIN: "UPDATE_PROJECTS_BEGIN",
    UPDATE_PROJECTS_SUCCESS: "UPDATE_PROJECTS_SUCCESS",
    LOAD_CUSTOMER_BY_NAME_BEGIN: "LOAD_CUSTOMER_BY_NAME_BEGIN",
    LOAD_CUSTOMER_BY_NAME_SUCCESS: "LOAD_CUSTOMER_BY_NAME_SUCCESS",
    LOAD_PROJECT_BY_PROJECT_NAME_BEGIN: "LOAD_PROJECT_BY_PROJECT_NAME_BEGIN",
    LOAD_PROJECT_BY_PROJECT_NAME_SUCCESS: "LOAD_PROJECT_BY_PROJECT_NAME_SUCCESS",
    LOAD_PROJECTS_BY_ERP_ORDER_NUMBER_BEGIN: "LOAD_PROJECTS_BY_ERP_ORDER_NUMBER_BEGIN",
    LOAD_PROJECTS_BY_ERP_ORDER_NUMBER_SUCCESS: "LOAD_PROJECTS_BY_ERP_ORDER_NUMBER_SUCCESS",
    LOAD_TASKS_BY_ERP_PRODUCT_ID_BEGIN: "LOAD_TASKS_BY_ERP_PRODUCT_ID_BEGIN",
    LOAD_TASKS_BY_ERP_PRODUCT_ID_SUCCESS: "LOAD_TASKS_BY_ERP_PRODUCT_ID_SUCCESS",
    LOAD_TIME_TYPES_BY_ERP_PRODUCT_ID_BEGIN: "LOAD_TIME_TYPES_BY_ERP_PRODUCT_ID_BEGIN",
    LOAD_TIME_TYPES_BY_ERP_PRODUCT_ID_SUCCESS: "LOAD_TIME_TYPES_BY_ERP_PRODUCT_ID_SUCCESS",
    LOAD_PRODUCTS_BY_ERP_PRODUCT_ID_BEGIN: "LOAD_PRODUCTS_BY_ERP_PRODUCT_ID_BEGIN",
    LOAD_PRODUCTS_BY_ERP_PRODUCT_ID_SUCCESS: "LOAD_PRODUCTS_BY_ERP_PRODUCT_ID_SUCCESS",
    CREATE_INTEGRATION_ITEM_GROUPS_BEGIN: "CREATE_INTEGRATION_ITEM_GROUPS_BEGIN",
    CREATE_INTEGRATION_ITEM_GROUPS_SUCCESS: "CREATE_INTEGRATION_ITEM_GROUPS_SUCCESS",
    LOAD_TIME_TYPES_BY_PRODUCT_NAME_BEGIN: "LOAD_TIME_TYPES_BY_PRODUCT_NAME_BEGIN",
    LOAD_TIME_TYPES_BY_PRODUCT_NAME_SUCCESS: "LOAD_TIME_TYPES_BY_PRODUCT_NAME_SUCCESS",
    LOAD_TASKS_BY_TITLE_BEGIN: "LOAD_TASKS_BY_TITLE_BEGIN",
    LOAD_TASKS_BY_TITLE_SUCCESS: "LOAD_TASKS_BY_TITLE_SUCCESS",
    LOAD_PRODUCTS_BY_NAME_BEGIN: "LOAD_PRODUCTS_BY_NAME_BEGIN",
    LOAD_PRODUCTS_BY_NAME_SUCCESS: "LOAD_PRODUCTS_BY_NAME_SUCCESS",
    DELETE_INTEGRATION_ITEM_GROUP_BEGIN: "DELETE_INTEGRATION_ITEM_GROUP_BEGIN",
    DELETE_INTEGRATION_ITEM_GROUP_SUCCESS: "DELETE_INTEGRATION_ITEM_GROUP_SUCCESS",
    LOAD_INTEGRATION_ITEM_GROUP_BEGIN: " LOAD_INTEGRATION_ITEM_GROUP_BEGIN",
    LOAD_INTEGRATION_ITEM_GROUP_SUCCESS: " LOAD_INTEGRATION_ITEM_GROUP_SUCCESS",
    UPDATE_INTEGRATION_ITEM_GROUP_BEGIN: " UPDATE_INTEGRATION_ITEM_GROUP_BEGIN",
    UPDATE_INTEGRATION_ITEM_GROUP_SUCCESS: " UPDATE_INTEGRATION_ITEM_GROUP_SUCCESS",
    LOAD_REPORTED_HOURS_BETWEEN_DATES_BEGIN: "LOAD_REPORTED_HOURS_BETWEEN_DATES_BEGIN",
    LOAD_REPORTED_HOURS_BETWEEN_DATES_SUCCESS: "LOAD_REPORTED_HOURS_BETWEEN_DATES_SUCCESS",
    LOAD_UNHANDLED_SALARY_DETALIS_COUNT_BEGIN: " LOAD_UNHANDLED_SALARY_DETALIS_COUNT_BEGIN",
    LOAD_UNHANDLED_SALARY_DETALIS_COUNT_SUCCESS: " LOAD_UNHANDLED_SALARY_DETALIS_COUNT_SUCCESS",
    LOAD_ALL_PROJECT_COUNTS_BEGIN: "LOAD_ALL_PROJECT_COUNTS_BEGIN",
    LOAD_ALL_PROJECT_COUNTS_SUCCESS: "LOAD_ALL_PROJECT_COUNTS_SUCCESS",
    LOAD_PRODUCT_TYPE_BY_NAME_BEGIN: "LOAD_PRODUCT_TYPE_BY_NAME_BEGIN",
    LOAD_PRODUCT_TYPE_BY_NAME_SUCCESS: "LOAD_PRODUCT_TYPE_BY_NAME_SUCCESS",
    LOAD_UNIT_BY_NAME_BEGIN: "LOAD_UNIT_BY_NAME_BEGIN",
    LOAD_UNIT_BY_NAME_SUCCESS: "LOAD_UNIT_BY_NAME_SUCCESS",
    LOAD_ALL_ACTIVE_TASKS_WITH_DATES_BEGIN: "LOAD_ALL_ACTIVE_TASKS_WITH_DATES_BEGIN",
    LOAD_ALL_ACTIVE_TASKS_WITH_DATES_SUCCESS: "LOAD_ALL_ACTIVE_TASKS_WITH_DATES_SUCCESS",
    POST_IMPORT_SINGLE_FORTNOX_SUPPLIER_INVOICE_BEGIN: "POST_IMPORT_SINGLE_FORTNOX_SUPPLIER_INVOICE_BEGIN",
    POST_IMPORT_SINGLE_FORTNOX_SUPPLIER_INVOICE_SUCCESS: "POST_IMPORT_SINGLE_FORTNOX_SUPPLIER_INVOICE_SUCCESS",
    LOAD_INVOICE_LINES_BEGIN: "LOAD_INVOICE_LINES_BEGIN",
    LOAD_INVOICE_LINES_SUCCESS: "LOAD_INVOICE_LINES_SUCCESS",
    LOAD_PROJECT_ASSIGNMENTS_BEGIN: "LOAD_PROJECT_ASSIGNMENTS_BEGIN",
    LOAD_PROJECT_ASSIGNMENTS_SUCCESS: "LOAD_PROJECT_ASSIGNMENTS_SUCCESS",
    CLEAR_PRODUCT_COUNTS: "CLEAR_PRODUCT_COUNTS",
    CLEAR_TASKS: "CLEAR_TASKS",
    LOAD_TENANT_MIGRATION_STATUS_BEGIN: "LOAD_TENANT_MIGRATION_STATUS_BEGIN",
    LOAD_TENANT_MIGRATION_STATUS_SUCCESS: "LOAD_TENANT_MIGRATION_STATUS_SUCCESS",
    LOAD_TENANT_MIGRATION_REDIRECT_URL_BEGIN: "LOAD_TENANT_MIGRATION_REDIRECT_URL_BEGIN",
    LOAD_TENANT_MIGRATION_REDIRECT_URL_SUCCESS: "LOAD_TENANT_MIGRATION_REDIRECT_URL_SUCCESS",
    CREATE_PIPEDRIVE_LEAD_BEGIN: "CREATE_PIPEDRIVE_LEAD_BEGIN",
    CREATE_PIPEDRIVE_LEAD_SUCCESS: "CREATE_PIPEDRIVE_LEAD_SUCCESS",
    UPDATE_PIPEDRIVE_LEAD_BEGIN: "UPDATE_PIPEDRIVE_LEAD_BEGIN",
    UPDATE_PIPEDRIVE_LEAD_SUCCESS: "UPDATE_PIPEDRIVE_LEAD_SUCCESS",
    LOAD_GADGET_QUOTATION_SENDING_URL_BEGIN: "LOAD_GADGET_QUOTATION_SENDING_URL_BEGIN",
    LOAD_GADGET_QUOTATION_SENDING_URL_SUCCESS: "LOAD_GADGET_QUOTATION_SENDING_URL_SUCCESS",
    LOAD_GADGET_VARIATION_SENDING_URL_BEGIN: "LOAD_GADGET_VARIATION_SENDING_URL_BEGIN",
    LOAD_GADGET_VARIATION_SENDING_URL_SUCCESS: "LOAD_GADGET_VARIATION_SENDING_URL_SUCCESS",
};

export const fetchTenantMigrationStatusBegin = () => ({ type: types.LOAD_TENANT_MIGRATION_STATUS_BEGIN });
export const fetchTenantMigrationStatusComplete = (payload) => ({ type: types.LOAD_TENANT_MIGRATION_STATUS_SUCCESS, payload });
export const fetchGadgetQuotationSendingUrlBegin = () => ({ type: types.LOAD_GADGET_QUOTATION_SENDING_URL_BEGIN });
export const fetchGadgetQuotationSendingUrlComplete = (payload) => ({ type: types.LOAD_GADGET_QUOTATION_SENDING_URL_SUCCESS, payload });
export const fetchGadgetVariationSendingUrlBegin = () => ({ type: types.LOAD_GADGET_VARIATION_SENDING_URL_BEGIN });
export const fetchGadgetVariationSendingUrlComplete = (payload) => ({ type: types.LOAD_GADGET_VARIATION_SENDING_URL_SUCCESS, payload });
export const fetchTenantMigrationRedirectUrlBegin = () => ({ type: types.LOAD_TENANT_MIGRATION_REDIRECT_URL_BEGIN });
export const fetchTenantMigrationRedirectUrlComplete = (payload) => ({ type: types.LOAD_TENANT_MIGRATION_REDIRECT_URL_SUCCESS, payload });
export const clearDocuments = () => ({ type: types.CLEAR_DOCUMENTS })
export const clearReportsToAttest = () => ({ type: types.CLEAR_REPORTS_TO_ATTEST })
export const clearPriceListsItemDiscounts = () => ({ type: types.CLEAR_PRICE_LISTS_ITEM_DISCOUNTS })
export const clearTimeType = () => ({ type: types.CLEAR_TIME_TYPE })
export const clearEmployeeData = () => ({ type: types.CLEAR_EMPLOYEE_DATA })
export const clearProduct = () => ({ type: types.CLEAR_PRODUCT })
export const clearProducts = () => ({ type: types.CLEAR_PRODUCTS })
export const clearTasks = () => ({ type: types.CLEAR_TASKS })
export const clearProductCounts = () => ({ type: types.CLEAR_PRODUCT_COUNTS })
export const clearTaskStats = () => ({ type: types.CLEAR_TASK_STATS })
export const clearProductStats = () => ({ type: types.CLEAR_PRODUCT_STATS })
export const clearProjectForms = () => ({ type: types.CLEAR_PROJECT_FORMS })
export const clearProject = () => ({ type: types.CLEAR_PROJECT })
export const clearReports = () => ({ type: types.CLEAR_REPORTS })
export const clearDailyReports = () => ({ type: types.CLEAR_DAILY_REPORTS })
export const clearInvoices = () => ({ type: types.CLEAR_INVOICES })
export const clearInvoice = () => ({ type: types.CLEAR_INVOICE })
export const clearReportedTime = () => ({ type: types.CLEAR_REPORTED_TIME })
export const clearReportedProduct = () => ({ type: types.CLEAR_REPORTED_PRODUCT })
export const clearReportedNote = () => ({ type: types.CLEAR_REPORTED_NOTE })
export const clearSupplierInvoiceProjectRelation = () => ({ type: types.CLEAR_SUPPLIER_INVOICE_PROJECT_RELATION })
export const clearCustomerObject = () => ({ type: types.CLEAR_CUSTOMER_OBJECT })
export const clearCustomerObjects = () => ({ type: types.CLEAR_CUSTOMER_OBJECTS })
export const clearCustomers = () => ({ type: types.CLEAR_CUSTOMERS})
export const clearCustomer = () => ({ type: types.CLEAR_CUSTOMER})
export const clearCustomerCounts = () => ({ type: types.CLEAR_CUSTOMER_COUNTS})
export const clearProjects = (namespace = "") => ({ type: types.CLEAR_PROJECTS, namespace })
export const clearProjectCounts = () => ({ type: types.CLEAR_PROJECT_COUNTS })
export const clearTask = () => ({ type: types.CLEAR_TASK })
export const clearSalaries = () => ({ type: types.CLEAR_SALARIES })
export const clearEmployeeReportedTimeSum = () => ({ type: types.CLEAR_EMPLOYEE_REPORTED_TIME_SUM })
export const clearSupplierInvoice = () => ({ type: types.CLEAR_SUPPLIER_INVOICE })
export const clearSupplierInvoices = () => ({ type: types.CLEAR_SUPPLIER_INVOICES })
export const clearProjectAccessEmployeeRelations = () => ({ type: types.CLEAR_PROJECT_ACCESS_EMPLOYEE_RELATIONS })

export const deleteProjectAccessEmployeeRelationBegin = () => ({ type: types.DELETE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_BEGIN })
export const deleteProjectAccessEmployeeRelationSuccess = () => ({ type: types.DELETE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_SUCCESS })

export const createProjectAccessEmployeeRealtionBegin = () => ({ type: types.CREATE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_BEGIN })
export const createProjectAccessEmployeeRelationSuccess = () => ({ type: types.CREATE_PROJECT_ACCESS_EMPLOYEE_RELATIONS_SUCCESS })

export const createInvoiceInFortnoxBegin = () => ({ type: types.CREATE_INVOICE_IN_FORTNOX_BEGIN })
export const createInvoiceInFortnoxSuccess = () => ({ type: types.CREATE_INVOICE_IN_FORTNOX_SUCCESS })

export const postImportSingleSupplierInvoiceFromFortnoxBegin = () => ({ type: types.POST_IMPORT_SINGLE_FORTNOX_SUPPLIER_INVOICE_BEGIN })
export const postImportSingleSupplierInvoiceFromFortnoxSuccess = () => ({ type: types.POST_IMPORT_SINGLE_FORTNOX_SUPPLIER_INVOICE_SUCCESS })

export const createInvoiceInEaccountingBegin = () => ({ type: types.CREATE_INVOICE_IN_EACCOUNTING_BEGIN })
export const createInvoiceInEaccountingSuccess = () => ({ type: types.CREATE_INVOICE_IN_EACCOUNTING_SUCCESS })

export const loadProjectAccessEmployeeRelationsBegin = () => ({ type: types.LOAD_PROJECT_ACCESS_EMPLOYEE_RELATIONS_BEGIN })
export const loadProjectAccessEmployeeRelationsSuccess = (payload) => ({ type: types.LOAD_PROJECT_ACCESS_EMPLOYEE_RELATIONS_SUCCESS, payload })

export const loadReportedAbsenceBegin = () => ({ type: types.LOAD_REPORTED_ABSENCE_BEGIN })
export const loadReportedAbsenceSuccess = (payload) => ({ type: types.LOAD_REPORTED_ABSENCE_SUCCESS, payload })

export const loadFortnoxArticlesBegin = () => ({ type: types.LOAD_FORTNOX_ARTICLES_BEGIN })
export const loadFortnoxArticlesSuccess = (payload) => ({ type: types.LOAD_FORTNOX_ARTICLES_SUCCESS, payload })

export const loadFortnoxSupplierInvoicesBegin = () => ({ type: types.LOAD_FORTNOX_SUPPLIER_INVOCIES_BEGIN })
export const loadFortnoxSupplierInvoicesSuccess = (payload) => ({ type: types.LOAD_FORTNOX_SUPPLIER_INVOCIES_SUCCESS, payload })

export const importSingleFortnoxDataToMyGizmoBegin = () => ({ type: types.IMPORT_SINGLE_FORTNOX_DATA_TO_MYGIZMO_BEGIN })
export const importSingleFortnoxDataToMyGizmoSuccess = () => ({ type: types.IMPORT_SINGLE_FORTNOX_DATA_TO_MYGIZMO_SUCCESS })

export const importFortnoxDataToMyGizmoBegin = () => ({ type: types.IMPORT_FORTNOX_DATA_TO_MYGIZMO_BEGIN })
export const importFortnoxDataToMyGizmoSuccess = () => ({ type: types.IMPORT_FORTNOX_DATA_TO_MYGIZMO_SUCCESS })

export const loadFortnoxArticleUnitsBegin = () => ({ type: types.LOAD_FORTNOX_ARTICLE_UNITS_BEGIN })
export const loadFortnoxArticleUnitsSuccess = (payload) => ({ type: types.LOAD_FORTNOX_ARTICLE_UNITS_SUCCESS, payload })

export const loadFortnoxArticleUnitGroupsBegin = () => ({ type: types.LOAD_FORTNOX_ARTICLE_UNIT_GROUPS_BEGIN })
export const loadFortnoxArticleUnitGroupsSuccess = (payload) => ({ type: types.LOAD_FORTNOX_ARTICLE_UNIT_GROUPS_SUCCESS, payload })

export const updateFortnoxArticleUnitGroupsBegin = () => ({ type: types.UPDATE_FORTNOX_ARTICLE_UNIT_GROUPS_BEGIN })
export const updateFortnoxArticleUnitGroupsSuccess = () => ({ type: types.UPDATE_FORTNOX_ARTICLE_UNIT_GROUPS_SUCCESS })

export const loadEaccountingArticlesBegin = () => ({ type: types.LOAD_EACCOUNTING_ARTICLES_BEGIN })
export const loadEaccountingArticlesSuccess = (payload) => ({ type: types.LOAD_EACCOUNTING_ARTICLES_SUCCESS, payload })

export const importSingleEaccountingDataToMyGizmoBegin = () => ({ type: types.IMPORT_SINGLE_EACCOUNTING_DATA_TO_MYGIZMO_BEGIN })
export const importSingleEaccountingDataToMyGizmoSuccess = () => ({ type: types.IMPORT_SINGLE_EACCOUNTING_DATA_TO_MYGIZMO_SUCCESS })

export const importEaccountingDataToMyGizmoBegin = () => ({ type: types.IMPORT_EACCOUNTING_DATA_TO_MYGIZMO_BEGIN })
export const importEaccountingDataToMyGizmoSuccess = () => ({ type: types.IMPORT_EACCOUNTING_DATA_TO_MYGIZMO_SUCCESS })

export const loadEaccountingArticleUnitsBegin = () => ({ type: types.LOAD_EACCOUNTING_ARTICLE_UNITS_BEGIN })
export const loadEaccountingArticleUnitsSuccess = (payload) => ({ type: types.LOAD_EACCOUNTING_ARTICLE_UNITS_SUCCESS, payload })

export const loadEaccountingArticleUnitGroupsBegin = () => ({ type: types.LOAD_EACCOUNTING_ARTICLE_UNIT_GROUPS_BEGIN })
export const loadEaccountingArticleUnitGroupsSuccess = (payload) => ({ type: types.LOAD_EACCOUNTING_ARTICLE_UNIT_GROUPS_SUCCESS, payload })

export const updateEaccountingArticleUnitGroupsBegin = () => ({ type: types.UPDATE_EACCOUNTING_ARTICLE_UNIT_GROUPS_BEGIN })
export const updateEaccountingArticleUnitGroupsSuccess = () => ({ type: types.UPDATE_EACCOUNTING_ARTICLE_UNIT_GROUPS_SUCCESS })

export const loadFortnoxStatusBegin = () => ({ type: types.LOAD_FORTNOX_STATUS_BEGIN })
export const loadFortnoxStatusSuccess = (payload) => ({ type: types.LOAD_FORTNOX_STATUS_SUCCESS, payload })

export const loadEaccountingStatusBegin = () => ({ type: types.LOAD_EACCOUNTING_STATUS_BEGIN })
export const loadEaccountingStatusSuccess = (payload) => ({ type: types.LOAD_EACCOUNTING_STATUS_SUCCESS, payload })

export const loadEaccountingOrdersBegin = () => ({ type: types.LOAD_EACCOUNTING_ORDERS_BEGIN })
export const loadEaccountingOrdersSuccess = (payload) => ({ type: types.LOAD_EACCOUNTING_ORDERS_SUCCESS, payload })

export const loadEaccountingCustomersBegin = () => ({ type: types.LOAD_EACCOUNTING_CUSTOMERS_BEGIN })
export const loadEaccountingCustomersSuccess = (payload) => ({ type: types.LOAD_EACCOUNTING_CUSTOMERS_SUCCESS, payload })

export const loadFortnoxOrdersBegin = () => ({ type: types.LOAD_FORTNOX_ORDERS_BEGIN })
export const loadFortnoxOrdersSuccess = (payload) => ({ type: types.LOAD_FORTNOX_ORDERS_SUCCESS, payload })

export const loadFortnoxCustomersBegin = () => ({ type: types.LOAD_FORTNOX_CUSTOMERS_BEGIN })
export const loadFortnoxCustomersSuccess = (payload) => ({ type: types.LOAD_FORTNOX_CUSTOMERS_SUCCESS, payload })

export const loadUseOfferLinkBegin = () => ({ type: types.LOAD_USE_OFFER_LINK_BEGIN })
export const loadUseOfferLinkSuccess = (payload) => ({ type: types.LOAD_USE_OFFER_LINK_SUCCESS, payload })

export const sendOfferLinkAnswerBegin = () => ({ type: types.SEND_OFFER_LINK_ANSWER_BEGIN })
export const sendOfferLinkAnswerSuccess = () => ({ type: types.SEND_OFFER_LINK_ANSWER_SUCCESS })

export const loadOffersBegin = () => ({ type: types.LOAD_OFFERS_BEGIN })
export const loadOffersSuccess = (payload) => ({ type: types.LOAD_OFFERS_SUCCESS, payload })

export const clearOfferCounts = () => ({ type: types.CLEAR_OFFER_COUNTS })
export const loadOfferCountsBegin = () => ({ type: types.LOAD_OFFER_COUNTS_BEGIN })
export const loadOfferCountsSuccess = (payload) => ({ type: types.LOAD_OFFER_COUNTS_SUCCESS, payload })
export const loadAllOfferCountsBegin = () => ({ type: types.LOAD_ALL_OFFER_COUNTS_BEGIN })
export const loadAllOfferCountsSuccess = (payload) => ({ type: types.LOAD_ALL_OFFER_COUNTS_SUCCESS, payload })

export const clearPaymentPlanElements = () => ({ type: types.CLEAR_PAYMENT_PLAN_ELEMENTS })
export const loadPaymentPlanElementsBegin = () => ({ type: types.LOAD_PAYMENT_PLAN_ELEMENTS_BEGIN })
export const loadPaymentPlanElementsSuccess = (payload) => ({ type: types.LOAD_PAYMENT_PLAN_ELEMENTS_SUCCESS, payload })
export const createPaymentPlanElementBegin = () => ({ type: types.CREATE_PAYMENT_PLAN_ELEMENT_BEGIN })
export const createPaymentPlanElementSuccess = () => ({ type: types.UPDATE_PAYMENT_PLAN_ELEMENT_SUCCESS })
export const updatePaymentPlanElementBegin = () => ({ type: types.UPDATE_PAYMENT_PLAN_ELEMENT_BEGIN })
export const updatePaymentPlanElementSuccess = () => ({ type: types.UPDATE_PAYMENT_PLAN_ELEMENT_SUCCESS })
export const deletePaymentPlanElementBegin = () => ({ type: types.DELETE_PAYMENT_PLAN_ELEMENT_BEGIN })
export const deletePaymentPlanElementSuccess = () => ({ type: types.DELETE_PAYMENT_PLAN_ELEMENT_SUCCESS })

export const loadOfferBegin = () => ({ type: types.LOAD_OFFER_BEGIN })
export const loadOfferSuccess = (payload) => ({ type: types.LOAD_OFFER_SUCCESS, payload })

export const clearOffers = () => ({ type: types.CLEAR_OFFERS })
export const clearOffer = () => ({ type: types.CLEAR_OFFER })

export const copyOfferToNewVersionBegin = () => ({ type: types.COPY_OFFER_TO_NEW_VERSION_BEGIN })
export const copyOfferToNewVersionSuccess = () => ({ type: types.COPY_OFFER_TO_NEW_VERSION_SUCCESS})
export const copyOfferToTemplateBegin = () => ({ type: types.COPY_OFFER_TO_TEMPLATE_BEGIN })
export const copyOfferToTemplateSuccess = () => ({ type: types.COPY_OFFER_TO_TEMPLATE_SUCCESS})

export const createOfferBegin = () => ({ type: types.CREATE_OFFER_BEGIN })
export const createOfferSuccess = () => ({ type: types.CREATE_OFFER_SUCCESS })

export const updateOfferBegin = () => ({ type: types.UPDATE_OFFER_BEGIN })
export const updateOfferSuccess = () => ({ type: types.UPDATE_OFFER_SUCCESS })

export const deleteOfferBegin = () => ({ type: types.DELETE_OFFER_BEGIN })
export const deleteOfferSuccess = () => ({ type: types.DELETE_OFFER_SUCCESS })

export const createOfferLinkBegin = () => ({ type: types.CREATE_OFFER_LINK_BEGIN })
export const createOfferLinkSuccess = () => ({ type: types.CREATE_OFFER_LINK_SUCCESS })

export const deleteOfferLinkBegin = () => ({ type: types.DELETE_OFFER_LINK_BEGIN })
export const deleteOfferLinkSuccess = () => ({ type: types.DELETE_OFFER_LINK_SUCCESS })

export const loadFortnoxFinancialYearsBegin = () => ({ type: types.LOAD_FORTNOX_FINANCIAL_YEARS_BEGIN })
export const loadFortnoxFinancialYearsSuccess = (payload) => ({ type: types.LOAD_FORTNOX_FINANCIAL_YEARS_SUCCESS, payload })

export const loadFortnoxVoucherSeriesBegin = () => ({ type: types.LOAD_FORTNOX_VOUCHER_SERIES_BEGIN })
export const loadFortnoxVoucherSeriesSuccess = (payload) => ({ type: types.LOAD_FORTNOX_VOUCHER_SERIES_SUCCESS, payload })

export const importFortnoxSupplierInvociesBegin = () => ({ type: types.IMPORT_FORTNOX_SUPPLIER_INVOICES_BEGIN })
export const importFortnoxSupplierInvociesSuccess = () => ({ type: types.IMPORT_FORTNOX_SUPPLIER_INVOICES_SUCCESS })

export const lookupPriceInPricelistBegin = () => ({ type: types.LOOKUP_PRICE_IN_PRICELIST_BEGIN})
export const lookupPriceInPricelistSuccess = (payload) => ({ type: types.LOOKUP_PRICE_IN_PRICELIST_SUCCESS, payload})
export const clearPriceInPriceList = () => ({ type: types.CLEAR_PRICE_IN_PRICE_LIST})

export const loadHouseWorkDeductionTypesBegin = () => ({ type: types.LOAD_HOUSE_WORK_DEDUCTION_TYPES_BEGIN })
export const loadHouseWorkDeductionTypesSuccess = (payload) => ({ type: types.LOAD_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS, payload })

export const createHouseWorkDeductionTypesBegin = () => ({ type: types.CREATE_HOUSE_WORK_DEDUCTION_TYPES_BEGIN })
export const createHouseWorkDeductionTypesSuccess = () => ({ type: types.CREATE_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS })

export const updateHouseWorkDeductionTypesBegin = () => ({ type: types.UPDATE_HOUSE_WORK_DEDUCTION_TYPES_BEGIN })
export const updateHouseWorkDeductionTypesSuccess = () => ({ type: types.UPDATE_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS })

export const deleteHouseWorkDeductionTypesBegin = () => ({ type: types.DELETE_HOUSE_WORK_DEDUCTION_TYPES_BEGIN })
export const deleteHouseWorkDeductionTypesSuccess = () => ({ type: types.DELETE_HOUSE_WORK_DEDUCTION_TYPES_SUCCESS })

export const loadSupplierInvoiceBegin = () => ({ type: types.LOAD_SUPPLIER_INVOICE_BEGIN })
export const loadSupplierInvoiceSuccess = (payload) => ({ type: types.LOAD_SUPPLIER_INVOICE_SUCCESS, payload })
export const loadSupplierInvoicesBegin = () => ({ type: types.LOAD_SUPPLIER_INVOICES_BEGIN })
export const loadSupplierInvoicesSuccess = (payload) => ({ type: types.LOAD_SUPPLIER_INVOICES_SUCCESS, payload })
export const createSupplierInvoiceBegin = () => ({ type: types.CREATE_SUPPLIER_INVOICE_BEGIN })
export const createSupplierInvoiceSuccess = () => ({ type: types.CREATE_SUPPLIER_INVOICE_SUCCESS })
export const updateSupplierInvoiceBegin = () => ({ type: types.UPDATE_SUPPLIER_INVOICE_BEGIN })
export const updateSupplierInvoiceSuccess = () => ({ type: types.UPDATE_SUPPLIER_INVOICE_SUCCESS })
export const deleteSupplierInvoiceBegin = () => ({ type: types.DELETE_SUPPLIER_INVOICE_BEGIN })
export const deleteSupplierInvoiceSuccess = () => ({ type: types.DELETE_SUPPLIER_INVOICE_SUCCESS })

export const createSupplierInvoiceProjectRelationBegin = () => ({ type: types.CREATE_SUPPLIER_INVOICE_PROJECT_RELATION_BEGIN })
export const createSupplierInvoiceProjectRelationSuccess = () => ({ type: types.CREATE_SUPPLIER_INVOICE_PROJECT_RELATION_SUCCESS })

export const createPipedriveLeadBegin = () => ({ type: types.CREATE_PIPEDRIVE_LEAD_BEGIN })
export const createPipedriveLeadSuccess = () => ({ type: types.CREATE_PIPEDRIVE_LEAD_SUCCESS })

export const updatePipedriveLeadBegin = () => ({ type: types.UPDATE_PIPEDRIVE_LEAD_BEGIN })
export const updatePipedriveLeadSuccess = () => ({ type: types.UPDATE_PIPEDRIVE_LEAD_SUCCESS })

export const updateSupplierInvoiceProjectRelationBegin = () => ({ type: types.UPDATE_SUPPLIER_INVOICE_PROJECT_RELATION_BEGIN })
export const updateSupplierInvoiceProjectRelationSuccess = () => ({ type: types.UPDATE_SUPPLIER_INVOICE_PROJECT_RELATION_SUCCESS })
export const deleteSupplierInvoiceProjectRelationBegin = () => ({ type: types.DELETE_SUPPLIER_INVOICE_PROJECT_RELATION_BEGIN })
export const deleteSupplierInvoiceProjectRelationSuccess = () => ({ type: types.DELETE_SUPPLIER_INVOICE_PROJECT_RELATION_SUCCESS })
export const loadInvoiceLinesBegin = () => ({ type: types.LOAD_INVOICE_LINES_BEGIN })
export const loadInvoiceLinesSuccess = (payload) => ({ type: types.LOAD_INVOICE_LINES_SUCCESS, payload })

export const signOut = () => ({
    type: types.SIGN_OUT,
});

export const hideFooter = () => ({
    type: types.HIDE_FOOTER
})

export const showFooter = () => ({
    type: types.SHOW_FOOTER
})

export const loadEmployeeBookingsBegin = () => ({
    type: types.LOAD_EMPLOYEE_BOOKINGS_BEGIN,
});

export const loadEmployeeBookingsSuccess = (bookings) => ({
    type: types.LOAD_EMPLOYEE_BOOKINGS_COMPLETE,
    bookings,
});

export const loadReportsBegin = (namespace = "") => ({
    type: types.LOAD_REPORTS_BEGIN,
    namespace
});

export const loadReportsSuccess = (reports, filter, namespace = "") => ({
    type: types.LOAD_REPORTS_COMPLETE,
    reports,
    filter,
    namespace
});

export const loadReportsToAttestBegin = () => ({
    type: types.LOAD_REPORTS_TO_ATTEST_BEGIN,
});

export const loadReportsToAttestSuccess = (payload) => ({
    type: types.LOAD_REPORTS_TO_ATTEST_SUCCESS,
    payload
});

export const loadReportsForMeToAttestBegin = () => ({
    type: types.LOAD_REPORTS_FOR_ME_TO_ATTEST_BEGIN,
});

export const loadReportsForMeToAttestSuccess = (payload) => ({
    type: types.LOAD_REPORTS_FOR_ME_TO_ATTEST_SUCCESS,
    payload
});

export const loadMyRecentProjectsBegin = () => ({
    type: types.LOAD_MY_RECENT_PROJECTS_BEGIN
})

export const loadMyRecentProjectsSuccess = (myRecentProjects) => ({
    type: types.LOAD_MY_RECENT_PROJECTS_COMPLETE,
    myRecentProjects
})

export const clearBookingProject = () => ({ type: types.CLEAR_BOOKING_PROJECT})
export const loadBookingProjectBegin = () => ({ type: types.LOAD_BOOKING_PROJECT_BEGIN})
export const loadBookingProjectSuccess = (payload) => ({ type: types.LOAD_BOOKING_PROJECT_COMPLETE, payload})

export const loadProjectsBegin = (payload, namespace ="") => ({
    type: types.LOAD_PROJECTS_BEGIN,
    payload,
    namespace
})

export const loadProjectsSuccess = (projects, search, namespace) => ({
    type: types.LOAD_PROJECTS_COMPLETE,
    projects,
    search,
    namespace
})

export const loadProjectBegin = (namespace = "") => ({
    type: types.LOAD_PROJECT_BEGIN,
    namespace,
})

export const loadProjectSuccess = (project, namespace = "") => ({
    type: types.LOAD_PROJECT_COMPLETE,
    project,
    namespace,
})

export const loadActiveTimeTypesBegin = () => ({
    type: types.LOAD_ACTIVE_TIME_TYPES_BEGIN
})

export const loadActiveTimeTypesSuccess = (timeTypes) => ({
    type: types.LOAD_ACTIVE_TIME_TYPES_COMPLETE,
    timeTypes
})

export const loadMoreProjectsBegin = () => ({
    type: types.LOAD_MORE_PROJECTS_BEGIN
})

export const loadMoreProjectsSuccess = (projects, namespace) => ({
    type: types.LOAD_MORE_PROJECTS_COMPLETE,
    projects,
    namespace
})

export const loadMoreCustomersBegin = () => ({
    type: types.LOAD_MORE_CUSTOMERS_BEGIN
})

export const loadMoreCustomersSuccess = (customers) => ({
    type: types.LOAD_MORE_CUSTOMERS_COMPLETE,
    customers
})

export const requestStarted = () => ({
    type: types.REQUEST_STARTED,
});

export const requestCompleted = () => ({
    type: types.REQUEST_COMPLETED,
});

export const signInUserSuccess = (payload) => ({
    type: types.SIGN_IN_USER_SUCCESS,
    payload,
});

export const signInUserSuccessButNeedToPickBackend = (payload) => ({
    type: types.SIGN_IN_USER_SUCCESS_BUT_NEED_TO_PICK_BACKEND,
    payload,
});

export const pickUserConnectedBackend = (payload) => ({
    type: types.PICK_USER_CONNECTED_BACKEND,
    payload
})

export const signInUserFailed = (payload) => ({
    type: types.SIGN_IN_USER_FAILED,
    payload,
});

export const loadUserDataSuccess = (payload) => ({
    type: types.LOAD_USER_DATA_SUCCESS,
    payload,
});

export const loadUserDataFailed = (payload) => ({
    type: types.LOAD_USER_DATA_FAILED,
    payload,
});

export const showInactiveAccountModal = () => ({
    type: types.SHOW_INACTIVE_ACCOUNT_MODAL,
});

export const hideInactiveAccountModal = () => ({
    type: types.HIDE_INACTIVE_ACCOUNT_MODAL,
});

export const createReportedTimeBegin = () => ({
    type: types.CREATE_REPORTED_TIME_BEGIN
})

export const createReportedTimeSuccess = () => ({
    type: types.CREATE_REPORTED_TIME_COMPLETE
})

export const updateReportedTimeBegin = () => ({
    type: types.UPDATE_REPORTED_TIME_BEGIN
})

export const updateReportedTimeSuccess = () => ({
    type: types.UPDATE_REPORTED_TIME_COMPLETE
})

export const deleteReportedTimeBegin = () => ({
    type: types.DELETE_REPORTED_TIME_BEGIN
})

export const deleteReportedTimeSuccess = () => ({
    type: types.DELETE_REPORTED_TIME_COMPLETE
})

export const createReportedProductBegin = () => ({
    type: types.CREATE_REPORTED_PRODUCT_BEGIN
})

export const createReportedProductSuccess = () => ({
    type: types.CREATE_REPORTED_PRODUCT_COMPLETE
})

export const updateReportedProductBegin = () => ({
    type: types.UPDATE_REPORTED_PRODUCT_BEGIN
})

export const updateReportedProductSuccess = () => ({
    type: types.UPDATE_REPORTED_PRODUCT_COMPLETE
})

export const deleteReportedProductBegin = () => ({
    type: types.DELETE_REPORTED_PRODUCT_BEGIN
})

export const deleteReportedProductSuccess = () => ({
    type: types.DELETE_REPORTED_PRODUCT_COMPLETE
})

export const createProjectFromOfferBegin = () => ({
    type: types.CREATE_PROJECT_FROM_OFFER_BEGIN
})
export const createProjectFromOfferSuccess = () => ({
    type: types.CREATE_PROJECT_FROM_OFFER_SUCCESS
})

export const loadTasksBegin = () => ({
    type: types.LOAD_TASKS_BEGIN
})

export const loadTasksComplete = (payload) => ({
    type: types.LOAD_TASKS_COMPLETE,
    payload
})

export const createProjectAssignmentBegin = () => ({
    type: types.CREATE_PROJECT_ASSIGNMENT_BEGIN
})

export const createProjectAssignmentComplete = () => ({
    type: types.CREATE_PROJECT_ASSIGNMENT_COMPLETE
})

export const loadProductsBegin = () => ({
    type: types.LOAD_PRODUCTS_BEGIN
})

export const loadProductsComplete = (payload) => ({
    type: types.LOAD_PRODUCTS_COMPLETE,
    payload
})

export const loadMoreProductsBegin = () => ({
    type: types.LOAD_MORE_PRODUCTS_BEGIN
})

export const loadMoreProductsComplete = (payload) => ({
    type: types.LOAD_MORE_PRODUCTS_COMPLETE,
    payload
})

export const createProjectProductAssignmentBegin = () => ({
    type: types.CREATE_PROJECT_PRODUCT_ASSIGNMENT_BEGIN
})

export const createProjectProductAssignmentComplete = () => ({
    type: types.CREATE_PROJECT_PRODUCT_ASSIGNMENT_COMPLETE
})

export const loadProductAssignmentStatsBegin = () => ({
    type: types.LOAD_PRODUCT_ASSIGNMENT_STATS_BEGIN
})

export const loadProductAssignmentStatsComplete = (payload) => ({
    type: types.LOAD_PRODUCT_ASSIGNMENT_STATS_COMPLETE,
    payload
})

export const createFreetextProjectAssignmentBegin = () => ({
    type: types.CREATE_FREETEXT_PROJECT_ASSIGNMENT_BEGIN
})
export const createFreetextProjectAssignmentComplete = () => ({
    type: types.CREATE_FREETEXT_PROJECT_ASSIGNMENT_COMPLETE
})

export const createDocumentBegin = () => ({
    type: types.CREATE_DOCUMENT_BEGIN
})
export const updateDocumentBegin = () => ({
    type: types.UPDATE_DOCUMENT_BEGIN
})
export const deleteDocumentBegin = () => ({
    type: types.DELETE_DOCUMENT_BEGIN
})

export const createDocumentComplete = () => ({
    type: types.CREATE_DOCUMENT_COMPLETE
})
export const updateDocumentComplete = () => ({
    type: types.UPDATE_DOCUMENT_COMPLETE
})
export const deleteDocumentComplete = () => ({
    type: types.DELETE_DOCUMENT_COMPLETE
})

export const createNoteBegin = () => ({
    type: types.CREATE_NOTE_BEGIN
})
export const updateNoteBegin = () => ({
    type: types.UPDATE_NOTE_BEGIN
})
export const deleteNoteBegin = () => ({
    type: types.DELETE_NOTE_BEGIN
})
export const createNoteComplete = () => ({
    type: types.CREATE_NOTE_COMPLETE
})
export const updateNoteComplete = () => ({
    type: types.UPDATE_NOTE_COMPLETE
})
export const deleteNoteComplete = () => ({
    type: types.DELETE_NOTE_COMPLETE
})

export const loadDocumentCategoriesBegin = () => ({
    type: types.LOAD_DOCUMENT_CATEGORIES_BEGIN
})
export const loadDocumentCategoriesComplete = (payload) => ({
    type: types.LOAD_DOCUMENT_CATEGORIES_COMPLETE,
    payload
})

export const markProjectAsDoneBegin = () => ({
    type: types.MARK_PROJECT_AS_DONE_BEGIN
})
export const markProjectAsDoneComplete = () => ({
    type: types.MARK_PROJECT_AS_DONE_COMPLETE
})
export const markProjectAsUndoneBegin = () => ({
    type: types.MARK_PROJECT_AS_UNDONE_BEGIN
})
export const markProjectAsUndoneComplete = () => ({
    type: types.MARK_PROJECT_AS_UNDONE_COMPLETE
})

export const createReportedAbsenceBegin = () => ({
    type: types.CREATE_REPORTED_ABSENCE_BEGIN
})

export const createReportedAbsenceSuccess = () => ({
    type: types.CREATE_REPORTED_ABSENCE_COMPLETE
})

export const updateReportedAbsenceBegin = () => ({
    type: types.UPDATE_REPORTED_ABSENCE_BEGIN
})

export const updateReportedAbsenceSuccess = () => ({
    type: types.UPDATE_REPORTED_ABSENCE_COMPLETE
})

export const deleteReportedAbsenceBegin = () => ({
    type: types.DELETE_REPORTED_ABSENCE_BEGIN
})

export const deleteReportedAbsenceSuccess = () => ({
    type: types.DELETE_REPORTED_ABSENCE_COMPLETE
})

export const loadActiveAbsenceTypesBegin = () => ({
    type: types.LOAD_ACTIVE_ABSENCE_TYPES_BEGIN
})

export const loadActiveAbsenceTypesComplete = (payload) => ({
    type: types.LOAD_ACTIVE_ABSENCE_TYPES_COMPLETE,
    payload
})

export const loadSettingsBegin = () => ({
    type: types.LOAD_SETTINGS_BEGIN
})

export const loadSettingsComplete = (payload) => ({
    type: types.LOAD_SETTINGS_COMPLETE,
    payload
})

export const loadProjectFormsBegin = () => ({
    type: types.LOAD_PROJECT_FORMS_BEGIN
})

export const loadProjectFormsComplete = (payload) => ({
    type: types.LOAD_PROJECT_FORMS_COMPLETE,
    payload
})

export const loadCustomerObjectsBegin = () => ({
    type: types.LOAD_CUSTOMER_OBJECTS_BEGIN
})

export const loadCustomerObjectsComplete = (payload) => ({
    type: types.LOAD_CUSTOMER_OBJECTS_COMPLETE,
    payload
})

export const loadCustomersBegin = (payload) => ({
    type: types.LOAD_CUSTOMERS_BEGIN,
    payload
})

export const loadCustomersComplete = (payload, search) => ({
    type: types.LOAD_CUSTOMERS_COMPLETE,
    payload,
    search
})

export const createCustomerBegin = () => ({
    type: types.CREATE_CUSTOMER_BEGIN
})

export const createCustomerComplete = () => ({
    type: types.CREATE_CUSTOMER_COMPLETE
})

export const createCustomerObjectBegin = () => ({
    type: types.CREATE_CUSTOMER_OBJECT_BEGIN
})

export const createCustomerObjectComplete = () => ({
    type: types.CREATE_CUSTOMER_OBJECT_COMPLETE
})

export const createProjectBegin = () => ({
    type: types.CREATE_PROJECT_BEGIN
})

export const createProjectComplete = () => ({
    type: types.CREATE_PROJECT_COMPLETE
})

export const loadEmployeesBegin = () => ({
    type: types.LOAD_EMPLOYEES_BEGIN
})

export const loadEmployeesComplete = (payload) => ({
    type: types.LOAD_EMPLOYEES_COMPLETE,
    payload
})

export const loadProjectVariationCountsBegin = () => ({ type: types.LOAD_PROJECT_VARIATION_COUNTS_BEGIN })
export const loadProjectVariationCountsSuccess = (payload) => ({ type: types.LOAD_PROJECT_VARIATION_COUNTS_SUCCESS, payload })

export const activateCustomerBegin = () => ({
    type: types.ACTIVATE_CUSTOMER_BEGIN
})

export const activateCustomerComplete = () => ({
    type: types.ACTIVATE_CUSTOMER_COMPLETE
})

export const loadTodosBegin = () => ({
    type: types.LOAD_TODOS_BEGIN
})

export const loadTodosComplete = (payload) => ({
    type: types.LOAD_TODOS_COMPLETE,
    payload
})

export const deleteCustomerBegin = () => ({ type: types.DELETE_CUSTOMER_BEGIN })
export const deleteCustomerSuccess = () => ({ type: types.DELETE_CUSTOMER_SUCCESS })

export const submitRegisterUserDetails = (payload) => ({ type: types.SUBMIT_REGISTER_USER_DETAILS, payload })
export const sendAccessTokenBegin = () => ({ type: types.SEND_ACCESS_TOKEN_BEGIN })
export const sendAccessTokenSuccess = () => ({ type: types.SEND_ACCESS_TOKEN_SUCCESS })
export const checkAccessTokenBegin = () => ({ type: types.CHECK_ACCESS_TOKEN_BEGIN })
export const checkAccessTokenSuccess = (payload) => ({ type: types.CHECK_ACCESS_TOKEN_SUCCESS, payload })
export const checkIfEmailExistsBegin = () => ({ type: types.CHECK_IF_EMAIL_EXISTS_BEGIN })
export const checkIfEmailExistsSuccess = (payload) => ({ type: types.CHECK_IF_EMAIL_EXISTS_SUCCESS, payload })
export const checkLoginDetailsBegin = () => ({ type: types.CHECK_LOGIN_DETAILS_BEGIN })
export const checkLoginDetailsSuccess = (payload) => ({ type: types.CHECK_LOGIN_DETAILS_SUCCESS, payload })
export const createUserWithTokenBegin = () => ({ type: types.CREATE_USER_WITH_TOKEN_BEGIN })
export const createUserWithTokenSuccess = () => ({ type: types.CREATE_USER_WITH_TOKEN_SUCCESS })
export const createTenantProspectBegin = () => ({ type: types.CREATE_TENANT_PROSPECT_BEGIN })
export const createTenantProspectSuccess = () => ({ type: types.CREATE_TENANT_PROSPECT_SUCCESS })

export const checkPasswordResetTokenBegin = () => ({ type: types.CHECK_PASSWORD_RESET_BEGIN })
export const checkPasswordResetTokenSuccess = (payload) => ({ type: types.CHECK_PASSWORD_RESET_SUCCESS, payload })
export const resetPasswordBegin = () => ({ type: types.RESET_PASSWORD_BEGIN })
export const resetPasswordSuccess = () => ({ type: types.RESET_PASSWORD_SUCCESS })

export const loadBookableResourcesBegin = () => ({ type: types.LOAD_BOOKABLE_RESOURCES_BEGIN })
export const loadBookableResourcesSuccess = (payload) => ({ type: types.LOAD_BOOKABLE_RESOURCES_SUCCESS, payload })
export const loadBookableVehiclesBegin = () => ({ type: types.LOAD_BOOKABLE_VEHICLES_BEGIN })
export const loadBookableVehiclesSuccess = (payload) => ({ type: types.LOAD_BOOKABLE_VEHICLES_SUCCESS, payload })

export const clearBookings = () => ({ type: types.CLEAR_BOOKINGS })
export const loadBookingsBegin = () => ({ type: types.LOAD_BOOKINGS_BEGIN })
export const loadBookingsSuccess = (payload) => ({ type: types.LOAD_BOOKINGS_SUCCESS, payload })
export const createBookingBegin = () => ({ type: types.CREATE_BOOKING_BEGIN })
export const createBookingSuccess = () => ({ type: types.CREATE_BOOKING_SUCCESS })
export const updateBookingBegin = () => ({ type: types.UPDATE_BOOKING_BEGIN })
export const updateBookingSuccess = () => ({ type: types.UPDATE_BOOKING_SUCCESS })
export const removeBookingBegin = () => ({ type: types.DELETE_BOOKING_BEGIN })
export const removeBookingSuccess = () => ({ type: types.DELETE_BOOKING_SUCCESS })

export const createBookingResourceBegin = () => ({ type: types.CREATE_BOOKING_RESOURCE_BEGIN })
export const createBookingResourceSuccess = () => ({ type: types.CREATE_BOOKING_RESOURCE_SUCCESS })
export const updateBookingResourceBegin = () => ({ type: types.UPDATE_BOOKING_RESOURCE_BEGIN })
export const updateBookingResourceSuccess = () => ({ type: types.UPDATE_BOOKING_RESOURCE_SUCCESS })
export const removeBookingResourceBegin = () => ({ type: types.DELETE_BOOKING_RESOURCE_BEGIN })
export const removeBookingResourceSuccess = () => ({ type: types.DELETE_BOOKING_RESOURCE_SUCCESS })

export const createFreetextProductAssignmentBegin = () => ({ type: types.CREATE_FREETEXT_PRODUCT_ASSIGNMENT_BEGIN })
export const createFreetextProductAssignmentSuccess = () => ({ type: types.CREATE_FREETEXT_PRODUCT_ASSIGNMENT_SUCCESS })

export const loadUnitsBegin = () => ({ type: types.LOAD_UNITS_BEGIN })
export const loadUnitsSuccess = (payload) => ({ type: types.LOAD_UNITS_SUCCESS, payload })

export const loadFormTemplatesBegin = () => ({ type: types.LOAD_FORM_TEMPLATES_BEGIN })
export const loadFormTemplatesSuccess = (payload) => ({ type: types.LOAD_FORM_TEMPLATES_SUCCESS, payload })

export const createProjectFormFromTemplateBegin = () => ({ type: types.CREATE_PROJECT_FORM_FROM_TEMPLATE_BEGIN })
export const createProjectFormFromTemplateSuccess = () => ({ type: types.CREATE_PROJECT_FORM_FROM_TEMPLATE_SUCCESS })

export const loadSignatureBegin = () => ({ type: types.LOAD_SIGNATURE_BEGIN })
export const loadSignatureSuccess = (payload) => ({ type: types.LOAD_SIGNATURE_SUCCESS, payload })

export const loadTotalAmountToInvoiceBegin = () => ({ type: types.LOAD_TOTAL_AMOUNT_TO_INVOICE_BEGIN })
export const loadTotalAmountToInvoiceSuccess = (payload) => ({ type: types.LOAD_TOTAL_AMOUNT_TO_INVOICE_SUCCESS, payload })

export const sendSignatureConfirmationBegin = () => ({ type: types.SEND_SIGNATURE_CONFIRMATION_BEGIN })
export const sendSignatureConfirmationSuccess = () => ({ type: types.SEND_SIGNATURE_CONFIRMATION_SUCCESS })

export const loadDailyReportsBegin = () => ({ type: types.LOAD_DAILY_REPORTS_BEGIN })
export const loadDailyReportsSuccess = (payload) => ({ type: types.LOAD_DAILY_REPORTS_SUCCESS, payload })

export const loadCurrentTenantFeatureFagsBegin = () => ({ type: types.LOAD_CURRENT_TENANT_FEATURE_FLAGS_BEGIN })
export const loadCurrentTenantFeatureFagsSuccess = (payload) => ({ type: types.LOAD_CURRENT_TENANT_FEATURE_FLAGS_SUCCESS, payload })

export const loadProjectCountsBegin = () => ({ type: types.LOAD_PROJECT_COUNTS_BEGIN })
export const loadProjectCountsSuccess = (payload) => ({ type: types.LOAD_PROJECT_COUNTS_SUCCESS, payload })

export const loadAllProjectCountsBegin = () => ({ type: types.LOAD_ALL_PROJECT_COUNTS_BEGIN })
export const loadAllProjectCountsSuccess = (payload) => ({ type: types.LOAD_ALL_PROJECT_COUNTS_SUCCESS, payload })

export const exportProjectsToFortnoxBegin = () => ({ type: types.EXPORT_PROJECTS_TO_FORTNOX_BEGIN })
export const exportProjectsToFortnoxSuccess = () => ({ type: types.EXPORT_PROJECTS_TO_FORTNOX_SUCCESS })

export const updateProjectBegin = () => ({ type: types.UPDATE_PROJECT_BEGIN })
export const updateProjectSuccess = () => ({ type: types.UPDATE_PROJECT_SUCCESS })

export const loadCustomerBegin = () => ({ type: types.LOAD_CUSTOMER_BEGIN})
export const loadCustomerSuccess = (payload) => ({ type: types.LOAD_CUSTOMER_SUCCESS, payload})

export const createAttestBegin = () => ({ type: types.CREATE_ATTEST_BEGIN })
export const createAttestSuccess = () => ({ type: types.CREATE_ATTEST_SUCCESS })

export const createAttestsBegin = () => ({ type: types.CREATE_ATTESTS_BEGIN })
export const createAttestsSuccess = () => ({ type: types.CREATE_ATTESTS_SUCCESS })

export const loadAttestsBegin = () => ({ type: types.LOAD_ATTESTS_BEGIN })
export const loadAttestsSuccess = (payload) => ({ type: types.LOAD_ATTESTS_SUCCESS, payload })

export const deleteAttestBegin = () => ({ type: types.DELETE_ATTEST_BEGIN })
export const deleteAttestSuccess = () => ({ type: types.DELETE_ATTEST_SUCCESS })

export const markProjectAsInactiveBegin = () => ({ type: types.MARK_PROJECT_AS_INACTIVE_BEGIN })
export const markProjectAsInactiveSuccess = () => ({ type: types.MARK_PROJECT_AS_INACTIVE_SUCCESS })

export const markProjectAsUninactiveBegin = () => ({ type: types.MARK_PROJECT_AS_UNINACTIVE_BEGIN })
export const markProjectAsUninactiveSuccess = () => ({ type: types.MARK_PROJECT_AS_UNINACTIVE_SUCCESS })

export const deleteProjectBegin = () => ({ type: types.DELETE_PROJECT_BEGIN })
export const deleteProjectSuccess = () => ({ type: types.DELETE_PROJECT_SUCCESS })

export const updateProjectFormBegin = () => ({ type: types.UPDATE_PROJECT_FORM_BEGIN })
export const updateProjectFormSuccess = () => ({ type: types.UPDATE_PROJECT_FORM_SUCCESS })

export const deleteProjectAssignmentBegin = () => ({ type: types.DELETE_PROJECT_ASSIGNMENT_BEGIN})
export const deleteProjectAssignmentSuccess = () => ({ type: types.DELETE_PROJECT_ASSIGNMENT_SUCCESS})
export const deleteProjectProductAssignmentBegin = () => ({ type: types.DELETE_PROJECT_PRODUCT_ASSIGNMENT_BEGIN})
export const deleteProjectProductAssignmentSuccess = () => ({ type: types.DELETE_PROJECT_PRODUCT_ASSIGNMENT_SUCCESS})

export const deleteProjectFormBegin = () => ({ type: types.DELETE_PROJECT_FORM_BEGIN})
export const deleteProjectFormSuccess = () => ({ type: types.DELETE_PROJECT_FORM_SUCCESS})

export const createProjectFormBegin = () => ({ type: types.CREATE_PROJECT_FORM_BEGIN })
export const createProjectFormSuccess = () => ({ type: types.CREATE_PROJECT_FORM_SUCCESS })

export const loadProjectVariationsBegin = () => ({ type: types.LOAD_PROJECT_VARIATIONS_BEGIN })
export const loadProjectVariationsSuccess = (payload) => ({ type: types.LOAD_PROJECT_VARIATIONS_SUCCESS, payload })

export const clearProjectVariation = () => ({ type: types.CLEAR_PROJECT_VARIATION })
export const clearProjectVariations = () => ({ type: types.CLEAR_PROJECT_VARIATIONS })

export const loadProjectVariationBegin = () => ({ type: types.LOAD_PROJECT_VARIATION_BEGIN })
export const loadProjectVariationSuccess = (payload) => ({ type: types.LOAD_PROJECT_VARIATION_SUCCESS, payload })

export const createProjectVariationBegin = () => ({ type: types.CREATE_PROJECT_VARIATION_BEGIN })
export const createProjectVariationSuccess = () => ({ type: types.CREATE_PROJECT_VARIATION_SUCCESS })

export const updateProjectVariationBegin = () => ({ type: types.UPDATE_PROJECT_VARIATION_BEGIN })
export const updateProjectVariationSuccess = () => ({ type: types.UPDATE_PROJECT_VARIATION_SUCCESS })

export const deleteProjectVariationBegin = () => ({ type: types.DELETE_PROJECT_VARIATION_BEGIN })
export const deleteProjectVariationSuccess = () => ({ type: types.DELETE_PROJECT_VARIATION_SUCCESS })

export const createProjectVariationLinkBegin = () => ({ type: types.CREATE_PROJECT_VARIATION_LINK_BEGIN })
export const createProjectVariationLinkSuccess = () => ({ type: types.CREATE_PROJECT_VARIATION_LINK_SUCCESS })

export const deleteProjectVariationLinkBegin = () => ({ type: types.DELETE_PROJECT_VARIATION_LINK_BEGIN })
export const deleteProjectVariationLinkSuccess = () => ({ type: types.DELETE_PROJECT_VARIATION_LINK_SUCCESS })

export const loadUseProjectVariationLinkBegin = () => ({ type: types.LOAD_USE_PROJECT_VARIATION_LINK_BEGIN })
export const loadUseProjectVariationLinkSuccess = (payload) => ({ type: types.LOAD_USE_PROJECT_VARIATION_LINK_SUCCESS, payload })

export const sendProjectVariationLinkAnswerBegin = () => ({ type: types.SEND_PROJECT_VARIATION_LINK_ANSWER_BEGIN })
export const sendProjectVariationLinkAnswerSuccess = () => ({ type: types.SEND_PROJECT_VARIATION_LINK_ANSWER_SUCCESS })

export const createFormTemplateBegin = () => ({ type: types.CREATE_FORM_TEMPLATE_BEGIN })
export const createFormTemplateSuccess = () => ({ type: types.CREATE_FORM_TEMPLATE_SUCCESS })

export const updateFormTemplateBegin = () => ({ type: types.UPDATE_FORM_TEMPLATE_BEGIN })
export const updateFormTemplateSuccess = () => ({ type: types.UPDATE_FORM_TEMPLATE_SUCCESS })

export const deleteFormTemplateBegin = () => ({ type: types.DELETE_FORM_TEMPLATE_BEGIN })
export const deleteFormTemplateSuccess = () => ({ type: types.DELETE_FORM_TEMPLATE_SUCCESS })

export const createInvoiceBegin = () => ({ type: types.CREATE_INVOICE_BEGIN })
export const createInvoiceSuccess = () => ({ type: types.CREATE_INVOICE_SUCCESS })

export const loadInvoicesBegin = () => ({ type: types.LOAD_INVOICES_BEGIN })
export const loadInvoicesSuccess = (payload) => ({ type: types.LOAD_INVOICES_SUCCESS, payload })

export const loadInvoiceBegin = () => ({ type: types.LOAD_INVOICE_BEGIN })
export const loadInvoiceSuccess = (payload) => ({ type: types.LOAD_INVOICE_SUCCESS, payload })

export const deleteInvoiceBegin = () => ({ type: types.DELETE_INVOICE_BEGIN })
export const deleteInvoiceSuccess = () => ({ type: types.DELETE_INVOICE_SUCCESS })

export const loadCompanyLogoBegin = () => ({ type: types.LOAD_COMPANY_LOGO_BEGIN })
export const loadCompanyLogoSuccess = (payload) => ({ type: types.LOAD_COMPANY_LOGO_SUCCESS, payload })

export const loadReportedTimeBegin = () => ({ type: types.LOAD_REPORTED_TIME_BEGIN })
export const loadReportedTimeSuccess = (payload) => ({ type: types.LOAD_REPORTED_TIME_SUCCESS, payload })

export const loadReportedProductBegin = () => ({ type: types.LOAD_REPORTED_PRODUCT_BEGIN })
export const loadReportedProductSuccess = (payload) => ({ type: types.LOAD_REPORTED_PRODUCT_SUCCESS, payload })

export const loadReportedNoteBegin = () => ({ type: types.LOAD_REPORTED_NOTE_BEGIN })
export const loadReportedNoteSuccess = (payload) => ({ type: types.LOAD_REPORTED_NOTE_SUCCESS, payload })

export const loadSupplierInvoiceProjectRelationBegin = () => ({ type: types.LOAD_SUPPLIER_INVOICE_PROJECT_RELATIONS_BEGIN })
export const loadSupplierInvoiceProjectRelationSuccess = (payload) => ({ type: types.LOAD_SUPPLIER_INVOICE_PROJECT_RELATIONS_SUCCESS, payload })

export const loadCustomerObjectBegin = () => ({ type: types.LOAD_CUSTOMER_OBJECT_BEGIN })
export const loadCustomerObjectSucces = (payload) => ({ type: types.LOAD_CUSTOMER_OBJECT_SUCCESS, payload })

export const updateCustomerObjectBegin = () => ({ type: types.UPDATE_CUSTOMER_OBJECT_BEGIN })
export const updateCustomerObjectSuccess = () => ({ type: types.UPDATE_CUSTOMER_OBJECT_SUCCESS })

export const loadAbsenceTypeBegin = () => ({ type: types.LOAD_ABSENCE_TYPES_BEGIN })
export const loadAbsenceTypeSuccess = (payload) => ({ type: types.LOAD_ABSENCE_TYPES_SUCCESS, payload })

export const createAbsenceTypeBegin = () => ({ type: types.CREATE_ABSENCE_TYPE_BEGIN })
export const createAbsenceTypeSuccess = () => ({ type: types.CREATE_ABSENCE_TYPE_SUCCESS })

export const updateAbsenceTypeBegin = () => ({ type: types.UPDATE_ABSENCE_TYPE_BEGIN })
export const updateAbsenceTypeSuccess = () => ({ type: types.UPDATE_ABSENCE_TYPE_SUCCESS })

export const deleteAbsenceTypeBegin = () => ({ type: types.DELETE_ABSENCE_TYPE_BEGIN })
export const deleteAbsenceTypeSuccess = () => ({ type: types.DELETE_ABSENCE_TYPE_SUCCESS })

export const loadCustomerCountsBegin = () => ({ type: types.LOAD_CUSTOMER_COUNTS_BEGIN })
export const loadCustomerCountsSuccess = (payload) => ({ type: types.LOAD_CUSTOMER_COUNTS_SUCCESS, payload })

export const updateCustomerBegin = () => ({ type: types.UPDATE_CUSTOMER_BEGIN })
export const updateCustomerSuccess = () => ({ type: types.UPDATE_CUSTOMER_SUCCESS })

export const deleteCustomerObjectBegin = () => ({ type: types.DELETE_CUSTOMER_OBJECT_BEGIN })
export const deleteCustomerObjectSuccess = () => ({ type: types.DELETE_CUSTOMER_OBJECT_SUCCESS })

export const loadDocumentsBegin = () => ({ type: types.LOAD_DOCUMENTS_BEGIN })
export const loadDocumentsSuccess = (payload) => ({ type: types.LOAD_DOCUMENTS_SUCCESS, payload })

export const loadAreCustomerProjectsDoneBegin = () => ({ type: types.LOAD_ARE_CUSTOMER_PROJECTS_DONE_BEGIN })
export const loadAreCustomerProjectsDoneSuccess = (payload) => ({ type: types.LOAD_ARE_CUSTOMER_PROJECTS_DONE_SUCCESS, payload })

export const postMarkCustomerAsInactiveBegin = () => ({ type: types.POST_MARK_CUSTOMER_AS_INACTIVE_BEGIN})
export const postMarkCustomerAsInactiveSuccess = () => ({ type: types.POST_MARK_CUSTOMER_AS_INACTIVE_SUCCESS})

export const postMarkCustomerAsActiveBegin = () => ({ type: types.POST_MARK_CUSTOMER_AS_ACTIVE_BEGIN})
export const postMarkCustomerAsActiveSuccess = () => ({ type: types.POST_MARK_CUSTOMER_AS_ACTIVE_SUCCESS})

export const createSalaryBegin = () => ({ type: types.CREATE_SALARY_BEGIN })
export const createSalarySuccess = () => ({ type: types.CREATE_SALARY_SUCCESS })

export const deleteSalaryBegin = () => ({ type: types.DELETE_SALARY_BEGIN })
export const deleteSalarySuccess = () => ({ type: types.DELETE_SALARY_SUCCESS })

export const craeteEmployeeBegin = () => ({ type: types.CRAETE_EMPLOYEE_BEGIN })
export const createEmployeeSuccess = () => ({ type: types.CRAETE_EMPLOYEE_SUCCESS })

export const loadDoesEmployeesEmailExistBegin = () => ({ type: types.LOAD_DOES_EMPLOYEES_EMAIL_EXIST_BEGIN })
export const loadDoesEmployeesEmailExistSuccess = () => ({ type: types.LOAD_DOES_EMPLOYEES_EMAIL_EXIST_SUCCESS })

export const sendUserInviteToCurrentTennantBegin = () => ({ type: types.SEND_USER_INVITE_TO_CURRENT_TENNANT_BEGIN })
export const sendUserInviteToCurrentTennantSuccess = () => ({ type: types.SEND_USER_INVITE_TO_CURRENT_TENNANT_SUCCESS })

export const loadAllTimeTypesBegin = () => ({ type: types. LOAD_ALL_TIME_TYPES_BEGIN })
export const loadAllTimeTypesSuccess = (payload) => ({ type: types. LOAD_ALL_TIME_TYPES_SUCCESS, payload })

export const updateUsersAllowedToSeePriceBegin = () => ({ type: types.UPDATE_USERS_ALLOWED_TO_SEE_PRICE_BEGIN })
export const updateUsersAllowedToSeePriceSuccess = () => ({ type: types.UPDATE_USERS_ALLOWED_TO_SEE_PRICE_SUCCESS })

export const loadPriceListsBegin = () => ({ type: types.LOAD_PRICE_LISTS_BEGIN })
export const loadPriceListsSuccess = (payload) => ({ type: types.LOAD_PRICE_LISTS_SUCCESS, payload })

export const createProjectElementBegin = () => ({ type: types.CREATE_PROJECT_ELEMENT_BEGIN })
export const createProjectElementSuccess = () => ({ type: types.CREATE_PROJECT_ELEMENT_SUCCESS })

export const updateProjectElementBegin = () => ({ type: types.UPDATE_PROJECT_ELEMENT_BEGIN })
export const updateProjectElementSuccess = () => ({ type: types.UPDATE_PROJECT_ELEMENT_SUCCESS })

export const removeProjectElementBegin = () => ({ type: types.REMOVE_PROJECT_ELEMENT_BEGIN })
export const removeProjectElementSuccess = () => ({ type: types.REMOVE_PROJECT_ELEMENT_SUCCESS })

export const updateSettingsBegin = () => ({ type: types.UPDATE_SETTINGS_BEGIN })
export const updateSettingsSuccess = () => ({ type: types.UPDATE_SETTINGS_SUCCESS })

export const uploadCompanyLogoBegin = () => ({ type: types.UPDATE_COMPANY_LOGO_BEGIN })
export const uploadCompanyLogoSuccess = () => ({ type: types.UPDATE_COMPANY_LOGO_SUCCESS })

export const updateCustomProjectSequenceBegin = () => ({ type: types.UPDATE_CUSTOM_PROJECT_SEQUENCE_BEGIN }) 
export const updateCustomProjectSequenceSuccess = () => ({ type: types.UPDATE_CUSTOM_PROJECT_SEQUENCE_SUCCESS }) 

export const createTimeTypeBegin = () => ({ type: types.CREATE_TIME_TYPE_BEGIN })
export const createTimeTypeSuccess = () => ({ type: types.CREATE_TIME_TYPE_SUCCESS })

export const loadTimeTypeBegin = () => ({ type: types.LOAD_TIME_TYPE_BEGIN })
export const loadTimeTypeSuccess = (payload) => ({ type: types.LOAD_TIME_TYPE_SUCCESS, payload })

export const deleteTimeTypeBegin = () => ({ type: types.DELETE_TIME_TYPE_BEGIN })
export const deleteTimeTypeSuccess = () => ({ type: types.DELETE_TIME_TYPE_SUCCESS })

export const createDocumentCategoryBegin = () => ({ type: types.CREATE_DOCUMENT_CATEGORY_BEGIN })
export const createDocumentCategorySuccess = () => ({ type: types.CREATE_DOCUMENT_CATEGORY_SUCCESS })

export const updateTimeTypeBegin = () => ({ type: types.UPDATE_TIME_TYPE_BEGIN })
export const updateTimeTypeSuccess = () => ({ type: types.UPDATE_TIME_TYPE_SUCCESS })

export const updateDocumentCategoryBegin = () => ({ type: types.UPDATE_DOCUMENT_CATEGORY_BEGIN })
export const updateDocumentCategorySuccess = () => ({ type: types.UPDATE_DOCUMENT_CATEGORY_SUCCESS })

export const deleteDocumentCategoryBegin = () => ({ type: types.DELETE_DOCUMENT_CATEGORY_BEGIN })
export const deleteDocumentCategorySuccess = () => ({ type: types.DELETE_DOCUMENT_CATEGORY_SUCCESS })

export const updateUsersAllowedToEditReportsBegin = () => ({ type: types.UPDATE_USERS_ALLOWED_TO_EDIT_REPORTS_BEGIN })
export const updateUsersAllowedToEditReportsSuccess = () => ({ type: types.UPDATE_USERS_ALLOWED_TO_EDIT_REPORTS_SUCCESS })

export const loadOfferLibraryTextBlocksBegin = () => ({ type: types.LOAD_OFFER_LIBRARY_TEXT_BLOCKS_BEGIN })
export const loadOfferLibraryTextBlocksSuccess = (payload) => ({ type: types.LOAD_OFFER_LIBRARY_TEXT_BLOCKS_SUCCESS, payload })

export const createOfferLibraryTextBlocksBegin = () => ({ type: types.CREATE_OFFER_LIBRARY_TEXT_BLOCK_BEGIN })
export const createOfferLibraryTextBlocksSuccess = () => ({ type: types.CREATE_OFFER_LIBRARY_TEXT_BLOCK_SUCCESS})

export const updateOfferLibraryTextBlocksBegin = () => ({ type: types.UPDATE_OFFER_LIBRARY_TEXT_BLOCK_BEGIN })
export const updateOfferLibraryTextBlocksSuccess = () => ({ type: types.UPDATE_OFFER_LIBRARY_TEXT_BLOCK_SUCCESS})

export const deleteOfferLibraryTextBlocksBegin = () => ({ type: types.DELETE_OFFER_LIBRARY_TEXT_BLOCK_BEGIN })
export const deleteOfferLibraryTextBlocksSuccess = () => ({ type: types.DELETE_OFFER_LIBRARY_TEXT_BLOCK_SUCCESS})

export const updateShowTaskFirstBegin = () => ({ type: types.UPDATE_SHOW_TASK_FIRST_BEGIN })
export const updateShowTaskFirstSuccess = () => ({ type: types.UPDATE_SHOW_TASK_FIRST_SUCCESS })

export const createPriceAdjustmentBegin = () => ({ type: types.CREATE_PRICE_ADJUSTMENT_BEGIN })
export const createPriceAdjustmentSuccess = () => ({ type: types.CREATE_PRICE_ADJUSTMENT_SUCCESS })

export const deletePriceAdjustmentBegin = () => ({ type: types.DELETE_PRICE_ADJUSTMENT_BEGIN })
export const deletePriceAdjustmentDelete = () => ({ type: types.DELETE_PRICE_ADJUSTMENT_SUCCESS })

export const loadPriceListsItemDiscountsBegin = () => ({ type: types.LOAD_PRICE_LISTS_ITEM_DISCOUNTS_BEGIN })
export const loadPriceListsItemDiscountsSuccess = (payload) => ({ type: types.LOAD_PRICE_LISTS_ITEM_DISCOUNTS_SUCCESS, payload })

export const createPriceListDiscountsBegin = () => ({ type: types.CREATE_PRICE_LIST_DISCOUNTS_BEGIN })
export const createPriceListDiscountsSuccess = () => ({ type: types.CREATE_PRICE_LIST_DISCOUNTS_SUCCESS })

export const deletePriceListDiscountsBegin = () => ({ type: types.DELETE_PRICE_LIST_DISCOUNTS_BEGIN })
export const deletePriceListDiscountsSuccess = () => ({ type: types.DELETE_PRICE_LIST_DISCOUNTS_SUCCESS })

export const updatePriceAdjustmentBegin = () => ({ type: types.UPDATE_PRICE_ADJUSTMENT_BEGIN })
export const updatePriceAdjustmentSuccess = () => ({ type: types.UPDATE_PRICE_ADJUSTMENT_SUCCESS })

export const loadOngoingProjectsBegin = () => ({ type: types.LOAD_ONGOING_PROJECTS_BEGIN })
export const loadOngoingProjectsSuccess = (payload) => ({ type: types.LOAD_ONGOING_PROJECTS_SUCCESS, payload })

export const loadOpenProjectsWithCustomerNameBegin = () => ({ type: types.LOAD_OPON_PROJECTS_WITH_CUSTOMER_NAME_BEGIN })
export const loadOpenProjectsWithCustomerNameSuccess = (payload) => ({ type: types.LOAD_OPON_PROJECTS_WITH_CUSTOMER_NAME_BEGIN, payload })

export const loadProjectStatsBegin = () => ({ type: types.LOAD_PROJECT_STATS_BEGIN })
export const loadProjectStatsSuccess = (payload) => ({ type: types.LOAD_PROJECT_STATS_SUCCESS, payload })

export const loadUnhandledReportsBegin = () => ({ type: types.LOAD_UNHANDLED_REPORTS_BEGIN })
export const loadUnhandledReportsSuccess = (payload) => ({ type: types.LOAD_UNHANDLED_REPORTS_SUCCESS, payload })

export const loadUnhandledReportedTimeBetweenDatesBegin = () => ({ type: types.LOAD_UNHANDLED_REPORTED_TIME_BETWEEN_DATES_BEGIN })
export const loadUnhandledReportedTimeBetweenDatesSuccess = (payload) => ({ type: types.LOAD_UNHANDLED_REPORTED_TIME_BETWEEN_DATES_SUCCESS, payload })

export const loadUnhandledInvoiceAmountSumBegin = () => ({ type: types.LOAD_UNHANDLED_INVOICE_AMOUNT_SUM_BEGIN })
export const loadUnhandledInvoiceAmountSumSuccess = (payload) => ({ type: types.LOAD_UNHANDLED_INVOICE_AMOUNT_SUM_SUCCESS, payload })

export const loadReportedHoursBetweenDatesBegin = () => ({ type: types.LOAD_REPORTED_HOURS_BETWEEN_DATES_BEGIN })
export const loadReportedHoursBetweenDatesSuccess = (payload) => ({ type: types.LOAD_REPORTED_HOURS_BETWEEN_DATES_SUCCESS, payload })

export const loadUnhandledSalaryDetailsCountBegin = () => ({ type: types.LOAD_UNHANDLED_SALARY_DETALIS_COUNT_BEGIN })
export const loadUnhandledSalaryDetailsCountSuccess = (payload) => ({ type: types.LOAD_UNHANDLED_SALARY_DETALIS_COUNT_SUCCESS, payload })

export const loadProjectsAdressInformationBegin = () => ({ type: types.LOAD_PROJECTS_ADRESS_INFORMATION_BEGIN })
export const loadProjectsAdressInformationSuccess = (payload) => ({ type: types.LOAD_PROJECTS_ADRESS_INFORMATION_SUCCESS, payload })

export const loadProjectAssignmentsBegin = () => ({ type: types.LOAD_PROJECT_ASSIGNMENTS_BEGIN })
export const loadProjectAssignmentsSuccess = (payload) => ({ type: types.LOAD_PROJECT_ASSIGNMENTS_SUCCESS, payload })

export const loadProjectAssignments = (projectId) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(loadProjectAssignmentsBegin())

    return getProjectAssignments(
        backend,
        token,
        projectId
        )
    .then(res => {

        dispatch(loadProjectAssignmentsSuccess(res))
        dispatch(requestCompleted())

        return res
    })
}}

export const loadProjectsAdressInformation = () => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(loadProjectsAdressInformationBegin())

    return getProjectsAdressInformation(
        token,
        backend,
        )
    .then(res => {

        dispatch(loadProjectsAdressInformationSuccess(res))
        dispatch(requestCompleted())

        return res
    })
}}

export const createArticleWithObject = ({
    articleId,
    name,
    description,
    productCategory,
    unit,
    unit_id,
    price,
    cost,
    onSalary,
    salaryCode,
    salary,
    productTypeId,
    product_kind,
    check_box,
    erp_product_id,
    product_type_id,
}: CreateArticleWithObject) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend, user },
    } = getState();

    dispatch(requestStarted())
    dispatch(createArticleBegin())

    return postArticleWithObject(
        token,
        backend,
        articleId,
        name,
        description,
        productCategory,
        unit,
        unit_id,
        price,
        cost,
        onSalary,
        salaryCode,
        salary,
        productTypeId,
        product_kind,
        check_box,
        erp_product_id,
        product_type_id,
        user.EmployeeID,
        user.EmployeeID,
        )
    .then(res => {

        dispatch(createArticleSuccess())
        dispatch(requestCompleted())

        return res
    })
}}

export const loadProjectAccessEmployeeRelations = (projectId) => {

    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted());
        dispatch(loadProjectAccessEmployeeRelationsBegin())

        return getProjectAccessEmployeeRelations(
            backend,
            token,
            projectId
        ).then(res => {

            dispatch(loadProjectAccessEmployeeRelationsSuccess(res.projectAccessEmployeeRelations))
            dispatch(requestCompleted())

            return res
        })
    }
}

export const loadGadgetQuotationSendingUrl = (dbName, offerId) => {
    return (dispatch) => {

        dispatch(fetchGadgetQuotationSendingUrlBegin())

        return getGadgetQuotationSendingUrl(dbName, offerId)
            .then(res => {
                dispatch(fetchGadgetQuotationSendingUrlComplete(res));

                return res;
            })
    }
}

export const loadGadgetVariationSendingUrl = (dbName, variationId) => {
    return (dispatch) => {

        dispatch(fetchGadgetVariationSendingUrlBegin())

        return getGadgetVariationSendingUrl(dbName, variationId)
            .then(res => {
                dispatch(fetchGadgetVariationSendingUrlComplete(res));

                return res;
            })
    }
}

export const loadTenantMigrationStatus = () => {
    return (dispatch, getState) => {
        const {
            app: { token, backend }
        } = getState()
        dispatch(fetchTenantMigrationStatusBegin())

        return getTenantMigrationStatus(token, backend)
            .then(res => {
                dispatch(fetchTenantMigrationStatusComplete(res.tenant_migration_status));

                return res;
            })
    }
}

export const loadTenantMigrationStatusByDBName = (dbName) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend }
        } = getState()
        dispatch(fetchTenantMigrationStatusBegin())

        return getDBMigrationStatus(token, backend, dbName)
            .then(res => {
                dispatch(fetchTenantMigrationStatusComplete(res.tenant_migration_status));

                return res;
            })
    }
}

export const loadTenantMigrationRedirectUrl = () => {
    return (dispatch, getState) => {
        const {
            app: { token, backend }
        } = getState()
        dispatch(fetchTenantMigrationRedirectUrlBegin())

        return getTenantMigrationRedirectUrl(token, backend)
            .then(res => {
                dispatch(fetchTenantMigrationRedirectUrlComplete(res));

                return res;
            })
    }
}

export const loadUnhandledSalaryDetailsCount = () => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(loadUnhandledSalaryDetailsCountBegin())

    return getUnhandledSalaryDetailsCount(
        token,
        backend,
        )
    .then(res => {

        dispatch(loadUnhandledSalaryDetailsCountSuccess(res))
        dispatch(requestCompleted())

        return res
    })
}}

export const updateProject2 = (project) => {

    return (dispatch, getState) => {
        const {
            app: { token, backend, user },
        } = getState();

        dispatch(requestStarted())
        dispatch(updateProjectBegin())
        return putProject(
                token,
                backend,
                project.id,
                project.project_name,
                project.description,
                project.customer_id,
                project.start_date,
                project.end_date,
                project.internal_information,
                project.project_type,
                project.estimated_hours,
                project.fixed_price,
                project.project_leader,
                project.need_customer_signature,
                project.customer_object_id,
                project.has_external_project_leader,
                project.external_project_leader_name,
                project.property_code,
                user.EmployeeID,
                project.erp_customer_id,
                project.erp_project_id,
            ).then((res)=>{
                dispatch(updateProjectSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const importSingleSupplierInvoiceFromFortnox = (id, financialYear) => {

    return (dispatch, getState) => {
        const {
            app: { token, backend, user },
        } = getState();

        dispatch(requestStarted());
        dispatch(postImportSingleSupplierInvoiceFromFortnoxBegin())
        return postImportSingleSupplierInvoiceFromFortnox(
                token,
                backend,
                user.EmployeeID,
                id,
                financialYear,
            ).then((res)=>{
                dispatch(postImportSingleSupplierInvoiceFromFortnoxSuccess())
                dispatch(requestCompleted())
                return res
            })
    }
}

export const updateProject = (id, project) => {

    return (dispatch, getState) => {
        const {
            app: { token, backend, user },
        } = getState();

        dispatch(requestStarted())
        dispatch(updateProjectBegin())
        return putProject(
                token,
                backend,
                id,
                project.project_name,
                project.description,
                project.customer.id,
                project.start_date,
                project.end_date,
                project.internal_information,
                project.project_type,
                project.estimated_hours,
                project.fixed_price,
                project.project_leader,
                project.need_customer_signature,
                project.customer_object_id,
                project.has_external_project_leader,
                project.external_project_leader_name,
                project.property_code,
                user.EmployeeID,
                project.erp_customer_id,
                project.erp_project_id,
                project.erp_order_number,
                project.fortnox_document_number,
                project.ShowProjectInMap,
                project.PaymentPlanMaxTotalSafetyPercentage,
                project.PaymentPlanSafetyPercentage,
                project.UsePaymentPlan,
                project.ShowPaymentPlanDueDate,
                project.ShowPaymentPlanInvoiceDate,
                project.sendConfirmationEmailTo,
                project.onlyShowProjectForSelectedEmployees,
            ).then((res)=>{
                dispatch(updateProjectSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadReportedHoursBetweenDates = (fromDate, toDate) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(loadReportedHoursBetweenDatesBegin())

    return getReportedHoursBetweenDates(
        token,
        backend,
        fromDate,
        toDate,
        )
    .then(res => {

        dispatch(loadReportedHoursBetweenDatesSuccess(res))
        dispatch(requestCompleted())

        return res
    })
}}
interface InvoiceLines {
    filter?,
    projectId?,
}

export const loadInvoiceLines = ({ filter, projectId } : InvoiceLines) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(loadInvoiceLinesBegin())

    return getInvoiceLines(
        token,
        backend,
        filter,
        projectId,
        )
    .then(res => {

        dispatch(loadInvoiceLinesSuccess(res.invoiceLines))
        dispatch(requestCompleted())

        return res
    })
}}


export const loadUnhandledInvoiceAmountSum = () => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(loadUnhandledInvoiceAmountSumBegin())

    return getUnhandledInvoiceAmountSum(
        token,
        backend,
        )
    .then(res => {

        dispatch(loadUnhandledInvoiceAmountSumSuccess(res))
        dispatch(requestCompleted())

        return res
    })
}}

export const loadUnhandledReportedTimeBetweenDates = (fromDate, toDate) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(loadUnhandledReportedTimeBetweenDatesBegin())

    return getUnhandledReportedTimeBetweenDates(
        token,
        backend,
        fromDate,
        toDate,
        )
    .then(res => {

        dispatch(loadUnhandledReportedTimeBetweenDatesSuccess(res))
        dispatch(requestCompleted())

        return res
    })
}}

export const loadUnhandledReports = () => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(loadUnhandledReportsBegin())

    return getUnhandledReports(
        token,
        backend,
        )
    .then(res => {
 
        dispatch(loadUnhandledReportsSuccess(res))
        dispatch(requestCompleted())

        return res
    })
}}

export const loadProjectStats = () => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(loadProjectStatsBegin())

    return getProjectStats(
        token,
        backend,
        )
    .then(res => {

        dispatch(loadProjectStatsSuccess(res))
        dispatch(requestCompleted())

        return res
    })
}}


export const loadOpenProjectsWithCustomerName = ( startTime, endTime ) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(loadOpenProjectsWithCustomerNameBegin())

    return getOpenProjectsWithCustomerName(
        token,
        backend,
        startTime,
        endTime,
        )
    .then(res => {

        dispatch(loadOpenProjectsWithCustomerNameSuccess(res))
        dispatch(requestCompleted())

        return res
    })
}}

export const loadOngoingProjects = (  filter, search = "", page = 0 ) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(loadOngoingProjectsBegin())

    return getProjects(
        backend,
        token,
        filter,
        search,
        page
        )
    .then(res => {

        dispatch(loadOngoingProjectsSuccess(res))
        dispatch(requestCompleted())

        return res
    })
}}
export const updatePriceListDiscountsBegin = () => ({ type: types.UPDATE_PRICE_LIST_DISCOUNTS_BEGIN })
export const updatePriceListDiscountsSuccess = () => ({ type: types.UPDATE_PRICE_LIST_DISCOUNTS_SUCCESS })

export const sendUserInviteToCurrentTennantAgainBegin = () => ({ type: types.SEND_USER_INVITE_TO_CURRENT_TENNANT_AGAIN_BEGIN })
export const sendUserInviteToCurrentTennantAgainSuccess = () => ({ type: types.SEND_USER_INVITE_TO_CURRENT_TENNANT_AGAIN_SUCCESS })

export const postJsonFileSaveBegin = () => ({ type: types.POST_JSON_FILE_SAVE_BEGIN })
export const postJsonFileSaveSuccess = (payload) => ({ type: types.POST_JSON_FILE_SAVE_SUCCESS, payload })

export const postJsonFileReadBegin = () => ({ type: types.POST_JSON_FILE_READ_BEGIN })
export const postJsonFileReadSuccess = (payload) => ({ type: types.POST_JSON_FILE_READ_SUCCESS, payload })

export const loadCustomersWithDatesBegin = () => ({ type: types.LOAD_CUSTOMERS_WITH_DATES_BEGIN })
export const loadCustomersWithDatesSuccess = (payload) => ({ type: types.LOAD_CUSTOMERS_WITH_DATES_SUCCESS, payload })

export const loadProductsWithDatesBegin = () => ({ type: types.LOAD_PRODUCTS_WITH_DATES_BEGIN })
export const loadProductsWithDatesSuccess = (payload) => ({ type: types.LOAD_PRODUCTS_WITH_DATES_SUCCESS, payload })

export const loadAllActiveTasksWithDatesBegin = () => ({ type: types.LOAD_ALL_ACTIVE_TASKS_WITH_DATES_BEGIN })
export const loadAllActiveTasksWithDatesSuccess = (payload) => ({ type: types.LOAD_ALL_ACTIVE_TASKS_WITH_DATES_SUCCESS, payload })

export const loadInvoicesWithDatesBegin = () => ({ type: types.LOAD_INVOICES_WITH_DATES_BEGIN })
export const loadInvoicesWithDatesSuccess = (payload) => ({ type: types.LOAD_INVOICES_WITH_DATES_SUCCESS, payload })

export const loadAllIntegrationItemGroupsBegin = () => ({ type: types.LOAD_ALL_INTEGRATION_ITEM_GROUPS_BEGIN })
export const loadAllIntegrationItemGroupsSuccess = (payload) => ({ type: types.LOAD_ALL_INTEGRATION_ITEM_GROUPS_SUCCESS, payload })

export const loadProjectsByErpProjectIdBegin = () => ({ type: types.LOAD_PROJECTS_BY_ERP_PROJECT_ID_BEGIN })
export const loadProjectsByErpProjectIdSuccess = (payload) => ({ type: types.LOAD_PROJECTS_BY_ERP_PROJECT_ID_SUCCESS, payload })

export const loadCustomersByErpCustomerIdBegin = () => ({ type: types.LOAD_CUSTOMERS_BY_ERP_CUSTOMER_ID_BEGIN })
export const loadCustomersByErpCustomerIdSuccess = (payload) => ({ type: types.LOAD_CUSTOMERS_BY_ERP_CUSTOMER_ID_SUCCESS, payload })

export const updateProjectsBegin = () => ({ type: types.UPDATE_PROJECTS_BEGIN })
export const updateProjectsSuccess = () => ({ type: types.UPDATE_PROJECTS_SUCCESS })

export const loadCustomerByNameBegin = () => ({ type: types.LOAD_CUSTOMER_BY_NAME_BEGIN })
export const loadCustomerByNameSuccess = (payload) => ({ type: types.LOAD_CUSTOMER_BY_NAME_SUCCESS, payload })

export const loadProjectByProjectNameBegin = () => ({ type: types.LOAD_PROJECT_BY_PROJECT_NAME_BEGIN })
export const loadProjectByProjectNameSuccess = (payload) => ({ type: types.LOAD_PROJECT_BY_PROJECT_NAME_SUCCESS, payload })

export const loadProjectsByErpOrderNumberBegin = () => ({ type: types.LOAD_PROJECTS_BY_ERP_ORDER_NUMBER_BEGIN })
export const loadProjectsByErpOrderNumberSuccess = (payload) => ({ type: types.LOAD_PROJECTS_BY_ERP_ORDER_NUMBER_SUCCESS, payload })

export const loadTasksByErpProductIdBegin = () => ({ type: types.LOAD_TASKS_BY_ERP_PRODUCT_ID_BEGIN })
export const loadTasksByErpProductIdSuccess = (payload) => ({ type: types.LOAD_TASKS_BY_ERP_PRODUCT_ID_SUCCESS, payload })

export const loadTimeTypesByErpProductIdBegin = () => ({ type: types.LOAD_TIME_TYPES_BY_ERP_PRODUCT_ID_BEGIN })
export const loadTimeTypesByErpProductIdSuccess = (payload) => ({ type: types.LOAD_TIME_TYPES_BY_ERP_PRODUCT_ID_SUCCESS, payload })

export const loadProductsByErpProductIdBegin = () => ({ type: types.LOAD_PRODUCTS_BY_ERP_PRODUCT_ID_BEGIN })
export const loadProductsByErpProductIdSuccess = (payload) => ({ type: types.LOAD_PRODUCTS_BY_ERP_PRODUCT_ID_SUCCESS, payload })

export const createIntegrationItemGroupsBegin = () => ({ type: types.CREATE_INTEGRATION_ITEM_GROUPS_BEGIN })
export const createIntegrationItemGroupsSuccess = () => ({ type: types.CREATE_INTEGRATION_ITEM_GROUPS_SUCCESS })

export const loadTimeTypesByProductNameBegin = () => ({ type: types.LOAD_TIME_TYPES_BY_PRODUCT_NAME_BEGIN })
export const loadTimeTypesByProductNameSuccess = (payload) => ({ type: types.LOAD_TIME_TYPES_BY_PRODUCT_NAME_SUCCESS, payload })

export const loadTasksByTitleBegin = () => ({ type: types.LOAD_TASKS_BY_TITLE_BEGIN })
export const loadTasksByTitleSuccess = (payload) => ({ type: types.LOAD_TASKS_BY_TITLE_SUCCESS, payload })

export const loadProductsByNameBegin = () => ({ type: types.LOAD_PRODUCTS_BY_NAME_BEGIN })
export const loadProductsByNameSuccess = (payload) => ({ type: types.LOAD_PRODUCTS_BY_NAME_SUCCESS, payload })

export const loadProductTypeByNameBegin = () => ({ type: types.LOAD_PRODUCT_TYPE_BY_NAME_BEGIN })
export const loadProductTypeByNameSuccess = (payload) => ({ type: types.LOAD_PRODUCT_TYPE_BY_NAME_SUCCESS, payload })

export const deleteIntegrationItemGroupBegin = () => ({ type: types.DELETE_INTEGRATION_ITEM_GROUP_BEGIN })
export const deleteIntegrationItemGroupSuccess = () => ({ type: types.DELETE_INTEGRATION_ITEM_GROUP_SUCCESS })

export const loadIntegrationItemGroupBegin = () => ({ type: types.LOAD_INTEGRATION_ITEM_GROUP_BEGIN })
export const loadIntegrationItemGroupSuccess = (payload) => ({ type: types.LOAD_INTEGRATION_ITEM_GROUP_SUCCESS, payload })

export const updateIntegrationItemGroupBegin = () => ({ type: types.UPDATE_INTEGRATION_ITEM_GROUP_BEGIN })
export const updateIntegrationItemGroupSuccess = () => ({ type: types.UPDATE_INTEGRATION_ITEM_GROUP_SUCCESS })

export const loadUnitByNameBegin = () => ({ type: types.LOAD_UNIT_BY_NAME_BEGIN })
export const loadUnitByNameSuccess = (payload) => ({ type: types.LOAD_UNIT_BY_NAME_SUCCESS, payload })


export const loadUnitByName = (unitName) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadUnitByNameBegin())

        return getUnitByName(
            backend,
            token, 
            unitName
            )
            .then((res) => {

                dispatch(loadUnitByNameSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const updateIntegrationItemGroup = (id, newIntegrationItemGroup) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(updateIntegrationItemGroupBegin())

        return putIntegrationItemGroup(
            token, 
            backend,
            id,
            newIntegrationItemGroup,
            )
            .then((res) => {

                dispatch(updateIntegrationItemGroupSuccess())
                dispatch(requestCompleted())

                return res
            })
        }
    }

export const loadIntegrationItemGroup = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadIntegrationItemGroupBegin())

        return getIntegrationItemGroups(
            token, 
            backend,
            id
            )
            .then((res) => {

                dispatch(loadIntegrationItemGroupSuccess(res))
                dispatch(requestCompleted())

                return res
            })
        }
    }

export const removeIntegrationItemGroup = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(deleteIntegrationItemGroupBegin())

        return deleteIntegrationItemGroup(
            token, 
            backend,
            id
            )
            .then((res) => {

                dispatch(deleteIntegrationItemGroupSuccess())
                dispatch(requestCompleted())

                return res
            })
        }
    }
      

export const loadProductTypeByName = (productTypeName) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadProductTypeByNameBegin())

        return getProductTypeByName(
            token, 
            backend,
            productTypeName
            )
            .then((res) => {

                dispatch(loadProductTypeByNameSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadProductsByName = (productName) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadProductsByNameBegin())

        return getProductsByName(
            token, 
            backend,
            productName
            )
            .then((res) => {

                dispatch(loadProductsByNameSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadTasksByTitle = (title) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadTasksByTitleBegin())

        return getTasksByTitle(
            token, 
            backend,
            title
            )
            .then((res) => {

                dispatch(loadTasksByTitleSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadTimeTypesByProductName = (productName) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadTimeTypesByProductNameBegin())

        return getTimeTypesByProductName(
            backend,
            token, 
            productName
            )
            .then((res) => {

                dispatch(loadTimeTypesByProductNameSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const createIntegrationItemGroups = (newIntegrationItemGroup) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(createIntegrationItemGroupsBegin())

        return postIntegrationItemGroups(
            token, 
            backend,
            newIntegrationItemGroup
            )
            .then((res) => {

                dispatch(createIntegrationItemGroupsSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadProductsByErpProductId = (erpproductid) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadProductsByErpProductIdBegin())

        return getProductsByErpProductId(
            token, 
            backend,
            erpproductid
            )
            .then((res) => {

                dispatch(loadProductsByErpProductIdSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadTimeTypesByErpProductId = (erpproductid) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadTimeTypesByErpProductIdBegin())

        return getTimeTypesByErpProductId(
            token, 
            backend,
            erpproductid
            )
            .then((res) => {

                dispatch(loadTimeTypesByErpProductIdSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadTasksByErpProductId = (erpproductid) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadTasksByErpProductIdBegin())

        return getTasksByErpProductId(
            token, 
            backend,
            erpproductid
            )
            .then((res) => {

                dispatch(loadTasksByErpProductIdSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadProjectsByErpOrderNumber = (erpOrderNumber) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadProjectsByErpOrderNumberBegin())

        return getProjectsByErpOrderNumber(
            token, 
            backend,
            erpOrderNumber
            )
            .then((res) => {

                dispatch(loadProjectsByErpOrderNumberSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}


export const loadProjectByProjectName = (projectName) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadProjectByProjectNameBegin())

        return getProjectByProjectName(
            token, 
            backend,
            projectName
            )
            .then((res) => {

                dispatch(loadProjectByProjectNameSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadCustomerByName = (customerName) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadCustomerByNameBegin())

        return getCustomersByName(
            token, 
            backend,
            customerName
            )
            .then((res) => {

                dispatch(loadCustomerByNameSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const updateProjects = (projectId, project) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(updateProjectsBegin())

        return postProjects(
            token, 
            backend,
            projectId,
            project,
            )
            .then((res) => {

                dispatch(updateProjectsSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadCustomersByErpCustomerId = (erpprojectid) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadCustomersByErpCustomerIdBegin())

        return getCustomersByErpCustomerId(
            token, 
            backend,
            erpprojectid,
            )
            .then((res) => {

                dispatch(loadCustomersByErpCustomerIdSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadProjectsByErpProjectId = (erpprojectid) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadProjectsByErpProjectIdBegin())

        return getProjectsByErpProjectId(
            token, 
            backend,
            erpprojectid,
            )
            .then((res) => {

                dispatch(loadProjectsByErpProjectIdSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadAllIntegrationItemGroups = () => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadAllIntegrationItemGroupsBegin())

        return getAllIntegrationItemGroups(
            token, 
            backend,
            )
            .then((res) => {

                dispatch(loadAllIntegrationItemGroupsSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadInvoicesWithDates = (startDate, endDate) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadInvoicesWithDatesBegin())

        return getInvoicesWithDates(
            token, 
            backend,
            startDate,
            endDate
            )
            .then((res) => {

                dispatch(loadInvoicesWithDatesSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadProductsWithDates = (startDate, endDate) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadProductsWithDatesBegin())

        return getProductsWithDates(
            token, 
            backend,
            startDate,
            endDate
            )
            .then((res) => {

                dispatch(loadProductsWithDatesSuccess(res.products))
                dispatch(requestCompleted())

                return res.products
            })
    }
}

export const loadAllActiveTasksWithDates = (startDate, endDate) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadAllActiveTasksWithDatesBegin())

        return getAllActiveTasksWithDates(
            token, 
            backend,
            startDate,
            endDate
            )
            .then((res) => {

                dispatch(loadAllActiveTasksWithDatesSuccess(res.data))
                dispatch(requestCompleted())

                return res.data
            })
    }
}

export const loadCustomersWithDates = (startDate, endDate) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(loadCustomersWithDatesBegin())

        return getCustomersWithDates(
            token, 
            backend,
            startDate,
            endDate
            )
            .then((res) => {

                dispatch(loadCustomersWithDatesSuccess(res.data))
                dispatch(requestCompleted())

                return res.data
            })
    }
}

export const loadJsonFile = (loadFile) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(postJsonFileReadBegin())

        return postJsonFileRead(
            token, 
            backend,
            loadFile
            )
            .then((res)=>{

                dispatch(postJsonFileReadSuccess(res[0]))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const updateJsonFile = (json_data) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(postJsonFileSaveBegin())

        return postJsonFileSave(
            token, 
            backend, 
            json_data,
            )
            .then((res)=>{

                dispatch(postJsonFileSaveSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const sendUserInviteToCurrentTennantAgain = (UserActivationID, ToEmail, ToPhoneNumber) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()
        
        dispatch(requestStarted())
        dispatch(sendUserInviteToCurrentTennantAgainBegin())

        return postSendUserInviteToCurrentTennantAgain(
            token, 
            backend, 
            UserActivationID,
            ToEmail,
            ToPhoneNumber,
            )
            .then((res)=>{

                dispatch(sendUserInviteToCurrentTennantAgainSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const updatePriceListDiscounts = (PriceListID, Discount, ArticleID, Type, id, FixedPrice = "discount") => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()
        
        dispatch(requestStarted())
        dispatch(updatePriceListDiscountsBegin())

        return putPriceListDiscount(
            token, 
            backend, 
            user.EmployeeID, 
            user.EmployeeID,
            PriceListID,
            Discount,
            ArticleID,
            Type,
            id,
            FixedPrice,
            )
            .then((res)=>{

                dispatch(updatePriceListDiscountsSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const updatePriceAdjustment = (id, name, discount) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend, user },
    } = getState();

    dispatch(requestStarted())
    dispatch(updatePriceAdjustmentBegin())

    return putPriceAdjustment(
        token,
        backend,
        user.EmployeeID,
        id,
        name,
        discount,
        )
    .then(res => {

        dispatch(updatePriceAdjustmentSuccess())
        dispatch(requestCompleted())

        return res
    })
}}

export const removePriceListDiscounts = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend }
        } = getState()
        
        dispatch(requestStarted())
        dispatch(deletePriceListDiscountsBegin())

        return deletePriceListDiscounts(
            token, 
            backend,
            id,
            )
            .then((res)=>{

                dispatch(deletePriceListDiscountsSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const createProjectAccessEmployeeRelation = (projectId, employeeId) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend, user }
        } = getState()

        dispatch(requestStarted)
        dispatch(createProjectAccessEmployeeRealtionBegin())

        return postProjectAccessEmployeeRelation(
            backend,
            token,
            projectId,
            employeeId,
            user.EmployeeID,
        ).then(res => {

            dispatch(createProjectAccessEmployeeRelationSuccess())
            dispatch(requestCompleted())

            return res
        })
    }
}

export const createPriceListDiscounts = (PriceListID, Discount, ArticleID, Type, FixedPrice) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()
        
        dispatch(requestStarted())
        dispatch(createPriceListDiscountsBegin())

        return postPriceListDiscounts(
            token, 
            backend, 
            user.EmployeeID, 
            user.EmployeeID,
            PriceListID,
            Discount,
            ArticleID,
            Type,
            FixedPrice,
            )
            .then((res)=>{

                dispatch(createPriceListDiscountsSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadPriceListsItemDiscounts = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()
     
        dispatch(requestStarted())
        dispatch(loadPriceListsItemDiscountsBegin())

        return getPriceLists(token, backend, id)
            .then((res)=>{

                dispatch(loadPriceListsItemDiscountsSuccess(res.discounts))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const removePriceAdjustment = (id) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(deletePriceAdjustmentBegin())

    return deletePriceAdjustment(
        token,
        backend,
        id,
        )
    .then(res => {

        dispatch(deletePriceAdjustmentDelete())
        dispatch(requestCompleted())

        return res
    })
}}

export const removeProjectAccessEmployeeRelation = (id) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(deleteProjectAccessEmployeeRelationBegin())

    return deleteProjectAccessEmployeeRelation(
        backend,
        token,
        id,
        )
    .then(res => {

        dispatch(deleteProjectAccessEmployeeRelationSuccess())
        dispatch(requestCompleted())

        return res
    })
}}

export const createPriceAdjustment = (name, discount) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend, user },
    } = getState();

    dispatch(requestStarted())
    dispatch(createPriceAdjustmentBegin())

    return postPriceAdjustment(
        token,
        backend,
        user.EmployeeID,
        user.EmployeeID,
        name,
        discount,
        )
    .then(res => {

        dispatch(createPriceAdjustmentSuccess())
        dispatch(requestCompleted())

        return res
    })
}}

export const updateShowTaskFirst = (taskId = "none") => {

    return (dispatch, getState) => {

    const {
        app: { token, backend, user },
    } = getState();

    dispatch(requestStarted())
    dispatch(updateShowTaskFirstBegin())

    return putShowTaskFirst(
        token,
        backend,
        user.EmployeeID,
        taskId,
        )
    .then(res => {

        dispatch(updateShowTaskFirstSuccess())
        dispatch(requestCompleted())

        return res
    })
}}

export const updateUsersAllowedToEditReports = (restriction) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend, user },
    } = getState();

    dispatch(requestStarted())
    dispatch(updateUsersAllowedToEditReportsBegin())

    return putUsersAllowedToEditReports(
        token,
        backend,
        user.EmployeeID,
        restriction,
        )
    .then(res => {

        dispatch(updateUsersAllowedToEditReportsSuccess())
        dispatch(requestCompleted())

        return res
    })
}}

export const removeDocumentCategory = (id) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(deleteDocumentCategoryBegin())

    return deleteDocumentCategory(
        token,
        backend,
        id,
        )
    .then(res => {

        dispatch(deleteDocumentCategorySuccess())
        dispatch(requestCompleted())

        return res
    })
}}


export const updateDocumentCategory = (id, name) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(updateDocumentCategoryBegin())

    return putDocumentCategory(
        token,
        backend,
        id,
        name,
        )
    .then(res => {

        dispatch(updateDocumentCategorySuccess())
        dispatch(requestCompleted())

        return res
    })
}}

export const updateTimeType = (id, name, description, short_name, salary_code, tab_sequence, sales_price, cost_price, active, erp_product_id) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend, user },
    } = getState();

    dispatch(requestStarted())
    dispatch(updateTimeTypeBegin())

    return putTimeType(
        token,
        backend,
        user.EmployeeID,
        id,
        name,
        description,
        short_name,
        salary_code,
        tab_sequence,
        sales_price,
        cost_price,
        active,
        erp_product_id,
        )
    .then(res => {

        dispatch(updateTimeTypeSuccess())
        dispatch(requestCompleted())

        return res
    })
}}

export const createDocumentCategory = (name) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(createDocumentCategoryBegin())

    return postDocumentCategory(
        token,
        backend,
        name,
        )
    .then(res => {

        dispatch(createDocumentCategorySuccess())
        dispatch(requestCompleted())

        return res
    })
}}

export const removeTimeType = (id) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(deleteTimeTypeBegin())

    return deleteTimeType(
        token,
        backend,
        id,
        )
    .then(res => {

        dispatch(deleteTimeTypeSuccess())
        dispatch(requestCompleted())

        return res
    })
}}

export const loadTimeType = (id) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend },
    } = getState();

    dispatch(requestStarted())
    dispatch(loadTimeTypeBegin())

    return getTimeType(
        token,
        backend,
        id,
        )
    .then(res => {

        dispatch(loadTimeTypeSuccess(res[0]))
        dispatch(requestCompleted())

        return res
    })
}}


export const createTimeType = (name, description, short_name, salary_code, tab_sequence, sales_price, cost_price, active, erp_product_id) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend, user },
    } = getState();

    dispatch(requestStarted())
    dispatch(createTimeTypeBegin())

    return postTimeType(
        token,
        backend,
        name,
        description,
        short_name,
        salary_code,
        tab_sequence,
        sales_price,
        cost_price,
        active,
        erp_product_id,
        user.EmployeeID,
        user.EmployeeID,
        )
    .then(res => {

        dispatch(createTimeTypeSuccess())
        dispatch(requestCompleted())

        return res
    })
}}

export const updateCustomProjectSequence = (startFrom) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend, user },
    } = getState();

    dispatch(requestStarted())
    dispatch(updateCustomProjectSequenceBegin())

    return postCustomProjectSequence(
        token, 
        backend,
        startFrom ? startFrom : 1,
    )
    .then(res => {

        dispatch(updateCustomProjectSequenceSuccess())
        dispatch(requestCompleted())

        return res
    })
}}

export const uploadCompanyLogo = (image) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend, user },
    } = getState();

    dispatch(requestStarted())
    dispatch(uploadCompanyLogoBegin())

    return postUploadCompanyLogo(
        token, 
        backend,
        user.EmployeeID,
        image,
        )
    .then(res => {

        dispatch(uploadCompanyLogoSuccess())
        dispatch(requestCompleted())

        return res
    })
}}

interface Settings {
    UseAttest?,
    ShowTaskDescriptionOnInvoiceDetails?,
    UseCustomProjectSequence?,
    ImportFortnoxSupplierInvoicesFromDate?,
    FortnoxSupplierInvoiceVoucherSeries?,
    DefaultOfferHeadline?,
    DefaultOfferFooter?,
    StartOfferNumberSequenceAt?
    FortnoxExportProjectInfoToInvoiceAs?
    VismaImportCustomers?,
    VismaImportProjects?,
    VismaImportOrders?,
    VismaImportProducts?,
    VismaExportCustomers?,
    VismaExportProducts?,
    VismaExportInvoiceProposal?,
    VismaExportBreakOnDate?,
    VismaExportBreakOnAll?,
    VismaExportDisplayComment?,
    VismaExportStartDate?,
    VismaImportStartDate?,
    VismaImportEndDate?,
    VismaExportEndDate?,
    VismaImportCreateReportProject?,
    StartCustomProjectSequenceAt?,
    UsersAllowedToSeePrice?,
    UseProjectVariations?,
    UsersCanCreateProjectVariations?,
    DefaultPaymentPlanMaxTotalSafetyPercentage?,
    DefaultPaymentPlanSafetyPercentage?,
    DefaultPaymentPlanDaysBeforeDueDate?,
    DaysBeforePaymentPlanDueDateWarning?,
    UsePaymentPlans?,
    UseProjectAccesses?,
}
interface CreateArticleWithObject {
    articleId?,
    name?,
    description?,
    price?,
    productCategory?,
    unit?,
    unit_id?,
    cost?,
    onSalary?,
    salaryCode?,
    salary?,
    productTypeId?,
    product_kind?,
    check_box?,
    created_by_id?,
    changed_by_id?,
    StartCustomProjectSequenceAt?
    UsersAllowedToSeePrice?
    erp_product_id?,
    product_type_id?,
}

export const updateSettings = ({
        UseAttest,
        ShowTaskDescriptionOnInvoiceDetails,
        UseCustomProjectSequence,
        ImportFortnoxSupplierInvoicesFromDate,
        FortnoxSupplierInvoiceVoucherSeries,
        FortnoxExportProjectInfoToInvoiceAs,
        VismaImportCustomers,
        VismaImportProjects,
        VismaImportOrders,
        VismaImportProducts,
        VismaExportCustomers,
        VismaExportProducts,
        VismaExportInvoiceProposal,
        VismaExportBreakOnDate,
        VismaExportBreakOnAll,
        VismaExportDisplayComment,
        DefaultOfferHeadline,
        DefaultOfferFooter,
        StartOfferNumberSequenceAt,
        VismaExportStartDate,
        VismaExportEndDate,
        VismaImportStartDate,
        VismaImportEndDate,
        VismaImportCreateReportProject,
        StartCustomProjectSequenceAt,
        UsersAllowedToSeePrice,
        UseProjectVariations,
        UsersCanCreateProjectVariations,
        DefaultPaymentPlanMaxTotalSafetyPercentage,
        DefaultPaymentPlanSafetyPercentage,
        DefaultPaymentPlanDaysBeforeDueDate,
        DaysBeforePaymentPlanDueDateWarning,
        UsePaymentPlans,
        UseProjectAccesses,
    } : Settings) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(updateSettingsBegin ())

        return postSettings(
            token, 
            backend, 
            user.EmployeeID, 
            UseAttest, 
            ShowTaskDescriptionOnInvoiceDetails, 
            UseCustomProjectSequence, 
            ImportFortnoxSupplierInvoicesFromDate,
            FortnoxSupplierInvoiceVoucherSeries,
            FortnoxExportProjectInfoToInvoiceAs,
            VismaImportCustomers,
            VismaImportProjects,
            VismaImportOrders,
            VismaImportProducts,
            VismaExportCustomers,
            VismaExportProducts,
            VismaExportInvoiceProposal,
            VismaExportBreakOnDate,
            VismaExportBreakOnAll,
            VismaExportDisplayComment,
            DefaultOfferHeadline,
            DefaultOfferFooter,
            StartOfferNumberSequenceAt,
            VismaExportStartDate,
            VismaExportEndDate,
            VismaImportStartDate,
            VismaImportEndDate,
            VismaImportCreateReportProject,
            StartCustomProjectSequenceAt,
            UsersAllowedToSeePrice,
            UseProjectVariations,
            UsersCanCreateProjectVariations,
            DefaultPaymentPlanMaxTotalSafetyPercentage,
            DefaultPaymentPlanSafetyPercentage,
            DefaultPaymentPlanDaysBeforeDueDate,
            DaysBeforePaymentPlanDueDateWarning,
            UsePaymentPlans,
            UseProjectAccesses,
        )
            .then((res)=>{

                dispatch(updateSettingsSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadPriceLists = () => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadPriceListsBegin ())

        return getPriceLists(token, backend)
            .then((res)=>{

                dispatch(loadPriceListsSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}


export const updateUsersAllowedToSeePrice = (allowed = "false") => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(updateUsersAllowedToSeePriceBegin())

        return postUsersAllowedToSeePrice(token, backend, user.EmployeeID, allowed)
            .then((res)=>{

                dispatch(updateUsersAllowedToSeePriceSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadAllTimeTypes = () => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadAllTimeTypesBegin())

        return getAllTimeTypes(token, backend)
            .then((res)=>{

                dispatch(loadAllTimeTypesSuccess(res.timeType))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const sendUserInviteToCurrentTennant = (email, role, id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(sendUserInviteToCurrentTennantBegin())

        return postSendUserInviteToCurrentTennant(token, backend, email, role, id)
            .then((res)=>{
                dispatch(sendUserInviteToCurrentTennantSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const doesEmployeesEmailExist = (email) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadDoesEmployeesEmailExistBegin())

        return getDoesEmployeesEmailExist(token, backend, email)
            .then((res)=>{
                dispatch(requestCompleted())
                dispatch(loadDoesEmployeesEmailExistSuccess())

                return res
            })
    }
}


export const createEmployee = (firstName, lastName, email, employeeNr, mobileNr, role) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(craeteEmployeeBegin())

        return postEmployee(token, backend, firstName, lastName, email, employeeNr, mobileNr, role)
            .then((res)=>{
                dispatch(requestCompleted())
                dispatch(createEmployeeSuccess ())

                return res
            })
    }
}

export const removeSalary = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteSalaryBegin())

        return deleteSalary(token, backend, id)
            .then((res)=>{
                dispatch(requestCompleted())
                dispatch(deleteSalarySuccess())

                return res
            })
    }
}

export const createSalary = (
    EmployeeID,
    SalaryStartDate,
    SalaryEndDate,
    ReportedAbsences,
    ReportedTimes,
    ReportedProducts,
    Notes) => {

    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(createSalaryBegin())

        return postSalary(backend, token, EmployeeID, SalaryStartDate, SalaryEndDate, ReportedAbsences, ReportedTimes, ReportedProducts, Notes, user.EmployeeID)
            .then((res)=>{
                dispatch(requestCompleted())
                dispatch(createSalarySuccess())

                return res
            })
    }
}

export const loadAreCustomerProjectDone = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadAreCustomerProjectsDoneBegin())

        return getAreCustomerProjectsDone(backend, token, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadAreCustomerProjectsDoneSuccess(res))

                return res
            })
    }
}

export const loadArticleRegistryBegin = () => ({ type: types.LOAD_ARTICLES_REGISTRY_BEGIN })
export const loadArticleRegistrySuccess = (payload) => ({ type: types.LOAD_ARTICLES_REGISTRY_SUCCESS, payload })

export const createArticleBegin = () => ({ type: types.CREATE_ARTICLE_BEGIN })
export const createArticleSuccess = () => ({ type: types.CREATE_ARTICLE_SUCCESS })

export const deleteArticleBegin = () => ({ type: types.DELETE_ARTICLE_BEGIN })
export const deleteArticleSuccess = () => ({ type: types.DELETE_ARTICLE_SUCCESS })

export const updateArticleBegin = () => ({ type: types.UPDATE_ARTICLE_BEGIN })
export const updateArticleSuccess = () => ({ type: types.UPDATE_ARTICLE_SUCCESS })

export const loadProductCountsBegin = () => ({ type: types.LOAD_PRODUCT_COUNTS_BEGIN })
export const loadProductCountsSuccess = (payload) => ({ type: types.LOAD_PRODUCT_COUNTS_SUCCESS, payload })

export const loadProductBegin = () => ({ type: types.LOAD_PRODUCT_BEGIN })
export const loadProductSuccess = (payload) => ({ type: types.LOAD_PRODUCT_SUCCESS, payload })

export const loadProductTypesBegin = () => ({ type: types.LOAD_PRODUCT_TYPES_BEGIN })
export const loadProductTypesSuccess = (payload) => ({ type: types.LOAD_PRODUCT_TYPES_SUCCESS, payload })

export const loadAllTasksBegin = () => ({ type: types.LOAD_ALL_TASKS_BEGIN })
export const loadAllTasksSuccess = (payload) => ({ type: types.LOAD_ALL_TASKS_SUCCESS, payload })

export const loadProductStatsBegin = () => ({ type: types.LOAD_PRODUCT_STATS_BEGIN })
export const loadProductStatsSuccess = (payload) => ({ type: types.LOAD_PRODUCT_STATS_SUCCESS, payload })

export const loadFilteredAttestableReportsBegin = () => ({ type: types.LOAD_FILTERED_ATTESTABLE_REPORTS_BEGIN })
export const loadFilteredAttestableReportsSuccess = (payload) => ({ type: types.LOAD_FILTERED_ATTESTABLE_REPORTS_SUCCESS, payload })

export const createUnitBegin = () => ({ type: types.CREATE_UNIT_BEGIN })
export const createUnitSuccess = () => ({ type: types.CREATE_UNIT_SUCCESS })

export const updateUnitBegin = () => ({ type: types.UPDATE_UNIT_BEGIN })
export const updateUnitSuccess = () => ({ type: types.UPDATE_UNIT_SUCCESS })

export const createProductTypeBegin = () => ({ type: types.CREATE_PRODUCT_TYPE_BEGIN })
export const createProductTypeSuccess = () => ({ type: types.CREATE_PRODUCT_TYPE_SUCCESS })

export const updateProductTypeBegin = () => ({ type: types.UPDATE_PRODUCT_TYPE_BEGIN })
export const updateProductTypeSuccess = () => ({ type: types.UPDATE_PRODUCT_TYPE_SUCCESS })

export const loadTaskWithTaskTypeBegin = () => ({ type: types.LOAD_TASK_WITH_TASK_TYPE_BEGIN })
export const loadTaskWithTaskTypeSuccess = (payload) => ({ type: types.LOAD_TASK_WITH_TASK_TYPE_SUCCESS, payload })

export const loadTaskTypesBegin = () => ({ type: types.LOAD_TASK_TYPES_BEGIN })
export const loadTaskTypesSuccess = (payload) => ({ type: types.LOAD_TASK_TYPES_SUCCESS, payload })

export const deleteTaskBegin = () => ({ type: types.DELETE_TASK_BEGIN })
export const deleteTaskSuccess = () => ({ type: types.DELETE_TASK_SUCCESS })

export const updateTaskBegin = () => ({ type: types.UPDATE_UPDATE_TASK_BEGIN })
export const updateTaskSuccess = () => ({ type: types.UPDATE_UPDATE_TASK_SUCCESS })

export const updateTaskTypeBegin = () => ({ type: types.UPDATE_TASK_TYPE_BEGIN }) 
export const updateTaskTypeSuccess = () => ({ type: types.UPDATE_TASK_TYPE_SUCCESS }) 

export const createTaskTypeBegin = () => ({ type: types.CREATE_TASK_TYPE_BEGIN }) 
export const createTaskTypeSuccess = () => ({ type: types.CREATE_TASK_TYPE_SUCCESS }) 

export const createTaskBegin = () => ({ type: types.CREATE_TASK_BEGIN }) 
export const createTaskSuccess = () => ({ type: types.CREATE_TASK_SUCCESS }) 

export const deleteTaskTypeBegin = () => ({ type: types.DELETE_TASK_TYPE_BEGIN })
export const deleteTaskTypeSuccess = () => ({ type: types.DELETE_TASK_TYPE_SUCCESS })

export const deleteProductTypeBegin = () => ({ type: types.DELETE_PRODUCT_TYPE_BEGIN })
export const deleteProductTypeSuccess = () => ({ type: types.DELETE_PRODUCT_TYPE_SUCCESS })

export const deleteUnitBegin = () => ({ type: types.DELETE_UNIT_BEGIN })
export const deleteUnitSuccess = () => ({ type: types.DELETE_UNIT_SUCCESS })

export const loadTaskStatsBegin = () => ({ type: types.LOAD_TASK_STATS_BEGIN })
export const loadTaskStatsSuccess = (payload) => ({ type: types.LOAD_TASK_STATS_SUCCESS, payload })

export const updateEmployeeBegin = () => ({ type: types.UPDATE_EMPLOYEE_BEGIN }) 
export const updateEmployeeSuccess = () => ({ type: types.UPDATE_EMPLOYEE_SUCCESS }) 

export const loadRevokeSoftDeletedProductBegin = () => ({ type: types.LOAD_REVOKE_SOFT_DELETED_PRODUCT_BEGIN })
export const loadRevokeSoftDeletedProductSuccess = () => ({ type: types.LOAD_REVOKE_SOFT_DELETED_PRODUCT_SUCCESS })

export const updateRevokeSoftDeletedTaskBegin = () => ({ type: types.UPDATE_REVOKE_SOFT_DELETED_TASK_BEGIN })
export const updateRevokeSoftDeletedTaskSuccess = () => ({ type: types.UPDATE_REVOKE_SOFT_DELETED_TASK_SUCCESS })

export const updateRevokeSoftDeletedTimeTypeBegin = () => ({ type: types.UPDATE_REVOKE_SOFT_DELETED_TIMETYPE_BEGIN })
export const updateRevokeSoftDeletedTimeTypeSuccess = () => ({ type: types.UPDATE_REVOKE_SOFT_DELETED_TIMETYPE_SUCCESS })

export const loadEmployeeBegin = () => ({ type: types.LOAD_EMPLOYEE_BEGIN })
export const loadEmployeeSuccess = (payload) => ({ type: types.LOAD_EMPLOYEE_SUCCESS, payload })

export const deleteEmployeeSoftDeleteBegin = () => ({ type: types.DELETE_EMPLOYEE_SOFT_DELETE_BEGIN })
export const deleteEmployeeSoftDeleteSuccess = () => ({ type: types.DELETE_EMPLOYEE_SOFT_DELETE_SUCCESS })

export const loadRegisterEmployeesBegin = () => ({ type: types.LOAD_REGISTER_EMPLOYEES_BEGIN })
export const loadRegisterEmployeesSuccess = (payload) => ({ type: types.LOAD_REGISTER_EMPLOYEES_SUCCESS, payload })

export const loadEmployeeCountBegin = () => ({ type: types.LOAD_EMPLOYEE_COUNT_BEGIN })
export const loadEmployeeCountSuccess = (payload) => ({ type: types.LOAD_EMPLOYEE_COUNT_SUCCESS, payload })

export const loadRevokeSoftDeletedEmployeeBegin = () => ({ type: types.LOAD_REVOKE_SOFT_DELETED_EMPLOYEE_BEGIN })
export const loadRevokeSoftDeletedEmployeeSuccess = () => ({ type: types.LOAD_REVOKE_SOFT_DELETED_EMPLOYEE_SUCCESS })

export const loadAreTimeTypeHandledBegin = () => ({ type: types.LOAD_ARE_TIME_TYPE_HANDLED_BEGIN })
export const loadAreTimeTypeHandledSuccess = () => ({ type: types.LOAD_ARE_TIME_TYPE_HANDLED_SUCCESS })

export const updateRevokeSoftDeletedTimeType = (id) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(updateRevokeSoftDeletedTimeTypeBegin())

        return postRevokeSoftDeletedTimeType(token, backend, id)
        .then(res => {
 
            dispatch(updateRevokeSoftDeletedTimeTypeSuccess())
            dispatch(requestCompleted())

            return res
        })
    }
}

export const loadAreTimeTypeHandled = (id) => {

    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState();

        dispatch(requestStarted());
        dispatch(loadAreTimeTypeHandledBegin());

        return getAreTimeTypesHandled(token, backend, id)
        .then((res) => {
            dispatch(loadAreTimeTypeHandledSuccess());
            dispatch(requestCompleted());

            return res;
        })
    }
}


export const revokeDoftDeleteEmployee = (id) => {

    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState();

        dispatch(requestStarted());
        dispatch(loadRevokeSoftDeletedEmployeeBegin());

        return getRevokeSoftDeletedEmployee(token, backend, id)
        .then((res) => {
            dispatch(loadRevokeSoftDeletedEmployeeSuccess());
            dispatch(requestCompleted());

            return res;
        })
    }
}

export const loadEmployeeCount = (search = "") => {

    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState();

        dispatch(requestStarted());
        dispatch(loadEmployeeCountBegin());

        return getEmployeeCount(token, backend, search)
        .then((res) => {
            dispatch(loadEmployeeCountSuccess(res));
            dispatch(requestCompleted());

            return res;
        })
    }
}

export const loadFilteredEmployees = (active, query, page?, orderby?, sortDirection?) => {

    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState();

        dispatch(requestStarted());
        dispatch(loadRegisterEmployeesBegin());

        return getFilteredEmployees(token, backend, active, query, page, orderby, sortDirection)
        .then((res) => {
            dispatch(loadRegisterEmployeesSuccess(res));
            dispatch(requestCompleted());

            return res;
        })
    }
}

export const softDeleteEmployee = (id) => {

    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState();

        dispatch(requestStarted());
        dispatch(deleteEmployeeSoftDeleteBegin());

        return deleteEmployeeSoftDelete(token, backend, id)
        .then((res) => {
            dispatch(deleteEmployeeSoftDeleteSuccess());
            dispatch(requestCompleted());

            return res;
        })
    }
}

export const loadEmployee = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadEmployeeBegin())

        return getEmployee(token, backend, id)
        .then((res)=>{
            dispatch(requestCompleted())
            dispatch(loadEmployeeSuccess(res.employee))
            
            return res
        })
    }
}

export const updateRevokeSoftDeletedTask = (id) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(updateRevokeSoftDeletedTaskBegin())

        return postRevokeSoftDeletedTask(token, backend, id)
        .then(res => {
 
            dispatch(updateRevokeSoftDeletedTaskSuccess())
            dispatch(requestCompleted())

            return res
        })
    }
}

export const loadRevokeSoftDeletedProduct = (id) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadRevokeSoftDeletedProductBegin())

        return getRevokeSoftDeletedProduct(token, backend, id)
        .then(res => {

            dispatch(loadRevokeSoftDeletedProductSuccess())
            dispatch(requestCompleted())

            return res
        })
    }
}

export const updatePasswordBegin = () => ({ type: types.UPDATE_PASSWORD_BEGIN })
export const updatePasswordSuccess = () => ({ type: types.UPDATE_PASSWORD_SUCCESS })

export const updateUserDetailsBegin = () => ({ type: types.UPDATE_USER_DETAILS_BEGIN })
export const updateUserDetailsSuccess = () => ({ type: types.UPDATE_USER_DETAILS_SUCCESS })

export const updateProfilePictureBegin = () => ({ type: types.UPDATE_PROFILE_PICTURE_BEGIN })
export const updateProfilePictureSuccess = () => ({ type: types.UPDATE_PROFILE_PICTURE_SUCCESS })

export const loadEmployeeReportedHoursBegin = () => ({ type: types.LOAD_EMPLOYEE_REPORTED_HOURS_BEGIN })
export const loadEmployeeReportedHoursSuccess = (payload) => ({ type: types.LOAD_EMPLOYEE_REPORTED_HOURS_SUCCESS, payload })

export const loadDoesEmployeeNumberExistBegin = () => ({ type: types.LOAD_DOES_EMPLOYEE_NUMBER_EXIST_BEGIN })
export const loadDoesEmployeeNumberExistSuccess = () => ({ type: types.LOAD_DOES_EMPLOYEE_NUMBER_EXIST_SUCCESS })


export const doesEmployeeNumberExist = (id) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadDoesEmployeeNumberExistBegin())

        return getDoesEmployeeNumberExist(token, backend, id)
        .then(res => {

            dispatch(loadDoesEmployeeNumberExistSuccess())
            dispatch(requestCompleted())

            return res
        })
    }
}

export const sendOfferLinkAnswer = (backend, email, linkToken, answer, comment = "", signature?: Blob, signatureName?, pdf?: Blob) => {
    return (dispatch, getState) => {

        dispatch(requestStarted())
        dispatch(sendOfferLinkAnswerBegin())

        return postSendOfferLinkAnswer(backend, email, linkToken, answer, comment, signature, signatureName, pdf)
            .then(res => {

                dispatch(sendOfferLinkAnswerSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadUseOfferLink = (backend, email, linkToken) => {
    return (dispatch, getState) => {

        dispatch(requestStarted())
        dispatch(loadUseOfferLinkBegin())

        return getUseOfferLink(backend, email, linkToken)
            .then(res => {

                dispatch(loadUseOfferLinkSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadEmployeeReportedHours = (id) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadEmployeeReportedHoursBegin())

        return getEmployeeReportedHours(token, backend, id)
        .then(res => {

            dispatch(loadEmployeeReportedHoursSuccess(res))
            dispatch(requestCompleted())

            return res
        })
    }
}

export const updateProfilePicture = (image) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend, user },
    } = getState();

    dispatch(requestStarted())
    dispatch(updateProfilePictureBegin())

    return postProfilePicture(
        token, 
        backend,
        user.EmployeeID,
        image,
        )
    .then(res => {

        dispatch(updateProfilePictureSuccess())
        dispatch(requestCompleted())

        return res
    })
}}

export const updatePassword = (password) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend, user },
    } = getState();

    dispatch(requestStarted())
    dispatch(updatePasswordBegin())

    return postChangePassword(
        token, 
        backend,
        user.EmployeeID,
        password,
        )
    .then(res => {

        dispatch(updatePasswordSuccess())
        dispatch(requestCompleted())

        return res
    })
}}

export const updateUserDetails = ({ FirstName, LastName, Email, MobileNumber }) => {

    return (dispatch, getState) => {

    const {
        app: { token, backend, user },
    } = getState();

    dispatch(requestStarted())
    dispatch(updateUserDetailsBegin())

    return postUpdateUserDetails(
        token, 
        backend,
        user.EmployeeID,
        FirstName,
        LastName,
        Email,
        MobileNumber,
        )
    .then(res => {

        dispatch(updateUserDetailsSuccess())
        dispatch(requestCompleted())

        return res
    })
}}

export const updateEmployee = (
        id?: string,
        FirstName?: string,
        LastName?: string,
        PhoneNumber?: string,
        employee_nr?: string,
        adress_line1?: string,
        socialSecurityNumber?: string,
        e_mail?: string,
        Role?: string,
    ) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend, user },
        } = getState();

        dispatch(requestStarted())
        dispatch(updateEmployeeBegin())
        
        return putEmployee(
            token, 
            backend,
            id ? id : user.EmployeeID,
            FirstName,
            LastName,
            PhoneNumber,
            employee_nr,
            adress_line1,
            socialSecurityNumber,
            e_mail,
            Role,
            )
        .then(res => {

            dispatch(updateEmployeeSuccess())
            dispatch(requestCompleted())

            return res
    })
    }}

export const loadTaskStats = (id, fromDate, toDate) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadTaskStatsBegin())

        return getTaskStats(backend, token, id, fromDate, toDate)
        .then(res => {

            dispatch(loadTaskStatsSuccess(res.task))
            dispatch(requestCompleted())

            return res
        })
    }
}

interface LookupPriceInPriceList {
    CustomerID,
    TaskID,
    ProductID,
    TimeTypeID?,
    ProjectID?,

}

export const lookupPriceInPriceList = ({CustomerID, TaskID, TimeTypeID = null, ProductID, ProjectID = null} : LookupPriceInPriceList) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(lookupPriceInPricelistBegin())

        return postLookupPriceInPricelist(backend, token, CustomerID, TaskID, TimeTypeID, ProductID, ProjectID)
            .then(res => {
                dispatch(lookupPriceInPricelistSuccess(res.price))
                dispatch(requestCompleted())

                return res.price
            }) 
    }
}

export const loadOfferLibraryTextBlocks = () => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadOfferLibraryTextBlocksBegin())

        return getOfferLibraryTextBlocks(token, backend)
            .then(res => {
                dispatch(loadOfferLibraryTextBlocksSuccess(res.libraryTextBlocks))
                dispatch(requestCompleted())
                
                return res.libraryTextBlocks
            })
    }
}

export const createOfferLibraryTextBlock = (Title, Text) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(createOfferLibraryTextBlocksBegin())

        return postOfferLibraryTextBlock(token, backend, Title, Text, user.EmployeeID, user.EmployeeID)
            .then(() => {
                dispatch(createOfferLibraryTextBlocksSuccess())
                dispatch(requestCompleted())
            })
    }
}

export const updateOfferLibraryTextBlock = (id, Title, Text) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(updateOfferLibraryTextBlocksBegin())

        return putOfferLibraryTextBlock(token, backend, id, Title, Text, user.EmployeeID)
            .then(() => {
                dispatch(updateOfferLibraryTextBlocksSuccess())
                dispatch(requestCompleted())
            })
    }
}

export const removeOfferLibraryTextBlock = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteOfferLibraryTextBlocksBegin())

        return deleteOfferLibraryTextBlock(token, backend, id)
            .then(() => {
                dispatch(deleteOfferLibraryTextBlocksSuccess())
                dispatch(requestCompleted())
            })
    }
}

export const removeUnit = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        
        dispatch(deleteUnitBegin())

        return deleteUnit(token, backend, id)
            .then(res => {
                dispatch(deleteUnitSuccess())
                dispatch(requestCompleted())
                
                return res
            })
    }
}

export const removeProductType = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        
        dispatch(deleteProductTypeBegin())

        return deleteProductType(token, backend, id)
            .then(res => {
                dispatch(deleteProductTypeSuccess())
                dispatch(requestCompleted())
                
                return res
            })
    }
}

export const removeTaskType = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        
        dispatch(deleteTaskTypeBegin())

        return deleteTaskType(token, backend, id)
            .then(res => {
                dispatch(deleteTaskTypeSuccess())
                dispatch(requestCompleted())
                
                return res
            })
    }
}

export const createTaskNew = (
    title,
    description,
    task_type_id,
    sales_price,
    cost_price,
    active,
    erp_product_id = null
    ) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend, user },
        } = getState();
    
            dispatch(requestStarted())
            dispatch(createTaskBegin())

        return postTaskNew(
            token, 
            backend,
            user.EmployeeID,
            title,
            description,
            task_type_id,
            sales_price,
            cost_price,
            active,
            erp_product_id,
            )
        .then(res => {
        
            dispatch(createTaskSuccess())
            dispatch(requestCompleted())
        
            return res
        })
    }}
        
export const loadSalariesBegin = () => ({ type: types.LOAD_SALARIES_BEGIN })
export const loadSalariesSuccess = (payload) => ({ type: types.LOAD_SALARIES_SUCCESS, payload })


export const loadSalaries = (employeeId?) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend, user },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadSalariesBegin())

        return getSalaries(
            token, 
            backend,
            employeeId
            )
        .then(res => {

            dispatch(loadSalariesSuccess(res.salaries))
            dispatch(requestCompleted())

            return res.salaries
        })
    }
}

export const createTaskType = (
    type_title,
    description,
    ) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend, user },
        } = getState();

        dispatch(requestStarted())
        dispatch(createTaskTypeBegin())

        return postTaskType(
            token, 
            backend,
            user.EmployeeID,
            user.EmployeeID,
            type_title,
            description,
            )
        .then(res => {

            dispatch(createTaskTypeSuccess())
            dispatch(requestCompleted())

            return res
        })
    }
}

export const loadTotalAmountToInvoice = (from = "", to = "", search = "", customerId = "", onlyCountWithApproved = false) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend, user },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadTotalAmountToInvoiceBegin())

        return getTotalAmountToInvoice(backend, token, from, to, search, customerId, onlyCountWithApproved)
            .then((res)=>{
                dispatch(requestCompleted())
                dispatch(loadTotalAmountToInvoiceSuccess(res))

                return res
            })
    }
}

export const updateTaskType = (
    type_title,
    description,
    id
    ) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend, user },
        } = getState();

        dispatch(requestStarted())
        dispatch(updateTaskTypeBegin())

        return putTaskType(
            token, 
            backend,
            user.EmployeeID,
            type_title,
            description,
            id
            )
        .then(res => {

            dispatch(updateTaskTypeSuccess())
            dispatch(requestCompleted())

            return res
    })
    }}

export const updateTask = (
    title,
    description,
    task_type_id,
    active,
    sales_price,
    cost_price,
    on_salary,
    id,
    erpProductId,
    fortnox_article_number?,
    ) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend, user },
        } = getState();

        dispatch(requestStarted())
        dispatch(updateTaskBegin())

        return putTask(
            token, 
            backend,
            user.EmployeeID,
            user.EmployeeID,
            title,
            description,
            task_type_id,
            active,
            sales_price,
            cost_price,
            on_salary,
            id,
            erpProductId,
            fortnox_article_number,
            )
        .then(res => {

            dispatch(updateTaskSuccess())
            dispatch(requestCompleted())

            return res
        })
    }
}


export const removeTask = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteTaskBegin())

        return deleteTask(backend, token, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(deleteTaskSuccess())

                return res
            })
    }
}

export const markCustomerAsInactive = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(postMarkCustomerAsInactiveBegin())

        return postMarkCustomerAsInactive(backend, token, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(postMarkCustomerAsInactiveSuccess())

                return res
            })
    }
}

export const markCustomerAsActive = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(postMarkCustomerAsActiveBegin())

        return postMarkCustomerAsActive(backend, token, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(postMarkCustomerAsActiveSuccess())
            })

        }
}

export const loadTaskTypes = () => {
    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadTaskTypesBegin())

        return getTaskTypes(backend, token)
        .then(res => {

            dispatch(loadTaskTypesSuccess(res.task_type))
            dispatch(requestCompleted())

            return res
        })
    }
}

export const loadTaskWithTaskType = (id, hot = false) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        if(!hot) {
            dispatch(loadTaskWithTaskTypeBegin())
        }

        return getTaskWithTaskType(backend, token, id)
        .then(res => {
            dispatch(loadTaskWithTaskTypeSuccess(res.task[0]))
            dispatch(requestCompleted())

            return res
        })
    }
}

export const updateProductType = (
    name,
    description,
    id
) => {

    return (dispatch, getState) => {

        const {
            app: { token, backend, user }
        } = getState()
        
        
        dispatch(requestStarted())
        dispatch(updateProductTypeBegin())

        return putProductType(
            token,
            backend,
            user.EmployeeID,
            name,
            description,
            id
        ).then((res)=>{

            dispatch(requestCompleted())
            dispatch(updateProductTypeSuccess())

            return res
        })
    }
}

//Dispatch post
export const createProductType = (
    name,
    description,
) => {

return (dispatch, getState) => {
    //Destructing token and backend from store
    const {
        app: {token, backend, user}
    }  = getState()

    //Sets +/- 1 on number of ongoing requests to be used to show the loading bar,
    //at the top of the page and the loading spinner icon on the top left
    dispatch(requestStarted())

    //Dispatching action creator
    dispatch(createProductTypeBegin())

    ///Post request
    return postProductType(
        token, 
        backend,
        user.EmployeeID,
        user.EmployeeID,
        name,
        description,
        )
        .then(res => {

            //Sets +/- 1 on number of ongoing requests to be used to show the loading bar,
            //at the top of the page and the loading spinner icon on the top left
            dispatch(requestCompleted())

            //Dispatching action creator with data for payload
            dispatch(createProductTypeSuccess())

            return res
        })
}
}

export const updateUnit = (
    name,
    description,
    id
) => {

return (dispatch, getState) => {

    const {
        app: { token, backend, user }
    } = getState()
    
    
    dispatch(requestStarted())
    dispatch(updateUnitBegin())

    return putUnit(
        token,
        backend,
        user.EmployeeID,
        name,
        description,
        id
    ).then((res)=>{

        dispatch(requestCompleted())
        dispatch(updateUnitSuccess())

        return res
    })
}
}


//Dispatch post
export const createUnit = (
    name,
    description,
) => {

return (dispatch, getState) => {
    //Destructing token and backend from store
    const {
        app: {token, backend, user}
    }  = getState()

    //Sets +/- 1 on number of ongoing requests to be used to show the loading bar,
    //at the top of the page and the loading spinner icon on the top left
    dispatch(requestStarted())

    //Dispatching action creator
    dispatch(createUnitBegin())

    ///Post request
    return postUnit(
        token, 
        backend,
        name,
        description,
        user.EmployeeID,
        user.EmployeeID,
        )
        .then(res => {

            //Sets +/- 1 on number of ongoing requests to be used to show the loading bar,
            //at the top of the page and the loading spinner icon on the top left
            dispatch(requestCompleted())

            //Dispatching action creator with data for payload
            dispatch(createUnitSuccess())

            return res
        })
}
}


export const createOffer = (offer: Offer, rows, textBlocks, documents = "") => {
    return (dispatch, getState) => {
        const {
            app: { token, backend, user },
        } = getState();

        dispatch(requestStarted())
        dispatch(createOfferBegin())

        return postOffer(
            token, 
            backend, 
            offer.OfferDate, 
            offer.ExpireDate, 
            offer.DeliveryDate, 
            offer.OurReference, 
            offer.CustomerID, 
            offer.CustomerObjectID, 
            offer.InternalComment, 
            offer.ProjectID, 
            offer.Type, 
            offer.Status, 
            offer.SignatureID, 
            offer.VAT, 
            offer.MaxDeductionAmount,
            offer.NetTotal, 
            offer.Total, 
            offer.TotalVAT, 
            offer.TotalDeductionAmount,
            offer.ShowOfferRows,
            offer.ShowOfferRowTotals,
            offer.OfferRowsHeadline,
            offer.Headline,
            offer.Footer,
            offer.TemplateDescription,
            offer.YourReference,
            offer.ShowOfferRowDiscounts,
            offer.ShowOfferRowQuantities,
            offer.ShowOfferRowPricePerUnit,
            offer.UsePaymentPlan,
            offer.PaymentPlanMaxTotalSafetyPercentage,
            offer.PaymentPlanSafetyPercentage,
            offer.ShowPaymentPlanDueDate,
            offer.ShowPaymentPlanInvoiceDate,
            rows, 
            textBlocks, 
            documents,
            user.EmployeeID, 
            user.EmployeeID
        ).then(res => {
            dispatch(createOfferSuccess())
            dispatch(requestCompleted())

            return res
        })
    }
}

export const updateOffer = (id, offer: Offer, rows, textBlocks) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend, user },
        } = getState();

        dispatch(requestStarted())
        dispatch(updateOfferBegin())

        return putOffer(
            token, 
            backend, 
            id,
            offer.OfferDate, 
            offer.ExpireDate, 
            offer.DeliveryDate, 
            offer.OurReference, 
            offer.CustomerID, 
            offer.CustomerObjectID, 
            offer.InternalComment, 
            offer.ProjectID, 
            offer.Type, 
            offer.Status, 
            offer.SignatureID, 
            offer.VAT, 
            offer.MaxDeductionAmount,
            offer.NetTotal, 
            offer.Total, 
            offer.TotalVAT, 
            offer.TotalDeductionAmount, 
            offer.ShowOfferRows,
            offer.ShowOfferRowTotals,
            offer.OfferRowsHeadline,
            offer.Headline,
            offer.Footer,
            offer.TemplateDescription,
            offer.YourReference,
            offer.ShowOfferRowDiscounts,
            offer.ShowOfferRowQuantities,
            offer.ShowOfferRowPricePerUnit,
            offer.UsePaymentPlan,
            offer.PaymentPlanMaxTotalSafetyPercentage,
            offer.PaymentPlanSafetyPercentage,
            offer.ShowPaymentPlanDueDate,
            offer.ShowPaymentPlanInvoiceDate,
            rows, 
            textBlocks, 
            user.EmployeeID, 
            user.EmployeeID
        ).then(res => {
            dispatch(updateOfferSuccess())
            dispatch(requestCompleted())

            return res
        })
    }
}


export const removeOffer = (id) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend, user },
        } = getState();

        dispatch(requestStarted())
        dispatch(deleteOfferBegin())

        return deleteOffer(
            backend, 
            token, 
            id,
        ).then(res => {
            dispatch(deleteOfferSuccess())
            dispatch(requestCompleted())

            return res
        })
    }
}

export const loadOfferCounts = (type, search?) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadOfferCountsBegin())

        return getOfferCounts(token, backend, type, search)
            .then((res)=>{
                dispatch(requestCompleted())
                dispatch(loadOfferCountsSuccess(res))
                return res
            })
    }
}

export const loadAllOfferCounts = () => {
    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadAllOfferCountsBegin())

        return getOfferCounts(token, backend, "", "")
            .then((res)=>{
                dispatch(requestCompleted())
                dispatch(loadAllOfferCountsSuccess(res))
                return res
            })
    }
}

export const loadOffers = (type?, status?, page?, customerId?, search?, sortBy?, sortDirection?) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadOffersBegin())

        return getOffers(token, backend, type, status, page, customerId, search, sortBy, sortDirection)
            .then((res)=>{
                dispatch(requestCompleted())
                dispatch(loadOffersSuccess(res.offers))
                return res.offers
            })
    }
}

export const loadOffer = (id, hot = false) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        
        if(!hot) {
            dispatch(loadOfferBegin())
        }

        return getOffer(token, backend, id)
            .then((res)=>{
                dispatch(requestCompleted())
                dispatch(loadOfferSuccess(res.offer))
                return res.offer
            })
    }
}

export const loadFilteredAttestableReports = (fromDate, toDate) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadFilteredAttestableReportsBegin())

        return getFilteredAttestableReports(backend, token, fromDate, toDate)
        .then(res => {

            dispatch(loadFilteredAttestableReportsSuccess(res))
            dispatch(requestCompleted())

            return res
        })
    }
}


export const loadProductStats = (id, fromDate, toDate) => {
    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadProductStatsBegin())

        return getProductStats(backend, token, id, fromDate, toDate)
        .then(res => {

            dispatch(loadProductStatsSuccess(res.product))
            dispatch(requestCompleted())
        })
    }
}

export const loadAllTasks = () => {
    return (dispatch, getState) => {

        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadAllTasksBegin())

        return getAllTasks(token, backend)
        .then(res => {

            dispatch(loadAllTasksSuccess(res.data))
            dispatch(requestCompleted())

            return res.data
        })
    }
}

export const loadProductTypes = () => {
    return (dispatch, getState) => {
        
        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadProductTypesBegin())

        return getProductTypes(token, backend)

            .then((res)=>{
                dispatch(loadProductTypesSuccess(res.product_types))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadProduct = (id, hot = false) => {
    return (dispatch, getState) => {

        dispatch(requestStarted());
        if(!hot) {
            dispatch(loadProductBegin());
        }
        const {
            app: {token, backend}
        } = getState();

        return getProduct(backend, token, id )
            .then((res) => {

                dispatch(requestCompleted());

                if(res.product) {
                    dispatch(loadProductSuccess({...res.product}))
                }

                return res
            })
    }
}

export const loadDocuments = (KeyID, id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadDocumentsBegin())

        return getDocuments(token, backend, KeyID, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadDocumentsSuccess(res))

                return res

        })
    }
}

export const loadProductCounts = (search) => {
    return (dispatch, getState) => {
        
        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadProductCountsBegin())

        return getProductCounts(token, backend, search)

            .then((res)=>{
                dispatch(loadProductCountsSuccess(res))
                dispatch(requestCompleted())
            })
    }
}

export const removeCustomerObject = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

            dispatch(requestStarted())
        dispatch(deleteCustomerObjectBegin())

        return deleteCustomerObject(backend, token, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(deleteCustomerObjectSuccess())
            })
        }
    }
    
export const updateArticle = (
    name,
    description,
    productCategory,
    unit,
    price,
    cost_price,
    onSalary,
    salaryCode,
    salary,
    productTypeId,
    id,
    erpProductId?,
    fortnox_article_number?,
) => {

return (dispatch, getState) => {

    const {
        app: { token, backend, user }
    } = getState()
    
    
    dispatch(requestStarted())
    dispatch(updateArticleBegin())

    return putArticle(
        token,
        backend,
        user.EmployeeID,
        user.EmployeeID,
        name,
        description,
        productCategory,
        unit,
        price,
        cost_price,
        onSalary,
        salaryCode,
        salary,
        productTypeId,
        id,
        erpProductId,
        fortnox_article_number,
    ).then((res)=>{

        dispatch(requestCompleted())
        dispatch(updateArticleSuccess())

        return res
    })
}
}

export const removeArticle = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        
        dispatch(deleteArticleBegin())

        return deleteArticle(backend, token, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(deleteArticleSuccess())

                return res
            })
    }
}

export const updateCustomer = ({
    id,
    type,
    name,
    organisation_number,
    middle_name,
    last_name,
    e_mail,
    phone_number,
    adress_line1,
    adress_line2,
    adress_line3,
    adress_line4,
    postal_code,
    city,
    country,
    contact_person,
    erp_customer_id,
    fortnox_customer_number,
    price_list_id,
    lat,
    long,
}) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(updateCustomerBegin())

        const {
            app: {token, backend, user}
        } = getState()

        return putCustomer(
            backend,
            token,
            id,
            type,
            name,
            organisation_number,
            middle_name,
            last_name,
            e_mail,
            phone_number,
            adress_line1,
            adress_line2,
            adress_line3,
            adress_line4,
            postal_code,
            city,
            country,
            contact_person,
            erp_customer_id,
            fortnox_customer_number,
            user.EmployeeID,
            price_list_id,
            lat,
            long,
        )
        .then((res)=>{
            dispatch(requestCompleted())
            dispatch(updateCustomerSuccess())

            return res
        })
    }
}

export const removeCustomer = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteCustomerBegin())

        return deleteCustomer(backend, token, id)
            .then(res => {
                dispatch(deleteCustomerSuccess())
                dispatch(requestCompleted())
                return res
            })
    }
}

export const loadProjectVariation = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadProjectVariationBegin())

        return getProjectVariation(token, backend, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadProjectVariationSuccess(res.projectVariation))
                return res
            })
    }
}

export const loadProjectVariationCounts = (projectId, search = "") => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())    
        dispatch(loadProjectVariationCountsBegin())

        return getProjectVariationCounts(token, backend, projectId, search)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadProjectVariationCountsSuccess(res))
            })
    }
}

export const loadProjectVariations = (projectId, status = "", search = "", hot = false) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        if(!hot) {
            dispatch(loadProjectVariationsBegin())
        }

        return getProjectVariations(token, backend, projectId, status, search)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadProjectVariationsSuccess(res.projectVariations))

                return res.projectVariations
            })
    }
}

export const createProjectVariation = (body: ProjectVariation) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(createProjectVariationBegin())

        return postProjectVariation(token, backend, body)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(createProjectVariationSuccess())

                return res
            })
    }
}

export const updateProjectVariation = (id, body: ProjectVariation) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(updateProjectVariationBegin())

        return putProjectVariation(token, backend, id, body)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(updateProjectVariationSuccess())

                return res
            })
    }
}

export const removeProjectVariation = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteProjectVariationBegin())

        return deleteProjectVariation(backend, token, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(deleteProjectVariationSuccess())

                return res
            })
    }
}

export const createProjectVariationLink = (body: ProjectVariationLink) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(createProjectVariationLinkBegin())

        return postProjectVariationLink(token, backend, body)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(createProjectVariationLinkSuccess())

                return res
            })
    }
}

export const removeProjectVariationLink = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteProjectVariationLinkBegin())

        return deleteProjectVariationLink(backend, token, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(deleteProjectVariationLinkSuccess())

                return res
            })
    }
}

export const loadUseProjectVariationLink = (tenant, email, linkToken) => {
    return (dispatch, getState) => {

        dispatch(requestStarted())
        dispatch(loadUseProjectVariationLinkBegin())

        return getUseProjectVariationLink(tenant, email, linkToken)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadUseProjectVariationLinkSuccess(res))
            })
    }
}

export const sendProjectVariationLinkAnswer = (backend, email, linkToken, answer, comment = "", signature?: Blob, signatureName?) => {
    return (dispatch, getState) => {

        dispatch(requestStarted())
        dispatch(sendProjectVariationLinkAnswerBegin())

        return postSendProjectVariationLinkAnswer(backend, email, linkToken, answer, comment, signature, signatureName)
            .then(res => {

                dispatch(sendProjectVariationLinkAnswerSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadCustomerCounts = (search) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadCustomerCountsBegin())

        return getCustomerCounts(backend, token, search)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadCustomerCountsSuccess(res))

                return res

            })

        }
    }
//Dispatch post
export const createArticle = (
    name,
    description,
    productCategory,
    unit,
    price,
    cost,
    onSalary,
    salaryCode,
    salary,
    productTypeId
) => {

    return (dispatch, getState) => {
        //Destructing token and backend from store
        const {
            app: {token, backend, user}
        }  = getState()

        //Sets +/- 1 on number of ongoing requests to be used to show the loading bar,
        //at the top of the page and the loading spinner icon on the top left
        dispatch(requestStarted())

        //Dispatching action creator
        dispatch(createArticleBegin())

        ///Post request
        return postArticle(
            token, 
            backend,
            user.EmployeeID,
            user.EmployeeID,
            name,
            description,
            productCategory,
            unit,
            price,
            cost,
            onSalary,
            salaryCode,
            salary,
            productTypeId
            )
            .then(res => {
 
                //Sets +/- 1 on number of ongoing requests to be used to show the loading bar,
                //at the top of the page and the loading spinner icon on the top left
                dispatch(requestCompleted())

                //Dispatching action creator with data for payload
                dispatch(createArticleSuccess())

                return res
            })
    }
}

//Dispatch get
export const loadArticleRegistry = () => {
    return (dispatch, getState) => {
        //Destructing token and backend from store
        const {
            app: {token, backend}
        }  = getState()

        //Sets +/- 1 on number of ongoing requests to be used to show the loading bar,
        //at the top of the page and the loading spinner icon on the top left
        dispatch(requestStarted())

        //Dispatching action creator
        dispatch(loadArticleRegistryBegin())

        return getArticles(token, backend)
            .then(res => {
                //Sets +/- 1 on number of ongoing requests to be used to show the loading bar,
                //at the top of the page and the loading spinner icon on the top left
                dispatch(requestCompleted())

                //Dispatching action creator with data for payload
                dispatch(loadArticleRegistrySuccess(res.products))

                return res.products
            })
    }
}

export const removeAbsenceType = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteAbsenceTypeBegin())

        return deleteAbsenceType(backend, token, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(deleteAbsenceTypeSuccess())

                return res
            })
    }
}

export const updateAbsenceType = (
        absence_code, 
        active,  
        description, 
        id, 
        title
    ) => {

    return (dispatch, getState) => {

        const {
            app: { token, backend, user }
        } = getState()
        
        
        dispatch(requestStarted())
        dispatch(updateAbsenceTypeBegin())

        return putAbsenceType(
            backend,
            token,
            absence_code,
            active,
            user.EmployeeID,
            description,
            id,
            title,
        ).then((res)=>{

            dispatch(requestCompleted())
            dispatch(updateAbsenceTypeSuccess())

            return res
        })
    }
}

//Dispatch post
export const createAbsenceType = (
        absence_code,
        active,
        description,
        title,
        deleted_at = null
    ) => {

    return (dispatch, getState) => {
        //Destructing token and backend from store
        const {
            app: {token, backend, user}
        }  = getState()

        //Sets +/- 1 on number of ongoing requests to be used to show the loading bar,
        //at the top of the page and the loading spinner icon on the top left
        dispatch(requestStarted())

        //Dispatching action creator
        dispatch(createAbsenceTypeBegin())

        ///Post request
        return postAbsenceType(
            token, 
            backend, 
            absence_code,
            active,
            user.EmployeeID,
            user.EmployeeID,
            deleted_at,
            description,
            title
            )
            .then(res => {

                //Sets +/- 1 on number of ongoing requests to be used to show the loading bar,
                //at the top of the page and the loading spinner icon on the top left
                dispatch(requestCompleted())

                //Dispatching action creator with data for payload
                dispatch(createAbsenceTypeSuccess())

                return res
            })
    }
}

//Dispatch get
export const loadAbsenceTypes = () => {
    console.log("loading absence types")
    return (dispatch, getState) => {
        //Destructing token and backend from store
        const {
            app: {token, backend}
        }  = getState()

        //Sets +/- 1 on number of ongoing requests to be used to show the loading bar,
        //at the top of the page and the loading spinner icon on the top left
        dispatch(requestStarted())

        //Dispatching action creator
        dispatch(loadAbsenceTypeBegin())

        return getAbsence(token, backend)
            .then(res => {
                //Sets +/- 1 on number of ongoing requests to be used to show the loading bar,
                //at the top of the page and the loading spinner icon on the top left
                dispatch(requestCompleted())
                console.log("loaded absence types")
                console.log("response", res)

                //Dispatching action creator with data for payload
                dispatch(loadAbsenceTypeSuccess(res.absence))

                return res.absence
            })
    }
}

interface CustomerObject {
    id?,
    name?,
    description?,
    adress_line1?,
    adress_line2?,
    adress_line3?,
    adress_line4?,
    postal_code?,
    city?,
    country?,
    contact_person?,
    phone_number?,
    lat?,
    lng?,
}

export const updateCustomerObject = (
    id,
    name,
    description,
    adress_line1,
    adress_line2,
    adress_line3,
    adress_line4,
    postal_code,
    city,
    country,
    contact_person,
    phone_number,
    lat,
    long,
    ) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(updateCustomerObjectBegin())

        const {
            app: {token, backend, user}
        } = getState()

        return putCustomerObject(
            backend,
            token,
            id,
            name,
            description,
            adress_line1,
            adress_line2,
            adress_line3,
            adress_line4,
            postal_code,
            city,
            country,
            contact_person,
            phone_number,
            lat,
            long,
            user.EmployeeID
        ).then((res)=>{
            dispatch(requestCompleted())
            dispatch(updateCustomerObjectSuccess())

            return res
        })
    }
}

export const loadCustomerObject = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        }  = getState()

        dispatch(requestStarted())
        dispatch(loadCustomerObjectBegin())
        return getCustomerObject(token, backend, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadCustomerObjectSucces(res.customerobject))
                return res.customerobject
            })
    }
}

export const loadSupplierInvoice = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadSupplierInvoiceBegin())

        return getSupplierInvoices(token, backend, 1, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadSupplierInvoiceSuccess(res))
                return res
            })
    }
}

export const loadSupplierInvoices = (page, search="", sortBy = "", sortDirection = "") => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadSupplierInvoicesBegin())

        return getSupplierInvoices(token, backend, page, undefined, search, sortBy, sortDirection)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadSupplierInvoicesSuccess(res))
            })
    }
}

export const createSupplierInvoice = (Description, InvoiceDate, DueDate, TotalAmount, file) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(createSupplierInvoiceBegin())

        return postSupplierInvoice(token, backend, Description, InvoiceDate, DueDate, TotalAmount, user.EmployeeID, file)
            .then((res) => {
                dispatch(requestCompleted())
                dispatch(createSupplierInvoiceSuccess())
                return res
            })
    }
}

export const updateSupplierInvoice = (id, Description, InvoiceDate, DueDate, TotalAmount, DeleteDocument, document, FortnoxGivenNumber?, FortnoxVoucherNumber?, FortnoxVoucherSeries?) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(updateSupplierInvoiceBegin())

        return putSupplierInvoice(token, backend, id, Description, InvoiceDate, DueDate, TotalAmount, user.EmployeeID, DeleteDocument, document, FortnoxGivenNumber, FortnoxVoucherNumber, FortnoxVoucherSeries)
            .then(() => {
                dispatch(requestCompleted())
                dispatch(updateSupplierInvoiceSuccess())
            })
    }
}

export const removeSupplierInvoice = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteSupplierInvoiceBegin())

        return deleteSupplierInvoice(token, backend, id)
            .then((res) => {
                dispatch(requestCompleted())
                dispatch(deleteSupplierInvoiceSuccess())

                return res
            })
    }
}

export const createProjectFromOffer = (id, ProjectName) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(createProjectFromOfferBegin())

        return postCreateProjectFromOffer(backend, token, id, ProjectName, user.EmployeeID)
            .then((res) => {
                dispatch(requestCompleted())
                dispatch(createProjectFromOfferSuccess())
                return res
            })
    } 
}

export const createSupplierInvoiceProjectRelation = (ProjectID, SupplierInvoiceID, Amount, Charge, AttachToInvoice, ProjectVariationID = null) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(createSupplierInvoiceProjectRelationBegin())

        return postSupplierInvoiceProjectRelation(backend, token, ProjectID, SupplierInvoiceID, Amount, Charge, AttachToInvoice, ProjectVariationID, user.EmployeeID)
            .then(() => {
                dispatch(requestCompleted())
                dispatch(createSupplierInvoiceProjectRelationSuccess())
            })
    } 
}


export const updateSupplierInvoiceProjectRelation = (id, ProjectID, SupplierInvoiceID, Amount, Charge, AttachToInvoice, ProjectVariationID = null) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(updateSupplierInvoiceProjectRelationBegin())

        return putSupplierInvoiceProjectRelation(backend, token, id, ProjectID, SupplierInvoiceID, Amount, Charge, AttachToInvoice, ProjectVariationID, user.EmployeeID)
            .then(() => {
                dispatch(requestCompleted())
                dispatch(updateSupplierInvoiceProjectRelationSuccess())
            })
    } 
}

export const removeSupplierInvoiceProjectRelation = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteSupplierInvoiceProjectRelationBegin())
        return deleteSupplierInvoiceProjectRelation(token, backend, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(deleteSupplierInvoiceProjectRelationSuccess())
                return res
            })
    }
}


export const loadSupplierInvoiceProjectRelations = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadSupplierInvoiceProjectRelationBegin())
        return getSupplierInvoiceProjectRelations(token, backend, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadSupplierInvoiceProjectRelationSuccess(res.supplier_invoice_project_relation))
                return res.supplier_invoice_project_relation
            })
    }
}

export const loadReportedNote = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadReportedNoteBegin())
        return getReportedNote(token, backend, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadReportedNoteSuccess(res.note))
                return res.note
            })
    }
}

export const copyOfferToNewVersion = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(copyOfferToNewVersionBegin())

        return postCopyOfferToNewVersion(backend, token, id, user.EmployeeID)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(copyOfferToNewVersionSuccess())
                return res.offer
            })
    }
}

export const copyOfferToTemplate = (id, TemplateDescription) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(copyOfferToTemplateBegin())

        return postCopyOfferToTemplate(backend, token, id, TemplateDescription, user.EmployeeID)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(copyOfferToTemplateSuccess())
                return res.offer
            })
    }
}


export const loadBookableResources = () => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadBookableResourcesBegin())

        return getBookableResources(token, backend)
            .then((res)=>{
                dispatch(requestCompleted())
                dispatch(loadBookableResourcesSuccess(res))
            })
    }
}

export const loadBookableVehicles = () => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadBookableVehiclesBegin())

        return getBookableVehicles(token, backend)
            .then((res)=>{
                dispatch(requestCompleted())
                dispatch(loadBookableVehiclesSuccess(res))
            })
    }
}

export const loadBookings = (StartTime, EndTime) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadBookingsBegin())

        return getBookings(token, backend, StartTime, EndTime)
            .then((res)=>{
                dispatch(requestCompleted())
                dispatch(loadBookingsSuccess(res))
            })
    }
}

export const createBooking = (StartTime, EndTime, EmployeeCount, ProjectID, ProjectElementID, OnWeekends) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(createBookingBegin())

        return postBooking(token, backend, StartTime, EndTime, EmployeeCount, ProjectID, ProjectElementID, OnWeekends, user.EmployeeID)
            .then((res)=>{
                dispatch(requestCompleted())
                dispatch(createBookingSuccess())

                return res
            })
    }
}


export const updateBooking = (id, StartTime, EndTime, EmployeeCount, ProjectID, ProjectElementID, OnWeekends) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(updateBookingBegin())

        return putBooking(token, backend, id, StartTime, EndTime, EmployeeCount, ProjectID, ProjectElementID, OnWeekends, user.EmployeeID)
            .then(()=>{
                dispatch(requestCompleted())
                dispatch(updateBookingSuccess())
            })
    }
}


export const removeBooking = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(removeBookingBegin())

        return deleteBooking(token, backend, id)
            .then(()=>{
                dispatch(requestCompleted())
                dispatch(removeBookingSuccess())
            })
    }
}

export const createBookingResource = (BookingID, ProductID, EmployeeID) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(createBookingResourceBegin())

        return postBookingResource(token, backend, BookingID, ProductID, EmployeeID, user.EmployeeID)
            .then(()=>{
                dispatch(requestCompleted())
                dispatch(createBookingResourceSuccess())
            })
    }
}

export const updateBookingResource = (id, BookingID, ProductID, EmployeeID) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(updateBookingResourceBegin())

        return putBookingResource(token, backend, id, BookingID, ProductID, EmployeeID, user.EmployeeID)
            .then(()=>{
                dispatch(requestCompleted())
                dispatch(updateBookingResourceSuccess())
            })
    }
}


export const removeBookingResource = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(removeBookingResourceBegin())

        return deleteBookingResource(token, backend, id)
            .then(()=>{
                dispatch(requestCompleted())
                dispatch(removeBookingResourceSuccess())
            })
    }
}


export const removeOfferLink = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteOfferLinkBegin())

        return deleteOfferLink(backend, token, id)
            .then(()=>{
                dispatch(requestCompleted())
                dispatch(deleteOfferLinkSuccess())
            })
    }
}

export const createOfferLink = (OfferID, SendToEmail) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(createOfferLinkBegin())

        return postOfferLink(token, backend, OfferID, SendToEmail, user.EmployeeID)
            .then(()=>{
                dispatch(requestCompleted())
                dispatch(createOfferLinkSuccess())
            })
    }
}

export const loadReportedTime = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadReportedTimeBegin())
        return getReportedTime(token, backend, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadReportedTimeSuccess(res.reported_time))
                return res.reported_time
            })
    }
}

export const loadReportedProduct = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadReportedProductBegin())
        return getReportedProduct(token, backend, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadReportedProductSuccess(res.reported_product))
                return res.reported_product
            })
    }
}

export const loadCompanyLogo = () => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadCompanyLogoBegin())
        return getCompanyLogo(token, backend)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadCompanyLogoSuccess(res.url))
            })
    }
}

export const loadHouseWorkDeductionTypes = () => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadHouseWorkDeductionTypesBegin())

        return getHouseWorkDeductionTypes(token, backend)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadHouseWorkDeductionTypesSuccess(res.houseWorkDeductionTypes))
                return res.houseWorkDeductionTypes
            })
    }    
}

export const createHouseWorkDeductionTypes = (Description, Deduction) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(createHouseWorkDeductionTypesBegin())

        return postHouseWorkDeductionType(token, backend, Description, Deduction, user.EmployeeID)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(createHouseWorkDeductionTypesSuccess())
                return res
            })
    }    
}


export const updateHouseWorkDeductionTypes = (id, Description, Deduction) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(updateHouseWorkDeductionTypesBegin())

        return putHouseWorkDeductionType(token, backend, id, Description, Deduction, user.EmployeeID)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(updateHouseWorkDeductionTypesSuccess())
                return res
            })
    }    
}


export const removeHouseWorkDeductionType = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteHouseWorkDeductionTypesBegin())

        return deleteHouseWorkDeductionType(token, backend, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(deleteHouseWorkDeductionTypesBegin())
                return res
            })
    }    
}

export const removeInvoice = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteInvoiceBegin())
        return deleteInvoice(token, backend, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(deleteInvoiceSuccess())
            })
    }
}

export const loadInvoice = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadInvoiceBegin())
        return getInvoice(token, backend, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadInvoiceSuccess(res))

                return res
            })
    }
}

export const loadInvoices = (projectId?, customerId?) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadInvoicesBegin())
        return getInvoices(token, backend, projectId, customerId)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadInvoicesSuccess(res.invoices))
                
                return res
            })
    }
}

export const createInvoice = (BreakOnDate, ShowTimeType, ShowPriceOnSpecification, ShowComment = true, CustomerID, CustomerObjectID, ProjectID, InvoiceStartDate, InvoiceEndDate, ReportedTimes, ReportedProducts, Notes, SupplierInvoiceProjectRelations, ProjectVariations?, PaymentPlanElements?, invoiceLines?) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(createInvoiceBegin())
        return postInvoice(backend, token, BreakOnDate, ShowTimeType, ShowPriceOnSpecification, ShowComment, CustomerID, CustomerObjectID, ProjectID, InvoiceStartDate, InvoiceEndDate, ReportedTimes, ReportedProducts, Notes, SupplierInvoiceProjectRelations, ProjectVariations, PaymentPlanElements, invoiceLines, user.EmployeeID)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(createInvoiceSuccess())

                return res
            })
    }
}

export const createProjectForm = (projectId, employeeId, name = "", description = "", components?) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(createProjectFormBegin())
        return postProjectForm(backend, token, projectId, employeeId, name, description, user.EmployeeID, components)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(createProjectFormSuccess())

                return res
            })
    }
}

export const loadPaymentPlanElements = ({projectId, offerId, toDate, fromDate, filter}:any) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(loadPaymentPlanElementsBegin())
        return getPaymentPlanElements(token, backend, projectId, offerId, toDate, fromDate, filter)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadPaymentPlanElementsSuccess(res.paymentPlanElements))

                return res.paymentPlanElements
            })
    }
}

export const createPaymentPlanElement = (body: PaymentPlanElement) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(createPaymentPlanElementBegin())
        return postPaymentPlanElement(token, backend, body)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(createPaymentPlanElementSuccess())

                return res
            })
    }
}

export const updatePaymentPlanElement = (id, body: PaymentPlanElement) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(updatePaymentPlanElementBegin())
        return putPaymentPlanElement(token, backend, id, body)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(updatePaymentPlanElementSuccess())

                return res
            })
    }
}

export const removePaymentPlanElement = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(deletePaymentPlanElementBegin())
        return deletePaymentPlanElement(backend, token, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(deletePaymentPlanElementSuccess())

                return res
            })
    }
}

export const createFormTemplate = (projectId, active, name = "", description = "", components?) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(createFormTemplateBegin())
        return postFormTemplate(backend, token, projectId, active, name, description, user.EmployeeID, components)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(createFormTemplateSuccess())

                return res
            })
    }
}

export const removeProjectForm = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteProjectFormBegin())

        return deleteProjectForm(backend, token, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(deleteProjectFormSuccess())

                return res
            })
    }
}

export const removeFormTemplate = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteFormTemplateBegin())

        return deleteFormTemplate(backend, token, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(deleteFormTemplateSuccess())

                return res
            })
    }
}

export const removeProjectAssignment = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteProjectAssignmentBegin())

        return deleteProjectAssignment(backend, token, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(deleteProjectAssignmentSuccess())

                return res
            })
    }
}

export const removeProjectProductAssignment = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(deleteProjectProductAssignmentBegin())

        return deleteProjectProductAssignment(backend, token, id)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(deleteProjectProductAssignmentSuccess())

                return res
            })
    }
}

export const updateProjectForm = (id, employeeId, name = "", description = "", components?) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(updateProjectFormBegin())
        return putProjectForm(backend, token, id, employeeId, name, description, user.EmployeeID, components)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(updateProjectFormSuccess())

                return res
            })
    }
}

interface ProjectForm {
    id, 
    employeeId?: any,
    signatureBy?: any,
    name?: any,
    description?: any,
    components?: any,
}

export const updateProjectForm2 = ({
    id, 
    employeeId,
    signatureBy,
    name,
    description,
    components,
} : ProjectForm) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(updateProjectFormBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return putProjectForm(
            backend, 
            token, 
            id,
            employeeId,
            name,
            description,
            user.EmployeeID,
            components,
            signatureBy,
        ).then(res => {
            dispatch(requestCompleted())
            dispatch(updateProjectFormSuccess())
            return res
        })
    }
}

export const updateFormTemplate = (id, active, name = "", description = "", components?) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend, user}
        } = getState()

        dispatch(requestStarted())
        dispatch(updateFormTemplateBegin())
        return putFormTemplate(backend, token, id, active, name, description, user.EmployeeID, components)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(updateFormTemplateSuccess())

                return res
            })
    }
}

export const removeProject = (id) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(deleteProjectBegin())
        return deleteProject(
                backend, token, id
            ).then((res)=>{
                dispatch(deleteProjectSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const markProjectAsInactive = (id) => {
    
    return (dispatch, getState) => {
        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(markProjectAsInactiveBegin())
        return postUnmarkProjectAsInactive(
                backend, token, id
            ).then((res)=>{
                dispatch(markProjectAsInactiveSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const markProjectAsUninactive = (id) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(markProjectAsUninactiveBegin())
        return postUnmarkProjectAsUninactive(
                backend, token, id
            ).then((res)=>{
                dispatch(markProjectAsUninactiveSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const removeAttest = (id) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(deleteAttestBegin())
        return deleteAttest(
                backend, token, id
            ).then((res)=>{
                dispatch(deleteAttestSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadAttests = ({ReportedTimeID = "", ReportedProductID = "", ReportedAbsenceID = ""}) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadAttestsBegin())
        return getAttests(
                backend, token, ReportedTimeID, ReportedProductID, ReportedAbsenceID
            ).then((res)=>{
                dispatch(loadAttestsSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadAttests2 = () => {
    return (dispatch, getState) => {
        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadAttestsBegin())
        return getAttests(
                backend, token, "", "", ""
            ).then((res)=>{
                dispatch(loadAttestsSuccess(res))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const createAttest = ({ReportedTimeID = "", ReportedProductID = "", ReportedAbsenceID = ""}) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(createAttestBegin())
        return postAttest(
                backend, token, ReportedTimeID, ReportedProductID, ReportedAbsenceID
            ).then((res)=>{
                dispatch(createAttestSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const createAttests = (attests) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend, user },
        } = getState();

        dispatch(requestStarted())
        dispatch(createAttestsBegin())
        return postAttests(
                backend, token, attests.map(attest => ({...attest, EmployeeID: user.EmployeeID, CreatedByID: user.EmployeeID}))
            ).then((res)=>{
                dispatch(createAttestsSuccess())
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadCustomer = (id, hot = false) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        if(!hot) {
            dispatch(loadCustomerBegin())
        }
        return getCustomer(
                token,
                backend,
                id
            ).then((res)=>{
                dispatch(loadCustomerSuccess(res.customer))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const createProjectElement = (Description, ColorCode, EstimatedTimeInHours, FixedPrice, ProjectID, TaskID, ParentProjectElementID) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend, user }
        } = getState()

        dispatch(requestStarted())
        dispatch(createProjectElementBegin())

        return postProjectElement(
            backend, 
            token, 
            Description, 
            ColorCode, 
            EstimatedTimeInHours, 
            FixedPrice, 
            ProjectID, 
            TaskID, 
            ParentProjectElementID, 
            user.EmployeeID
        ).then(()=>{
            dispatch(requestCompleted())
            dispatch(createProjectElementSuccess())
        })
    }
}

export const updateProjectElement = (id, {Description, ColorCode, EstimatedTimeInHours, FixedPrice, ProjectID, TaskID, ParentProjectElementID}) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend, user }
        } = getState()

        dispatch(requestStarted())
        dispatch(updateProjectElementBegin())

        return putProjectElement(
            backend, 
            token, 
            id,
            {
                Description, 
                ColorCode, 
                EstimatedTimeInHours, 
                FixedPrice, 
                ProjectID, 
                TaskID, 
                ParentProjectElementID
            },
            user.EmployeeID
        ).then(()=>{
            dispatch(requestCompleted())
            dispatch(updateProjectElementSuccess())
        })
    }
}

export const removeProjectElement = (id) => {
    return (dispatch, getState) => {
        const {
            app: {token, backend}
        } = getState()

        dispatch(requestStarted())
        dispatch(removeProjectElementBegin())
        return deleteProjectElement(backend, token, id)
            .then(() => {
                dispatch(requestCompleted())
                dispatch(removeProjectElementSuccess())
            })
    }
}

export const exportProjectsToFortnox = () => {
    return (dispatch, getState) => {
        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(exportProjectsToFortnoxBegin())
        return postExportProjectsToFortnox(token, backend)
            .then((res)=>{
                dispatch(exportProjectsToFortnoxSuccess())
                dispatch(requestCompleted())

                return res
            })
            .catch(()=>{
                dispatch(exportProjectsToFortnoxSuccess())
                dispatch(requestCompleted())
            })
    }
}

export const loadAllProjectCounts = (search) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadAllProjectCountsBegin())
        return getProjectCounts(token, backend, search)
            .then((res)=>{
                dispatch(loadAllProjectCountsSuccess(res))
                dispatch(requestCompleted())
            })
    }
}

export const loadProjectCounts = (search, customerId?, projectLeader?) => {
    return (dispatch, getState) => {
        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadProjectCountsBegin())
        return getProjectCounts(token, backend, search, customerId, projectLeader)
            .then((res)=>{
                dispatch(loadProjectCountsSuccess(res))
                dispatch(requestCompleted())
            })
    }
}

export const loadCurrentTenantFeatureFlags = () => {
    return (dispatch, getState) => {
        const {
            app: { token, backend },
        } = getState();

        dispatch(requestStarted())
        dispatch(loadCurrentTenantFeatureFagsBegin())
        return getFeatureFlags(token, backend)
            .then((res)=>{
                dispatch(loadCurrentTenantFeatureFagsSuccess(res.tenantFeatureFlags))
                dispatch(requestCompleted())
            })
    }
}

export const sendAccessToken = (email, companyName) => {
    return (dispatch) => {
        dispatch(sendAccessTokenBegin())
        return postSendToken(email, companyName)
            .then(res => {
                dispatch(sendAccessTokenSuccess())
            })
    }
}

export const checkAccessToken = (accessToken, email) => {
    return (dispatch) => {
        dispatch(checkAccessTokenBegin())
        return postCheckToken(accessToken, email)
            .then(res => {
                dispatch(checkAccessTokenSuccess({
                    checkedEmail: email,
                    doesEmailExist: "yes",
                    checkedAccessToken: accessToken,
                    accessTokenStatus: res.status,
                    checkedAccessTokenTenantName: res.activation ? res.activation.TenantName : null
                }))
            })
    }
}

export const checkLoginDetails = (email, password) => {
    return (dispatch) => {
        dispatch(checkLoginDetailsBegin())

        return new Promise(resolve => {
            postAuthenticateUser(email, password)
                .then(res => {
                    if(res.access_token) {
                        dispatch(checkLoginDetailsSuccess({
                            registerFormUserPassword: password,
                            checkedEmail: email,
                            doesEmailExist: "yes",
                            checkedEmailToken: res.access_token
                        }))
                        resolve("valid")
                    }

                    resolve("invalid")
                })
            })
    }
}

export const checkIfEmailExists = (email, company) => {
    return (dispatch) => {
        dispatch(checkIfEmailExistsBegin())
        return postDoesEmailExist(email)
            .then(res => {
                dispatch(checkIfEmailExistsSuccess({
                    doesEmailExist: res.answer,
                    checkedEmail: email,
                    registerFormCompanyName: company
                }))
                return res
            })
    }
}

export const createUserWithAccessToken = (accessToken, email, firstName = "", lastName = "",password = "", mobileNumber = "") => {
    return (dispatch) => {
        dispatch(createUserWithTokenBegin())
        return postCreateUserWithToken(accessToken, email, firstName, lastName, password, mobileNumber)
            .then((res) => {
                dispatch(createUserWithTokenSuccess())
                return res
            })
    }
}

export const createPipedriveLead = (company, email) => {
    return (dispatch) => {

        dispatch(createPipedriveLeadBegin());

        return postPipedriveLead(company, email)
                    .then(res => {
                        dispatch(createPipedriveLeadSuccess());
                        return res;
                    });
    }
}

export const updatePipedriveLead = (newCompanyName, currentCompanyName, name, email, phone) => {
    return (dispatch) => {

        dispatch(updatePipedriveLeadBegin());

        return putPipedriveLead(newCompanyName, currentCompanyName, name, email, phone)
                    .then(res => {
                        dispatch(updatePipedriveLeadSuccess());
                        return res;
                    });
    }
}

export const createTenantProspect = (token, Name, FirstName = "", LastName = "", SendWelcomeEmail = false) => {
    return (dispatch, getState) => {

        dispatch(createTenantProspectBegin())

        return new Promise(resolve => {
            postTenantProspect(token, Name, FirstName, LastName, SendWelcomeEmail)
                .then(res => {
                    dispatch(createTenantProspectSuccess())
                    resolve(res.tenant.DB_NAME)
                })
        })
    }
}

export const signInUser = (email: string, password: string, tenant_employee_id = "", backend = "", tenant_role = "") => (dispatch) => {
    dispatch(requestStarted());
    return postAuthenticateUser(email, password, tenant_employee_id, backend, tenant_role).then(
            (response) => {

                const token = response.access_token

                if(backend && tenant_employee_id && tenant_role) {
                    dispatch(
                        signInUserSuccess({
                            token,
                            backend
                        })
                    );
                    dispatch(requestCompleted());
                    dispatch(loadUserData());
                    dispatch(navigateHome());
                    return
                }

                getCurrentUserBackends(token).then(res => {
                    const user = res.user
                    if(user.ConnectedTenants && user.ConnectedTenants.length === 1) {
                        const tenant = user.ConnectedTenants[0]
                        dispatch(signInUser(email, password, tenant.TenantEmployeeID, tenant.DB_NAME, tenant.TenantRole))
                        dispatch(requestCompleted());
                    } else if(user.ConnectedTenants && user.ConnectedTenants.length > 1) {
                        dispatch(signInUserSuccessButNeedToPickBackend({
                            token,
                            userConnectedBackends: user.ConnectedTenants
                        }))
                        dispatch(requestCompleted());
                    } else {
                        errorToast("Din MyGizmo profil är inte kopplat till någon miljö")
                        dispatch(requestCompleted());
                    }
                })
            }
        );
};

export const loadUserConnectedBackends = () => {
    return (dispatch, getState) => {
        const {
            app: { token },
        } = getState();

        dispatch(requestStarted());
        getCurrentUserBackends(token).then(res => {
            const user = res.user
            if(user.ConnectedTenants && user.ConnectedTenants.length === 1) {
                const backend = user.ConnectedTenants[0].DB_NAME
                dispatch(
                    signInUserSuccess({
                        token,
                        backend
                    })
                );
                dispatch(requestCompleted());
                dispatch(loadUserData());
            } else if(user.ConnectedTenants && user.ConnectedTenants.length > 1) {
                dispatch(signInUserSuccessButNeedToPickBackend({
                    token,
                    userConnectedBackends: user.ConnectedTenants
                }))
                dispatch(requestCompleted());
            }
        })
    }
}

export const loadUserData = () => {
    return (dispatch, getState) => {
        const {
            app: { backend, token },
        } = getState();
        if (token) {
            dispatch(requestStarted());
            return getCurrentUser(token, backend)
                .then(({ user }) => {
                  
                    if(!user.EmployeeID) {
                        dispatch(requestCompleted());
                        dispatch(clearForm());
                        dispatch(
                            loadUserDataSuccess({
                                user,
                                employee: {}
                            })
                        );
                        return {
                            user,
                            employee: {}
                        }
                    }

                    return getEmployee(token, backend, user.EmployeeID).then(
                        ({ employee }) => {
                            dispatch(requestCompleted());
                            dispatch(clearForm());
                            dispatch(
                                loadUserDataSuccess({
                                    user,
                                    employee,
                                })
                            );
                            return {
                                user,
                                employee,
                            }
                        }
                    );
                });
        } else {
            dispatch(loadUserDataFailed({}));
        }
    };
};

export const resetUserPasswordSuccess = () => ({
    type: types.RESET_USER_PASSWORD_SUCCESS,
});

export const resetUserPasswordFailed = () => ({
    type: types.RESET_USER_PASSWORD_FAILED,
});

export const activateAccountCompleted = (
    status: 'INITIAL' | 'SUCCESS' | 'FAILED'
) => ({
    type: types.ACTIVATE_ACCOUNT_COMPLETED,
    payload: status,
});

export const forgotPassword = (email) => {
    return (dispatch) => {
        dispatch(requestStarted());
        return postForgotPassword(email)
            .then((data) => {
                dispatch(requestCompleted());
                if (data.error) {
                    dispatch(
                        showModal({
                            title: 'Error',
                            text: translateResetPasswordStatus(data.error),
                        })
                    );
                    return dispatch(resetUserPasswordFailed());
                } else {
                    dispatch(
                        showModal({
                            variant: 'success',
                            title: 'Success',
                            text:
                                'Återställningen av lösenordet lyckades! Det har nu skickats ett nytt mail med en aktiveringslänk.'
                        })
                    );
                    dispatch(push('/login'));
                    return dispatch(resetUserPasswordSuccess());
                }
            });
    };
};

export const loadSignature = (id) => {
    return (dispatch, getState) => {
        dispatch(loadSignatureBegin());
        dispatch(requestStarted());
        const {
            app: { token, backend},
        } = getState();
        getSignature(backend, token, id)
            .then((res) => {
                dispatch(requestCompleted());
                dispatch(loadSignatureSuccess(res.signature));
            });
    };
}

export const sendSignatureConfirmation = (id, sendToEmail) => {
    return (dispatch, getState) => {
        dispatch(sendSignatureConfirmationBegin());
        dispatch(requestStarted());
        const {
            app: { token, backend},
        } = getState();
        return postSendSignatureConfirmation(backend, token, id, sendToEmail)
            .then((res) => {
                dispatch(requestCompleted());
                dispatch(sendSignatureConfirmationSuccess());
            });
    };
}

export const checkPasswordResetToken = (email, token) => {
    return (dispatch) => {
        dispatch(requestStarted())
        dispatch(checkPasswordResetTokenBegin())
        return postCheckPasswordResetToken(token, email)
            .then(data => {
                dispatch(requestCompleted())
                dispatch(checkPasswordResetTokenSuccess({
                    checkedResetPasswordTokenStatus: data.status,
                    checkedResetPasswordEmail: email,
                    checkedResetPasswordToken: token
                }))
            })
    }
}

export const resetPasswordWithToken = (password, token, email) => {
    return (dispatch) => {
        dispatch(requestStarted())
        dispatch(resetPasswordBegin())
        return postResetPassword(token, email, password)
            .then(data => {
                dispatch(requestCompleted())
                dispatch(resetPasswordSuccess())
            })
    }
}

export const activateAccount = (activateToken, backend) => {
    return (dispatch) => {
        dispatch(requestStarted());
        getActivateAccount(activateToken, backend).then((result) => {
            dispatch(requestCompleted());
            if (result.error) {
                return dispatch(activateAccountCompleted('FAILED'));
            } else {
                dispatch(setPassword(''));
                dispatch(setEmail(result.user.email));
                return dispatch(activateAccountCompleted('SUCCESS'));
            }
        });
    };
};

export const loadEmployeeBookings = (from, to) => {
    return (dispatch, getState) => {
        dispatch(loadEmployeeBookingsBegin());
        dispatch(requestStarted());
        const {
            app: { token, backend, user },
        } = getState();
        getEmployeeBookings(backend, token, from, to, user.EmployeeID)
            .then((bookings) => {
                dispatch(requestCompleted());
                dispatch(loadEmployeeBookingsSuccess(bookings));
            });
    };
};

export const loadReportsToAttest = (reportFilter = "toAttest", employeeId = "", projectId = "", from = "", to = "") => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(loadReportsToAttestBegin());
        const {
            app: { token, backend, user },
        } = getState();
        const filter = {
            employeeId,
            projectId,
            from,
            to
        }
        getReports(backend, token, filter, reportFilter)
                .then((reports) => {
                    dispatch(requestCompleted());
                    dispatch(loadReportsToAttestSuccess(reports));
                });
    };
}

export const loadReportsForMeToAttest = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(loadReportsForMeToAttestBegin());
        const {
            app: { token, backend, user },
        } = getState();
        const filter = {
            user: user.EmployeeID
        }
        getReports(backend, token, filter, "toAttest")
                .then((reports) => {
                    dispatch(requestCompleted());
                    dispatch(loadReportsForMeToAttestSuccess(reports));
                });
    };
}


export const loadDailyReports = (date) => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(loadDailyReportsBegin());
        const {
            app: { token, backend, user },
        } = getState();
        const filter = {
            from: date,
            to: date,
            employeeId: user.EmployeeID
        }
        getReports(backend, token, filter)
            .then((reports) => {
                dispatch(requestCompleted());
                dispatch(loadDailyReportsSuccess(reports));
            });
    };
};


export const loadReportCounts = (from, to, employeeId?, projectId?, type?, hot = false, customerId?, salaryId?, timeTypeId?) => {
    return (dispatch, getState) => {
        if(!hot) {
            dispatch(requestStarted());
        }
        const {
            app: { token, backend, user },
        } = getState();
        const filter = {
            from: from,
            to: to,
            employeeId: employeeId ? employeeId : user.EmployeeID,
            projectId,
            customerId,
            salaryId,
            timeTypeId,
        }

        if(employeeId === "any") {
            delete filter.employeeId
        }

        return getReports(backend, token, filter, type)
            .then((res) => {
                dispatch(requestCompleted());
                return res
            });
    };
};

export const loadReports = (from, to, employeeId?, projectId?, type?, hot = false, customerId?, salaryId?, onlyApproved?, namespace = "", timeTypeId?) => {
    return (dispatch, getState) => {
        if(!hot) {
            dispatch(requestStarted());
            dispatch(loadReportsBegin(namespace));
        }
        const {
            app: { token, backend, user },
        } = getState();

        const filter = {
            from: from,
            to: to,
            employeeId: employeeId ? employeeId : user.EmployeeID,
            projectId,
            customerId,
            salaryId,
            onlyApproved,
            timeTypeId,
        }

        if(employeeId === "any") {
            delete filter.employeeId
        }

        return getReports(backend, token, filter, type)
            .then((reports) => {
                dispatch(requestCompleted());
                dispatch(loadReportsSuccess(reports, filter, namespace));
                return reports
            });
    };
};

export const loadMyRecentProjects = (showDoneProjects, from, to, search) => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(loadMyRecentProjectsBegin());
        const {
            app: {token, backend, user}
        } = getState();

        getProjects(backend, token, !showDoneProjects ? "myActiveRecentProjects" : "myRecentProjects", search, 1, from, to)
        .then((myRecentProjects) => {
            dispatch(requestCompleted());
            dispatch(loadMyRecentProjectsSuccess(myRecentProjects))
        })
    }
}

export const loadUnits = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadUnitsBegin())

        const {
            app: {token, backend}
        } = getState();

        return getUnits(backend, token)
            .then((res) => {
                dispatch(loadUnitsSuccess(res.units))
                dispatch(requestCompleted())

                return res.units
            })
    }
}

export const loadBookingProject = (id) => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(loadBookingProjectBegin());
        
        const {
            app: {token, backend}
        } = getState();

        return getProject(backend, token, id)
            .then((res) => {
                dispatch(requestCompleted());
                if(res.project) {
                    dispatch(loadBookingProjectSuccess({
                        ...res.project,
                        ...res,
                        project: undefined
                    }))
                }

                return res
            })
    }
}



export const loadProject = (id, hot = false, byCustomSequenceId = false, namespace = "") => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        if(!hot) {
            dispatch(loadProjectBegin(namespace));
        }
        const {
            app: {token, backend}
        } = getState();

        return getProject(backend, token, id, byCustomSequenceId)
            .then((res) => {
                dispatch(requestCompleted());
                if(res.project) {
                    dispatch(loadProjectSuccess({
                        ...res.project,
                        ...res,
                        project: undefined,
                    },
                    namespace,
                    ))
                }

                return res
            })
    }
}

export const loadFormTemplates = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadFormTemplatesBegin())
        const {
            app: {token, backend}
        } = getState();

        return getFormTemplates(token, backend)
            .then((res)=>{
                dispatch(requestCompleted())
                dispatch(loadFormTemplatesSuccess(res))
            })
    }
}

export const createProjectFormFromTemplate = (projectId, formTemplateId, responsee?) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(createProjectFormFromTemplateBegin())
        const {
            app: {token, backend, user}
        } = getState();

        return postProjectFormFromTemplate(backend, token, projectId, formTemplateId, user.EmployeeID, responsee)
            .then(()=>{
                dispatch(requestCompleted())
                dispatch(createProjectFormFromTemplateSuccess())
            })
    }
}


export const loadProjects = (filter, search, page, orderBy = "", customerId?, projectLeader?, from?, to?, hot = false, namespace = "", onlyCountWithApproved = false) => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        if(!hot) {
            dispatch(loadProjectsBegin(search, namespace));
        }
        const {
            app: {token, backend}
        } = getState();

        return getProjects(backend, token, filter, search, page, from, to, orderBy, customerId, projectLeader, onlyCountWithApproved)
        .then((projects) => {
            dispatch(requestCompleted());
            dispatch(loadProjectsSuccess(projects, search, namespace))
            return projects
        })
    }
}

export const loadMoreCustomers = (search, page) => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(loadMoreCustomersBegin());
        const {
            app: {token, backend}
        } = getState();

        getCustomers(token, backend, search, page)
            .then((customers) => {
                dispatch(requestCompleted());
                dispatch(loadMoreCustomersSuccess(customers))
            })
    }
}

export const loadMoreProjects = (filter, search, page, orderBy = "", customerId?, onlyCountWithApproved = false, namespace = "") => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(loadMoreProjectsBegin());
        const {
            app: {token, backend}
        } = getState();

        getProjects(backend, token, filter, search, page, undefined, undefined, orderBy, customerId, undefined, onlyCountWithApproved)
        .then((projects) => {
            dispatch(requestCompleted());
            dispatch(loadMoreProjectsSuccess(projects, namespace))
        })
    }
}

export const loadActiveTimeTypes = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(loadActiveTimeTypesBegin());
        const {
            app: {token, backend}
        } = getState();

        return getActiveTimeTypes(backend, token)
            .then(res => {
                const timeTypes = res.timeType
                dispatch(requestCompleted())
                dispatch(loadActiveTimeTypesSuccess(timeTypes))

                return timeTypes
            })
    }
}

export const createReportedTime = (timeInHours, date, timeTypeId, comment, assignment_id, task_id, project_id, customer_id, employee_id?, sales_price?, cost_price?, salary_time_in_hours?, invoice_time_in_hours?, project_variation_id?) => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(createReportedTimeBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return postReportedTime(backend, token, employee_id || user.EmployeeID, timeInHours, date, user.EmployeeID, timeTypeId, comment, assignment_id, task_id, project_id, customer_id, sales_price, cost_price, salary_time_in_hours, invoice_time_in_hours, project_variation_id)
        .then(() => {
            dispatch(requestCompleted())
            dispatch(createReportedTimeSuccess())
        })
    }
}

export const updateReportedTime = (id, timeInHours, date, timeTypeId, comment, assignment_id, task_id, sales_price?, cost_price?, invoice_time_in_hours?, salary_time_in_hours?, employee_id?, project_id?, project_variation_id?) => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(updateReportedTimeBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return putReportedTime(backend, token, id, employee_id, timeInHours, date, user.EmployeeID, timeTypeId, comment, assignment_id, task_id, sales_price, cost_price, invoice_time_in_hours, salary_time_in_hours, project_id, project_variation_id)
        .then(() => {
            dispatch(requestCompleted())
            dispatch(updateReportedTimeSuccess())
        })
    }
}

export const removeReportedTime = (id) => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(deleteReportedTimeBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return deleteReportedTime(backend, token, id)
        .then(() => {
            dispatch(requestCompleted())
            dispatch(deleteReportedTimeSuccess())
        })
    }
}

export const createReportedProduct = (quantity, date, comment, product_assignment_id, product_id, project_id, customer_id, assignment_id, employee_id?, name?, unit_id?, sales_price?, cost_price?, salary_quantity?, invoice_quantity?, project_variation_id?) => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(createReportedProductBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return postReportedProduct(backend, token, employee_id || user.EmployeeID, quantity, date, user.EmployeeID, comment, product_assignment_id, product_id, project_id, customer_id, assignment_id, name, unit_id, sales_price, cost_price, salary_quantity, invoice_quantity, project_variation_id)
        .then(() => {
            dispatch(requestCompleted())
            dispatch(createReportedProductSuccess())
        })
    }
}

export const updateReportedProduct = (id, quantity, date, comment, product_assignment_id, product_id, assignment_id, sales_price?, cost_price?, invoice_quantity?, salary_quantity?, employee_id?, name?, unit_id?, project_id?, project_variation_id?) => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(updateReportedProductBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return putReportedProduct(backend, token, id, employee_id, quantity, date, user.EmployeeID, comment, product_assignment_id, product_id, assignment_id, sales_price, cost_price, invoice_quantity, salary_quantity, name, unit_id, project_id, project_variation_id)
        .then(() => {
            dispatch(requestCompleted())
            dispatch(updateReportedProductSuccess())
        })
    }
}

export const removeReportedProduct = (id) => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(deleteReportedProductBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return deleteReportedProduct(backend, token, id)
        .then((res) => {
            dispatch(requestCompleted())
            dispatch(deleteReportedProductSuccess())

            return res
        })
    }
}

export const loadTasks = (sortByTitle = false) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadTasksBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return getTasks(backend, token)
        .then(res => {
            dispatch(requestCompleted())

            if(sortByTitle) {
                dispatch(loadTasksComplete(res.data.sort(sortByMultipleKeys(["title", "description"]))))
            } else {
                dispatch(loadTasksComplete(res.data))
            }
                
        })
    }
}

export const loadProducts = (search, page, productKind, hot = false, orderBy = "",) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        if(!hot) {
            dispatch(loadProductsBegin())
        }

        const {
            app: {token, backend, user}
        } = getState();

        return getProducts(backend, token, search, page, productKind, orderBy)
        .then(res => {
            dispatch(requestCompleted())
            dispatch(loadProductsComplete(res.products))

            return res.products
        })
    }
}

export const loadMoreProducts = (search, page, productKind, orderBy = "") => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadMoreProductsBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return getProducts(backend, token, search, page, productKind, orderBy)
        .then(res => {
            dispatch(requestCompleted())
            dispatch(loadMoreProductsComplete(res.products))
        })
    }
}

export const createProduct = (title, description, unitId, productKind) => {

    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(createFreetextProductAssignmentBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return postProduct(backend, token, title, description, unitId, productKind, user.EmployeeID)
            .then(res => {
                if(res.error) {
                    errorToast(res.error.message)
                    dispatch(requestCompleted())
                    dispatch(createFreetextProductAssignmentSuccess())
                    return Promise.reject()
                } else {

                    dispatch(requestCompleted())
                    dispatch(createFreetextProductAssignmentSuccess())

                    return res.product
                }
            })
    }
}

export const createFreetextProductAssignment = (title, description, unitId, productKind, projectId) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(createFreetextProductAssignmentBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return postProduct(backend, token, title, description, unitId, productKind, user.EmployeeID)
            .then(res => {
                if(res.error) {
                    errorToast(res.error.message)
                    dispatch(requestCompleted())
                    dispatch(createFreetextProductAssignmentSuccess())
                    return Promise.reject()
                } else {
                    return postProjectProductAssignment(backend, token, projectId, res.product.id, user.EmployeeID, undefined, undefined, undefined, undefined, undefined)
                        .then(res => {
                            dispatch(requestCompleted())
                            dispatch(createFreetextProductAssignmentSuccess())

                            return res.product_assignment
                        })
                    }
            })
    }
}

export const createTask = (title, description) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(createFreetextProjectAssignmentBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return postTask(backend, token, title, description, user.EmployeeID)
            .then(res => {
                if(res.error) {
                    errorToast(res.error.message)
                    dispatch(requestCompleted())
                    dispatch(createFreetextProjectAssignmentComplete())
                    return Promise.reject()
                } else {
                    dispatch(requestCompleted())
                    dispatch(createFreetextProjectAssignmentComplete())

                    return res.task
                }
            })
    }
}

export const createFreetextProjectAssignment = (title, description, projectId) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(createFreetextProjectAssignmentBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return postTask(backend, token, title, description, user.EmployeeID)
            .then(res => {
                if(res.error) {
                    errorToast(res.error.message)
                    dispatch(requestCompleted())
                    dispatch(createFreetextProjectAssignmentComplete())
                    return Promise.reject()
                } else {
                    return postProjectAssignment(backend, token, projectId, res.task.id, user.EmployeeID, undefined, undefined, undefined, undefined, undefined)
                        .then((res) => {
                            dispatch(requestCompleted())
                            dispatch(createFreetextProjectAssignmentComplete())

                            return res.assignment
                        })
                }
            })
    }
}

interface Assignment {
    projectId, 
    taskId, 
    description?: string, 
    salesPrice?: any, 
    costPrice?: any, 
    estimatedHours?: any, 
    quantity?: any,
    unhandledQuantity?: any,
    toInvoiceQuantity?: any,
    discount?: any, 
    active?: any, 
    id?: any,
}

export const createProjectAssignment = ({
    projectId, 
    taskId, 
    description, 
    salesPrice,
    costPrice,
    estimatedHours,
    quantity,
    unhandledQuantity,
    toInvoiceQuantity,
    discount, 
    active, 
    id,
} : Assignment) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(createProjectAssignmentBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return postProjectAssignment(
            backend, 
            token, 
            projectId, 
            taskId, 
            user.EmployeeID, 
            description, 
            salesPrice, 
            costPrice,
            estimatedHours,
            quantity,
            unhandledQuantity,
            toInvoiceQuantity,
            discount, 
            active, 
            id
        ).then(res => {
            dispatch(requestCompleted())
            dispatch(createProjectAssignmentComplete())
            return res
        })
    }
}

interface ProductAssignment {
    projectId, 
    productId, 
    description?: string, 
    salesPrice?: any, 
    costPrice?: any, 
    estimatedQuantity?: any, 
    quantity?: any,
    unhandledQuantity?: any,
    toInvoiceQuantity?: any,
    discount?: any, 
    active?: any, 
    id?: any,
}

export const createProjectProductAssignment = ({
    projectId, 
    productId, 
    description, 
    salesPrice,
    costPrice,
    estimatedQuantity,
    quantity,
    unhandledQuantity,
    toInvoiceQuantity,
    discount, 
    active, 
    id
} : ProductAssignment) => {

    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(createProjectProductAssignmentBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return postProjectProductAssignment(
            backend, 
            token, 
            projectId, 
            productId, 
            user.EmployeeID, 
            description, 
            salesPrice,
            costPrice,
            estimatedQuantity,
            quantity,
            unhandledQuantity,
            toInvoiceQuantity,
            discount, 
            active, 
            id
        ).then(res => {
            dispatch(requestCompleted())
            dispatch(createProjectProductAssignmentComplete())
            return res
        })
    }
}

export const loadProductAssignmentStats = (productAssignmentId) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadProductAssignmentStatsBegin())

        const {
            app: {token, backend}
        } = getState();

        return getProductAssignmentStats(backend, token, productAssignmentId)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadProductAssignmentStatsComplete(res))
            })
    }
}

export const createDocument = (title, text, mimeType, readAccessLevel, editAccessLevel, document, key, keyId, categoryId?) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(createDocumentBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return postDocument(backend, token, title, text, mimeType, readAccessLevel, editAccessLevel, document, user.EmployeeID, key, keyId, categoryId)
            .then(() => {
                dispatch(requestCompleted())
                dispatch(createDocumentComplete())
            })
    }
}

export const updateDocument = (id, title, text, readAccessLevel, editAccessLevel, categoryId?) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(updateDocumentBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return putDocument(backend, token, id, title, text, readAccessLevel, editAccessLevel, user.EmployeeID, categoryId)
            .then(()=>{
                dispatch(requestCompleted())
                dispatch(updateDocumentComplete())
            })
    }
}

export const removeDocument = (id) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(deleteDocumentBegin())

        const {
            app: {token, backend}
        } = getState();

        return deleteDocument(backend, token, id)
            .then(() => {
                dispatch(requestCompleted())
                dispatch(deleteDocumentComplete())
            })
    }
}

export const createNote = (title, text, date, projectId, isPublic = false, employee_id?) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(createNoteBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return postNote(backend, token, title, text, date, projectId, isPublic, employee_id || user.EmployeeID, user.EmployeeID)
            .then(()=>{
                dispatch(requestCompleted())
                dispatch(createNoteComplete())
            })
    }
}

export const updateNote = (id, title, text, employeeId = "", date = "", projectId = "") => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(updateNoteBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return putNote(backend, token, id, title, text, employeeId, date, projectId, user.EmployeeID)
            .then(()=>{
                dispatch(requestCompleted())
                dispatch(updateNoteComplete())
            })
    }
}

export const removeNote = (id) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(deleteNoteBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return deleteNote(backend, token, id)
            .then((res)=>{
                dispatch(requestCompleted())
                dispatch(deleteNoteComplete())
                return res
            })
    }
}

export const loadDocumentCategories = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadDocumentCategoriesBegin())

        const {
            app: {token, backend, user}
        } = getState()

        return getDocumentCategories(token, backend)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadDocumentCategoriesComplete(res))
            })
    }
}

export const markProjectAsDone = (projectId) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(markProjectAsDoneBegin())

        const {
            app: {token, backend}
        } = getState()

        return postMarkProjectAsDone(backend, token, projectId)
            .then(() => {
                dispatch(requestCompleted())
                dispatch(markProjectAsDoneComplete())
            })
    }
}

export const markProjectAsUndone = (projectId) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(markProjectAsUndoneBegin())

        const {
            app: {token, backend}
        } = getState()

        return postMarkProjectAsUndone(backend, token, projectId)
            .then(() => {
                dispatch(requestCompleted())
                dispatch(markProjectAsUndoneComplete())
            })
    }
}

export const createReportedAbsence = (timeInHours, date, comment, absenceId, employeeId?) => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(createReportedAbsenceBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return postReportedAbsence(backend, token, employeeId || user.EmployeeID, timeInHours, date, user.EmployeeID, comment, absenceId)
        .then(() => {
            dispatch(requestCompleted())
            dispatch(createReportedAbsenceSuccess())
        })
    }
}

export const updateReportedAbsence = (id, timeInHours, comment, date?, employeeid?, absenceid?) => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(updateReportedAbsenceBegin())

        const {
            app: {token, backend, user}
        } = getState();

        return putReportedAbsence(backend, token, id, timeInHours, user.EmployeeID, comment, date, employeeid, absenceid)
        .then(() => {
            dispatch(requestCompleted())
            dispatch(updateReportedAbsenceSuccess())
        })
    }
}

export const removeReportedAbsence = (id) => {
    return (dispatch, getState) => {
        dispatch(requestStarted());
        dispatch(deleteReportedAbsenceBegin())

        const {
            app: {token, backend}
        } = getState();

        return deleteReportedAbsence(backend, token, id)
        .then(() => {
            dispatch(requestCompleted())
            dispatch(deleteReportedAbsenceSuccess())
        })
    }
}

export const loadActiveAbsenceTypes = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadActiveAbsenceTypesBegin())

        const {
            app: {token, backend}
        } = getState()

        return getActiveAbsenceTypes(token, backend)
            .then(res => {
                dispatch(requestCompleted())
                dispatch(loadActiveAbsenceTypesComplete(res.absence))
            })
    }
}

export const signOutAction = () => {
    return (dispatch, getState) => {
        localStorage.removeItem('token');
        localStorage.removeItem('backend');
        dispatch(signOut())
        dispatch(push("/login"))
    }
}

export const loadSettings = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadSettingsBegin())

        const {
            app: {token, backend}
        } = getState()

        return getSettings(backend, token)
            .then(res => {
                dispatch(loadSettingsComplete(res.settings))
                dispatch(requestCompleted())
                return res.settings
            })
    }
}

export const loadProjectForms = (projectId = null, hot = false) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        if(!hot) {
            dispatch(loadProjectFormsBegin())
        }

        const {
            app: {token, backend, user}
        } = getState()

        return getProjectForms(backend, token, (projectId ? null : user.EmployeeID), !projectId, projectId)
            .then(res => {
                dispatch(loadProjectFormsComplete(res))
                dispatch(requestCompleted())
            })
    }
}

export const loadCustomerObjects = (customerId) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadCustomerObjectsBegin())

        const {
            app: {token, backend}
        } = getState()

        return getCustomerObjects(token, backend, customerId)
            .then(res => {
                dispatch(loadCustomerObjectsComplete(res.data))
                dispatch(requestCompleted())
                return res
            })
    }
}

export const loadCustomers = (query, page:any = 1, filter = "active", sortField = "",  sortOrder = "") => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadCustomersBegin(query))

        const {
            app: {token, backend}
        } = getState()

        return getCustomers(token, backend, query, page, filter, sortField, sortOrder)
            .then(res => {
                dispatch(loadCustomersComplete(res, query))
                dispatch(requestCompleted())

                return res
            })
    }
}

export const loadTodos = () => {

    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadTodosBegin())

        const {
            app: { token, backend, user, settings },
        } = getState();
        const filter = {
            user: user.EmployeeID
        }

        const promises: any = []

        if(settings.UseAttest === "true") {
            promises.push(getReports(backend, token, filter, "toAttest"))
        } else {
            promises.push(new Promise(resolve => {resolve({times:[], products: [], notes: []})}))
        }

        promises.push(getProjectForms(backend, token, user.EmployeeID, true))

        const toDate = moment().add(settings.DaysBeforePaymentPlanDueDateWarning, 'days').format('YYYY-MM-DD');
        promises.push(getPaymentPlanElements(
            token,
            backend,
            undefined,
            undefined,
            toDate,
            undefined,
            "unhandled",
        ))

        Promise.all(promises).then((res: any) => {

            let todos = 0;

            if(res.length > 0) {
                todos += res[0].times.length
                todos += res[0].products.length
                todos += res[0].notes.length

                if(res[1]) {
                    let projectForms = res[1];
                    projectForms = filterUserForms(projectForms, user);
                    todos += projectForms.length;
                }
                if(res[2]){
                    todos += res[2].paymentPlanElements.length
                }
            }

            dispatch(loadTodosComplete(todos))
            dispatch(requestCompleted())
        })
    }
}

interface Customer {
    type?,
    name?,
    organisation_number?,
    middle_name?,
    last_name?,
    e_mail?,
    phone_number?,
    adress_line1?,
    adress_line2?,
    adress_line3?,
    adress_line4?,
    postal_code?,
    city?,
    country?,
    contact_person?,
    erp_customer_id?,
    lat?,
    long?,
}

export const createCustomer = ({
    type,
    name,
    organisation_number,
    middle_name,
    last_name,
    e_mail,
    phone_number,
    adress_line1,
    adress_line2,
    adress_line3,
    adress_line4,
    postal_code,
    city,
    country,
    contact_person,
    erp_customer_id,
    lat,
    long,
} : Customer) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(createCustomerBegin())

        const {
            app: {token, backend, user}
        } = getState()

        return postCustomer(
            backend,
            token,
            type,
            name,
            organisation_number,
            middle_name,
            last_name,
            e_mail,
            phone_number,
            adress_line1,
            adress_line2,
            adress_line3,
            adress_line4,
            postal_code,
            city,
            country,
            contact_person,
            erp_customer_id,
            user.EmployeeID,
            lat,
            long,
        )
        .then((res)=>{
            if(res.customer) {
                return postCustomerObject(backend, token, res.customer.id, "HuvudAdress", null, null, null, null, null, null, null, null, null, null, null, null, user.EmployeeID)
                    .then(() => {
                        dispatch(requestCompleted())
                        dispatch(createCustomerComplete())
                        return res
                    })
            } else {
                dispatch(requestCompleted())
                dispatch(createCustomerComplete())
                return res
            }
        })
    }
}
interface CreateCustomerObject {
    customer_id?,
    name?,
    description?,
    adress_line1?,
    adress_line2?,
    adress_line3?,
    adress_line4?,
    postal_code?,
    city?,
    country?,
    contact_person?,
    phone_number?,
    lat?,
    long?,
}
export const createCustomerObject = ({
    customer_id,
    name,
    description,
    adress_line1,
    adress_line2,
    adress_line3,
    adress_line4,
    postal_code,
    city,
    country,
    contact_person,
    phone_number,
    lat,
    long,
} : CreateCustomerObject) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(createCustomerObjectBegin())

        const {
            app: {token, backend, user}
        } = getState()

        return postCustomerObject(
            backend,
            token,
            customer_id,
            name,
            description,
            adress_line1,
            adress_line2,
            adress_line3,
            adress_line4,
            postal_code,
            city,
            country,
            contact_person,
            phone_number,
            lat,
            long,
            user.EmployeeID
        ).then((res)=>{
            dispatch(requestCompleted())
            dispatch(createCustomerObjectComplete())

            return res
        })
    }
}

interface CreateProject {
    name?,
    description?,
    customer_id?,
    start_date?,
    end_date?,
    internal_information?,
    project_type?,
    estimated_hours?,
    fixed_price?,
    project_leader?,
    need_customer_signature?,
    customer_object_id?,
    has_external_project_leader?,
    external_project_leader_name?,
    property_code?,
    erp_customer_id?,
    erp_project_id?,
    erp_order_number?,
    project_name?,
    ShowProjectInMap?,
    onlyShowProjectForSelectedEmployees?,
}

export const createProject = ({
    name,
    description,
    customer_id,
    start_date,
    end_date,
    internal_information,
    project_type,
    estimated_hours,
    fixed_price,
    project_leader,
    need_customer_signature,
    customer_object_id,
    has_external_project_leader,
    external_project_leader_name,
    property_code,
    erp_customer_id = "",
    erp_project_id = "",
    erp_order_number = "",
    project_name = "",
    ShowProjectInMap,
    onlyShowProjectForSelectedEmployees,
} : CreateProject) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(createProjectBegin())
        
        const {
            app: {token, backend, user}
        } = getState()

        return postProject(
            backend,
            token,
            name,
            description,
            customer_id,
            start_date,
            end_date,
            internal_information,
            project_type,
            estimated_hours,
            fixed_price,
            project_leader,
            need_customer_signature,
            customer_object_id,
            has_external_project_leader,
            external_project_leader_name,
            property_code,
            erp_customer_id,
            erp_project_id,
            erp_order_number,
            project_name,
            user.EmployeeID,
            ShowProjectInMap,
            onlyShowProjectForSelectedEmployees,
        ).then((res) => {
            dispatch(requestCompleted())
            dispatch(createProjectComplete())

            return res
        })
    }
}

export const importSingleFortnoxDataToMyGizmo = (key, id) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(importSingleFortnoxDataToMyGizmoBegin())

        const {
            app: {token, backend, user}
        } = getState()

        return postImportSingleDataToMyGizmo(token, backend, key, id, user.EmployeeID)
            .then(res => {
                dispatch(importSingleFortnoxDataToMyGizmoSuccess())
                dispatch(requestCompleted())
                return res
            })
    }
}

export const importFortnoxDataToMyGizmo = (key) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(importFortnoxDataToMyGizmoBegin())

        const {
            app: {token, backend, user}
        } = getState()

        return postImportDataToMyGizmo(token, backend, key, user.EmployeeID)
            .then(res => {
                dispatch(importFortnoxDataToMyGizmoSuccess())
                dispatch(requestCompleted())
                return res
            })
    }
}

export const loadFortnoxArticles = (page, sortBy = "articlenumber", sortOrder = "descending", searchBy = "description", search = "") => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadFortnoxArticlesBegin())

        const {
            app: {token, backend}
        } = getState()

        return getFortnoxArticles(token, backend, page, sortBy, sortOrder, searchBy, search)
            .then(res => {
                dispatch(loadFortnoxArticlesSuccess(res))
                dispatch(requestCompleted())
                return res
            })
    }
}

export const loadFortnoxArticleUnits = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadFortnoxArticleUnitsBegin())

        const {
            app: {token, backend}
        } = getState()

        return getFortnoxArticleUnits(token, backend)
            .then(res => {
                dispatch(loadFortnoxArticleUnitsSuccess(res.articleUnits))
                dispatch(requestCompleted())
                return res.articleUnits
            })
    }
}

export const loadFortnoxArticleUnitGroups = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadFortnoxArticleUnitGroupsBegin())

        const {
            app: {token, backend}
        } = getState()

        return getFortnoxArticleUnitGroups(token, backend)
            .then(res => {
                dispatch(loadFortnoxArticleUnitGroupsSuccess(res))
                dispatch(requestCompleted())
                return res
            })
    }
}

export const updateFortnoxArticleUnitGroups = (unitGroups) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(updateFortnoxArticleUnitGroupsBegin())

        const {
            app: {token, backend, user}
        } = getState()

        return postFortnoxArticleUnitGroups(backend, token, unitGroups, user.EmployeeID)
            .then(res => {
                dispatch(updateFortnoxArticleUnitGroupsSuccess())
                dispatch(requestCompleted())
                return res
            })
    }
}

export const importFortnoxSupplierInvocies = (financialYearDate) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(importFortnoxSupplierInvociesBegin())

        const {
            app: {token, backend, user}
        } = getState()

        return postSyncSupplierInvoicesFromFortnox(token, backend, financialYearDate, user.EmployeeID)
            .then(res => {
                dispatch(importFortnoxSupplierInvociesSuccess())
                dispatch(requestCompleted())
                return res
            })
    }  
}

export const loadFortnoxVoucherSeries = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadFortnoxVoucherSeriesBegin())

        const {
            app: {token, backend}
        } = getState()

        return getFortnoxVoucherSeries(token, backend)
            .then(res => {
                dispatch(loadFortnoxVoucherSeriesSuccess(res))
                dispatch(requestCompleted())
                return res
            })
    }  
}

export const loadFortnoxFinancialYears = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadFortnoxFinancialYearsBegin())

        const {
            app: {token, backend}
        } = getState()

        return getFortnoxFinancialYears(token, backend)
            .then(res => {
                dispatch(loadFortnoxFinancialYearsSuccess(res))
                dispatch(requestCompleted())
                return res
            })
    }  
}

export const loadFortnoxSupplierInvoices = (page, financialYearDate, sortBy = "transactiondate", sortOrder = "descending", search = "") => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadFortnoxSupplierInvoicesBegin())

        const {
            app: {token, backend}
        } = getState()

        return getFortnoxSupplierInvoices(token, backend, page, financialYearDate, sortBy, sortOrder, search)
            .then(res => {
                dispatch(loadFortnoxSupplierInvoicesSuccess(res))
                dispatch(requestCompleted())
                return res
            })
    }  
}

export const loadFortnoxStatus = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadFortnoxStatusBegin())

        const {
            app: {token, backend}
        } = getState()

        return getFortnoxStatus(token, backend)
            .then(res => {
                dispatch(loadFortnoxStatusSuccess(res.status))
                dispatch(requestCompleted())
                return res.status
            })
    }
}

export const loadEaccountingStatus = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadEaccountingStatusBegin())

        const {
            app: {token, backend}
        } = getState()

        return getEaccountingStatus(token, backend)
            .then(res => {
                dispatch(loadEaccountingStatusSuccess(res.status))
                dispatch(requestCompleted())
                return res.status
            })
    }
}


export const importSingleEaccountingDataToMyGizmo = (key, id) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(importSingleEaccountingDataToMyGizmoBegin())

        const {
            app: {token, backend, user}
        } = getState()

        return postImportSingleEaccountingDataToMyGizmo(token, backend, key, id, user.EmployeeID)
            .then(res => {
                dispatch(importSingleEaccountingDataToMyGizmoSuccess())
                dispatch(requestCompleted())
                return res
            })
    }
}

export const importSelectedEaccountingDataToMyGizmo = (type, ids) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(importSingleEaccountingDataToMyGizmoBegin())

        const {
            app: {token, backend, user}
        } = getState()

        return postImportSelectedEaccountingDataToMyGizmo(token, backend, type, ids, user.EmployeeID)
            .then(res => {
                dispatch(importSingleEaccountingDataToMyGizmoSuccess())
                dispatch(requestCompleted())
                return res
            })
    }
}

export const importEaccountingDataToMyGizmo = (key) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(importEaccountingDataToMyGizmoBegin())

        const {
            app: {token, backend, user}
        } = getState()

        return postImportEaccountingDataToMyGizmo(token, backend, key, user.EmployeeID)
            .then(res => {
                dispatch(importEaccountingDataToMyGizmoSuccess())
                dispatch(requestCompleted())
                return res
            })
    }
}

export const loadEaccountingArticles = (page, sortBy = "Name", search = "") => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadEaccountingArticlesBegin())

        const {
            app: {token, backend}
        } = getState()

        return getEaccountingArticles(token, backend, page, sortBy, search)
            .then(res => {
                dispatch(loadEaccountingArticlesSuccess(res))
                dispatch(requestCompleted())
                return res
            })
    }
}

export const loadEaccountingArticleUnits = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadEaccountingArticleUnitsBegin())

        const {
            app: {token, backend}
        } = getState()

        return getEaccountingArticleUnits(token, backend)
            .then(res => {
                dispatch(loadEaccountingArticleUnitsSuccess(res.articleUnits))
                dispatch(requestCompleted())
                return res.articleUnits
            })
    }
}

export const loadEaccountingArticleUnitGroups = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadEaccountingArticleUnitGroupsBegin())

        const {
            app: {token, backend}
        } = getState()

        return getEaccountingArticleUnitGroups(token, backend)
            .then(res => {
                dispatch(loadEaccountingArticleUnitGroupsSuccess(res))
                dispatch(requestCompleted())
                return res
            })
    }
}

export const loadEaccountingOrders = (page, sortBy = "Number", search = "") => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadEaccountingOrdersBegin())

        const {
            app: {token, backend}
        } = getState()

        return getEaccountingOrders(token, backend, page, sortBy, search)
            .then(res => {
                dispatch(loadEaccountingOrdersSuccess(res))
                dispatch(requestCompleted())
                return res
            })
    }
}

export const loadEaccountingCustomers = (page, sortBy = "CustomerNumber", search = "") => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadEaccountingCustomersBegin())

        const {
            app: {token, backend}
        } = getState()

        return getEaccountingCustomers(token, backend, page, sortBy, search)
            .then(res => {
                dispatch(loadEaccountingCustomersSuccess(res))
                dispatch(requestCompleted())
                return res
            })
    }
}

export const loadFortnoxOrders = (page = 1, sortBy = "documentnumber", sortOrder = "descending", search = "") => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadFortnoxOrdersBegin())

        const {
            app: {token, backend}
        } = getState()

        return getFortnoxOrders(token, backend, page, sortBy, sortOrder, search)
            .then(res => {
                dispatch(loadFortnoxOrdersSuccess(res))
                dispatch(requestCompleted())
                return res
            })
    }
}

export const loadFortnoxCustomers = (page = 1, sortBy = "customernumber", sortOrder = "descending", search = "") => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadFortnoxCustomersBegin())

        const {
            app: {token, backend}
        } = getState()

        return getFortnoxCustomers(token, backend, page, sortBy, sortOrder, search)
            .then(res => {
                dispatch(loadFortnoxCustomersSuccess(res))
                dispatch(requestCompleted())
                return res
            })
    }
}

export const updateEaccountingArticleUnitGroups = (unitGroups) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(updateEaccountingArticleUnitGroupsBegin())

        const {
            app: {token, backend, user}
        } = getState()

        return postEaccountingArticleUnitGroups(backend, token, unitGroups, user.EmployeeID)
            .then(res => {
                dispatch(updateEaccountingArticleUnitGroupsSuccess())
                dispatch(requestCompleted())
                return res
            })
    }
}

export const activateCustomer = (customerId, revokeSoftDelete = false) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(activateCustomerBegin())

        const {
            app: {token, backend}
        } = getState()

        return getActivateCustomer(token, backend, customerId, revokeSoftDelete)
            .then(res => {
                dispatch(activateCustomerComplete())
                dispatch(requestCompleted())
                return res
            })
    }
}

export const loadEmployees = () => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadEmployeesBegin())

        const {
            app: {token, backend}
        } = getState()

        return getEmployees(token, backend)
            .then(res => {

                dispatch(loadEmployeesComplete(res.data))
                dispatch(requestCompleted())

                return res.data
            });
    }
}

export const loadReportedAbsence = (id) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(loadReportedAbsenceBegin())

        const {
            app: {token, backend}
        } = getState()

        return getReportedAbsence(token, backend, id)
            .then(res => {
                dispatch(loadReportedAbsenceSuccess(res.reported_absence))
                dispatch(requestCompleted())

                return res.reported_absence
            });
    } 
}

 
export const createInvoiceInEaccounting = (invoiceId, invoiceRows, blob?) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(createInvoiceInEaccountingBegin())

        const {
            app: {token, backend}
        } = getState()

       return postInvoiceInEaccounting(token, backend, invoiceId, invoiceRows, blob)
            .then(res => {
                dispatch(createInvoiceInEaccountingSuccess())
                dispatch(requestCompleted())
                return res
            });
    }
}
 
export const createInvoiceInFortnox = (invoiceId, invoiceRows, blob?) => {
    return (dispatch, getState) => {
        dispatch(requestStarted())
        dispatch(createInvoiceInFortnoxBegin())

        const {
            app: {token, backend, settings}
        } = getState()

        const addProjectName = settings.FortnoxExportProjectInfoToInvoiceAs || false

       return postInvoiceInFortnox(token, backend, invoiceId, invoiceRows, addProjectName, blob)
            .then(res => {
                dispatch(createInvoiceInFortnoxSuccess())
                dispatch(requestCompleted())
                return res
            });
    }
}

export const navigateHome = (date = "") => {
    return (dispatch, getState) => {
        dispatch(loadReportsBegin())
        dispatch(loadEmployeeBookingsBegin())
        if(date) {
            dispatch(push('/h/' + date))
        } else {
            dispatch(push('/'));
        }
    }
}

export const navigateToEditProjectDate = (date, projectId, from?) => {
    return (dispatch, getState) => {
        dispatch(loadActiveTimeTypesBegin())
        dispatch(loadProjectBegin())
        dispatch(loadReportsBegin())
        if(from) {
            dispatch(push('/edit/' + date + "/project/" + projectId, { from }));
        } else {
            dispatch(push('/edit/' + date + "/project/" + projectId));
        }

    }
}

export const navigateToEditAbsence = (date) => {
    return (dispatch) => {
        dispatch(loadReportsBegin())
        dispatch(loadActiveAbsenceTypesBegin())

        dispatch(push("/absence/" + date));
    }
}

export const navigateToCopyYesterdayReports = (date) => {
    return (dispatch) => {
        dispatch(loadReportsBegin())
        dispatch(push("/copy/" + date));
    }
}

export const navigateToTodos = () => {
    return (dispatch) => {
        dispatch(loadReportsBegin())
        dispatch(push("/todo"))
    }
}
